import * as React from "react";
import { Link } from "react-router-dom";
import { RouteStrings } from "routes/RouteStrings";
import i18n from "i18n/i18n";
import { currencyLocaleFormatter } from "formatters/currencyFormatters";
import { formatDateForTable } from "helpers/timeHelpers";

const STATUSES = [
    "open",
    "rejected",
    "complete",
    "acquiring_offers",
    "in_manual_check",
    "approved_waiting_kyc",
    "approved_waiting_shipping",
    "approved_booked",
    "approved_waiting_documents",
    "in_cancellation",
    "cancelled_by_bank",
    "cancelled_by_merchant",
    "expired",
    "closed",
];

export const columns = [
    {
        title: i18n.t("tables:applicationId"),
        dataIndex: "short_id",
        key: "short_id",
    },

    {
        title: i18n.t("tables:createdAt"),
        dataIndex: "created_at",
        render: (_, appl) => {
            return formatDateForTable(appl?.created_at);
        },
        sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    },
    {
        title: i18n.t("tables:amount"),
        dataIndex: "amount",
        sorter: (a, b) => Number.parseInt(a.amount) - Number.parseInt(b.amount),
        render: (_, appl) => {
            return currencyLocaleFormatter(appl?.amount);
        },
    },
    {
        title: i18n.t("tables:durationMonths"),
        dataIndex: "duration_months",
        sorter: (a, b) => Number.parseInt(a.duration_months) - Number.parseInt(b.duration_months),
    },
    {
        title: i18n.t("tables:status"),
        dataIndex: "status",
        render: (value) => (value ? i18n.t(`applicationStates:${value}`) : "-"),
        filters: STATUSES.map((status) => {
            return {
                text: i18n.t(`applicationStates:${status}`),
                value: status,
            };
        }),
        onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
        title: "",
        dataIndex: "lastCol",
        key: "lastCol",
        render: (text, record) => {
            let link = RouteStrings.MerchantApplicationBase + record.application_id;

            return <Link to={link}>{i18n.t("tables:view")}</Link>;
        },
    },
];
