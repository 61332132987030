import { ApiWrapper } from "api/ApiWrapper";
import { ApiResponse, ApplicationAction, CreateClientObject, LogoPutRequest, LogoRequest, OfferResponse } from "api/types";
import { UpdateClient } from "api/types/client";

export const adminGetApplications = async (page: number, searchStr?: string): Promise<any> => {
    let path;

    if (searchStr) {
        path = `/admin/applications?text=${searchStr}&page=${page}`;
    } else {
        path = `/admin/applications?page=${page}`;
    }

    return ApiWrapper.get(path) as Promise<any>;
};

export const adminGetApplication = async (id: string): Promise<any> => {
    const path = `/admin/applications/${id}`;

    return ApiWrapper.get(path) as Promise<any>;
};

export const adminGetApplicationPaymentLink = async (id: string): Promise<any> => {
    const path = `/admin/applications/${id}/payment_link`;

    return ApiWrapper.get(path) as Promise<any>;
};

export const adminGetClients = async (page: number, searchStr?: string): Promise<any> => {
    let path;

    if (searchStr) {
        path = `/admin/clients?text=${searchStr}&page=${page}`;
    } else {
        path = `/admin/clients?page=${page}`;
    }

    return ApiWrapper.get(path) as Promise<any>;
};

export const adminGetClient = async (clientId: string): Promise<any> => {
    const path = `/admin/clients/${clientId}`;

    return ApiWrapper.get(path);
};

export const adminCreateClient = async (client: CreateClientObject): Promise<any> => {
    const path = `/admin/clients`;

    return ApiWrapper.post(path, client);
};

export const adminUpdateClient = async (client_id: string, client: UpdateClient): Promise<any> => {
    const path = `/admin/clients/${client_id}`;

    return ApiWrapper.put(path, client);
};

export const adminCreateClientApplication = async (clientId: string, application: any): Promise<any> => {
    const path = `/admin/clients/${clientId}/new_application`;

    return ApiWrapper.post(path, application);
};

export const adminCheckRegisterTokenValid = async (registerToken: string): Promise<ApiResponse<void>> => {
    const path = `/admin/account/invites/${registerToken}`;

    return ApiWrapper.get(path, true);
};

export const adminRegisterAccount = async (registerToken: string, newPassword: string): Promise<ApiResponse<void>> => {
    const path = `/admin/account/register`;

    const body = {
        password: newPassword,
        token: registerToken,
    };

    return ApiWrapper.post(path, body, true);
};

export const logoutAdmin = async (): Promise<any> => {
    const path = `/admin/account/logout`;

    return ApiWrapper.post(path, {});
};

export const adminConfirmShipping = async (applicationId: string): Promise<any> => {
    const path = `/admin/applications/${applicationId}/confirm_shipping`;

    return ApiWrapper.post(path, {});
};



export const adminChangeApplicationStates = async (
    applicationId: string,
    action: ApplicationAction
): Promise<any> => {
    const path = `/admin/applications/${applicationId}/action`;

    const body = {
        id: applicationId,
        action: action,
    };

    return ApiWrapper.post(path, body);
};

export const adminGetMerchantOriginalLogo = async (merchantId: string): Promise<ApiResponse<LogoRequest>>  => {
    const path = `/admin/merchants/${merchantId}/original_logo`;

    return ApiWrapper.get(path);
};
export const adminUploadMerchantOriginalLogo = async (merchantId, uri: ArrayBuffer | string): Promise<ApiResponse<LogoPutRequest>>  => {
    const path = `/admin/merchants/${merchantId}/original_logo`;

    return ApiWrapper.put(path,{image_data_uri:uri});
};




export const admintGetSelectedOffer = async (applicationId: string): Promise<ApiResponse<OfferResponse>> => {
    const path = `/admin/applications/${applicationId}/selected_offer`;

    return ApiWrapper.get(path);
};