import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Spin } from "antd";
import { DATE_FORMAT } from "api/apiConfig";
import styles from "components/common/pages/application-flow/ApplicationFlow.module.scss";
import { AnchoredSelect } from "components/common/presenters/anchored-select/AnchoredSelect";
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { BackButton } from "components/common/presenters/back-button/BackButton";
import { CustomPageHeader } from "components/common/presenters/custom-page-header/CustomPageHeader";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { isAdminApp, isClientApp, isMerchantApp } from "helpers/appHelpers";
import { checkAuth, checkAuthMerchant } from "helpers/authHelpers";
import hashHistory from "helpers/hashHistory";
import history from "helpers/history";
import { pageLoad } from "helpers/pageLoad";
import { preventStepSkip } from "helpers/preventStepSkip";
import { isDateOlderThanTwoYears } from "helpers/timeHelpers";
import useMobileCheck from "helpers/useMobileCheck";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteStrings } from "routes/RouteStrings";
import { setLastSuccessPage } from "storage/actions/appActions";
import { AppContext } from "storage/context/appContext";
import { LocalApplicationFlowDataManager } from "storage/LocalApplicationFlowDataManager";
import { MaritalStatusesList } from "types/applicationProcess";
import { countriesDe, countriesEn } from "types/countries";
import { getPostCodeValidationPattern } from "validators/inputs";
import OrderSummary from "../summary/OrderSummary";

const { Option } = Select;

export const ApplicationStepTwoPage = () => {
    const { t, i18n } = useTranslation();

    const [form] = Form.useForm();
    const [loading] = React.useState(false);
    const [checkingLoggedIn, setCheckingLoggedIn] = React.useState(false);
    const [generalError, setGeneralError] = React.useState("");
    const [{ lastSuccessPage }, dispatch] = React.useContext(AppContext);
    const [state, setState] = React.useState({
        salutation: "",
        first_name: "",
        last_name: "",
        date_of_birth: "",
        place_of_birth: "",
        resident_since: "",
        phone: "",
        mobile_number: "",
    });
    const isMobile = useMobileCheck();

    const applDataManager = new LocalApplicationFlowDataManager();
    const appl = applDataManager.get();
    React.useEffect(() => {
        if (isClientApp() || isAdminApp()) {
            checkAuth(setCheckingLoggedIn, null, () => {
                hashHistory.push(RouteStrings.ApplicationFlowStepOne);
            });
        } else {
            checkAuthMerchant(setCheckingLoggedIn, null, () => {
                history.push(RouteStrings.Login);
            });
        }

        pageLoad(dispatch, RouteStrings.ApplicationFlowStepTwo);

        preventStepSkip(lastSuccessPage, hashHistory);
    }, []);

    const [showExtraAddressData, setShowExtraAddressData] = React.useState<boolean>(false);

    const persistData = (values: any) => {
        const applDataManager = new LocalApplicationFlowDataManager();

        const appl = applDataManager.get();

        appl.personal_info.salutation = values.salutation;
        appl.personal_info.first_name = values.first_name;
        appl.personal_info.last_name = values.last_name;
        appl.personal_info.date_of_birth =   moment(values.date_of_birth == "" ? undefined : values.date_of_birth).format(DATE_FORMAT.API);
        appl.personal_info.place_of_birth = values.place_of_birth;
        appl.personal_info.nationality = values.nationality;
        appl.personal_info.marital_status = values.marital_status;
        appl.address_info.street_name = values.street_name;
        appl.address_info.street_number = values.street_number;
        appl.address_info.post_code = values.post_code;
        appl.address_info.city = values.city;
        appl.address_info.country = values.country;
        appl.address_info.resident_since =moment(values.resident_since == "" ? undefined : values.resident_since).format(DATE_FORMAT.API);

        if (isDateOlderThanTwoYears(state.resident_since)) {
            appl.address_info.previous_address.street_name = values.prev_street_name;
            appl.address_info.previous_address.street_number = values.prev_street_number;
            appl.address_info.previous_address.post_code = values.prev_post_code;
            appl.address_info.previous_address.city = values.prev_city;
            appl.address_info.previous_address.country = values.prev_country;
        }

        applDataManager.update(appl);
    };

    const handleContinue = async (values: any) => {
        if (isMerchantApp()) {
            dispatch(setLastSuccessPage(RouteStrings.ApplicationFlowStepTwo));
            hashHistory.push(RouteStrings.ApplicationFlowStepThree);
            return;
        }

        if (loading) {
            return;
        }

        try {
            dispatch(setLastSuccessPage(RouteStrings.ApplicationFlowStepTwo));
            hashHistory.push(RouteStrings.ApplicationFlowStepThree);
        } catch (error) {
            console.error(error);

            if ((error as any).response?.data === "ErrApplicationDemoRejected") {
                // appl.status = ApplicationStates.Rejected;
                // applDataManager.update(appl);

                // goTo(RouteStrings.ApplicationFlowStepResult);

                return;
            } else {
                setGeneralError(t("errors:generalError"));
            }
        }
    };

    const handleOnFinish = async (values: any) => {
        setGeneralError("");
        persistData(values);

        await handleContinue(values);
    };

    React.useEffect(() => {
        const applDataManager = new LocalApplicationFlowDataManager();
        const appl = applDataManager.get();

        if (appl == null) {
            return;
        }

        form.setFieldsValue({
            salutation: appl.personal_info.salutation,
            first_name: appl.personal_info.first_name || appl?.draft_info?.first_name,
            last_name: appl.personal_info.last_name || appl?.draft_info?.last_name,
            place_of_birth: appl.personal_info.place_of_birth,
            nationality: appl.personal_info.nationality,
            marital_status: appl.personal_info.marital_status,
            street_name: appl.address_info.street_name,
            street_number: appl.address_info.street_number,
            city: appl.address_info.city,
            country: appl.address_info.country,
            post_code: appl.address_info.post_code,
            prev_street_name: appl.address_info.previous_address.street_name,
            prev_street_number: appl.address_info.previous_address.street_number,
            prev_post_code: appl.address_info.previous_address.post_code,
            prev_city: appl.address_info.previous_address.city,
            prev_country: appl.address_info.previous_address.country,
        });
        appl.personal_info.date_of_birth && form.setFieldsValue({
            date_of_birth: moment(appl.personal_info.date_of_birth),
        });
        appl.address_info.resident_since && form.setFieldsValue({
            resident_since: moment(appl.address_info.resident_since),
        });
    }, []);

    React.useEffect(() => {
        const date = moment(form.getFieldValue("resident_since"));
        const now = moment();

        if (now.diff(date, "years") <= 2) {
            setShowExtraAddressData(true);
        } else {
            setShowExtraAddressData(false);
        }
    }, [form.getFieldValue("resident_since")]);

    const renderExtraAddressData = () => {
        return (
            <React.Fragment>
                <Spacer hx={2} />

                <h4 className={styles.bold}>{t("applProcess:subtitleAdressData2StepTwo")}</h4>

                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item
                            name="prev_street_name"
                            label={t("streetName")}
                            rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                        >
                            <Input
                                data-cy="prev_street_name"
                                className={styles.input}
                                placeholder={t("example") + " Musterstrasse"}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="prev_street_number"
                            label={t("streetNumber")}
                            rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                        >
                            <Input
                                data-cy="prev_street_number"
                                className={styles.input}
                                placeholder={t("example") + " 123"}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item
                            name="prev_post_code"
                            label={t("postCode")}
                            rules={[
                                {
                                    required: false,
                                    message: t("formErrors:invalidPostCode"),
                                    pattern: getPostCodeValidationPattern(),
                                },
                            ]}
                        >
                            <Input
                                data-cy="prev_post_code"
                                className={styles.input}
                                placeholder={t("example") + " 12345"}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="prev_city"
                            label={t("city")}
                            rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                        >
                            <Input
                                data-cy="prev_city"
                                className={styles.input}
                                placeholder={t("example") + " Berlin"}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name="prev_country"
                    label={t("country")}
                    rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                >
                    <AnchoredSelect data-cy="prev_country" className={styles.select} allowClear>
                        {countryListToMap.map((country, idx) => {
                            return (
                                <Option key={`country-${idx}`} value={country.code.toLowerCase()}>
                                    {country.name}
                                </Option>
                            );
                        })}
                    </AnchoredSelect>
                </Form.Item>
            </React.Fragment>
        );
    };

    const countryListToMap = i18n.language === "de" ? countriesDe : countriesEn;
    return (
        <div className={styles.page}>
            <ApplicationProcessHeader checkLoggedInStatus />

            <CustomPageHeader small={isMobile} />

            <div className={styles.container}>
                {checkingLoggedIn ? (
                    <Spin
                        className={styles.spin}
                        indicator={<Loading3QuartersOutlined style={{ fontSize: 34 }} spin />}
                    />
                ) : (
                    <React.Fragment>
                        <Row gutter={24} align={"top"}>
                            <Col span={isMobile ? 24 : 16}>
                                <Card>
                                    <div className={styles.innerContainer}>
                                        <BackButton
                                            route={RouteStrings.ApplicationFlowStepOne}
                                            hashHistory={hashHistory}
                                        />

                                        <Spacer hx={2} />
                                        <h2 className={styles.processTitle}>{t("applProcess:titleStepTwo")}</h2>

                                        <Form
                                            layout={"vertical"}
                                            form={form}
                                            onFinish={handleOnFinish}
                                            scrollToFirstError
                                        >
                                            <h4 className={styles.bold}>
                                                {t("applProcess:subtitlePersonalDataStepTwo")}
                                            </h4>

                                            <Form.Item
                                                name="salutation"
                                                label={t("salutation")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 1,
                                                        max: 100,
                                                    },
                                                ]}
                                            >
                                                <AnchoredSelect
                                                    data-cy="salutation"
                                                    className={styles.select}
                                                    allowClear
                                                    placeholder={t("pleaseSelectOption")}
                                                >
                                                    <Option value="Herr">Herr</Option>
                                                    <Option value="Frau">Frau</Option>
                                                </AnchoredSelect>
                                            </Form.Item>

                                            <Form.Item
                                                name="first_name"
                                                label={t("firstName")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 2,
                                                        max: 100,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    data-cy="first_name"
                                                    className={styles.input}
                                                    placeholder={t("example") + " Hans"}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            first_name: e.target.value,
                                                        })
                                                    }
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="last_name"
                                                label={t("lastName")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 2,
                                                        max: 100,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    data-cy="last_name"
                                                    className={styles.input}
                                                    placeholder={`${t("example")} Mustermann`}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            last_name: e.target.value,
                                                        })
                                                    }
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="date_of_birth"
                                                label={t("dateOfBirth")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                    },
                                                ]}
                                            >
                                                <DatePicker
                                                    className={styles.datePicker}
                                                    format={DATE_FORMAT.DE}
                                                    allowClear={true}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="place_of_birth"
                                                label={t("placeOfBirth")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 1,
                                                        max: 100,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    data-cy="place_of_birth"
                                                    className={styles.input}
                                                    placeholder={`${t("example")} Berlin`}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            place_of_birth: e.target.value,
                                                        })
                                                    }
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="nationality"
                                                label={t("nationality")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 1,
                                                        max: 100,
                                                    },
                                                ]}
                                            >
                                                <AnchoredSelect
                                                    data-cy="nationality"
                                                    className={styles.select}
                                                    allowClear
                                                    placeholder={t("pleaseSelectOption")}
                                                >
                                                    {countryListToMap.map((country, idx) => {
                                                        return (
                                                            <Option key={`country-${idx}`} value={country.code}>
                                                                {country.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </AnchoredSelect>
                                            </Form.Item>

                                            <Form.Item
                                                name="marital_status"
                                                label={t("maritalStatus")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 1,
                                                        max: 100,
                                                    },
                                                ]}
                                            >
                                                <AnchoredSelect
                                                    data-cy="marital_status"
                                                    className={styles.select}
                                                    allowClear
                                                    placeholder={t("pleaseSelectOption")}
                                                >
                                                    {MaritalStatusesList.map((val, i) => {
                                                        return (
                                                            <Select.Option
                                                                data-cy={`address_country_option_${i}`}
                                                                key={i}
                                                                value={val}
                                                            >
                                                                {t(`maritalStatuses:${val}`)}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </AnchoredSelect>
                                            </Form.Item>

                                            <Spacer hx={2} />
                                            <h4 className={styles.bold}>
                                                {t("applProcess:subtitleAdressDataStepTwo")}
                                            </h4>

                                            <Row gutter={12}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="street_name"
                                                        label={t("streetName")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t("errors:invalidData"),
                                                                min: 1,
                                                                max: 100,
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            data-cy="street_name"
                                                            className={styles.input}
                                                            placeholder={t("example") + " Musterstrasse"}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="street_number"
                                                        label={t("streetNumber")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t("errors:invalidData"),
                                                                min: 1,
                                                                max: 100,
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            data-cy="street_number"
                                                            className={styles.input}
                                                            placeholder={t("example") + " 123"}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={12}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="post_code"
                                                        label={t("postCode")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t("formErrors:invalidPostCode"),
                                                                pattern: getPostCodeValidationPattern(),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            data-cy="post_code"
                                                            className={styles.input}
                                                            placeholder={t("example") + " 12345"}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="city"
                                                        label={t("city")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t("errors:invalidData"),
                                                                min: 1,
                                                                max: 100,
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            data-cy="city"
                                                            className={styles.input}
                                                            placeholder={t("example") + " Berlin"}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Form.Item
                                                name="country"
                                                label={t("country")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 1,
                                                        max: 100,
                                                    },
                                                ]}
                                            >
                                                <AnchoredSelect data-cy="country" className={styles.select} allowClear>
                                                    {countryListToMap.map((country, idx) => {
                                                        return (
                                                            <Option key={`country-${idx}`} value={country.code}>
                                                                {country.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </AnchoredSelect>
                                            </Form.Item>

                                            <Form.Item
                                                name="resident_since"
                                                label={t("residentSince")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                    },
                                                ]}
                                            >
                                                <DatePicker
                                                    className={styles.datePicker}
                                                    format={DATE_FORMAT.DE}
                                                    allowClear={true}
                                                    onChange={(value) => {
                                                        setState({
                                                            ...state,
                                                            resident_since: moment(value).format(),
                                                        });
                                                    }}
                                                />
                                            </Form.Item>

                                            {showExtraAddressData && renderExtraAddressData()}

                                            {generalError ? (
                                                <>
                                                    <div className={styles.error}>{generalError}</div>
                                                    <Spacer />
                                                </>
                                            ) : null}

                                            <Spacer />
                                            <Spacer />
                                            <Spacer />

                                            <Form.Item>
                                                <Row className={styles.rowCenterX}>
                                                    <Button data-cy="button_continue" type="primary" htmlType="submit">
                                                        {t("buttons:continue")}
                                                    </Button>
                                                </Row>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={isMobile ? 24 : 8}>
                                <OrderSummary draftData={appl.draft_info} />
                            </Col>
                        </Row>
                        <Spacer />
                        <Row justify="start" gutter={24}>
                            <Col span={isMobile ? 24 : 16} style={{ textAlign: "center" }}>
                                <a href={appl?.draft_info?.cancel_url}>
                                    Cancel and return to {appl?.draft_info?.merchant_name}
                                </a>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};
