import { Button, Col, Row, Table } from "antd";
import Search from "antd/lib/input/Search";
import { Merchant } from "api/types";
import { columns } from "components/admin/presenters/admin-home-tabs/merchants-list-tab/merchantsListColumns";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PaginationType } from "types/adminPanel";
import styles from "./MerchantsListTab.module.scss";

export interface MerchantsListTabProps {
    loading: boolean
    merchants: Merchant[]
    onAddMerchant: () => void
    handleMerchantsSearch: (query: string) => void
    pagination: PaginationType
    getMerchants: (page: number, query?: string) => Promise<void>
}

export const MerchantsListTab = (props: MerchantsListTabProps) => {
    const { t } = useTranslation();

    const [searchStr, setSearchStr] = useState<string>("");

    return (
        <div className={styles.adminHomeTab}>
            <Button
                className={styles.addMerchant}
                onClick={() => {
                    props.onAddMerchant();
                }}
            >
                {t("buttons:addMerchant")}
            </Button>
            <Spacer hx={1} />

            <Row className={styles.headerRow}>
                <Col span={24}>
                    <div className="row-flex-end">
                        <Search
                            className="search-bar"
                            placeholder={t("freeTextSearch")}
                            value={searchStr}
                            onChange={(e) => {
                                setSearchStr(e.target.value?.trim());
                            }}
                            onSearch={(value) => {
                                props.handleMerchantsSearch(searchStr);
                            }}
                        />
                    </div>
                </Col>
                <Col>
                    <p
                        className="clearSearch"
                        onClick={() => {
                            setSearchStr("");
                            props.handleMerchantsSearch("");
                        }}
                    >
                        Suche zurücksetzen
                    </p>
                </Col>
            </Row>

            <Table
                loading={props.loading}
                columns={columns}
                dataSource={props.merchants}
                scroll={{ x: true }}
                onChange={(e) => props.getMerchants(e.current)}
                pagination={{
                    position: ["bottomCenter"],
                    current: props.pagination.current_page,
                    total: props.pagination.total_rows,
                    pageSize: props.pagination.items_per_page,
                    showSizeChanger:false,
                }}
            />
        </div>
    );
};
