import { Col, Row, Table } from "antd";
import Search from "antd/lib/input/Search";
import styles from "components/admin/presenters/admin-home-tabs/AdminHomeTabs.module.scss";
import * as React from "react";
import { adminGetApplications } from "api/admin/admin";
import { PreviewPane } from "../../preview-pane/PreviewPane";
import { useTranslation } from "react-i18next";
import i18n from "i18n/i18n";
import { Link } from "react-router-dom";
import { formatDateForTable } from "helpers/timeHelpers";
import { useDateRangeFilterOptions } from "hooks/TableFilterOptions";
import { currencyLocaleFormatter } from "formatters/currencyFormatters";
import { RouteStrings } from "routes/RouteStrings";
import { PaginationType } from "types/adminPanel";

/**
 * Only used by the admin. It's actually more like its own page, so we should rename it.
 * It should also manage its data alone.
 */
export const ApplicationsTab = () => {

    const {t} = useTranslation();
    
    const [loading, setLoading] = React.useState(false);
    const [appl, setAppl] = React.useState([]);
    const [searchStr, setSearchStr] = React.useState(null);
    const [selectedAppl, setSelectedAppl] = React.useState("");
    const [selectedRow, setSelectedRow] = React.useState(null); 

    const [pagination, setPagination] = React.useState<PaginationType>({});

    const getApplications = async (page: number, searchStr?: string) => {
        // filter: ApplicationFilter
        setLoading(true);

        setAppl([]);

        const getApplReq = await adminGetApplications(page, searchStr);

        if (getApplReq && getApplReq.status == 200) {
            setLoading(false);
            setAppl(getApplReq.data.result);
            setPagination(getApplReq.data.pagination);
        } else {
            setLoading(false);
            setAppl([]);
        }
    };

    const handleSearch = () => {
        if (searchStr && searchStr?.length > 2) {
            // TODO: perform search
            getApplications(1, searchStr);
        } else if (searchStr === "") {
            getApplications(1);
        }
    };

    const STATUSES = [
        "open",
        "rejected",
        "complete",
        "acquiring_offers",
        "in_manual_check",
        "approved_waiting_kyc",
        "approved_waiting_shipping",
        "approved_booked",
        "approved_waiting_documents",
        "in_cancellation",
        "cancelled_by_bank",
        "cancelled_by_merchant",
        "expired",
        "closed",
    ];
    
    const columns = [
        {
            title: i18n.t("tables:applicationId"),
            dataIndex: "short_id",
            key: "short_id",
        },
    
        {
            title: i18n.t("tables:createdAt"),
            dataIndex: "created_at",
            key: "created_at",
            render: (_, appl) => {
                return formatDateForTable(appl?.created_at);
            },
            ...useDateRangeFilterOptions("created_at"),
        },
        {
            title: i18n.t("tables:amount"),
            dataIndex: "amount",
            key: "amount",
            sorter: (a, b) => Number.parseInt(a.amount) - Number.parseInt(b.amount),
            render: (_, appl) => {
                return currencyLocaleFormatter(appl?.amount);
            },
        },
        {
            title: i18n.t("tables:durationMonths"),
            dataIndex: "duration_months",
            key: "duration",
            sorter: (a, b) => Number.parseInt(a.duration_months) - Number.parseInt(b.duration_months),
        },
        {
            title: i18n.t("tables:status"),
            dataIndex: "status",
            key: "status",
            render: (value) => (value ? i18n.t(`applicationStates:${value}`) : "-"),
            filters: STATUSES.map((status) => {
                return {
                    key:status,
                    text: i18n.t(`applicationStates:${status}`),
                    value: status,
                };
            }),
            onFilter:(value, record) => record.status.indexOf(value) === 0,
        },
        {
            title: i18n.t("tables:merchant"),
            dataIndex: "merchant_name",
            key: "merchant_name",
            render: (value) => (value ? value : "-"),
        },
        {
            title: "",
            dataIndex: "lastCol",
            key: "lastCol",
            render: (text, record) => {
                let link = RouteStrings.AdminApplicationBase + record.application_id;
    
                return <Link to={link}>{i18n.t("tables:view")}</Link>;
            },
        },
    ];
    

    React.useEffect(() => {
        // getApplications(1, filter);
        getApplications(1);
    }, []);

    const handleRowClicked = (appl, rowIndex: number) => {
        setSelectedAppl(appl.application_id);
        setSelectedRow(rowIndex);
    };

    return (
        <section className="admin-home-tab">
            <Row>
                <Col span={24}>
                    <Search
                        className="search-bar"
                        placeholder={t("freeTextSearch")}
                        value={searchStr}
                        onChange={(e) => {
                            setSearchStr(e.target.value);
                        }}
                        onSearch={() => {
                            handleSearch();
                        }}
                    />
                </Col>
                <Col>
                    <p
                        className="clearSearch"
                        onClick={() => {
                            setSearchStr("");
                            getApplications(1);
                        }}
                    >
                        Suche zurücksetzen
                    </p>
                </Col>
            </Row>

            <Row gutter={12}>
                <Col span={18}>
                    <Table
                        className={styles.smallTable}
                        rowKey={(record) => record.short_id}
                        rowClassName={(_, idx) => (idx === selectedRow ? styles.selected : "")}
                        pagination={{
                            position: ["bottomCenter"],
                            current: pagination.current_page,
                            total: pagination.total_rows,
                            pageSize: pagination.items_per_page,
                            showSizeChanger:false,
                        }}
                        onChange={(e) => getApplications(e.current)}
                        loading={loading}
                        columns={columns}
                        dataSource={appl}
                        scroll={{ x: true }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: () => handleRowClicked(record, rowIndex),
                            };
                        }}
                    />
                </Col>
                <Col span={6}>
                    <PreviewPane applicationId={selectedAppl} />
                </Col>
            </Row>
        </section>
    );
};
