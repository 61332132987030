export class RouteStrings {
    // Admin
    public static AdminRouteBase = "/admin";
    public static AdminLogin = RouteStrings.AdminRouteBase + "/login";

    public static AdminHome = RouteStrings.AdminRouteBase + "/home";

    public static AdminApplicationBase = RouteStrings.AdminRouteBase + "/application/";
    public static AdminApplicationDrilldown = RouteStrings.AdminRouteBase + "/application/:applicationId";

    public static AdminClientBase = RouteStrings.AdminRouteBase + "/client/";

    public static AdminMerchantBase = RouteStrings.AdminRouteBase + "/merchant/";
    public static AdminMerchantDrilldown = RouteStrings.AdminMerchantBase + ":merchantId";

    public static AdminMerchantContactBase = RouteStrings.AdminRouteBase + "/merchant-user/";
    public static AdminMerchantContactDrilldown = RouteStrings.AdminMerchantContactBase + ":merchantUserId";

    public static AdminMerchantList = RouteStrings.AdminHome + "#merchants";

    public static AdminUserDrilldown = RouteStrings.AdminRouteBase + "/user/:userId";
    public static AdminClientDrilldown = RouteStrings.AdminRouteBase + "/client/:clientId";

    public static AdminPaymentMenu = RouteStrings.AdminRouteBase + "/payment-menu";

    public static AdminAcceptInvitation = RouteStrings.AdminRouteBase + "/account/invites/:registerToken";
    public static AdminResetPassword = RouteStrings.AdminRouteBase + "/reset/:resetToken";

    // Client
    public static ClientRouteBase = "/clients";
    public static ClientAcceptInvitation = RouteStrings.ClientRouteBase + "/account/invites/:registerToken";
    public static ClientGrantAccess = RouteStrings.ClientRouteBase + "/access";
    public static ClientResetPassword = RouteStrings.ClientRouteBase + "/account/resets/:resetToken";
    public static ClientDashboard = RouteStrings.ClientRouteBase + "/dashboard";
    public static ClientSettingsPage = RouteStrings.ClientRouteBase + "/settings";


    // Merchant
    public static MerchantRouteBase = "/merchant";
    public static MerchantHome = RouteStrings.MerchantRouteBase + "/home";
    public static MerchantApplicationBase = RouteStrings.MerchantRouteBase + "/application/";
    public static MerchantApplicationDrilldown = RouteStrings.MerchantRouteBase + "/application/:applicationId";

    public static MerchantAcceptInvitation = RouteStrings.MerchantRouteBase + "/account/invites/:registerToken";
    public static MerchantResetPassword = RouteStrings.MerchantRouteBase + "/account/resets/:resetToken";

    // User
    public static Index = "/";
    public static SignUp = "/signup";
    public static Login = "/login";

    public static ApplicationFlowBase = "/application";
    public static ApplicationFlowStepOne = "/1";
    public static ApplicationFlowStepLoanInfo = "/loan-info";
    public static ApplicationFlowStepTwo = "/2";
    public static ApplicationFlowStepThree = "/3";
    public static ApplicationFlowStepConfirmation = "/4";
    public static ApplicationFlowStepOffer = "/offer";

    public static ApplicationFlowStepLoan = "/application/loan";
    public static ApplicationFlowStepCompanyInfo = "/application/company-info";
    public static ApplicationFlowStepContactInfo = "/application/contact-info";
    public static ApplicationFlowStepCreateAccount = "/application/create-account";

    // This is the order of steps they appear in the application header.
    // Logic for which step should be skipped or how it should be named is in /navigation/applicationSteps
    public static readonly DEFAULT_PROCESS_FLOW_PATHS = [
        RouteStrings.ApplicationFlowStepOne,
        RouteStrings.ApplicationFlowStepTwo,
        RouteStrings.ApplicationFlowStepThree,
        RouteStrings.ApplicationFlowStepConfirmation,
    ];

    public static readonly USER_PROCESS_FLOW_PATHS = [
        RouteStrings.ApplicationFlowStepOne,
        RouteStrings.ApplicationFlowStepTwo,
        RouteStrings.ApplicationFlowStepThree,
        RouteStrings.ApplicationFlowStepConfirmation,
        RouteStrings.ApplicationFlowStepOffer
    ];

    public static readonly MERCHANT_PROCESS_FLOW_PATHS = [
        RouteStrings.ApplicationFlowStepOne,
        RouteStrings.ApplicationFlowStepLoanInfo,
        RouteStrings.ApplicationFlowStepTwo,
        RouteStrings.ApplicationFlowStepThree,
        RouteStrings.ApplicationFlowStepConfirmation,
        RouteStrings.ApplicationFlowStepOffer
    ];


    public static UserDashboardBase = "/dashboard";
    public static UserApplicationDrilldownBase = "/dashboard/application/";
    public static ApplicationsOverview = RouteStrings.UserDashboardBase + "/applications";
    public static ApplicationDrilldown = RouteStrings.UserDashboardBase + "/application/:applicationId";
    public static MyContracts = RouteStrings.UserDashboardBase + "/my-contracts";
    public static UserInvoiceInfo = RouteStrings.UserDashboardBase + "/invoice-info/:applicationId";
    public static UserDebitorInfo = RouteStrings.UserDashboardBase + "/debitor-info/:applicationId";
    public static UserSettings = RouteStrings.UserDashboardBase + "/settings";
    public static UserAddBankAccount = RouteStrings.UserDashboardBase + "/add-bank-account";
    public static ConfirmEmail = "/clients/confirm_email/:token";
    public static ResetPassword = "/clients/reset/:resetToken";

    public static NotFoundPage = "/404";
}
