import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Form, Input, Row, Select, Spin, Typography } from "antd";
import { clientGetApplicationDraft } from "api/client";
import { loginClientUser, registerClientUser } from "api/client/client";
import { AxiosError } from "axios";
import styles from "components/common/pages/application-flow/ApplicationFlow.module.scss";
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { CustomPageHeader } from "components/common/presenters/custom-page-header/CustomPageHeader";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import hashHistory from "helpers/hashHistory";
import history from "helpers/history";
import { isValidPassword } from "helpers/inputValidationHelpers";
import { pageLoad } from "helpers/pageLoad";
import useMobileCheck from "helpers/useMobileCheck";
import * as React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { RouteStrings } from "routes/RouteStrings";
import { setLastSuccessPage } from "storage/actions/appActions";
import { AppContext } from "storage/context/appContext";
import { LocalApplicationFlowDataManager } from "storage/LocalApplicationFlowDataManager";
import OrderSummary from "../summary/OrderSummary";

export interface DraftInfo {
    amount: string
    email: string
    cancel_url: string
    success_url: string
    failure_url: string
    merchant_name: string
    repayment_options: {
        loan_duration: number
        monthly_rate: string
    }[]
}

export const ApplicationStepOnePage = (props) => {
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const [checkingLoggedIn] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [generalError, setGeneralError] = React.useState("");
    const [isLogin, setIsLogin] = React.useState(false);
    const [draftData, setDraftData] = React.useState<DraftInfo>();

    const isMobile = useMobileCheck();

    const [, dispatch] = useContext(AppContext);

    function getQuery() {
        return new URLSearchParams(props?.location?.search);
    }
    const applDataManager = new LocalApplicationFlowDataManager();
    const appl = applDataManager.get();

    const persistSessionId = () => {
        const query = getQuery();

        const session_id = query.get("sid");
        // if session was set but user tried to skip steps this prevents steps 2->1 from overwriting the session_id and getting rejected.
        session_id && (appl.session_id = session_id);

        applDataManager.update(appl);
    };

    const fetchSessionInfo = async () => {
        try {
            const { data } = await clientGetApplicationDraft(appl.session_id);
            appl.draft_info = data;
            setDraftData(data);

            // preset some values
            appl.address_info.city = data.address_city;
            appl.address_info.country = data.address_country_code.toUpperCase();
            appl.address_info.post_code = data.address_post_code;
            appl.address_info.street_name = data.address_street;

            applDataManager.update(appl);
        } catch (err) {
            const error = err as AxiosError;
            // wrong api auth
            if (error?.response?.status === 404) {
                history.push("/404");
            }
        }
    };

    const handleDurationChange = (selected: number) => {
        appl.duration_months = selected;
        appl.draft_info = draftData;
        applDataManager.update(appl);
    };

    React.useEffect(() => {
        persistSessionId();
        // TODO: add session loan duration values to dropdown
    }, []);

    React.useEffect(() => {
        fetchSessionInfo();
    }, []);

    const [state, setState] = React.useState({
        email: "",
        password: "",
        repeatPassword: "",
    });

    React.useEffect(() => {
        pageLoad(dispatch, RouteStrings.ApplicationFlowStepOne);
    }, []);
    // on finish is fired after succesful validation of ALL form fields
    const handleOnFinish = async (values: any) => {
        if (loading) {
            return;
        }

        setLoading(true);
        setGeneralError("");

        if (!isLogin && state.password != state.repeatPassword) {
            setLoading(false);
            setGeneralError(t("errors:passwordsDoNotMatch"));
            return;
        }

        try {
            if (isLogin) {
                await loginClientUser(values.email, values.password);
            } else {
                await registerClientUser({
                    email: values.email,
                    password: values.password,
                });
                await loginClientUser(values.email, values.password);
            }
            setLoading(false);

            dispatch(setLastSuccessPage(RouteStrings.ApplicationFlowStepOne));

            hashHistory.push(RouteStrings.ApplicationFlowStepTwo);
        } catch (error) {
            console.error(error);
            setLoading(false);

            setGeneralError(t("errors:loginError"));
        }
    };

    React.useEffect(() => {
        // This page is not visible in the merchant app, so no need to check which app this is.
        // Note that we only redirect to step 2 if the user IS logged in.
        // That's different than the rest of the flow where we redirect to step 1 if the user is NOT logged in.
        // checkAuth(setCheckingLoggedIn,
        //     goTo.bind(this, RouteStrings.ApplicationFlowStepOne),
        //     null);
        form.setFieldsValue({
            email: draftData && draftData.email,
        });
    }, [draftData]);

    return (
        <div className={styles.page}>
            <ApplicationProcessHeader checkLoggedInStatus />
            {!isMobile && <CustomPageHeader />}
            <div className={styles.container}>
                {checkingLoggedIn ? (
                    <Spin
                        className={styles.spin}
                        indicator={<Loading3QuartersOutlined style={{ fontSize: 34 }} spin />}
                    />
                ) : (
                    <React.Fragment>
                        <Row gutter={24} align={"top"}>
                            <Col span={isMobile ? 24 : 16}>
                                <Card>
                                    <div className={styles.innerContainer}>
                                        <h2 className={styles.processTitle}>{t("applProcess:titleStepOne")}</h2>
                                        <h3 className={styles.processSubTitle}>{t("applProcess:subTitleStepOne")}</h3>

                                        <Typography.Title level={5}>Laufzeit</Typography.Title>
                                        <Select onChange={handleDurationChange} defaultValue={12}>
                                            {draftData?.repayment_options.map((plan, idx) => {
                                                return (
                                                    <Select.Option key={idx} value={plan.loan_duration}>
                                                        {plan.monthly_rate}€/Monat für {plan.loan_duration} Monate
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>

                                        <Spacer />
                                        <Typography.Title level={5}>
                                            {isLogin ? "Login" : "Registration"}
                                        </Typography.Title>
                                        <Form
                                            layout={"vertical"}
                                            form={form}
                                            onFinish={handleOnFinish}
                                            scrollToFirstError
                                        >
                                            <Form.Item
                                                name="email"
                                                label={t("email")}
                                                rules={[{ required: true, message: t("errors:emailInvalid") }]}
                                            >
                                                <Input
                                                    data-cy="email"
                                                    className={styles.input}
                                                    placeholder={t("example") + " example@example.com"}
                                                    onChange={(e) => {
                                                        setState({
                                                            ...state,
                                                            email: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="password"
                                                label={t("password")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:passwordInvalid"),
                                                   
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (value && !isValidPassword(value)) {
                                                                return Promise.reject(t("errors:passwordInvalid"));
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input.Password
                                                    data-cy="input_password"
                                                    className={styles.input}
                                                    onChange={(e) => {
                                                        setState({
                                                            ...state,
                                                            password: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </Form.Item>

                                            {isLogin ? null : (
                                                <Form.Item
                                                    name="passwordRepeat"
                                                    label={t("passwordRepeat")}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t("errors:passwordInvalid"),
                                                            min: 6,
                                                            max: 100,
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password
                                                        data-cy="input_password_repeat"
                                                        className={styles.input}
                                                        onChange={(e) => {
                                                            setState({
                                                                ...state,
                                                                repeatPassword: e.target.value,
                                                            });
                                                        }}
                                                    />
                                                </Form.Item>
                                            )}

                                            <Spacer />

                                            {isLogin ? null : (
                                                <>
                                                    <Form.Item
                                                        name="tc_accepted"
                                                        valuePropName="checked"
                                                        rules={[
                                                            {
                                                                validator: (_, value) =>
                                                                    value
                                                                        ? Promise.resolve()
                                                                        : Promise.reject(t("errors:acceptAGB")),
                                                            },
                                                        ]}
                                                    >
                                                        <Checkbox>
                                                            Hiermit beauftrage ich die Liquitree GmbH mit der
                                                            Kostenlosen Kreditvermittlung für meinen Einkauf und
                                                            akzeptiere die{" "}
                                                            <a
                                                                className="link-red"
                                                                href="https://www.liquitree.com/wp-content/uploads/2022/08/AGB_Liquitree_K.docx.pdf"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                AGB
                                                            </a>{" "}
                                                            und{" "}
                                                            <a
                                                                className="link-red"
                                                                href="https://www.liquitree.com/wp-content/uploads/2022/08/DSGVO.docx.pdf"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                Datenschutzhinweise
                                                            </a>
                                                        </Checkbox>
                                                    </Form.Item>
                                                    <p className={styles.moreinfo}>
                                                        Der Erhalt der{" "}
                                                        <a
                                                            href="https://www.liquitree.com/wp-content/uploads/2022/08/vorvertragliche_Informationen.docx.pdf"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Pflichtinformationen{" "}
                                                        </a>{" "}
                                                        bestätige ich.
                                                    </p>
                                                </>
                                            )}

                                            <div className={styles.error}>{generalError}</div>
                                            <Spacer />
                                            <Spacer />

                                            <Form.Item>
                                                <Row className={styles.rowCenterX}>
                                                    <Button type="primary" htmlType="submit" loading={loading}>
                                                        {t("buttons:continue")}
                                                    </Button>
                                                </Row>
                                                <Spacer />
                                            </Form.Item>

                                            <Spacer />

                                            <Form.Item className={styles.itemCenter}>
                                                <a
                                                    className={styles.link}
                                                    onClick={() => {
                                                        setIsLogin(!isLogin);
                                                    }}
                                                >
                                                    {isLogin ? t("hasNoAccount") : t("hasAccount")}
                                                </a>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={isMobile ? 24 : 8}>
                                <OrderSummary draftData={draftData ? draftData : {}} />
                            </Col>
                        </Row>
                        <Spacer />
                        <Row justify="start" gutter={24}>
                            <Col span={isMobile ? 24 : 16} style={{ textAlign: "center" }}>
                                <a href={draftData?.cancel_url}>Cancel and return to {draftData?.merchant_name}</a>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};
