import styles from "components/merchant-panel/merchant-home/MerchantHomePage.module.scss";
import { SideMenu } from "components/common/presenters/side-menu/SideMenu";
import { getMerchantMenuItems, goToApplicationFlowStepOne } from "navigation/navigationHelpers";
import * as React from "react";
import { useState } from "react";
import { MerchantApplicationsTab } from "components/merchant-panel/applications-tab/MerchantApplicationsTab";
import { MerchantSettingsTab } from "components/merchant-panel/settings-tab/MerchantSettingsTab";
import { Button, Layout, Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Header, Content } = Layout;

export const MerchantHomePage = () => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [activeHash, setActiveHash] = useState(null);

    const {t} = useTranslation();

    const hashes = ["#applications", "#settings", null];

    React.useEffect(() => {
        const hash = window.location.hash;

        if (hash == null || hash == "") {
            return;
        }

        if (activeHash == null) {
            setActiveHash(hash);
        }

        if (activeTabIndex !== hashes.indexOf(hash)) {
            setActiveTabIndex(hashes.indexOf(hash));
        }
    }, [window.location.hash]);

    const tabsContent = [<MerchantApplicationsTab />, <MerchantSettingsTab />];

    const menuItems = getMerchantMenuItems();

    return (
        <Layout className={styles.adminPage}>
            <SideMenu
                activeHash={window.location.hash}
                isLoggedIn={true}
                onItemClick={(index) => {
                    if (hashes[index] == null) {
                        return;
                    }

                    setActiveTabIndex(index);

                    window.location.hash = hashes[index];
                }}
            />

            <Layout className={styles.tabContainer}>
                <Header className={styles.headerRow}>
                    <Typography.Title level={2}>{menuItems[activeTabIndex]}</Typography.Title>
                    {activeTabIndex === 0 && (
                        <Button
                            data-cy="button_new_application"
                            className={styles.headerButton}
                            type={"primary"}
                            onClick={() => {
                                goToApplicationFlowStepOne();
                            }}
                        >
                            {t("buttons:newInquiry")}
                        </Button>
                    )}
                </Header>

                <Content className={styles.merchantContent}>{tabsContent[activeTabIndex]}</Content>
            </Layout>
        </Layout>
    );
};

