import { Checkbox, Table } from "antd";
import { adminGetToDos } from "api/admin/todos";
import { columns } from "components/admin/presenters/admin-home-tabs/dashboard-tab/columns";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { wait } from "helpers/misc";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { PaginationType } from "types/adminPanel";
import { isPaidOutState } from "../../../../../storage/genericHelpers";
import styles from "./DashboardTab.module.scss";

export const DashboardTab = () => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);

    const [filterAssignedToMe, setFilterAssignedToMe] = useState<boolean>(false);
    const [filterPaidOut, setFilterPaidOut] = useState<boolean>(false);

    const [data, setData] = useState([]);
    const [pagination, setPagination] = React.useState<PaginationType>({});

    const getToDos = async (page: number) => {
        try {
            setLoading(true);

            const getToDosReq = await adminGetToDos(page);

            await wait(250);
    
            if (getToDosReq && getToDosReq?.status == 200) {
                // A little timeout, so the loader doesn't just flash if the backend returns quickly
                setData(getToDosReq?.data?.result);
                setPagination(getToDosReq?.data?.pagination);
                
            } else {
                // A little timeout, so the loader doesn't just flash if the backend returns quickly
                setData([]);
            }

        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        let filtered = [];

        if (filterAssignedToMe) {
            filtered = filtered.filter((e) => e.adminId === 'abc123');
        }

        if (filterPaidOut) {
            filtered = filtered.filter((e) => isPaidOutState(e.status));
        }
	
        setData(filtered);
    }, [filterAssignedToMe, filterPaidOut]);

    React.useEffect(() => {
        getToDos(1);
    }, []);

    return (
        <div className={styles.adminHomeTab}>
            <Spacer hx={2} />
            <Checkbox
                checked={filterPaidOut}
                onChange={(e) => {
                    setFilterPaidOut(e.target.checked);
                }}
            >
                {t("showOnlyClosed")}
            </Checkbox>
            <Checkbox
                checked={filterAssignedToMe}
                onChange={(e) => {
                    setFilterAssignedToMe(e.target.checked);
                }}
            >
                {t("showOnlyMine")}
            </Checkbox>
            <div className={styles.checkboxSpacer}></div>

            <Spacer hx={3} />

            <Table
                loading={loading}
                columns={columns}
                dataSource={data}
                onChange={(e) => getToDos(e.current)}
                pagination={{
                    position: ["bottomCenter"],
                    current: pagination.current_page,
                    total: pagination.total_rows,
                    pageSize: pagination.items_per_page,
                    showSizeChanger:false,
                }}
                scroll={{ x: true }}
            />
        </div>
    );
};
