// Enums
export enum Salutations {
    Herr = "Herr",
    Frau = "Frau",
}

export enum MaritalStatuses {
    Married = "married",
    Single = "single",
    Living_Separately = "separated",
    Divorced = "divorced",
    Widowed = "widowed",
    Cohabitation = "cohabitation",
}

export enum ResidenceTypes {
    Proprietary = "proprietary",
    Renting = "rent",
    With_Parents = "parents",
    No_Rent = "no_rent"
}

export enum IdMethods {
    Video_Chat = "videochat",
    Post = "post",
}

export enum IdDocTypes {
    Id_Card = "identity_card",
    Passport = "passport"
}

export enum WorkBranche {
    Banken = "banks",
    Bauunternehmen = "building_company",
    Bergbau = "mining",
    Bundeswehr = "army",
    Chemieindustrie = "chemical_industry",
    Diplomatischer_Dienst = "foreign_service",
    Farb_Druck_Papierindustrie = "paper_industry",
    Fischerei = "fishing",
    Gastronomie = "gastronomy",
    Groß_und_Einzelhandel = "wholesale_retail",
    Handwerk = "handcraft",
    Holzindustrie = "timber_industry",
    Hotelgewerbe = "hotel_business",
    Hütten_und_Stahlindustrie = "steel_industry",
    KFZ_Hersteller = "vehicle_manufacturer",
    Kindergärten = "kindergarden",
    Kirchliche_und_soziale_Einrichtungen = "welfare_service",
    Kranken_und_Pflegeanstalten = "care_institution",
    Land_und_Forstwirtschaft = "agriculture_forestry",
    Metallindustrie = "metal_industry",
    Nahrungs_und_Genussmittelindustrie = "food_industry",
    Öffentlicher_Dienst = "public_service",
    Reisewirtschaft = "travel_industry",
    Rundfunk_und_Fernsehanstalten = "broadcasting_institution",
    Seefahrt = "sea_faring",
    Sonstige = "miscellaneous",
    Speditionen = "freight_forwarding",
    Tankstellen = "petrol_stations",
    Textilindustrie = "textile_industry",
    Verlags_und_Zeitungswesen = "press",
    Versicherungen = "insurances",
    Walzwerke = "roller_mill",
}

export enum OccupationGroup {
    employee = "employee",
    public_servant = "public_servant",
    civil_servant = "civil_servant",
    labourer = "labourer",
    homemaker = "homemaker",
    pensioner = "pensioner",
    apprentice = "apprentice",
    student = "student",
    sales_representative = "sales_representative",
    sales_people = "sales_people",
}


// Consts
export const ResidenceTypesList = [
    ResidenceTypes.Proprietary,
    ResidenceTypes.Renting,
    ResidenceTypes.With_Parents,
    ResidenceTypes.No_Rent,
];

export const IdMethodsList = [
    IdMethods.Video_Chat,
    IdMethods.Post
];

export const IdDocTypesList = [
    IdDocTypes.Id_Card,
    IdDocTypes.Passport
];

export const WorkBranchesList = [
    WorkBranche.Banken,
    WorkBranche.Bauunternehmen,
    WorkBranche.Bergbau,
    WorkBranche.Bundeswehr,
    WorkBranche.Chemieindustrie,
    WorkBranche.Diplomatischer_Dienst,
    WorkBranche.Farb_Druck_Papierindustrie,
    WorkBranche.Fischerei,
    WorkBranche.Gastronomie,
    WorkBranche.Groß_und_Einzelhandel,
    WorkBranche.Handwerk,
    WorkBranche.Holzindustrie,
    WorkBranche.Hotelgewerbe,
    WorkBranche.Hütten_und_Stahlindustrie,
    WorkBranche.KFZ_Hersteller,
    WorkBranche.Kindergärten,
    WorkBranche.Kirchliche_und_soziale_Einrichtungen,
    WorkBranche.Kranken_und_Pflegeanstalten,
    WorkBranche.Land_und_Forstwirtschaft,
    WorkBranche.Metallindustrie,
    WorkBranche.Nahrungs_und_Genussmittelindustrie,
    WorkBranche.Öffentlicher_Dienst,
    WorkBranche.Reisewirtschaft,
    WorkBranche.Rundfunk_und_Fernsehanstalten,
    WorkBranche.Seefahrt,
    WorkBranche.Sonstige,
    WorkBranche.Speditionen,
    WorkBranche.Tankstellen,
    WorkBranche.Textilindustrie,
    WorkBranche.Verlags_und_Zeitungswesen,
    WorkBranche.Versicherungen,
    WorkBranche.Walzwerke,
];

export const OccupationGroupList = [
    OccupationGroup.employee,
    OccupationGroup.public_servant,
    OccupationGroup.civil_servant,
    OccupationGroup.labourer,
    OccupationGroup.homemaker,
    OccupationGroup.pensioner,
    OccupationGroup.apprentice,
    OccupationGroup.student,
    OccupationGroup.sales_representative,
    OccupationGroup.sales_people,
];

export const MaritalStatusesList = [
    MaritalStatuses.Married,
    MaritalStatuses.Single,
    MaritalStatuses.Living_Separately,
    MaritalStatuses.Divorced,
    MaritalStatuses.Widowed,
    MaritalStatuses.Cohabitation,
];

export const CountryCodesList = [
    "DE",
    "AT",
    "CH",
    "AD",
    "AE",
    "AF",
    "AI",
    "AM",
    "AO",
    "AQ",
    "AR",
    "AU",
    "AW",
    "BA",
    "BB",
    "BE",
    "BH",
    "BM",
    "BO",
    "BR",
    "BS",
    "BT",
    "BU",
    "BY",
    "BZ",
    "CA",
    "CG",
    "CL",
    "CM",
    "CO",
    "CR",
    "CU",
    "CY",
    "DK",
    "DM",
    "EC",
    "EE",
    "EG",
    "ET",
    "FI",
    "FJ",
    "FR",
    "GB",
    "GE",
    "GH",
    "GN",
    "GR",
    "GY",
    "HK",
    "HR",
    "HU",
    "ID",
    "IE",
    "IN",
    "IQ",
    "IR",
    "IS",
    "IL",
    "IT",
    "JM",
    "JO",
    "JP",
    "KE",
    "KO",
    "KW",
    "KZ",
    "LB",
    "LK",
    "LT",
    "LU",
    "MA",
    "MC",
    "ME",
    "MM",
    "MN",
    "MO",
    "MU",
    "MV",
    "MY",
    "NA",
    "NG",
    "NL",
    "NO",
    "NP",
    "NZ",
    "OM",
    "PA",
    "PE",
    "PH",
    "PK",
    "PO",
    "PT",
    "PY",
    "QA",
    "RO",
    "RU",
    "SA",
    "SC",
    "SE",
    "SG",
    "SK",
    "SN",
    "SO",
    "SP",
    "TH",
    "TN",
    "TR",
    "TW",
    "TZ",
    "UA",
    "UG",
    "US",
    "UY",
    "UZ",
    "VE",
    "VN",
    "YE",
    "ZA",
    "ZM",
    "ZW",
];

// Interfaces/Types

export interface PersonalInfo {
    salutation: Salutations | undefined
    first_name: string
    last_name: string
    date_of_birth: string
    place_of_birth: string
    nationality: string
    marital_status: MaritalStatuses | undefined
}

export interface AddressInfo {
    street_name: string
    street_number: string
    post_code: string
    city: string
    country: string
    resident_since: string
    previous_address?: {
        street_name: string
        street_number: string
        post_code: string
        city: string
        country: string
    }
}

export interface JobInfo {
    profession: string
    branche: string
    occupation_group: string
    employed_since: string
    employer: string
    employer_location: string
    prev_employed_since: string
    prev_employer_name: string
    net_income: string
}

export interface HouseholdInfo {
    residence_type: ResidenceTypes | undefined
    residence_monthly_cost: string
    number_of_children: number | undefined
    has_vehicle: boolean | undefined
}

export interface IdentificationInfo {
    id_doc_type: IdDocTypes | string
    id_doc_issued_place: string
    id_doc_number: string
    id_doc_issued_at: string
    id_doc_expires_at: string
    iban: string
    bic: string
    bank_name: string
    mobile_number: string
}

export enum SelectedMerchantApplicationFlow {
    Client_Exists,
    New_Client,
    No_Email,
}

// This interface should be implemented by the object that holds the data from
// every application flow step.
export interface ApplicationFlowForm {
    email: string
    selected_merchant_flow: SelectedMerchantApplicationFlow
    application_id: string
    client_id: string
    amount: number
    duration_months: number
    signature_jpg_data_uri: string
    personal_info: PersonalInfo
    address_info: AddressInfo
    job_info: JobInfo
    household_info: HouseholdInfo
    id_info: IdentificationInfo
    session_id: string
    draft_info?: any
    
}
export interface FlowStepTwoFormData extends PersonalInfo, AddressInfo {

}
export interface FlowStepThreeFormData extends JobInfo, HouseholdInfo {
    net_incomeVisible: string
    residence_monthly_costVisible: string
}

// the possible states the result screen can have
export enum FlowResultStates {
    Loading,
    Success,
    Rejected,
    Address_Not_Found,
    ManualReview
}

export enum ApplicationTypes {
    POS_LOAN = "pos_loan"
}

export interface AdminCreateApplicationForm {
    financing_amount: number
    financing_partner: string
    installements: number
}

export enum EntityTypes {
    Application = "application",
    Client = "client"
}

export interface AdminCreateToDoForm {
    entity_id: string // application or client ID
    entity_kind: EntityTypes
    assignee_id: string // bo_user_id
    details?: string
    due_date: string
    send_email: boolean
}

export enum MerchantStepOneStates {
    Initial, // email form is visible during the initial state
    Email_Found,
    Email_Found_Awaiting_Approval,
    Email_Not_Found,
    Email_Not_Found_Awaiting_Registration,
    No_Email,
    No_Email_Download_Docs,
    Access_Request_Expired
}

export interface SelectedOffer {
    offer_id: string
    short_id: string
    application_id: string
    is_selected: boolean
    partner_bank_name: string
    duration_months: number
    loan_amount: number
    total_amount: number
    interest_rate: number
    repayment_period_months: number
    installment_rate: number
    first_installment: number
    bank_specific_data: any // json.RawMessage
    is_deleted: boolean
    created_at: string
    kyc_url: string
}
