import { AdminGetMerchantContactResponseItem } from "api/types/merchant";
import {
    ApplicationStates,
    BoUserRoleID,
    ClientFinancingType,
    ClientType,
    Currencies,
    DocumentCategory,
    LeadStatus
} from "types/types";

export interface FirmenWissenCompanyData {
    crefonummer: string
    identnummer: string
    name: string
    handelsName: string
    land: string
    bundesland: string
    plz: string
    ort: string
    strasseHausnummer: string
}

export interface CrefoResult {
    companyNameSuggestions: FirmenWissenCompanyData[]
}

export interface GetApplicationNotesListResponse {
    note_id?: string
    author_id?: string
    author_name?: string
    note_text?: string
    created_at?: string // timestamp
}

export interface ApplicationReview {
    review_id: string
    created_at: string
    factoring_contract_settled: boolean
    global_cession: boolean
    svea_terms_accepted: boolean
    security_guarantee: boolean
    bank_negative_guarantee: boolean
    signature_list: boolean
    id_copy_of_representative: boolean
    company_registry_statement: boolean
    current_debitors_creditors_list: boolean
    last_annual_statement: boolean
    bwa_list: boolean
    confidential_questionary: boolean
    cash_note: boolean
    self_declaration: boolean
    owners_verification_with_info: boolean
}

export interface PostOrPutApplicationReview {
    factoring_contract_settled: boolean
    global_cession: boolean
    svea_terms_accepted: boolean
    security_guarantee: boolean
    bank_negative_guarantee: boolean
    signature_list: boolean
    id_copy_of_representative: boolean
    company_registry_statement: boolean
    current_debitors_creditors_list: boolean
    last_annual_statement: boolean
    bwa_list: boolean
    confidential_questionary: boolean
    cash_note: boolean
    self_declaration: boolean
    owners_verification_with_info: boolean
}

export interface ApplicationFilter {
    status?: string
    type?: any
    source?: string
    id?: string
}

export interface PostOrPutApplicationScore {
    checklist: ClientOrApplicationScoreChecklist
    currency: string
    previous_year: number
    current_year: number
    revenue_previous_year: number
    revenue_current_year: number
    equity_previous_year: number
    equity_current_year: number
    total_assets_previous_year: number
    total_assets_current_year: number
    current_assets_previous_year: number
    current_assets_current_year: number
    current_liabilities_previous_year: number
    current_liabilities_current_year: number
    ebitda_previous_year: number
    ebitda_current_year: number
    total_debt_previous_year: number
    total_debt_current_year: number
    analysis_note: string
}

export interface ApplicationScore {
    score_id: string
    application_id: string
    created_at: string
    checklist: ClientOrApplicationScoreChecklist
    currency: string
    previous_year: number
    current_year: number
    revenue_previous_year: number
    revenue_current_year: number
    equity_previous_year: number
    equity_current_year: number
    total_assets_previous_year: number
    total_assets_current_year: number
    current_assets_previous_year: number
    current_assets_current_year: number
    current_liabilities_previous_year: number
    current_liabilities_current_year: number
    ebitda_previous_year: number
    ebitda_current_year: number
    total_debt_previous_year: number
    total_debt_current_year: number
    equity_ratio_previous_year: number
    equity_ratio_current_year: number
    current_ratio_previous_year: number
    current_ratio_current_year: number
    debt_to_ebitda_ratio_previous_year: number
    debt_to_ebitda_ratio_current_year: number
    debt_to_equity_ratio_previous_year: number
    debt_to_equity_ratio_current_year: number
    revenue_growth: number
    analysis_note: string
}

export enum ScoreChecklistTypes {
    Loan_Checklist = "loan_checklist",
    Factoring_Checklist = "factoring_checklist",
    Client_Checklist = "client_checklist"
}

export interface ScoreChecklistItem {
    name: string
    is_checked: boolean
}

export interface ClientOrApplicationScoreChecklist {
    checklist_type: ScoreChecklistTypes
    version: number
    valid_from?: string // timestamp
    items: ScoreChecklistItem[]
}

export interface PostOrPutClientScore {
    checklist: ClientOrApplicationScoreChecklist
    currency: number
    previous_year: number
    current_year: number
    revenue_previous_year: number
    revenue_current_year: number
    equity_previous_year: number
    equity_current_year: number
    total_assets_previous_year: number
    total_assets_current_year: number
    current_assets_previous_year: number
    current_assets_current_year: number
    current_liabilities_previous_year: number
    current_liabilities_current_year: number
    ebitda_previous_year: number
    ebitda_current_year: number
    total_debt_previous_year: number
    total_debt_current_year: number
    analysis_note: string
}

export interface ClientScore {
    score_id: string
    client_id: string
    created_at: string
    checklist: ClientOrApplicationScoreChecklist
    currency: number
    previous_year: number
    current_year: number
    revenue_previous_year: number
    revenue_current_year: number
    equity_previous_year: number
    equity_current_year: number
    total_assets_previous_year: number
    total_assets_current_year: number
    current_assets_previous_year: number
    current_assets_current_year: number
    current_liabilities_previous_year: number
    current_liabilities_current_year: number
    ebitda_previous_year: number
    ebitda_current_year: number
    total_debt_previous_year: number
    total_debt_current_year: number
    equity_ratio_previous_year: number
    equity_ratio_current_year: number
    current_ratio_previous_year: number
    current_ratio_current_year: number
    debt_to_ebitda_ratio_previous_year: number
    debt_to_ebitda_ratio_current_year: number
    debt_to_equity_ratio_previous_year: number
    debt_to_equity_ratio_current_year: number
    revenue_growth: number
    analysis_note: string
}

export interface PutApplicationBankInfoReq {
    client_id: string
    bank_name: string
    iban: string
}

export interface PutApplicationStatusReq {
    client_id: string
    status: ApplicationStates
}

export interface PutApplicationKYCConfirmReq {
    client_id: string
    amount: number
    duration: number
    interest_rate: number
    required_signature: string
}

export interface CPCreateApplicationRequest {
    client: CPPostClient
    application: CPPostApplication
}

export interface CPPostClient {
    client_type: string
    contact_person_id?: string
    contact_person_email?: string
    company_name?: string
    company_legal_form?: string
    company_register_number?: string
    company_industry?: string
    company_product?: string
    company_number_of_employees?: string
    address_addition?: string
    address_street?: string
    address_house_number?: string
    address_post_code?: string
    address_city?: string
    address_region?: string
    address_country?: string
    salutation?: string
    title?: string
    first_name?: string
    last_name?: string
    phone?: string
    mobile_number?: string
    newsletter_subscription?: boolean

    credentials?: {
        email: string
        password: string
        is_email_confirmed: boolean
        is_enabled: boolean
    }
}

export interface CPPostApplication {
    type: any
    currency: string
    amount: number
    duration: number
    company_purpose: string
    purpose_info: string
    largest_debitors_list: string
    tax_id: string
}

export interface PostContactPerson {
    client_id: string
    email: string
    salutation: string
    title: string
    gender: string
    first_name: string
    last_name: string
    birth_date: string
    birth_place: string
    nationality: string
    phone: string
    mobile_number: string
    passport_number: string
    address_country: string
    address_addition: string
    address_street: string
    address_house_number: string
    address_post_code: string
    address_city: string
    newsletter_subscription: boolean
    created_at: string
    is_deleted: boolean
}

export interface UpdateContactPersonProfileRequest {
    email: string
    salutation?: string
    title?: string
    gender?: string
    first_name?: string
    last_name?: string
    birth_date?: string
    birth_place?: string
    nationality?: string
    phone?: string
    mobile_number?: string
    passport_number?: string
    address_country?: string
    address_addition?: string
    address_street?: string
    address_house_number?: string
    address_post_code?: string
    address_city?: string
    newsletter_subscription?: boolean
}

export interface ContactPerson {
    contact_person_id: string
    email: string
    salutation: string
    title: string
    gender: string
    first_name: string
    last_name: string
    birth_date: string
    birth_place: string
    nationality: string
    phone: string
    mobile_number: string
    passport_number: string
    address_country: string
    address_addition: string
    address_street: string
    address_house_number: string
    address_post_code: string
    address_city: string
    newsletter_subscription: boolean
    created_at: string
    is_deleted: boolean
}

export interface ContactPersonClient extends ContactPerson {
    is_signatory: boolean
    is_sole_signatory: boolean
    is_beneficial_owner: boolean
    pct_owned_shares: string
}

export enum ClientTypes {
    Lead = "lead",
    Client = "client"
}

export interface LoginRequest {
    email: string
    password: string
}

export interface LogoutRequest {
    email: string
    password: string
}

export interface ApiResponse<T> {
    data?: T
    status: number
}
export interface LogoRequest{
    original_logo_url: string
}
export interface LogoPutRequest{
    document_id: string
    download_url: string
}

export interface PostClientResponse {
    client_id: string
    contact_person_id: string
}

export interface PutClientRequest { // POST and PATCH endpoints accept this
    client_type: string
    company_name?: string
    company_legal_form?: string
    company_register_number?: string
    company_industry?: string
    company_product?: string
    company_number_of_employees?: string
    address_addition?: string
    address_street?: string
    address_house_number?: string
    address_post_code?: string
    address_city?: string
    address_region?: string
    address_country?: string
    language?: string
    company_website?: string
    tax_number?: string
    lead_status?: string
    lead_source?: string
    financing_type?: string
    lead_currency?: string
    lead_expected_volume?: number
    lead_expected_duration?: number
    bo_contact_name?: string
    broker_name?: string
    note?: string
}

export interface GetClientsListResponse {
    client_id?: string
    company_name?: string
    company_legal_form?: string
    bo_contact_name?: string
    address_country?: string
    address_region?: string
    lead_status?: LeadStatus
    lead_status_updated_at?: string // timestamp
    financing_type?: ClientFinancingType
    lead_currency?: Currencies
    lead_expected_volume?: number
    created_at?: string // timestamp
}

export interface GetClientResponse {
    client_id?: string
    client_type?: ClientType
    company_name?: string
    company_legal_form?: string
    company_register_number?: string
    company_industry?: string
    company_product?: string
    company_number_of_employees?: string
    address_addition?: string
    address_street?: string
    address_house_number?: string
    address_post_code?: string
    address_city?: string
    address_region?: string
    address_country?: string
    language?: string
    company_website?: string
    tax_number?: string
    lead_status?: LeadStatus
    lead_source?: string
    financing_type?: ClientFinancingType
    lead_currency?: string
    lead_expected_volume?: number
    lead_expected_duration?: number
    bo_contact_name?: string
    broker_name?: string
    created_at?: string // timestamp
    is_deleted?: boolean
    lead_status_updated_at?: string // timestamp
    first_name?: string
    last_name?: string
    bank_name?: string
    email_confirmed?: string
    iban?: string

    // joint entities
    has_application?: boolean
    is_account_created?: boolean
    contact_persons?: ContactPersonClient[]
}

export interface PatchClientStatusRequest {
    new_status: string
    reason: string
}

export interface PatchClientBankDetailsRequest {
    bank_name: string
    iban: string
}

export interface GetContactPersonResponse { // not final
    contact_person_id?: string
    email?: string
    salutation?: string
    title?: string
    gender?: string
    first_name?: string
    last_name?: string
    birth_date?: string // date 2020-01-30
    birth_place?: string
    nationality?: string
    phone?: string
    mobile_phone?: string
    passport_number?: string
    address_country?: string
    address_addition?: string
    address_street?: string
    address_house_number?: string
    address_post_code?: string
    address_city?: string
    created_at?: string // timestamp
    is_deleted?: boolean
}

export interface CPGetApplicationResponse { // not final
    id: string
    client_id: string
    bo_assignee_id: string
    contact_person_id: string
    type: any
    currency: Currencies
    amount: string // Decimal Number!!
    duration: number
    interest_rate: string // Decimal Number!!
    status: ApplicationStates
    company_name: string
    company_type: string
    company_country: string
    company_post_code: string
    company_number_of_employees: string
    company_street: string
    company_house_number: string
    company_city: string
    company_purpose: string
    company_register_number: string
    purpose: string
    purpose_info: string
    largest_debitors_list: string
    tax_id: string
    bank_name: string
    iban: string
    contract_id: string
    created_at: string // timestamp
    paid_out_on: string // timestamp
}

export interface CPPutApplicationDetails {
    amount: number
    duration: number
    currency: string
    purpose_info: string
    largest_debitors_list: string
    company_name: string
    company_type: string
    company_number_of_employees: string
    tax_id: string
    company_register_number: string
    company_purpose: string
    company_country: string
    company_street: string
    company_house_number: string
    company_post_code: string
    company_city: string
}

export interface GetClientNotesListResponse {
    note_id?: string
    author_id?: string
    author_name?: string
    note_text?: string
    created_at?: string // timestamp
}

export interface GetClientEventsListResponse {
    event_id?: string
    event_type?: string
    actor_id?: string
    actor_type?: string
    actor_name?: string
    message?: string
    created_at?: string // timestamp
}

export interface GetDocumentsListResponse {
    document_id?: string
    document_name?: string
    document_category?: DocumentCategory
    is_client_visible?: boolean // always undefined for client api response
    created_at?: string // timestamp
    fileToUpload?: File
    noIcons?: boolean
}

export interface RetrieveDocumentUploadLinkResponse {
    document_id: string
    upload_url: string
}

export interface GetBoUsersListItem {
    bo_user_id?: string
    bo_user_name?: string
    email?: string
    role?: BoUserRoleID
    is_email_confirmed?: boolean
    is_deleted?: boolean
}

export interface GetAdminProfileResponse {
    bo_user_id?: string
    bo_user_name?: string
    email?: string
    role?: string
    merchant_id?: string
    company?: {
        logo_url?: string
        display_name?: string
    }
}
export interface GetClientProfile {
    salutation?: string
    address_city: string
    address_country_code: string
    address_house_number: string
    address_post_code: string
    address_resident_since: string
    address_street: string
    bank_name: string
    bic: string
    birth_city: string
    birth_date: string
    bo_contact_name: string
    email: string
    employed_since: string
    employer_location: string
    employer_name: string
    family_status: string
    first_name: string
    has_vehicle: string
    iban: string
    id_doc_expires_at: string
    id_doc_issued_at: string
    id_doc_issued_place: string
    id_doc_number: string
    id_doc_type: string
    is_accepted_agb: true
    is_accepted_privacy_policy: true
    is_received_pre_contract_info: true
    job_branch: string
    job_title: string
    job_type: string
    language_code: string
    last_name: string
    living_costs: string
    mobile_number: string
    nationality_country_code: string
    net_income: string
    num_children: string
    prev_address_city: string
    prev_address_country_code: string
    prev_address_house_number: string
    prev_address_post_code: string
    prev_address_resident_since: string
    prev_address_street: string
    short_id: string
    title: string
    type_of_residence: string
}
export interface PostGenerateOfferForClientRequest {
    contact_person_id: string
    interest_rate_30_days: number
    interest_rate_60_days: number
    admin_fee_per_invoice: number
    max_advance_payment_percent: number
    is_with_global_assignment: boolean
    is_selective_factoring: boolean
    is_risk_assessment_completed: boolean
}

export interface PostBOUserRequest {
    name: string
    email: string
    role: BoUserRoleID
}


export interface ApplicationAction {
    color: string
    name: string
    params: {
        new_status: string
    }
}

/* NicerPay Types */
export interface ApplicationObject {
    actions: ApplicationAction[]
    application_id: string
    short_id: string
    client_id: string
    merchant_id?: string
    session_id?: string
    merchant_name: string
    created_by_merchant_user_id: string
    created_by_merchant_user_name: string
    bo_assignee_id: string
    type: string
    status: string
    currency: number
    amount: number
    duration_months: number
    repayment_period: string
    paid_out_on: string
    client_email: string
    client_title: string
    client_first_name: string
    client_last_name: string
    client_family_status: string
    client_birth_date: string
    client_birth_city: string
    client_nationality_country_code: string
    client_address_country_code: string
    client_address_street: string
    client_address_house_number: string
    client_address_post_code: string
    client_address_city: string
    client_address_resident_since: string
    client_prev_address_country_code: string
    client_prev_address_street: string
    client_prev_address_house_number: string
    client_prev_address_post_code: string
    client_prev_address_city: string
    client_prev_address_resident_since: string
    client_job_title: string
    client_job_branch: string
    client_job_type: string
    client_employed_since: string
    client_employer_name: string
    client_employer_location: string
    client_net_income: number
    client_type_of_residence: string
    client_living_costs: number
    client_num_children: number
    client_has_vehicle: boolean
    client_product_interest: string
    client_id_doc_type: string
    client_id_doc_issued_place: string
    client_id_doc_number: string
    client_id_doc_issued_at: string
    client_id_doc_expires_at: string
    client_iban: string
    client_bic: string
    client_bank_name: string
    client_mobile_number: string
    is_client_accepted_agb: boolean
    is_client_accepted_privacy_policy: boolean
    is_client_received_pre_contract_info: boolean
    created_at: string
    is_deleted: boolean
}

export interface CreateClientObject {
    email: string
    bo_contact_id?: string
    title?: string
    first_name?: string
    last_name?: string
    family_status?: string
    birth_date?: string
    birth_city?: string
    nationality_country_code?: string
    language_code?: string
    address_country_code?: string
    address_addition?: string
    address_street?: string
    address_house_number?: string
    address_post_code?: string
    address_city?: string
    address_resident_since?: string
    prev_address_country_code?: string
    prev_address_addition?: string
    prev_address_street?: string
    prev_address_house_number?: string
    prev_address_post_code?: string
    prev_address_city?: string
    job_title?: string
    job_branch?: string
    job_type?: string
    employed_since?: string
    employer_name?: string
    employer_location?: string
    net_income?: number
    type_of_residence?: string
    living_costs?: number
    num_children?: number
    has_vehicle?: string
    product_interest?: string
    id_doc_type?: string
    id_doc_issued_place?: string
    id_doc_number?: string
    iban?: string
    mobile_number?: string
}

export interface ClientObject extends CreateClientObject {
    client_id: string
    short_id: string
    id_doc_issued_at: string
    id_doc_expires_at: string
    bic: string
    bank_name: string
    is_accepted_agb: boolean
    is_accepted_privacy_policy: boolean
    is_received_pre_contract_info: boolean
    is_deleted: boolean
    created_at: string
    email_confirmed?: boolean
}

export interface ToDo {
    todo_id: string
    entity_id: string
    entity_kind: string
    created_by: string
    assignee_id: string
    kind: string
    status: string
    details: string
    due_date: string
    send_email: boolean
    created_at: string
    client_name: string
}

export interface CreateToDo {
    entity_id: string
    entity_kind: string
    assignee_id: string
    details: string
    due_date?: string
    send_email: boolean
}

export interface PatchToDo {
    assignee_id: string
    details: string
    due_date: string
    send_email: boolean
}

export enum ToDoStatuses {
    Open = "open",
    Complete = "complete"
}

export enum AdminCreateToDoStates {
    Loading,
    Success,
    Failure,
}

export interface Merchant {
    merchant_id: string
    short_id: string
    name: string
    display_name?: string
    registration_number: string
    tax_id: string
    general_email: string
    general_phone: string
    founding_date: string
    address_country_code: string
    address_street: string
    address_house_number: string
    address_post_code: string
    address_city: string
    website_url: string
    iban: string
    bic: string
    bank_name: string
    kind: string
    status: string
    is_deleted: boolean
    created_at: string
    is_registered: boolean
    contacts: AdminGetMerchantContactResponseItem[]
}
export interface MerchantUser {
    entity_id: string
    short_id: string
    merchant_id: string
    email: string
    title: string
    first_name: string
    last_name: string
    telephone_number: string
    mobile_number: string
    location: string
    created_at: string
    is_deleted: boolean
    is_enabled: boolean
    is_registered: boolean
}

export enum MerchantKind {
    Direct = "direct",
    Webshop = "webshop",
    PoinOfSale = "pos"
}
export interface PostOrPutMerchant {
    name: string
    website_url: string
    registration_number: string
    tax_id: string
    general_email: string
    general_phone: string
    founding_date: string
    address_country_code: string
    address_street: string
    address_house_number: string
    address_post_code: string
    address_city: string
    iban: string
    bic: string
    bank_name: string
    kind: string[]
}

export interface PostOrPutMerchantContact {
    title: string
    first_name: string
    last_name: string
    email: string
    telephone_number: string
}

export enum NoteCategories {
    Other = "other"
}


export interface OfferResponse{
    offer_id: string
    short_id: string
    application_id: string
    partner_bank_name: string
    duration_months: number
    loan_amount: number
    total_amount: number
    interest_rate: number
    repayment_period_months: number
    installment_rate: number
    first_installment: number
    created_at: string
    kyc_url: string | null 
    credit_answer: string | null
    is_selected: boolean
    is_deleted: boolean
    bank_specific_data: {
        payment_id: string
        payever_status: string
        stantander_status: string
        finance_id: string
        application_no: string
        signing_center_link: string
        bank_message?: string
    }
}

