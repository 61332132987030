import React, { useState } from "react";
import styles from "components/common/pages/grant-access-page/GrantAccessPage.module.scss";
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { Button, Card, message, Row, Typography } from "antd";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { clientGrantMerchantAccess } from "api/client/client";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined } from "@ant-design/icons";

export const GrantAccessPage = () => {
    const [grantSuccess, setGrantSuccess] = useState<boolean>(false);
    const { t } = useTranslation();

    function useQuery() {
        return new URLSearchParams(window?.location?.search);
    }

    const query = useQuery();
    const [token, setToken] = React.useState<string>(undefined);

    React.useEffect(() => {
        try {
            const queryToken = query.get("token");

            setToken(queryToken);
        } catch (err) {
            console.error(err);
        }
    }, []);

    const handleGrantAccess = async () => {
        try {
            await clientGrantMerchantAccess(token);
            setGrantSuccess(true);
        } catch (err) {
            console.error(err);
            void message.error(t("errors:clientGrantMerchantAccess"));
        }
    };

    return (
        <div className={styles.page}>
            <ApplicationProcessHeader hideNav={true} loginPage />
            <div className={styles.info}>
                {!grantSuccess ? (
                    <Card bordered={false}>
                        <div className={styles.container}>
                            <Typography.Title level={2}>Daten an Händler freigeben</Typography.Title>

                            <Typography.Paragraph className={styles.paragraph}>
                                Ein Händler hat für folgenden Einkauf Zugriff auf Ihre Daten angefordert:
                            </Typography.Paragraph>

                            <Spacer />
                            <Row className={styles.rowCenter}></Row>
                            <Spacer />

                            <Row className={styles.rowCenter}>
                                <Button type={"primary"} onClick={handleGrantAccess}>
                                    Daten freigeben
                                </Button>
                            </Row>
                            <Spacer />
                            <Spacer />
                            <Typography.Paragraph className={styles.smallParagraph}>
                                Falls Sie die Daten nicht freigeben möchten, oder Sie diesen Händler nicht kennen,
                                können Sie dieses Fenster schliessen. Ihre Daten werden nicht freigegeben.
                            </Typography.Paragraph>
                        </div>
                    </Card>
                ) : (
                    <Card bordered={false}>
                        <div className={styles.container}>
                            <Typography.Title level={2}>Daten erfolgreich weitergeleitet</Typography.Title>
                            <CheckCircleOutlined className={styles.success} />
                            <Spacer />

                            <Typography.Paragraph>
                                Der Händler wird Sie durch den Rest des Antragsprozesses führen. Sie können das Fenster
                                schliessen.
                            </Typography.Paragraph>
                        </div>
                    </Card>
                )}
            </div>
        </div>
    );
};
