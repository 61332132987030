import { Layout, Tabs } from "antd";
import { adminCreateClientApplication, adminGetApplication } from "api/admin/admin";
import { ApplicationObject } from "api/types";
import { ApplicationDetailsTab } from "components/admin/presenters/applications-drilldown-tabs/application-details-tab/ApplicationDetailsTab";
import { NotesTab, NotesTabType } from "components/admin/presenters/applications-drilldown-tabs/notes-tab/NotesTab";
import { OfferTab } from "components/admin/presenters/applications-drilldown-tabs/offer-tab/OfferTab";
import { NewOfferModal } from "components/common/presenters/new-offer-modal/NewOfferModal";
import { SideMenu } from "components/common/presenters/side-menu/SideMenu";
import { wait } from "helpers/misc";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AdminCreateApplicationForm, ApplicationTypes } from "types/applicationProcess";
import { Application, ApplicationStates, Currencies } from "types/types";
import { TopInfoSection, TopInfoSectionType } from "../../../common/presenters/top-info-section/TopInfoSection";
import { DocumentsTab } from "../../presenters/applications-drilldown-tabs/documents-tab/DocumentsTab";
import styles from "./ApplicationDrilldownPage.module.scss";
import "./TabMenu.scss";

const { TabPane } = Tabs;
const { Header, Content } = Layout;

export enum AdminCreateApplicationStates {
    Loading,
    User_Accepted,
    Bank_Rejection,
    Address_Not_Found,
}

export const ApplicationDrilldownPage = () => {
    const { t } = useTranslation();

    let { applicationId } = useParams<any>();

    const [appl, setAppl] = useState({} as Application);
    const [updatedApplStatus, setUpdatedApplStatus] = useState<null | ApplicationStates>();
    const [applObject] = useState({} as ApplicationObject);
    const [error, setError] = useState("");

    const [newOfferModalOpen, setNewOfferModalOpen] = useState(false);
    const [newAppl, setNewAppl] = useState({} as ApplicationObject);

    const [createApplicationResult, setCreateApplicationResult] = useState<AdminCreateApplicationStates | undefined>(
        undefined
    );

    // const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

    const tabTitles = [t("applicationDetails"), t("documents"), t("notes"), t("offerTab")];

    const [activeTab, setActiveTab] = useState("-1");

    const onTabChange = (key) => {
        setActiveTab(key);
    };

    const getDataAndSetState = async (): Promise<any> => {
        // separate try-catch block for each req group, because otherwise if 1 fails, the rest don't get executed
        try {
            setError("");

            const applReq = await adminGetApplication(applicationId);
            setAppl(applReq.data);

            // const cpListReq = await adminGetAllContactPersonsForClient(applReq.data.client_id);
            // setCpList(cpListReq.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleNewReminderSelected = () => {};

    const handleNewToDoSelected = () => {};

    const handleNewApplicationSelected = () => {
        setNewOfferModalOpen(true);
    };

    const handleCreateApplication = async (values: AdminCreateApplicationForm): Promise<void> => {
        try {
            const data = {
                // TODO: later replace with real merchant_id once we have merchants
                merchant_id: "merch_1uRGGynjyh65bWvX5CbXLkqGGPr",
                application_type: ApplicationTypes.POS_LOAN,
                currency: Currencies.EUR,
                amount: values.financing_amount,
                duration: values.installements,
            };

            setCreateApplicationResult(AdminCreateApplicationStates.Loading);

            await wait(1500);

            const createApplReq = await adminCreateClientApplication(appl?.client_id, data);
            const newAppl = await adminGetApplication(createApplReq?.data);

            setNewAppl(newAppl.data);
            setCreateApplicationResult(AdminCreateApplicationStates.User_Accepted);
        } catch (error) {
            console.error(error);

            // TODO: for now this is the only rejection reason, but this needs to change once the reasons are added to the server response
            setCreateApplicationResult(AdminCreateApplicationStates.Bank_Rejection);
        }
    };

    const handleCloseNewApplicationModal = () => {
        setNewOfferModalOpen(false);
    };

    useEffect(() => {
        try {
            getDataAndSetState();
        } catch (error) {
            console.error(error);
        }
    }, []);

    return (
        <Layout className={styles.page}>
            <SideMenu isLoggedIn={true} />

            <Layout className={styles.sectionRight}>
                <Header className={styles.header}>
                    <TopInfoSection
                        type={TopInfoSectionType.TypeApplication}
                        companyName={"Muster Shop GmbH"}
                        applicationId={applicationId}
                        application={appl}
                        applicationState={updatedApplStatus || appl?.status}
                        applicationType={appl?.type}
                        dropdownOptions={[t("newReminder"), t("newToDo"), t("newApplication")]}
                        dropdownActions={[
                            handleNewReminderSelected,
                            handleNewToDoSelected,
                            handleNewApplicationSelected,
                        ]}
                    />
                </Header>

                <Content className={styles.clientDrill}>
                    <Tabs className="tab-menu" defaultActiveKey="-1" activeKey={activeTab} onChange={onTabChange}>
                        <TabPane tab={tabTitles[0]} key={`-1`}>
                            <ApplicationDetailsTab
                                key="adt"
                                application={appl}
                                applObject={applObject}
                                appId={applicationId}
                                updateStatus={setUpdatedApplStatus}
                            />
                        </TabPane>

                        <TabPane tab={tabTitles[1]} key={`2`}>
                            <DocumentsTab key="dt" applicationId={applicationId} type="application" />
                        </TabPane>

                        <TabPane tab={tabTitles[2]} key={`3`}>
                            <NotesTab key="nt" entityId={applicationId} type={NotesTabType.Application} />
                        </TabPane>

                        <TabPane tab={tabTitles[3]} key={`4`}>
                            <OfferTab key="ot" applicationId={applicationId} />
                        </TabPane>
                    </Tabs>
                </Content>

                <NewOfferModal
                    open={newOfferModalOpen}
                    onOk={handleCreateApplication}
                    onCancel={handleCloseNewApplicationModal}
                    state={createApplicationResult}
                    newAppl={newAppl}
                    error={error}
                />
            </Layout>
        </Layout>
    );
};
