import React from "react";
import { Redirect, Route, Switch, Router } from "react-router-dom";
import { AdminHomePage } from "components/admin/pages/admin-home/AdminHomePage";
import { ApplicationDrilldownPage } from "components/admin/pages/application-drilldown/ApplicationDrilldownPage";
import { ClientDrilldownPage } from "components/admin/pages/client-drilldown/ClientDrilldownPage";
import { AdminMerchantUserDrilldownPage } from "components/admin/pages/merchant-drilldown/AdminMerchantContactDrilldownPage";
import { AdminMerchantDrilldownPage } from "components/admin/pages/merchant-drilldown/AdminMerchantDrilldownPage";
import { NotFoundPage } from "components/common/pages/404-page/NotFoundPage";
import { MerchantApplicationStepOnePage } from "components/common/pages/application-flow/merchant/step-1/MerchantApplicationStepOnePage";
import { ApplicationStepOnePage } from "components/common/pages/application-flow/step-1/ApplicationStepOnePage";
import { ApplicationStepTwoPage } from "components/common/pages/application-flow/step-2/ApplicationStepTwoPage";
import { ApplicationStepThreePage } from "components/common/pages/application-flow/step-3/ApplicationStepThreePage";
import { ApplicationStepConfirmationPage } from "components/common/pages/application-flow/step-confirmation/ApplicationStepConfirmationPage";
import { ApplicationStepLoanInfo } from "components/common/pages/application-flow/step-loan-info/ApplicationStepLoanInfoPage";
import { ApplicationStepOfferPage } from "components/common/pages/application-flow/step-offer/ApplicationStepFourOfferPage";
import { AuthenticationPage } from "components/common/pages/authentication-page/AuthenticationPage";
import { ClientDashboardPage } from "components/common/pages/client-dashboard/ClientDashboardPage";
import { ClientSettingsPage} from "components/common/pages/client-settings/ClientSettingsPage";
import { ConfirmEmailPage } from "components/common/pages/confirm-email-page/ConfirmEmailPage";
import { MerchantLoginPage } from "components/common/pages/merchant-login-page/MerchantLoginPage";
import { StepOneResetPasswordPage } from "components/common/pages/step-one-reset-password-page/StepOneResetPasswordPage";
import { MerchantApplicationDrilldownPage } from "components/merchant-panel/application-drilldown/MerchantApplicationDrilldownPage";
import { MerchantHomePage } from "components/merchant-panel/merchant-home/MerchantHomePage";
import { isAdminApp, isClientApp, isMerchantApp, isProductionApp } from "helpers/appHelpers";
import hashHistory from "helpers/hashHistory";
import history from "helpers/history";
import { RouteStrings } from "routes/RouteStrings";
import { AppProvider } from "storage/context/appContext";
import { AcceptInvitationPage } from "../components/common/pages/accept-invitaion-page/AcceptInvitationPage";
import { ResetPasswordPage } from "../components/common/pages/reset-password-page/ResetPasswordPage";
import { GrantAccessPage } from "components/common/pages/grant-access-page/GrantAccessPage";
import MockShop from "components/mock-shop/shop/MockShop";
import MockCart from "components/mock-shop/shop/MockCart";
import { ShopProvider } from "components/mock-shop/storage/shopContext";

const getClientAndAdminApplicationFlow = () => {
    if (isClientApp() && isProductionApp()) {
        return undefined;
    }

    return (
        <Route exact path={RouteStrings.ApplicationFlowBase}>
            <Router history={hashHistory}>
                <AppProvider>
                    <Switch>
                        <Route exact path={RouteStrings.Index}>
                            <Redirect to={RouteStrings.ApplicationFlowStepOne} />
                        </Route>
                        <Route exact path={RouteStrings.ApplicationFlowStepOne} component={ApplicationStepOnePage} />
                        <Route exact path={RouteStrings.ApplicationFlowStepTwo} component={ApplicationStepTwoPage} />
                        <Route
                            exact
                            path={RouteStrings.ApplicationFlowStepThree}
                            component={ApplicationStepThreePage}
                        />
                        <Route
                            exact
                            path={RouteStrings.ApplicationFlowStepConfirmation}
                            component={ApplicationStepConfirmationPage}
                        />
                        <Route
                            exact
                            path={RouteStrings.ApplicationFlowStepOffer}
                            component={ApplicationStepOfferPage}
                        />

                        <Route path={"*"} component={NotFoundPage} />
                    </Switch>
                </AppProvider>
            </Router>
        </Route>
    );
};

const getMerchantApplicationFlow = () => {
    // needs the same provider?
    return (
        <Route exact path={RouteStrings.ApplicationFlowBase}>
            <Router history={hashHistory}>
                <AppProvider>
                    <Switch>
                        <Route exact path={RouteStrings.Index}>
                            <Redirect to={RouteStrings.ApplicationFlowStepOne} />
                        </Route>
                        <Route path={RouteStrings.ApplicationFlowStepOne} component={MerchantApplicationStepOnePage} />
                        <Route path={RouteStrings.ApplicationFlowStepLoanInfo} component={ApplicationStepLoanInfo} />
                        <Route path={RouteStrings.ApplicationFlowStepTwo} component={ApplicationStepTwoPage} />
                        <Route path={RouteStrings.ApplicationFlowStepThree} component={ApplicationStepThreePage} />
                        <Route
                            path={RouteStrings.ApplicationFlowStepConfirmation}
                            component={ApplicationStepConfirmationPage}
                        />
                        <Route path={RouteStrings.ApplicationFlowStepOffer} component={ApplicationStepOfferPage} />

                        <Route path={"*"} component={NotFoundPage} />
                    </Switch>
                </AppProvider>
            </Router>
        </Route>
    );
};

const setupAdminRoutes = (): JSX.Element => {
    return (
        <React.Fragment>
            <Route
                exact
                path={[RouteStrings.SignUp, RouteStrings.Login]}
                render={(props) => <AuthenticationPage {...props} />}
            />

            <Route
                exact
                path={[RouteStrings.Index, RouteStrings.AdminRouteBase, RouteStrings.Login, RouteStrings.SignUp]}
            >
                <Redirect to={RouteStrings.AdminLogin} />
            </Route>

            <Route exact path={[RouteStrings.AdminLogin]} render={(props) => <AuthenticationPage {...props} />} />

            <Route path={RouteStrings.AdminHome} component={AdminHomePage} />

            <Route path={RouteStrings.AdminApplicationDrilldown} component={ApplicationDrilldownPage} />

            <Route path={RouteStrings.AdminMerchantDrilldown} component={AdminMerchantDrilldownPage} />
            <Route path={RouteStrings.AdminMerchantContactDrilldown} component={AdminMerchantUserDrilldownPage} />

            <Route path={RouteStrings.AdminClientDrilldown} component={ClientDrilldownPage} />

            <Route path={RouteStrings.AdminAcceptInvitation} component={AcceptInvitationPage} />
            <Route path={RouteStrings.AdminResetPassword} component={ResetPasswordPage} />
            <Route path={RouteStrings.ResetPassword} component={StepOneResetPasswordPage} />

            {getClientAndAdminApplicationFlow()}
        </React.Fragment>
    );
};

const setupClientRoutes = (): JSX.Element => {
    return (
        <React.Fragment>
            <Route exact path={RouteStrings.Index}>
                <Redirect to={RouteStrings.ApplicationFlowBase} />
            </Route>
            <Route
                exact
                path={[RouteStrings.SignUp, RouteStrings.Login]}
                render={(props) => <AuthenticationPage {...props} />}
            />

            <Route path={RouteStrings.ClientAcceptInvitation} component={AcceptInvitationPage} />
            <Route path={RouteStrings.ClientGrantAccess} component={GrantAccessPage} />
            {/* <Route path={RouteStrings.AdminResetPassword} component={ResetPasswordPage} /> */}
            <Route path={RouteStrings.ConfirmEmail} component={ConfirmEmailPage} />
            <Route path={RouteStrings.ResetPassword} component={StepOneResetPasswordPage} />
            <Route path={RouteStrings.ClientDashboard} component={ClientDashboardPage} />
            <Route path={RouteStrings.ClientSettingsPage} component={ClientSettingsPage} />

            {getClientAndAdminApplicationFlow()}

            {process.env.NODE_ENV === "development" && <Route exact path={"/mock/shop"} component={MockShop} />}
            {process.env.NODE_ENV === "development" && <Route exact path={"/mock/cart"} component={MockCart} />}
        </React.Fragment>
    );
};

const setupMerchantRoutes = (): JSX.Element => {
    return (
        <React.Fragment>
            {/* TODO: move towards a single authentication page, which logs you in based on the AppType */}
            <Route exact path={RouteStrings.Index}>
                <Redirect to={RouteStrings.Login} />
            </Route>
            <Route exact path={RouteStrings.MerchantApplicationBase}>
                <Redirect to={RouteStrings.Login} />
            </Route>
            <Route
                exact
                path={[RouteStrings.SignUp, RouteStrings.Login]}
                render={(props) => <MerchantLoginPage {...props} />}
            />
            <Route path={RouteStrings.MerchantHome} component={MerchantHomePage} />
            <Route path={RouteStrings.MerchantApplicationDrilldown} component={MerchantApplicationDrilldownPage} />
            <Route path={RouteStrings.MerchantAcceptInvitation} component={AcceptInvitationPage} />
            <Route path={RouteStrings.MerchantResetPassword} component={ResetPasswordPage} />
            {getMerchantApplicationFlow()}
        </React.Fragment>
    );
};

// TODO: add authenticated routes
export const Routes: React.FC<any> = () => {
    const clientRoutes = (
        <ShopProvider>
            <Switch>
                {setupClientRoutes().props.children}
                <Route exact path={RouteStrings.NotFoundPage} component={NotFoundPage} />
                <Route path={"*"} component={NotFoundPage} />
            </Switch>
        </ShopProvider>
    );

    const adminRoutes = (
        <Switch>
            {setupAdminRoutes().props.children}
            <Route exact path={RouteStrings.NotFoundPage} component={NotFoundPage} />
            <Route path={"*"} component={NotFoundPage} />
        </Switch>
    );

    const merchantRoutes = (
        <Switch>
            {setupMerchantRoutes().props.children}
            <Route exact path={RouteStrings.NotFoundPage} component={NotFoundPage} />
            <Route path={"*"} component={NotFoundPage} />
        </Switch>
    );

    return (
        <Router history={history}>
            {isAdminApp() && adminRoutes}

            {isClientApp() && clientRoutes}

            {isMerchantApp() && merchantRoutes}

            {/* TODO: setup client routes once we have specs for that part of the app */}
            {/* <Route path={RouteStrings.ApplicationsOverview} component={ApplicationsPage} />
                <Route path={RouteStrings.ApplicationDrilldown} component={UserApplicationDrilldownPage} />
                <Route path={RouteStrings.MyContracts} component={MyContractsPage} />
                <Route path={RouteStrings.UserSettings} component={SettingsPage} />
                <Route path={RouteStrings.UserAddBankAccount} component={AddBankAccountPage} /> */}
            {/* <Route path={RouteStrings.ConfirmEmail} component={ConfirmEmailPage} /> */}
            {/* <Route path={RouteStrings.ResetPassword} component={StepOneResetPasswordPage} /> */}
        </Router>
    );
};
