import { Button, message, Modal, PageHeader } from "antd";
import { adminCreateMerchant } from "api/admin/merchants";
import { PostOrPutMerchant } from "api/types";
import { CreateEditMerchantStepConfirm } from "components/admin/presenters/create-edit-merchant-modal/CreateEditMerchantStepConfirm";
import { CreateEditMerchantStepOne } from "components/admin/presenters/create-edit-merchant-modal/CreateEditMerchantStepOne";
import { CreateEditMerchantStepSuccess } from "components/admin/presenters/create-edit-merchant-modal/CreateEditMerchantStepSuccess";
import { CreateEditMerchantStepTwo } from "components/admin/presenters/create-edit-merchant-modal/CreateEditMerchantStepTwo";
import { ConfirmModalClose } from "components/common/presenters/confirm-modal-close/ConfirmModalClose";
import { ProgressBar } from "components/common/presenters/progress-bar/ProgressBar";
import { formatDateForAPI } from "helpers/timeHelpers";
import i18n from "i18n/i18n";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateEditMerchantFlow, CreateEditMerchantFlowStepOne, MerchantContactFromFlow } from "types/adminPanel";
import styles from "./CreateEditMerchantModal.module.scss";

interface CreateEditMerchantModalProps {
    modalOpen: boolean
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    // existingClientData: GetClientResponse
    onDataChanged?: () => void
}

export enum CreateEditMerchantModalSteps {
    MerchantInfo,
    ContactPersonInfo,
    Confirmation,
    Success
}

export const CreateEditMerchantModal = (props: CreateEditMerchantModalProps) => {
    const {t} = useTranslation();

    const [activeStep, setActiveStep] = React.useState<CreateEditMerchantModalSteps>(CreateEditMerchantModalSteps.MerchantInfo);

    const [secondaryModalOpen, setSecondaryModalOpen] = React.useState(false);

    const [state, setState] = useState<CreateEditMerchantFlow>({
        merchantInfo: {
            company_name: "",
            hr_number: "",
            website: "",
            vat_number: "",
            street: "",
            house_number: "",
            post_code: "",
            city: "",
            country: "",
            foundation_date: "",
            iban: "",
            bank: "",
            bic: "",
            company_email: "",
            phone_number: "",
            kind: [],
        },
        contactPersons: [],
    });

    const isUpdateOperation = false; // props.existingClientData ? true : false;

    const checkForm = () => {
        // check if at least one value is filled in a form.
        const notEmpty = Object.values(state).some((value) => value !== undefined || "");
        return notEmpty;
    };
    useEffect(() => {
        setActiveStep(CreateEditMerchantModalSteps.MerchantInfo);
    }, []);

    useEffect(() => {
        // setData(defaultState);
    }, [props.modalOpen]);

    const handleContinue = async (values) => {
        try {
            if (isUpdateOperation) {
                // TODO: implement adminUpdateMerchant() if needed.

            } else {
                if (activeStep === CreateEditMerchantModalSteps.MerchantInfo) {
                    // Persist data
                    setActiveStep(CreateEditMerchantModalSteps.ContactPersonInfo);
                } else if (activeStep === CreateEditMerchantModalSteps.ContactPersonInfo) {
                    // Persist data
                    setActiveStep(CreateEditMerchantModalSteps.Confirmation);
                } else if (activeStep === CreateEditMerchantModalSteps.Confirmation) {
                    // submit data to the backend
                    const data: PostOrPutMerchant = {
                        name: state.merchantInfo.company_name,
                        website_url: state.merchantInfo.website,
                        registration_number: state.merchantInfo.hr_number,
                        tax_id: state.merchantInfo.vat_number,
                        general_email: state.merchantInfo.company_email,
                        general_phone: state.merchantInfo.phone_number,
                        founding_date: formatDateForAPI(state.merchantInfo.foundation_date),
                        address_country_code: state.merchantInfo.country,
                        address_street: state.merchantInfo.street,
                        address_house_number: state.merchantInfo.house_number,
                        address_post_code: state.merchantInfo.post_code,
                        address_city: state.merchantInfo.city,
                        iban: state.merchantInfo.iban,
                        bic: state.merchantInfo.bic,
                        bank_name: state.merchantInfo.bank,
                        kind: state.merchantInfo.kind
                    };

                    await adminCreateMerchant(data);
                    message.success(t('messages:dataSaved'), 2);
                    setActiveStep(CreateEditMerchantModalSteps.Success);
                    
                    props.onDataChanged();
                }
            }
        } catch (e) {
            message.error(t('messages:couldNotSave'), 2);
            console.error(e);
        }
    };

    const secondaryModalOnCancel = () => {
        setSecondaryModalOpen(false);
    };

    const secondaryModalOnOk = () => {
        setSecondaryModalOpen(false);
        props.closeModal(false); 
    };

    const steps = [
        {
            title: `${i18n.t("modals:merchantModal_Step1")}`,
            subtitle: "",
            description: ""
        },
        {
            title: `${i18n.t("modals:merchantModal_Step2")}`,
            subtitle: "",
            description: ""
        },
        {
            title: `${i18n.t("modals:merchantModal_Step3")}`,
            subtitle: "",
            description: ""
        }
    ];

    const handleStepOneSubmitted = (form: CreateEditMerchantFlowStepOne) => {
        setState({
            ...state,
            merchantInfo: {
                ...form,
                foundation_date: moment(form?.foundation_date).format()
            }
        });

        setActiveStep(CreateEditMerchantModalSteps.ContactPersonInfo);
    };

    const handleStepTwoSubmitted = (form: MerchantContactFromFlow[]) => {
        
        setState({
            ...state,
            contactPersons: [
                ...form
            ]
        });
        
        setActiveStep(CreateEditMerchantModalSteps.Confirmation);
    };

    const handleStepConfirmSubmitted = () => {
        return null;
    };

    const handleStepSuccessSubmitted = () => {
        props.closeModal(false); 
    };

    const renderForm = (): JSX.Element => {
        switch (activeStep) {
        case CreateEditMerchantModalSteps.MerchantInfo:
            return <CreateEditMerchantStepOne data={state} onSubmit={handleStepOneSubmitted}/>;
        case CreateEditMerchantModalSteps.ContactPersonInfo:
            return <CreateEditMerchantStepTwo data={state} onSubmit={handleStepTwoSubmitted} setActiveStep={setActiveStep}/>;
        case CreateEditMerchantModalSteps.Confirmation:
            return <CreateEditMerchantStepConfirm onSubmit={handleStepConfirmSubmitted} merchant={state} setActiveStep={setActiveStep}/>;
        case CreateEditMerchantModalSteps.Success:
            return <CreateEditMerchantStepSuccess onSubmit={handleStepSuccessSubmitted}/>;
        default:
            return null;
        }
    };

    const renderModalFooter = (): JSX.Element => {
        switch (activeStep) {
        case CreateEditMerchantModalSteps.MerchantInfo:
            return null;
        case CreateEditMerchantModalSteps.ContactPersonInfo:
            return null;
        case CreateEditMerchantModalSteps.Confirmation:
            return (
                <div key="fr" className={styles.footerRow}>
                    <Button className={styles.buttonCancel} key="back"
                        onClick={(e: any) => {
                            setActiveStep(CreateEditMerchantModalSteps.ContactPersonInfo);
                        }}>
                        { t("buttons:back") }
                    </Button>
                    <Button className={styles.buttonOk} key="submit" onClick={(values) => {
                        handleContinue(values);
                    }}>
                        { t("buttons:submit") }
                    </Button>
                </div>
            );
        case CreateEditMerchantModalSteps.Success:
            return null;
        default:
            return (
                <div key="fr" className={styles.footerRow}>
                    <Button className={styles.buttonCancel} key="cancel"
                        onClick={(e: any) => {
                            props.closeModal(false); 
                        }}>
                        { t("buttons:cancel") }
                    </Button>
                    <Button className={styles.buttonOk} key="submit" onClick={(values) => {
                        handleContinue(values);
                    }}>
                        { t("buttons:submit") }
                    </Button>
                </div>
            );
        }
    };

    return (
        <div className={styles.container}>
    
            <ConfirmModalClose visible={secondaryModalOpen} onCancel={secondaryModalOnCancel} onOk={secondaryModalOnOk}/>

            <Modal width={1000}
                className={styles.modal}
                centered
                visible={props.modalOpen}
                destroyOnClose={true}
                maskClosable
                okText={t('buttons:ok')}
                cancelText={t('buttons:cancel')}
                afterClose={() => {
                    // Resets the modal step.
                    setActiveStep(CreateEditMerchantModalSteps.MerchantInfo);
                }}
                onCancel={(e: any) => {
                    if (!checkForm()) {
                        props.closeModal(false);
                    } else {
                        setSecondaryModalOpen(true);
                    }
                }}
                footer={renderModalFooter()}>

                <PageHeader
                    className={styles.header}
                    ghost={false}
                    title=""
                    subTitle=""
                >
                    <ProgressBar activeStep={activeStep} steps={steps} type={"navigation"}/>
                </PageHeader>
                
                {
                    renderForm()
                }

            </Modal>
        </div>
    );
};
