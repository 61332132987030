import { appActions } from "storage/reducers/appReducer";

export const setCurrentPage = (newPage: string) => {
    return {
        type: appActions.SET_PAGE,
        payload: { newPage },
    };
};

export const setLastSuccessPage = (lastPage: string) => {
    sessionStorage.setItem(
        "session:lastSuccessPage",
        JSON.stringify({
            lastSuccessPage: lastPage,
        })
    );
    return {
        type: appActions.SET_SUCCESSFUL_PAGE,
        payload: { lastPage },
    };
};
