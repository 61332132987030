import { Button, Checkbox, Col, DatePicker, Divider, Form, Input, Row, Select } from "antd";
import { DATE_FORMAT } from "api/apiConfig";
import { AnchoredSelect } from "components/common/presenters/anchored-select/AnchoredSelect";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { CreateEditMerchantFlow, CreateEditMerchantFlowStepOne } from "types/adminPanel";
import { CountryCodesList } from "types/applicationProcess";
import { getPostCodeValidationPattern } from "validators/inputs";
import styles from "./CreateEditMerchantModal.module.scss";

export interface CreateEditMerchantStepOneProps {
    data: CreateEditMerchantFlow
    onSubmit: (values: CreateEditMerchantFlowStepOne) => void
}

export const CreateEditMerchantStepOne = (props: CreateEditMerchantStepOneProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const handleOnFinish = async (values: CreateEditMerchantFlowStepOne) => {
        props.onSubmit(values);
    };

    const onCheckboxChange = (vals) => {
        form.setFieldsValue({
            kind: vals,
        });
    };
    const data = {
        ...props.data.merchantInfo,
        foundation_date: undefined,
    };

    if (props.data?.merchantInfo?.foundation_date) {
        data.foundation_date = moment(props.data?.merchantInfo.foundation_date);
    }
    const merchantTypeOptions = [
        { label: t("webshop"), value: "webshop" },
        { label: t("directSales"), value: "direct" },
        { label: t("pointOfSale"), value: "pos" },
    ];

    return (
        <Form layout={"vertical"} form={form} initialValues={data} onFinish={handleOnFinish}>
            <div className={styles.inputsContainer}>
                <h4 className={styles.boldText}>{t("companyData")}</h4>
                <Row gutter={36}>
                    <Col span={12}>
                        <Form.Item
                            name="company_name"
                            label={t("company")}
                            hasFeedback
                            rules={[{ required: true, message: t("errors:invalidData"), min: 1, max: 100 }]}
                        >
                            <Input data-cy="company_name" className={styles.input} />
                        </Form.Item>

                        <Form.Item
                            name="hr_number"
                            label={t("hrNumber")}
                            hasFeedback
                            rules={[{ required: true, message: t("errors:invalidData"), min: 2, max: 100 }]}
                        >
                            <Input data-cy="hr_number" className={styles.input} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="website" label={t("website")} hasFeedback>
                            <Input data-cy="birth_city" className={styles.input} />
                        </Form.Item>

                        <Form.Item
                            name="vat_number"
                            label={t("vatNumberLong")}
                            hasFeedback
                            rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                        >
                            <Input data-cy="vat_number" className={styles.input} />
                        </Form.Item>
                    </Col>
                </Row>

                <Spacer />

                <Row gutter={36}>
                    <Col span={12}>
                        <h4 className={styles.boldText}>{t("addressData")}</h4>
                        <Row>
                            <Col span={16}>
                                <Form.Item
                                    name="street"
                                    label={t("streetName")}
                                    hasFeedback
                                    rules={[{ required: true, message: t("errors:invalidData"), min: 1, max: 100 }]}
                                >
                                    <Input data-cy="street" className={styles.input} />
                                </Form.Item>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={6}>
                                <Form.Item
                                    name="house_number"
                                    label={t("streetNumber")}
                                    hasFeedback
                                    rules={[{ required: true, message: t("errors:invalidData"), min: 1, max: 100 }]}
                                >
                                    <Input data-cy="house_number" className={styles.input} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={6}>
                                <Form.Item
                                    name="post_code"
                                    label={t("postCode")}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("formErrors:invalidPostCode"),
                                            pattern: getPostCodeValidationPattern(),
                                        },
                                    ]}
                                >
                                    <Input data-cy="post_code" className={styles.input} />
                                </Form.Item>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={16}>
                                <Form.Item
                                    name="city"
                                    label={t("city")}
                                    hasFeedback
                                    rules={[{ required: true, message: t("errors:invalidData"), min: 1, max: 100 }]}
                                >
                                    <Input data-cy="city" className={styles.input} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item
                            name="address_country_code"
                            label={t("country")}
                            hasFeedback
                            rules={[{ required: true, message: t("errors:invalidData"), min: 1, max: 100 }]}
                        >
                            <AnchoredSelect data-cy="address_country_code" className={styles.select} allowClear>
                                {CountryCodesList.map((val, i) => {
                                    return (
                                        <Select.Option data-cy={`address_country_option_${i}`} key={i} value={val}>
                                            {t(`countries:${val}`)}
                                        </Select.Option>
                                    );
                                })}
                            </AnchoredSelect>
                        </Form.Item>

                        <Form.Item name="foundation_date" label={t("foundationDate")} hasFeedback>
                            <DatePicker className={styles.datePicker} format={DATE_FORMAT.DE} allowClear={true} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <h4 className={styles.boldText}>{t("bankInfo")}</h4>

                        <Form.Item
                            name="iban"
                            label={t("iban")}
                            hasFeedback
                            rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                        >
                            <Input data-cy="iban" className={styles.input} />
                        </Form.Item>

                        <Form.Item
                            name="bank"
                            label={t("bankName")}
                            hasFeedback
                            rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                        >
                            <Input data-cy="bank" className={styles.input} />
                        </Form.Item>

                        <Form.Item
                            name="bic"
                            label={t("bic")}
                            hasFeedback
                            rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                        >
                            <Input data-cy="bic" className={styles.input} />
                        </Form.Item>
                        <Form.Item name="kind" label={t("kind")} hasFeedback>
                            <Checkbox.Group options={merchantTypeOptions} onChange={onCheckboxChange}></Checkbox.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Spacer />
                <Divider />

                <h4 className={styles.boldText}>{t("companyContactInfo")}</h4>
                <Row gutter={36}>
                    <Col span={12}>
                        <Form.Item
                            name="company_email"
                            label={t("email")}
                            hasFeedback
                            rules={[
                                { required: true, type: "email", message: t("errors:invalidData"), min: 5, max: 150 },
                            ]}
                        >
                            <Input
                                data-cy="company_email"
                                className={styles.input}
                                placeholder={`${t("example")} ${t("placeholders:email")}`}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name="phone_number"
                            label={t("phone")}
                            hasFeedback
                            rules={[{ required: true, message: t("errors:invalidData"), min: 5, max: 150 }]}
                        >
                            <Input
                                data-cy="phone_number"
                                className={styles.input}
                                placeholder={`${t("example")}${t("placeholders:phone")}`}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Spacer />

                <Row className={styles.rowCenter} gutter={36}>
                    <Button data-cy="button_continue" type="primary" htmlType="submit">
                        {t("buttons:continue")}
                    </Button>
                </Row>
            </div>
        </Form>
    );
};
