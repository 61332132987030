import * as React from "react";
import { useEffect, useState } from "react";
import { DocumentSectionActor, DocumentsSection } from "../../documents-section/DocumentsSection";
import { adminGetClientDocuments } from "../../../../../api/document-service/adminOnClientDocuments";
import { GetDocumentsListResponse } from "../../../../../api/types";
import { DocumentType } from "../../../../../types/types";
import { PaginationType } from "types/adminPanel";

export interface UserDocsTabProps {
    clientId: string
}

export const UserDocsTab = (props: UserDocsTabProps) => {
    // const { t, i18n } = useTranslation();

    const [docs, setDocs] = useState([] as GetDocumentsListResponse[]);
    const [pagination, setPagination] = useState<PaginationType>({});

    const fetchClientDocuments = async (page: number) => {
        try {
            const docsReq = (await adminGetClientDocuments(props.clientId, page)) as any;
            setDocs(docsReq?.result);
            setPagination(docsReq?.pagination);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchClientDocuments(1);
    }, [props.clientId]);

    return (
        <DocumentsSection
            actor={DocumentSectionActor.Admin}
            entityType={DocumentType.Client}
            entityId={props.clientId}
            documents={docs}
            onDataChanged={(pageNum: number) => fetchClientDocuments(pageNum)}
            pagination={{
                current: pagination?.current_page,
                total: pagination?.total_rows,
                pageSize: pagination?.items_per_page,
                showSizeChanger:false,
            }}
        />
    );
};
