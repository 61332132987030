import {PageHeader} from 'antd';
import styles from "components/common/presenters/custom-page-header/CustomPageHeader.module.scss";
import {ProgressBar} from 'components/common/presenters/progress-bar/ProgressBar';
import {getCurrentPageStepNumber, getFlowSteps} from 'navigation/applicationSteps';
import React, {useEffect} from 'react';

export interface CustomPageHeaderProps {
    activeStep: number
    onBack?: () => void
    small?: boolean
}

export const CustomPageHeader = (props) => {
    const [activeStep, setActiveStep] = React.useState(null);

    // The header should know which step it's on automatically instead of manually passing
    // the active step in each place where CustomPageHeader is used.
    // That way we can reorder steps and won't need to update each step individually.
    useEffect(() => {
        let stepNumber = getCurrentPageStepNumber();
        setActiveStep(stepNumber);
    });

    // This component is not responsive out of the box when used with <Steps/>: https://github.com/ant-design/ant-design/issues/14463
    return (
        <PageHeader
            className={styles.header}
            ghost={false}
            title=""
            subTitle=""
        >
            <ProgressBar small={props.small} activeStep={activeStep} steps={getFlowSteps()}/>
        </PageHeader>
    );
};
