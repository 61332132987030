export const FINANCIAL = {
    MIN_NEGATIVE_VALUE: -1000000000,
    MIN_POSITIVE_VALUE: 0,
    MAX_POSITIVE_VALUE: 1000000000
};

export const COMPANY_SEARCH = {
    SEARCH_DELAY_MS: 400,
    SHOW_RESULTS_DELAY_MS: 400,
    NUMBER_OF_ROWS: 4
};

export const NETWORK_REQUEST_TIMEOUT_MS = 30000;

export const DATE_FORMAT = {
    API: 'YYYY-MM-DD',
    DE: 'DD.MM.YYYY',
    DE_YYYY_MME: 'MM.YYYY',
    DE_HH_MM: 'DD.MM.YYYY hh:mm',
    DE_HH_MM_SS: 'DD.MM.YYYY hh:mm:ss'
};

export const URL_BASE = process.env.REACT_APP_USER_SERVICE_URL || "https://localhost:8080";
