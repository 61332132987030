import { ArrowLeftOutlined, EditOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Row, Spin, Typography } from "antd";
import styles from "components/common/pages/application-flow/ApplicationFlow.module.scss";
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { BackButton } from "components/common/presenters/back-button/BackButton";
import { CustomPageHeader } from "components/common/presenters/custom-page-header/CustomPageHeader";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { isAdminApp, isClientApp } from "helpers/appHelpers";
import { checkAuth, checkAuthMerchant } from "helpers/authHelpers";
import hashHistory from "helpers/hashHistory";
import history from "helpers/history";
import { pageLoad } from "helpers/pageLoad";
import { preventStepSkip } from "helpers/preventStepSkip";
import useMobileCheck from "helpers/useMobileCheck";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteStrings } from "routes/RouteStrings";
import { setLastSuccessPage } from "storage/actions/appActions";
import { AppContext } from "storage/context/appContext";
import { LocalApplicationFlowDataManager } from "storage/LocalApplicationFlowDataManager";
import { ApplicationFlowForm } from "types/applicationProcess";
import OrderSummary from "../summary/OrderSummary";

export const ApplicationStepConfirmationPage = () => {
    const { t } = useTranslation();

    const [loading, setLoading] = React.useState(false);
    const [checkingLoggedIn, setCheckingLoggedIn] = React.useState(false);
    const [generalError] = React.useState("");
    const [{ lastSuccessPage }, dispatch] = React.useContext(AppContext);
    const [appl, setAppl] = React.useState({} as ApplicationFlowForm);

    const applDataManager = new LocalApplicationFlowDataManager();
    const applData = applDataManager.get();
    const isMobile = useMobileCheck();
   

    const [mobileSteps, setMobileSteps] = React.useState(1);

    // Only called if the user is authed
    const handleContinue = async () => {
        if (loading) {
            return null;
        }

        await setLoading(true);

        try {
            dispatch(setLastSuccessPage(RouteStrings.ApplicationFlowStepConfirmation));
            hashHistory.push(RouteStrings.ApplicationFlowStepOffer);
        } catch (error) {
            console.error(error);

            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (isClientApp() || isAdminApp()) {
            checkAuth(setCheckingLoggedIn, null, () => {
                hashHistory.push(RouteStrings.ApplicationFlowStepOne);
            });
        } else {
            checkAuthMerchant(setCheckingLoggedIn, null, () => {
                history.push(RouteStrings.Login);
            });
        }

        pageLoad(dispatch, RouteStrings.ApplicationFlowStepConfirmation);

        preventStepSkip(lastSuccessPage, hashHistory);
    }, []);

    React.useEffect(() => {
        const applDataManager = new LocalApplicationFlowDataManager();
        const appl = applDataManager.get();

        if (appl == null) {
            return;
        }

        setAppl(appl);

    }, []);

    const decreaseInnerSteps = () => {
        if (!(mobileSteps <= 1)) {
            setMobileSteps(mobileSteps - 1);
        }
    };

    const renderSectionPersonalInfo = () => {
        return (
            <Card
                title={<Typography.Title level={5}>Persönliche Angaben</Typography.Title>}
                extra={
                    <Button
                        type="link"
                        icon={<EditOutlined />}
                        className={styles.editButton}
                
                        onClick={() => {
                            hashHistory.push(RouteStrings.ApplicationFlowStepTwo);
                        }}
                    >
                        {t("buttons:adjust")}
                    </Button>
                }
            >
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Ansprache :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{appl?.personal_info?.salutation}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                Ansprache :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{appl?.personal_info?.salutation}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Vorname :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{appl?.personal_info?.first_name}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                Vorname :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{appl?.personal_info?.first_name}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Nachname :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{appl?.personal_info?.last_name}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                Nachname :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{appl?.personal_info?.last_name}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Geburtsdatum :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>
                                    {moment(appl?.personal_info?.date_of_birth).format("DD.MM.YYYY")}
                                </div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                Geburtsdatum :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>
                                    {moment(appl?.personal_info?.date_of_birth).format("DD.MM.YYYY")}
                                </div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Geburtsort :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{appl?.personal_info?.place_of_birth}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            {" "}
                            <Col span={12} className={styles.cardInfoLabel}>
                                Geburtsort :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{appl?.personal_info?.place_of_birth}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Nationalität :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{appl?.personal_info?.place_of_birth}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                Nationalität :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{appl?.personal_info?.place_of_birth}</div>
                            </Col>
                        </>
                    )}
                </Row>
            </Card>
        );
    };

    const renderSectionAddressInfo = () => {
        const hasPreviousAddress: boolean =
            !!appl?.address_info?.previous_address?.street_name &&
            !!appl?.address_info?.previous_address?.post_code &&
            !!appl?.address_info?.previous_address?.city &&
            !!appl?.address_info?.previous_address?.country;

        return (
            <Card
                title={<Typography.Title level={5}>{t("applProcess:stepFive:subtitleAdress")}</Typography.Title>}
                extra={
                    <Button
                        type="link"
                        icon={<EditOutlined/>}
                        className={styles.editButton}
                
                        onClick={() => {
                            hashHistory.push(RouteStrings.ApplicationFlowStepTwo);
                        }}
                    >
                        {t("buttons:adjust")}
                    </Button>
                }
            >
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Addresse :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>
                                    {appl?.address_info?.street_name} {appl?.address_info?.street_number}
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>
                                    {appl?.address_info?.post_code} {appl?.address_info?.city}
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{appl?.address_info?.country}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                Addresse :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>
                                    {appl?.address_info?.street_name} {appl?.address_info?.street_number}
                                </div>
                            </Col>
                            <Col span={12} offset={12}>
                                <div className={styles.info}>
                                    {appl?.address_info?.post_code} {appl?.address_info?.city}
                                </div>
                            </Col>
                            <Col span={12} offset={12}>
                                <div className={styles.info}>{appl?.address_info?.country}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                {t("residentSince")} :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>
                                    {moment(appl?.address_info?.resident_since).format("DD.MM.YYYY")}
                                </div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                {t("residentSince")} :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>
                                    {moment(appl?.address_info?.resident_since).format("DD.MM.YYYY")}
                                </div>
                            </Col>
                        </>
                    )}
                </Row>
                {hasPreviousAddress && (
                    <Row>
                        <Col span={12} className={styles.cardInfoLabel}>
                            Vorherige Adresse :
                        </Col>
                        <Col span={12}>
                            <div className={styles.info}>
                                {appl?.address_info?.previous_address?.street_name}{" "}
                                {appl?.address_info?.previous_address?.street_number}
                            </div>
                        </Col>
                        <Col span={12} offset={12}>
                            <div className={styles.info}>
                                {appl?.address_info?.previous_address?.post_code}{" "}
                                {appl?.address_info?.previous_address?.city}
                            </div>
                        </Col>
                        <Col span={12} offset={12}>
                            <div className={styles.info}>{appl?.address_info?.previous_address?.country}</div>
                        </Col>
                    </Row>
                )}
            </Card>
        );
    };

    const renderSectionWorkInfo = () => {
        return (
            <Card
                title={<Typography.Title level={5}>Berufliche Angaben</Typography.Title>}
                extra={
                    <Button
                        type="link"
                        icon={<EditOutlined />}
                        className={styles.editButton}
                        onClick={() => {
                            hashHistory.push(RouteStrings.ApplicationFlowStepThree);
                        }}
                    >
                        {t("buttons:adjust")}
                    </Button>
                }
            >
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Ausgeübter Beruf :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{appl?.job_info?.profession}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                Ausgeübter Beruf :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{appl?.job_info?.profession}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Branche :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{appl?.job_info?.branche}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                Branche :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{appl?.job_info?.branche}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Berufsgruppe :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{appl?.job_info?.occupation_group}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                Berufsgruppe :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{appl?.job_info?.occupation_group}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Angestelt seit :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>
                                    {moment(appl?.job_info?.employed_since).format("DD.MM.YYYY")}
                                </div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                Angestelt seit :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>
                                    {moment(appl?.job_info?.employed_since).format("DD.MM.YYYY")}
                                </div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Arbeitgeber :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{appl?.job_info?.employer}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                Arbeitgeber :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{appl?.job_info?.employer}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Standort :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{appl?.job_info?.employer_location}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                Standort :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{appl?.job_info?.employer_location}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Nettoeinkommen (p.m.) :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>€ {appl?.job_info?.net_income}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                Nettoeinkommen (p.m.) :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>€ {appl?.job_info?.net_income}</div>
                            </Col>
                        </>
                    )}
                </Row>
            </Card>
        );
    };

    const renderSectionHouseholdInfo = () => {
        return (
            <Card
                title={<Typography.Title level={5}>Haushaltsangaben</Typography.Title>}
                extra={
                    <Button
                        type="link"
                        icon={<EditOutlined />}
                        className={styles.editButton}
                        onClick={() => {
                            hashHistory.push(RouteStrings.ApplicationFlowStepThree);
                        }}
                    >
                        {t("buttons:adjust")}
                    </Button>
                }
            >
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Wohnart :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{appl?.household_info?.residence_type}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                Wohnart :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{appl?.household_info?.residence_type}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Wohnkosten (p.m.) :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>€ {appl?.household_info?.residence_monthly_cost}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                Wohnkosten (p.m.) :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>€ {appl?.household_info?.residence_monthly_cost}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Anzahl Kinder :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>${appl?.household_info?.number_of_children}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                Anzahl Kinder :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>${appl?.household_info?.number_of_children}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Fahrzeug o. Motorrad :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>
                                    {t(appl?.household_info?.has_vehicle ? "Ja" : "Nein")}
                                </div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={12} className={styles.cardInfoLabel}>
                                Fahrzeug o. Motorrad :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>
                                    {t(appl?.household_info?.has_vehicle ? "Ja" : "Nein")}
                                </div>
                            </Col>
                        </>
                    )}
                </Row>
            </Card>
        );
    };

    const renderMobileSteps = () => {
        switch (mobileSteps) {
        case 1:
            return (
                <>
                    <p>Schritt {mobileSteps} / 5</p>
                    <Col span={24}>{renderSectionPersonalInfo()}</Col>
                    <Spacer hx={2} />
                    <Row className={styles.rowCenterX}>
                        <button
                            className={styles.confirmation}
                            onClick={() => {
                                setMobileSteps(mobileSteps + 1);
                            }}
                        >
                            Confirm
                        </button>
                    </Row>
                </>
            );
        case 2:
            return (
                <>
                    <p>Schritt {mobileSteps} / 5</p>
                    <Col span={24}>{renderSectionAddressInfo()}</Col>
                    <Spacer hx={2} />
                    <Row className={styles.rowCenterX}>
                        <button
                            className={styles.confirmation}
                            onClick={() => {
                                setMobileSteps(mobileSteps + 1);
                            }}
                        >
                            Confirm
                        </button>
                    </Row>
                </>
            );

        case 3:
            return (
                <>
                    <p>Schritt {mobileSteps} / 5</p>

                    <Col span={24}>{renderSectionWorkInfo()}</Col>
                    <Spacer hx={2} />
                    <Row className={styles.rowCenterX}>
                        <button
                            className={styles.confirmation}
                            onClick={() => {
                                setMobileSteps(mobileSteps + 1);
                            }}
                        >
                            Confirm
                        </button>
                    </Row>
                </>
            );
        case 4:
            return (
                <>
                    <p>Schritt {mobileSteps} / 5</p>
                    <Col span={24}>{renderSectionHouseholdInfo()}</Col>
                    <Spacer hx={2} />
                    <Row className={styles.rowCenterX}>
                        <button
                            className={styles.confirmation}
                            onClick={() => {
                                setMobileSteps(mobileSteps + 1);
                            }}
                        >
                            Confirm
                        </button>
                    </Row>
                </>
            );
        case 5:
            return (
                <>
                    <p>Schritt {mobileSteps} / 5</p>

                    <Row>
                        <Col span={24}>
                            <OrderSummary draftData={applData?.draft_info} />
                        </Col>
                    </Row>
                    <Spacer hx={2} />
                    <div>{t("applProcess:infoStepFour")}</div>

                    <Spacer hx={2} />

                    <Row className={styles.rowCenterX}>
                        <div className={styles.continueMobile}>
                            <Button data-cy="button_continue" type="primary" onClick={handleContinue}>
                                {t("buttons:requestOffer")}
                            </Button>
                        </div>
                    </Row>
                </>
            );
        }
        return null;
    };

    return (
        <div className={styles.page}>
            <ApplicationProcessHeader checkLoggedInStatus />
            <CustomPageHeader small={isMobile} />
            <div className={styles.container}>
                {checkingLoggedIn ? (
                    <Spin
                        className={styles.spin}
                        indicator={<Loading3QuartersOutlined style={{ fontSize: 34 }} spin />}
                    />
                ) : (
                    <React.Fragment>
                        <Row gutter={24} align={"top"}>
                            <Col span={isMobile ? 24 : 16}>
                                <Card>
                                    <div className={styles.innerContainer}>
                                        <div className={styles.infoContainer}>
                                            {mobileSteps === 1 ? (
                                                <BackButton
                                                    route={RouteStrings.ApplicationFlowStepThree}
                                                    hashHistory={hashHistory}
                                                />
                                            ) : (
                                                <div className={styles.innerStepper} onClick={decreaseInnerSteps}>
                                                    <ArrowLeftOutlined /> Zurück
                                                </div>
                                            )}
                                            <Spacer hx={2} />

                                            <h2 className={styles.processTitle}>{t("applProcess:titleStepFour")}</h2>

                                            <Spacer hx={2} />

                                            {!isMobile ? (
                                                <>
                                                    <Row gutter={[24, 24]} style={{ width: "100%" }}>
                                                        <Col span={24}>{renderSectionPersonalInfo()}</Col>
                                                        <Col span={24}>{renderSectionAddressInfo()}</Col>
                                                        <Col span={24}>{renderSectionWorkInfo()}</Col>
                                                        <Col span={24}>{renderSectionHouseholdInfo()}</Col>

                                                        <Spacer hx={2} />

                                                        <div>{t("applProcess:infoStepFour")}</div>

                                                        <Spacer hx={2} />
                                                    </Row>

                                                    <Row className={styles.rowCenterX}>
                                                        <div className={styles.continueBtn}>
                                                            <Button
                                                                data-cy="button_continue"
                                                                type="primary"
                                                                onClick={handleContinue}
                                                            >
                                                                {t("buttons:requestOffer")}
                                                            </Button>
                                                        </div>
                                                    </Row>
                                                </>
                                            ) : (
                                                renderMobileSteps()
                                            )}

                                            {generalError ? (
                                                <>
                                                    <div className={styles.error}>{generalError}</div>
                                                    <Spacer />
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={8}>{!isMobile && <OrderSummary draftData={applData?.draft_info} />}</Col>
                        </Row>
                        <Spacer />
                        <Row justify="start" gutter={24}>
                            <Col span={isMobile ? 24 : 16} style={{ textAlign: "center" }}>
                                <a href={appl?.draft_info?.cancel_url}>
                                    Cancel and return to {appl?.draft_info?.merchant_name}
                                </a>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};
