import { ApiWrapper } from "api/ApiWrapper";
import {
    ApiResponse,
    ApplicationFilter,
    ApplicationReview,
    ApplicationScore,
    CPCreateApplicationRequest,
    CPGetApplicationResponse,
    CPPutApplicationDetails,
    CrefoResult,
    GetApplicationNotesListResponse,
    NoteCategories,
    PostOrPutApplicationReview,
    PostOrPutApplicationScore,
    PutApplicationBankInfoReq,
    PutApplicationKYCConfirmReq,
    PutApplicationStatusReq
} from "api/types";
import { Application } from "types/types";

// ADMIN
export const adminGetAllApplications = async (
    page: number,
    filter: ApplicationFilter
): Promise<ApiResponse<Application[]>> => {
    let path = `/admin/applications?page=${page}`;

    if (filter) {
        if (filter.id) {
            path += `&id=${filter.id}`;
        }
        if (filter.type) {
            path += `&type=${filter.type}`;
        }
        if (filter.source) {
            path += `&source=${filter.source}`;
        }
        if (filter.status) {
            path += `&status=${filter.status}`;
        }
    }

    return ApiWrapper.get(path);
};
export const adminGetApplicationById = async (id: string): Promise<any> => {
    const path = `/admin/applications/${id}`;

    return ApiWrapper.get(path) as Promise<Application>;
};
export const adminPutApplicationBankInfo = async (id: string, data: PutApplicationBankInfoReq): Promise<any> => {
    const path = `/admin/applications/${id}/bank_info`;

    return ApiWrapper.put(path, data) as Promise<Application>;
};

export const adminPostApplicationScore = async (
    applicationId: string,
    data: PostOrPutApplicationScore
): Promise<any> => {
    const path = `/admin/applications/${applicationId}/scores`;

    return ApiWrapper.post(path, data) as Promise<any>;
};
export const adminPutApplicationScore = async (id: string, data: PostOrPutApplicationScore): Promise<any> => {
    const path = `/admin/applications/${id}/scores`;

    return ApiWrapper.put(path, data) as Promise<any>;
};
export const adminGetApplicationScore = async (id: string): Promise<ApiResponse<ApplicationScore>> => {
    const path = `/admin/applications/${id}/scores`;

    return ApiWrapper.get(path) as Promise<ApiResponse<ApplicationScore>>;
};

export const adminPostApplicationReview = async (
    applicationId: string,
    data: PostOrPutApplicationReview
): Promise<any> => {
    const path = `/admin/applications/${applicationId}/reviews`;

    return ApiWrapper.post(path, data) as Promise<any>;
};
export const adminPutApplicationReview = async (id: string, data: PostOrPutApplicationReview): Promise<any> => {
    const path = `/admin/applications/${id}/reviews`;

    return ApiWrapper.put(path, data) as Promise<any>;
};
export const adminGetApplicationReview = async (id: string): Promise<ApiResponse<ApplicationReview>> => {
    const path = `/admin/applications/${id}/reviews`;

    return ApiWrapper.get(path) as Promise<ApiResponse<ApplicationReview>>;
};

export const adminGetApplicationNotes = async (
    applicationId: string,
    page: number
): Promise<ApiResponse<GetApplicationNotesListResponse[]>> => {
    const path = `/admin/applications/${applicationId}/notes?page=${page}`;

    return ApiWrapper.get(path);
};
export const adminPostApplicationNote = async (applicationId: string, note: string): Promise<void> => {
    const path = `/admin/applications/${applicationId}/notes`;

    const body = {
        note_text: note,
        note_category: NoteCategories.Other // there's only 1 note category as of now
    };

    return ApiWrapper.post(path, body);
};

// USER
export const getCompanySearchResults = async (
    companyName: string,
    countryCode: string
): Promise<ApiResponse<CrefoResult>> => {
    const path = `/company/${encodeURIComponent(companyName)}?country=${countryCode}`;

    return ApiWrapper.get(path);
};

export const userGetAllApplications = async (page: number): Promise<any> => {
    const path = `/contact_persons/applications?page=${page}`;

    return ApiWrapper.get(path) as Promise<Application>;
};

export const getApplicationById = async (id: string): Promise<ApiResponse<CPGetApplicationResponse>> => {
    const path = `/contact_persons/applications/${id}`;

    return ApiWrapper.get(path);
};

export const putApplicationBankInfo = async (id: string, data: PutApplicationBankInfoReq): Promise<any> => {
    const path = `/contact_persons/applications/${id}/bank_info`;

    return ApiWrapper.put(path, data) as Promise<Application>;
};

export const putApplicationStatus = async (id: string, data: PutApplicationStatusReq): Promise<any> => {
    const path = `/contact_persons/applications/${id}/status`;

    return ApiWrapper.put(path, data) as Promise<any>;
};

export const putApplicationStatusAdmin = async (id: string, data: PutApplicationStatusReq): Promise<any> => {
    const path = `/admin/applications/${id}/status`;

    return ApiWrapper.put(path, data) as Promise<any>;
};

export const putApplicationKYCConfirmAdmin = async (id: string, data: PutApplicationKYCConfirmReq): Promise<any> => {
    const path = `/admin/applications/${id}/confirm_kyc`;

    return ApiWrapper.put(path, data) as Promise<any>;
};

export const putApplicationAssigneeId = async (id: string, assigneeId?: string): Promise<any> => {
    const path = `/admin/applications/${id}/assignee`;
    const body = {
        bo_assignee_id: assigneeId,
    };

    return ApiWrapper.put(path, body) as Promise<any>;
};

export const cpPutApplicationDetails = async (applicationId: string, appl?: CPPutApplicationDetails): Promise<void> => {
    const path = `/contact_persons/applications/${applicationId}`;
    const body = {
        amount: appl.amount,
        duration: appl.duration,
        currency: appl.currency,
        purpose_info: appl.purpose_info,
        largest_debitors_list: appl.largest_debitors_list,
        company_name: appl.company_name,
        company_type: appl.company_type,
        company_number_of_employees: appl.company_number_of_employees,
        tax_id: appl.tax_id,
        company_register_number: appl.company_register_number,
        company_purpose: appl.company_purpose,
        company_country: appl.company_country,
        company_street: appl.company_street,
        company_house_number: appl.company_house_number,
        company_post_code: appl.company_post_code,
        company_city: appl.company_city,
    };

    return ApiWrapper.put(path, body) as Promise<any>;
};

export const postApplication = async (application: CPCreateApplicationRequest): Promise<ApiResponse<string>> => {
    const path = `/contact_persons/new/applications`;

    return ApiWrapper.post(path, application);
};
export const postApplicationAuthed = async (application: CPCreateApplicationRequest): Promise<ApiResponse<string>> => {
    const path = `/contact_persons/applications`;

    return ApiWrapper.post(path, application);
};

export const adminCreateApplicationForClient = async (clientID: string): Promise<any> => {
    const path = `/admin/applications`;
    const body = {
        client_id: clientID,
    };

    return ApiWrapper.post(path, body) as Promise<Application>;
};

export const getObject = async (applicationId: string): Promise<any> => {
    const path = `/contact_persons/applications/${applicationId}/objects`;

    return ApiWrapper.get(path) as Promise<any>;
};

export const postObject = async (applicationId: string, data: any): Promise<any> => {
    const path = `/contact_persons/applications/${applicationId}/objects`;

    return ApiWrapper.post(path, data) as Promise<any>;
};

export const putObject = async (objectId: string, data: any): Promise<any> => {
    const path = `/contact_persons/objects/${objectId}`;

    return ApiWrapper.put(path, data) as Promise<any>;
};

export const adminGetObject = async (applicationId: string): Promise<any> => {
    const path = `/admin/applications/${applicationId}/objects`;

    return ApiWrapper.get(path) as Promise<any>;
};

export const adminPostObject = async (applicationId: string, data: any): Promise<any> => {
    const path = `/admin/applications/${applicationId}/objects`;

    return ApiWrapper.post(path, data) as Promise<any>;
};

export const adminPutObject = async (objectId: string, data: any): Promise<any> => {
    const path = `/admin/objects/${objectId}`;

    return ApiWrapper.put(path, data) as Promise<any>;
};

export const adminPostCalculation = async (applicationId: string, data: any): Promise<any> => {
    const path = `/admin/applications/${applicationId}/calculations`;

    return ApiWrapper.post(path, data) as Promise<any>;
};

export const adminGetCalculations = async (applicationId: string): Promise<any> => {
    const path = `/admin/applications/${applicationId}/calculations`;

    return ApiWrapper.get(path) as Promise<any>;
};
