import { refreshClientSession } from "api/client/client";
import { refreshMerchantSession } from "api/merchant";
import { AxiosError } from "axios";

export const checkAuth = async (stateFunc: React.Dispatch<React.SetStateAction<boolean>>, navOnSuccess: () => void, navOnFail: () => void) => {
    try {
        stateFunc(true);

        const refreshSessionReq = await refreshClientSession(true);

        if (refreshSessionReq?.status === 200) {
            // Create a client - the backend will get the contact_person_id automatically, then create an application
            if (navOnSuccess) {
                navOnSuccess();
            }

            stateFunc(false);

            return true;
        }

        if (navOnFail) {
            navOnFail();
        }

        stateFunc(false);

        return false;

    } catch (error) {
        const err = error as AxiosError;
        stateFunc(false);

        if (err.response?.status === 403) {
            console.error("Not logged in");
        }

        if (navOnFail) {
            navOnFail();
        }

        return false;
    }
};


export const checkAuthMerchant = async (stateFunc: React.Dispatch<React.SetStateAction<boolean>>, navOnSuccess: () => void, navOnFail: () => void) => {
    try {
        stateFunc(true);

        const refreshSessionReq = await refreshMerchantSession(true);

        if (refreshSessionReq?.status === 200) {
            // Create a client - the backend will get the contact_person_id automatically, then create an application
            if (navOnSuccess) {
                navOnSuccess();
            }

            stateFunc(false);

            return true;
        }

        if (navOnFail) {
            navOnFail();
        }

        stateFunc(false);

        return false;

    } catch (error) {
        const err = error as AxiosError;
        stateFunc(false);

        if (err.response?.status === 403) {
            console.error("Not logged in");
        }

        if (navOnFail) {
            navOnFail();
        }

        return false;
    }
};

