import * as React from "react";
import styles from "components/common/pages/client-dashboard/ClientDashboardPage.module.scss";
import { Dropdown, Layout, Menu, Space, Typography } from "antd";
import { LogoutOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { RouteStrings } from "routes/RouteStrings";
import { logoutClient } from "api/client/client";
import { goTo } from "navigation/navigationHelpers";
import { useTranslation } from "react-i18next";
export const ClientHeader = () => {

    const { t } = useTranslation();

    const { Header } = Layout;
    const { Text } = Typography;

    const handleLogout = async () => {
        try {
            await logoutClient();
            goTo(RouteStrings.Login);
        } catch (err) {
            console.error(err);
        }
    };

    const menu = (
        <Menu>
            <Menu.Item icon={<SettingOutlined />}>
                <Text
                    onClick={() => {
                        window.location.href = RouteStrings.ClientSettingsPage;
                    }}
                >
                    {t("settings")}
                </Text>
            </Menu.Item>
            <Menu.Item icon={<LogoutOutlined />}>
                <Text onClick={handleLogout}>{t("buttons:logout")}</Text>
            </Menu.Item>
        </Menu>
    );
    return (
        <Header className={styles.headerContainer}>
            <div className={styles.headerRow}>
                <div className={styles.logo}></div>
                <Dropdown overlay={menu} trigger={["click"]}>
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <UserOutlined className={styles.avatar} />
                        </Space>
                    </a>
                </Dropdown>
            </div>
        </Header>
    );
};
