import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Radio, Row } from "antd";
import { CreateEditMerchantModalSteps } from "components/admin/presenters/create-edit-merchant-modal/CreateEditMerchantModal";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateEditMerchantFlow, MerchantContactFromFlow } from "types/adminPanel";
import { Salutations } from "types/applicationProcess";
import styles from "./CreateEditMerchantModal.module.scss";

export interface CreateEditMerchantStepTwoProps {
    data: CreateEditMerchantFlow
    setActiveStep: (step: CreateEditMerchantModalSteps) => void
    onSubmit: (values: MerchantContactFromFlow[]) => void
}

interface FormData {
    person_1_title: string
    person_1_first_name: string
    person_1_last_name: string
    person_1_email: string
    person_1_telephone_number: string
    person_1_is_admin: boolean
    person_2_title: string
    person_2_first_name: string
    person_2_last_name: string
    person_2_email: string
    person_2_telephone_number: string
    person_2_is_admin: boolean
    person_3_title: string
    person_3_first_name?: string
    person_3_last_name?: string
    person_3_email?: string
    person_3_telephone_number?: string
    person_3_is_admin?: boolean
}

export const CreateEditMerchantStepTwo = (props: CreateEditMerchantStepTwoProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [addMorePersons, setAddMorePersons] = useState<boolean>(false);

    const handleOnFinish = async (values: FormData) => {
        const contactPersons: MerchantContactFromFlow[] = [];

        // max number of contacts, only add them to state if form had them added.
        [1, 2, 3].forEach((num) => {
            if (values[`person_${num}_first_name`]) {
                contactPersons.push({
                    title: values[`person_${num}_title`],
                    first_name: values[`person_${num}_first_name`],
                    last_name: values[`person_${num}_last_name`],
                    email: values[`person_${num}_email`],
                    telephone_number: values[`person_${num}_telephone_number`],
                    is_admin: values[`person_${num}_is_admin`],
                });
            }
        });

        props.onSubmit(contactPersons);
    };

    const toggleExtendedForm = () => {
        setAddMorePersons(!addMorePersons);
    };

    const renderContactPersonForm = (index: number): JSX.Element => {
        return (
            <React.Fragment>
                <Row gutter={36}>
                    <Col span={12}>
                        <h4 className={styles.boldText}>
                            {t("contactPerson")} {index}
                        </h4>
                        <Form.Item
                            name={`person_${index}_title`}
                            rules={[{ required: index === 1, message: t("errors:invalidData") }]}
                        >
                            <Radio.Group data-cy="title">
                                <Radio value={Salutations.Herr}>{t("mr")}</Radio>
                                <Radio value={Salutations.Frau}>{t("ms")}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={36}>
                    <Col span={12}>
                        <Form.Item
                            name={`person_${index}_first_name`}
                            label={t("firstName")}
                            hasFeedback
                            rules={[{ required: index === 1, message: t("errors:invalidData"), min: 1, max: 100 }]}
                        >
                            <Input data-cy="first_name" className={styles.input} />
                        </Form.Item>

                        <Form.Item
                            name={`person_${index}_last_name`}
                            label={t("lastName")}
                            hasFeedback
                            rules={[{ required: index === 1, message: t("errors:invalidData"), min: 1, max: 100 }]}
                        >
                            <Input data-cy="last_name" className={styles.input} />
                        </Form.Item>

                        <Form.Item name={`person_${index}_is_admin`} valuePropName="checked">
                            <Checkbox>{t("inviteAsAdmin")}</Checkbox>
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name={`person_${index}_telephone_number`}
                            label={t("phone")}
                            hasFeedback
                            rules={[{ required: index === 1, message: t("errors:invalidData"), min: 2, max: 100 }]}
                        >
                            <Input data-cy="telephone_number" className={styles.input} />
                        </Form.Item>

                        <Form.Item
                            name={`person_${index}_email`}
                            label={t("email")}
                            hasFeedback
                            rules={[
                                {
                                    required: index === 1,
                                    type: "email",
                                    message: t("errors:invalidData"),
                                    min: 5,
                                    max: 150,
                                },
                            ]}
                        >
                            <Input data-cy="email" className={styles.input} />
                        </Form.Item>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const contact_data = {
        person_1_title: props.data.contactPersons[0]?.title,
        person_1_first_name: props.data.contactPersons[0]?.first_name,
        person_1_last_name: props.data.contactPersons[0]?.last_name,
        person_1_email: props.data.contactPersons[0]?.email,
        person_1_telephone_number: props.data.contactPersons[0]?.telephone_number,
        person_1_is_admin: props.data.contactPersons[0]?.is_admin,
        person_2_title: props.data.contactPersons[1]?.title,
        person_2_first_name: props.data.contactPersons[1]?.first_name,
        person_2_last_name: props.data.contactPersons[1]?.last_name,
        person_2_email: props.data.contactPersons[1]?.email,
        person_2_telephone_number: props.data.contactPersons[1]?.telephone_number,
        person_2_is_admin: props.data.contactPersons[1]?.is_admin,
        person_3_title: props.data.contactPersons[2]?.title,
        person_3_first_name: props.data.contactPersons[2]?.first_name,
        person_3_last_name: props.data.contactPersons[2]?.last_name,
        person_3_email: props.data.contactPersons[2]?.email,
        person_3_telephone_number: props.data.contactPersons[2]?.telephone_number,
        person_3_is_admin: props.data.contactPersons[2]?.is_admin,
    };

    return (
        <Form layout={"vertical"} form={form} initialValues={contact_data} onFinish={handleOnFinish}>
            <div className={styles.inputsContainer}>
                {renderContactPersonForm(1)}

                {renderContactPersonForm(2)}

                {addMorePersons && renderContactPersonForm(3)}

                <Spacer />

                <Row className={styles.rowCenter} onClick={toggleExtendedForm}>
                    <div className={styles.addContactPerson}>
                        <PlusCircleOutlined className={styles.icon} /> &nbsp; {t("addAdditionalContactPerson")}
                    </div>
                </Row>

                <Spacer hx={2} />
                <div key="fr" className={styles.footerRow}>
                    <Button
                        className={styles.buttonCancel}
                        key="back"
                        onClick={(e: any) => {
                            props.setActiveStep(CreateEditMerchantModalSteps.MerchantInfo);
                        }}
                    >
                        {t("buttons:back")}
                    </Button>
                    <Button data-cy="button_continue" type="primary" htmlType="submit">
                        {t("buttons:submit")}
                    </Button>
                </div>
            </div>
        </Form>
    );
};
