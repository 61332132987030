import { Row } from "antd";
import { adminUpdateToDoStatus, adminDeleteToDo } from "api/admin/todos";
import { ToDoStatuses } from "api/types";
import * as React from "react";
import { Link } from "react-router-dom";
import { RouteStrings } from "routes/RouteStrings";

export const columns = [
    {
        title: 'Erstellt von.',
        dataIndex: 'created_by',
        key: 'created_by',
    },
    {
        title: 'Kunde',
        dataIndex: 'client_name',
        key: 'client_name',
    },
    {
        title: 'Art',
        dataIndex: 'entity_kind',
        key: 'entity_kind',
    },
    {
        title: 'Details',
        dataIndex: 'details',
        key: 'details',
    },
    {
        title: 'Wann',
        dataIndex: 'created_at',
        key: 'created_at',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: '',
        dataIndex: 'view',
        key: 'view',
        render: (_, todo) => {
            let link;
            
            if (todo?.entity_id.slice(0,3) === "cli") {
                link = `${RouteStrings.AdminClientDrilldown.replace(":clientId", todo?.entity_id)}}`;
            } else {
                link = `${RouteStrings.AdminApplicationDrilldown.replace(":applicationId", todo?.entity_id)}}`;
            }

            return (
                <Link to={link}>Einsehen</Link>
            );
        }
    },
    {
        title: '',
        dataIndex: 'edit',
        key: 'edit',
        render: (_, todo) => {
            return (<Row>
                {
                    todo?.status === ToDoStatuses.Complete ?
                        <div className="custom-link" onClick={() => {
                            adminUpdateToDoStatus(todo?.todo_id, ToDoStatuses.Open);
                        }}>Reopen</div>
                        :
                        <div className="custom-link" onClick={() => {
                            adminUpdateToDoStatus(todo?.todo_id, ToDoStatuses.Complete);
                        }}>Complete</div>
                }
                &nbsp;|&nbsp;
                <div className="custom-link" onClick={() => {
                }}>Edit</div>&nbsp;|&nbsp;

                <div className="custom-link" onClick={() => {
                    adminDeleteToDo(todo?.todo_id);
                }}>Delete</div>&nbsp;|&nbsp;
            </Row>);
        } 
    },
];
