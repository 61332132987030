import { ApiWrapper } from "api/ApiWrapper";
import { CreateToDo, ToDoStatuses } from "api/types";

export const adminGetToDos = async (page: number): Promise<any> => {
    const path = `/admin/todos?page=${page}`;

    return ApiWrapper.get(path) as Promise<any>;
};

export const adminGetToDo = async (id: string): Promise<any> => {
    const path = `/admin/todos/${id}`;

    return ApiWrapper.get(path) as Promise<any>;
};

export const adminCreateToDo = async (todo: CreateToDo): Promise<any> => {
    const path = `/admin/todos`;

    return ApiWrapper.post(path, todo);
};

export const adminUpdateToDoStatus = async (id: string, status: ToDoStatuses): Promise<any> => {
    const path = `/admin/todos/${id}/status`;

    const body = {
        status
    };

    return ApiWrapper.put(path, body);
};

export const adminDeleteToDo = async (id: string): Promise<any> => {
    const path = `/admin/todos/${id}`;

    return ApiWrapper.del(path) as Promise<any>;
};
