import { Layout, Table } from "antd";
import { adminGetMerchantApplications, adminGetMerchantUser } from "api/admin/merchants";
import { ApplicationObject, MerchantUser } from "api/types";
import { MerchantUserDetailsSection } from "components/admin/pages/merchant-drilldown/MerchantUserDetailsSection";
import { SideMenu } from "components/common/presenters/side-menu/SideMenu";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import styles from "./AdminMerchantDrilldownPage.module.scss";
import "./AdminMerchantTabMenu.scss";
import { columns as applicationsListColumns } from "./merchantsDrilldownApplicationsColumns";

const { Header, Content } = Layout;

export const AdminMerchantUserDrilldownPage = () => {
    let { merchantUserId } = useParams<any>();

    const [merchantUser, setMerchantUser] = useState({} as MerchantUser);
    const [merchantApplicationsList, setMerchantApplicationsList] = useState([] as ApplicationObject[]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState("");

    console.log(error);

    const getDataAndSetState = async (): Promise<any> => {
        setLoading(true);
        setError("");

        // separate try-catch block for each req group, because otherwise if 1 fails, the rest don't get executed
        try {
            const contactReq = await adminGetMerchantUser(merchantUserId);
            setMerchantUser(contactReq?.data);
        } catch (error) {
            console.error(error);
        }

        try {
            const applReq = await adminGetMerchantApplications(merchantUserId, 1);
            setMerchantApplicationsList(applReq.data.result);
        } catch (error) {
            console.error(error);
        }

        setLoading(false);
    };

    useEffect(() => {
        try {
            getDataAndSetState();
        } catch (error) {
            console.error(error);
        }
    }, []);

    return (
        <Layout className={styles.page}>
            <SideMenu isLoggedIn={true} />

            <Layout className={styles.sectionRight}>
                <Header className={styles.header}>
                    <MerchantUserDetailsSection merchantUser={merchantUser} refreshData = {getDataAndSetState}/>
                </Header>

                <Content>
                    <Spacer hx={2} />
                    <Table
                        pagination={{ position: ["bottomCenter"] }}
                        loading={loading}
                        columns={applicationsListColumns}
                        dataSource={merchantApplicationsList}
                        scroll={{ x: true }}
                    />
                    <Spacer hx={2} />
                </Content>
            </Layout>
        </Layout>
    );
};
