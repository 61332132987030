import {ApiWrapper} from "../ApiWrapper";
import {ApiResponse, GetDocumentsListResponse, RetrieveDocumentUploadLinkResponse} from "../types";
import {DocumentCategory} from "../../types/types";

export const cpGetClientDocumentURL = async (clientId: string, documentId: string): Promise<ApiResponse<string>> => {
    const path = `/contact_persons/clients/${clientId}/documents/${documentId}/download_link`;

    return ApiWrapper.get(path);
};

export const cpGetClientDocuments = async (clientId: string): Promise<ApiResponse<GetDocumentsListResponse[]>> => {
    const path = `/contact_persons/clients/${clientId}/documents`;

    return ApiWrapper.get(path);
};

export const cpUpdateClientDocument = async (clientId: string, documentId: string, documentName: string, documentCategory: DocumentCategory): Promise<ApiResponse<void>> => {
    const path = `/contact_persons/clients/${clientId}/documents/${documentId}`;
    const body = {
        document_name: documentName,
        document_category: documentCategory,
    };

    return ApiWrapper.patch(path, body);
};

export const cpRetrieveClientDocumentUploadLink = async (clientId: string, fileName: string, documentCategory: DocumentCategory): Promise<ApiResponse<RetrieveDocumentUploadLinkResponse>> => {
    const path = `/contact_persons/clients/${clientId}/documents`;
    const body = {
        file_name: fileName,
        document_category: documentCategory,
    };

    return ApiWrapper.post(path, body);
};

export const cpSetClientDocumentUploadedSuccessfully = async (clientId: string, documentId: string): Promise<ApiResponse<void>> => {
    const path = `/contact_persons/clients/${clientId}/documents/${documentId}/upload_success`;

    return ApiWrapper.patch(path, {});
};

export const cpDeleteClientDocument = async (clientId: string, documentId: string): Promise<ApiResponse<void>> => {
    const path = `/contact_persons/clients/${clientId}/documents/${documentId}`;

    return ApiWrapper.del(path, {});
};
