import { EditOutlined } from "@ant-design/icons";
import { Card, Col, Row, Typography } from "antd";
import { ClientObject } from "api/types";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from "../ClientDrilldownPage.module.scss";

export interface LeadDetailsTabProps {
    client: ClientObject
    onEdit: () => void
}

export const LeadDetailsTab = (props: LeadDetailsTabProps) => {
    const { t } = useTranslation();

    const cl = props.client;

    console.error(cl);

    return (
        <>
            <div className={styles.clients}>
                {/* { JSON.stringify(props.clientData)} */}
                <>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Card
                                title={
                                    <Typography.Title className={styles.clientTitle} level={5}>
                                        Kundeninformationen
                                    </Typography.Title>
                                }
                                extra={
                                    <Row align="middle">
                                        <EditOutlined />
                                        &nbsp;
                                        <div className={styles.infoEdit} onClick={props.onEdit}>
                                            {t("buttons:adjust")}
                                        </div>
                                    </Row>
                                }
                            >
                                <Row gutter={12} justify={"space-between"}>
                                    <Col span={8}>
                                        <span className={styles.faintText}>Kunden ID : </span>
                                    </Col>
                                    <Col span={16}>
                                        <span>{cl.client_id}</span>
                                    </Col>
                                </Row>

                                <Row gutter={12} justify={"space-between"}>
                                    <Col span={8}>
                                        <span className={styles.faintText}>Registriert am :</span>
                                    </Col>
                                    <Col span={16}>
                                        <span>{cl.created_at ? cl.created_at.split("T")[0] : "-"}</span>
                                    </Col>
                                </Row>

                                <Row gutter={12} justify={"space-between"}>
                                    <Col span={8}>
                                        <span className={styles.faintText}> E-Mail bestätigt :</span>
                                    </Col>
                                    <Col span={16}>
                                        <span>{cl.email_confirmed ? cl.email_confirmed : "-"}</span>
                                    </Col>
                                </Row>

                                <Row gutter={12} justify={"space-between"}>
                                    <Col span={8}>
                                        <span className={styles.faintText}> Vorname :</span>
                                    </Col>
                                    <Col span={16}>
                                        <span>{cl.first_name ? cl.first_name : "-"}</span>
                                    </Col>
                                </Row>

                                <Row gutter={12} justify={"space-between"}>
                                    <Col span={8}>
                                        <span className={styles.faintText}> Nachname :</span>
                                    </Col>
                                    <Col span={16}>
                                        <span>{cl.last_name ? cl.last_name : "-"}</span>
                                    </Col>
                                </Row>

                                <Row gutter={12} justify={"space-between"}>
                                    <Col span={8}>
                                        <span className={styles.faintText}> Geburtsdatum :</span>
                                    </Col>
                                    <Col span={16}>
                                        <span>{cl.birth_date ? cl.birth_date : "-"}</span>
                                    </Col>
                                </Row>
                                <Row gutter={12} justify={"space-between"}>
                                    <Col span={8}>
                                        <span className={styles.faintText}> E-Mail :</span>
                                    </Col>
                                    <Col span={16}>
                                        <span>{cl.email ? cl.email : "-"}</span>
                                    </Col>
                                </Row>
                                <Row gutter={12} justify={"space-between"}>
                                    <Col span={8}>
                                        <span className={styles.faintText}>Handynummer :</span>
                                    </Col>
                                    <Col span={16}>
                                        <span>{cl.mobile_number ? cl.mobile_number : "-"}</span>
                                    </Col>
                                </Row>
                                <Row gutter={12} justify={"space-between"} align="top">
                                    <Col span={8}>
                                        <span className={styles.faintText}>Adresse :</span>
                                    </Col>
                                    <Col span={16}>
                                        <Typography.Text style={{ display: "inline-block", verticalAlign: "top" }}>
                                            <Typography.Text>
                                                {cl.address_street}, {cl.address_house_number}
                                            </Typography.Text>
                                            <br />
                                            <Typography.Text>
                                                {cl.address_post_code},{cl.address_city}
                                            </Typography.Text>
                                            <br />
                                            <Typography.Text>
                                                {t(cl.address_country_code).toUpperCase()}
                                            </Typography.Text>
                                            <br />
                                        </Typography.Text>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col span={12}>
                            <Card
                                title={
                                    <Typography.Title className={styles.clientTitle} level={5}>
                                        Beruf & Haushalt
                                    </Typography.Title>
                                }
                                extra={
                                    <Row align="middle">
                                        <EditOutlined />
                                        &nbsp;
                                        <div className={styles.infoEdit} onClick={props.onEdit}>
                                            {t("buttons:adjust")}
                                        </div>
                                    </Row>
                                }
                            >
                                <Row gutter={12} justify={"space-between"}>
                                    <Col span={8}>
                                        <span className={styles.faintText}>Beruf :</span>
                                    </Col>
                                    <Col span={16}>
                                        <span>{cl.job_type ? cl.job_type : "-"}</span>
                                    </Col>
                                </Row>

                                <Row gutter={12} justify={"space-between"}>
                                    <Col span={8}>
                                        <span className={styles.faintText}>Beschreibung :</span>
                                    </Col>
                                    <Col span={16}>
                                        <span>{cl.job_branch ? cl.job_branch : "-"}</span>
                                    </Col>
                                </Row>

                                <Row gutter={12} justify={"space-between"}>
                                    <Col span={8}>
                                        <span className={styles.faintText}>Angestellt seit :</span>
                                    </Col>
                                    <Col span={16}>
                                        <span>{cl.employed_since ? cl.employed_since : "-"}</span>
                                    </Col>
                                </Row>

                                <Row gutter={12} justify={"space-between"}>
                                    <Col span={8}>
                                        <span className={styles.faintText}>Arbeitgeber :</span>
                                    </Col>
                                    <Col span={16}>
                                        <span>{cl.employer_name ? cl.employer_name : "-"}</span>
                                    </Col>
                                </Row>

                                <Row gutter={12} justify={"space-between"}>
                                    <Col span={8}>
                                        <span className={styles.faintText}>Wohnt :</span>
                                    </Col>
                                    <Col span={16}>
                                        <span>{cl.address_addition ? cl.address_addition : "-"}</span>
                                    </Col>
                                </Row>

                                <Row gutter={12} justify={"space-between"}>
                                    <Col span={8}>
                                        <span className={styles.faintText}>Wohnkosten :</span>
                                    </Col>
                                    <Col span={16}>
                                        <span>{cl.living_costs ? cl.living_costs : "-"}</span>
                                    </Col>
                                </Row>

                                <Row gutter={12} justify={"space-between"}>
                                    <Col span={8}>
                                        <span className={styles.faintText}>Kinder :</span>
                                    </Col>
                                    <Col span={16}>
                                        <span>{cl.num_children ? cl.num_children : "-"}</span>
                                    </Col>
                                </Row>

                                <Row gutter={12} justify={"space-between"}>
                                    <Col span={8}>
                                        <span className={styles.faintText}>Fahrzeuge :</span>
                                    </Col>
                                    <Col span={16}>
                                        <span>{cl.has_vehicle ? cl.has_vehicle : "-"}</span>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Spacer hx={2} />
                </>
            </div>
        </>
    );
};
