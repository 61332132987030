import i18n from "i18n/i18n";

export const currencyLocaleFormatter = (value: number | string, precision: number = 2): string => {
    try {
        const localeString = i18n.language;

        const numberValue: number = parseFloat(value as any);

        if (isNaN(numberValue)) {
            return "-";
        }

        return numberValue.toLocaleString(localeString, {minimumFractionDigits: precision, maximumFractionDigits: precision});
    } catch (err) {
        console.log(err);
        return null;
    }
};

