import { Merchant } from "api/types";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import React, { Dispatch, SetStateAction } from "react";
import { CreateEditMerchantFlow } from "types/adminPanel";
import styles from "./CreateEditMerchantModal.module.scss";
import MerchantTab from '../../pages/merchant-drilldown/merchant-tabs/MerchantTab';
import { CreateEditMerchantModalSteps } from "./CreateEditMerchantModal";

export interface CreateEditMerchantStepConfirmProps {
    merchant: CreateEditMerchantFlow
    onSubmit: () => void
    setActiveStep?: Dispatch<SetStateAction<CreateEditMerchantModalSteps>>
}
    
export const CreateEditMerchantStepConfirm = (props: CreateEditMerchantStepConfirmProps) => {
    // const {t} = useTranslation();

    const getMerchantData = (merchant: CreateEditMerchantFlow): Merchant => {
        return {
            merchant_id: null,
            short_id: null,
            name: merchant.merchantInfo?.company_name,
            registration_number: merchant.merchantInfo?.hr_number,
            tax_id: merchant.merchantInfo?.vat_number,
            general_email: merchant.merchantInfo?.company_email,
            general_phone: merchant.merchantInfo?.phone_number,
            founding_date: merchant.merchantInfo?.foundation_date,
            address_country_code: merchant.merchantInfo?.country,
            address_street: merchant.merchantInfo?.street,
            address_house_number: merchant.merchantInfo?.house_number,
            address_post_code: merchant.merchantInfo?.post_code,
            address_city: merchant.merchantInfo?.city,
            website_url: merchant.merchantInfo?.website,
            iban: merchant.merchantInfo?.iban,
            bic: merchant.merchantInfo?.bic,
            bank_name: merchant.merchantInfo?.bank,
            kind: undefined,
            status: undefined,
            is_deleted: false,
            created_at: null,
            is_registered: undefined,
            contacts: merchant.contactPersons
        };
    };
  
    return (
        <div className={styles.inputsContainer}>

            <Spacer hx={2}/>
            
            <MerchantTab  merchantInfo={getMerchantData(props.merchant)} isSummary={true} setStep={props.setActiveStep}/>
        </div>
    );
};
