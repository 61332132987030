import { DownOutlined } from "@ant-design/icons";
import { Col, Dropdown, Menu, Row, Typography } from "antd";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { GetClientResponse } from "../../../../api/types";
import { Application, ApplicationStates } from "../../../../types/types";
import styles from "./TopInfoSection.module.scss";

interface TopInfoSectionProps {
    type?: TopInfoSectionType
    isContactPersonUI?: boolean
    short?: boolean
    companyName?: string
    applicationId?: string
    applicationState?: ApplicationStates
    applicationType?: any
    application?: Application
    clientData?: GetClientResponse

    // options and actions are paired by their index
    dropdownOptions?: string[]
    dropdownActions?: (() => void)[]
}

export enum TopInfoSectionType {
    TypeClient,
    TypeApplication,
}

export const TopInfoSection = (props: TopInfoSectionProps) => {
    const { t } = useTranslation();

    const getApplicationStatusLabel = (): string => {
        return props.applicationState ? t(`applicationStates:${props.applicationState}`) : "-";
    };


    const menu = (
        <Menu>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="">
                    {t("buttons:printCustomer")}
                </a>
            </Menu.Item>
            <Menu.Item icon={<DownOutlined />} disabled>
                <a target="_blank" rel="noopener noreferrer" href="">
                    {t("buttons:newToDo")}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="">
                    {t("buttons:newOffer")}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="">
                    {t("buttons:dsgvo")}
                </a>
            </Menu.Item>
        </Menu>
    );

    const renderDropdown = (): JSX.Element => {
        // <AnchoredSelect data-cy="top-info-select" className={styles.select} allowClear
        //     placeholder={t('actions')} onSelect={(val, item) => {
        //         // Add a delay, because the dropdown closes with an animation
        //         setTimeout(() => {
        //             if (props.dropdownActions[item?.key]) {
        //                 props.dropdownActions[item?.key]();
        //             }
        //         }, 150);
        //     }}>
        //     {
        //         props.dropdownOptions.map((val, i) => {
        //             return <Select.Option data-cy={`top-info-select-${i}`} key={i} value={val}>{val}</Select.Option>;
        //         })
        //     }
        // </AnchoredSelect>
        if (props.dropdownOptions) {
            return (
                <Dropdown overlay={menu}>
                    <a className={styles.dropdownMenu} onClick={(e) => e.preventDefault()}>
                        <DownOutlined /> Aktionen
                    </a>
                </Dropdown>
            );
        }

        return null;
    };

    const renderInfo = () => {
        if (props.type === TopInfoSectionType.TypeClient) {
            const client = props.clientData;
            // const firstContactPerson: GetContactPersonResponse = client.contact_persons?.sort((cp1, cp2) => moment(cp1.created_at).isBefore(cp2.created_at) ? -1 : 0)[0];

            return (
                <Row>
                    <Col span={22}>
                        <Row align="middle">
                            <span className={styles.desc}>Kunden / </span>
                            <Typography.Title className={styles.name}>
                                {client.first_name} {client.last_name}
                            </Typography.Title>
                        </Row>
                    </Col>
                    <Col span={2}>{renderDropdown()}</Col>
                </Row>
            );
        }

        if (props.type === TopInfoSectionType.TypeApplication) {
            // todo
            return (
                <>
                    <Row>
                        <Col span={22}>
                            <Row align="middle">
                                <span className={styles.desc}>{t("application")} / </span>
                                <Typography.Title className={styles.name}>{props.application.client_first_name} {props.application.client_last_name}</Typography.Title>
                            </Row>
                        </Col>
                        <Col span={2}>{renderDropdown()}</Col>
                    </Row>
                    <Row justify="start" gutter={24}>
                        <Col>
                            <span className={styles.descbottom}>{t("applicationId")} :</span>
                            <Typography.Text className={styles.topValue}>{props.application.short_id ? props.application.short_id : props.applicationId}</Typography.Text>
                        </Col>

                        <Col>
                            <span className={styles.descbottom}>{t("handler")} :</span>
                            <Typography.Text className={styles.topValue}>
                                {props.application.merchant_name}
                            </Typography.Text>
                        </Col>

                        <Col>
                            <span className={styles.descbottom}>{t("status")} :</span>
                            <Typography.Text className={styles.topValue}>{getApplicationStatusLabel()}</Typography.Text>
                        </Col>
                    </Row>
                </>
            );
        }

        // {itemName: t('applicationType'), itemValue: t(`applicationTypes:loan`)},

        return null;
    };

    return <section className={styles.section}>{renderInfo()}</section>;
};
