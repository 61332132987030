import React, { createContext, useReducer } from "react";
import { shopReducer, shopState } from "./shopReducer";
import laptop from "../shop/imgs/laptop.png";
import washer from "../shop/imgs/washingmachine.png";
import tv from "../shop/imgs/tv.png";

export const ShopContext = createContext(null);

const initialState: shopState = {
    session_id: "",
    cartItems: [
        { item: "laptop", quantity: 0, img: laptop, },
        { item: "washer", quantity: 0 ,img: washer,},
        { item: "tv", quantity: 0, img: tv,},
    ],
    stock: [
        {
            name: "laptop",
            img: laptop,
            desc: "A random laptop",
            price: 800,
        },
        {
            name: "washer",
            img: washer,
            desc: "A random washing machine",
            price: 950,
        },
        {
            name: "tv",
            img: tv,
            desc: "A random TV",
            price: 400,
        },
    ],
};

export const ShopProvider = (props) => {
    const [shopState, dispatch] = useReducer(shopReducer, initialState);

    return <ShopContext.Provider value={[shopState, dispatch]}>{props.children}</ShopContext.Provider>;
};
