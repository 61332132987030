import { LOAN } from "services/api/Globals";
import { ApplicationFlowForm } from "types/applicationProcess";

/**
 * Will be used to store localy application flow form data,
 * so the user's data is persisted if the user leaves the page before the data is saved to the backend.
 */
export class LocalApplicationFlowDataManager {
    private readonly LOCAL_STORAGE_KEY: string = "applicationFlowForm";

    public constructor() {
        this.init();
    }

    /**
     * Returns the stored form. The forst stores 1 application.
     */
    public get(): ApplicationFlowForm {
        try {
            const localData = localStorage.getItem(this.LOCAL_STORAGE_KEY);
            return JSON.parse(localData);
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public update(data): void {
        try {
            localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(data));
        } catch (error) {
            console.error(error);
        }
    }

    public reset(): void {
        try {
            localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(this.getInitialStorageState()));
        } catch (error) {
            console.error(error);
        }
    }

    public getInitialStorageState(): ApplicationFlowForm {
        return {
            email: "",
            selected_merchant_flow: undefined,
            application_id: undefined,
            client_id: undefined,
            session_id: undefined,
            amount: LOAN.MERCHANT_APPLICATION_DEFAULT_AMOUNT,
            duration_months: LOAN.MERCHANT_APPLICATION_DEFAULT_DURATION,
            signature_jpg_data_uri: undefined,
            personal_info: {
                salutation: null,
                first_name: "",
                last_name: "",
                date_of_birth: "",
                place_of_birth: "",
                nationality: "",
                marital_status: undefined,
            },
            address_info: {
                street_name: "",
                street_number: "",
                post_code: "",
                city: "",
                country: "",
                resident_since: "",
                previous_address: {
                    street_name: "",
                    street_number: "",
                    post_code: "",
                    city: "",
                    country: "",
                }
            },
            job_info: {
                profession: "",
                branche: "",
                occupation_group: "",
                employed_since: "",
                employer: "",
                employer_location: "",
                prev_employed_since: "",
                prev_employer_name: "",
                net_income: "",
            },
            household_info: {
                residence_type: undefined,
                residence_monthly_cost: "",
                number_of_children: undefined,
                has_vehicle: undefined,
            },
            id_info: {
                id_doc_type: "",
                id_doc_issued_place: "",
                id_doc_number: "",
                id_doc_issued_at: "",
                id_doc_expires_at: "",
                iban: "",
                bic: "",
                bank_name: "",
                mobile_number:"",
            },
        };
    }

    /**
     * If there's no object in local storage, an empty form data object is initialized and persisted.
     */
    private init(): void {
        try {
            const localDataInitalized: ApplicationFlowForm = this.getInitialStorageState();

            const localData = localStorage.getItem(this.LOCAL_STORAGE_KEY);

            if (localData == null) {
                localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(localDataInitalized));
            }
        } catch (error) {
            console.error(error);
        }
    }
}

