import { Card, Col, Row, Typography, Empty } from "antd";
import { admintGetSelectedOffer } from "api/admin/admin";
import { OfferResponse } from "api/types";
import styles from "components/admin/pages/application-drilldown/ApplicationDrilldownPage.module.scss";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface OfferTabProps {
    applicationId: string
}

export const OfferTab = (props: OfferTabProps) => {
    const { t } = useTranslation();

    const [selectedOffer, setSelectedOffer] = React.useState<OfferResponse>(null);

    const getDataAndSetState = async (): Promise<void> => {
        try {
            const { data } = await admintGetSelectedOffer(props.applicationId);
            setSelectedOffer(data);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        getDataAndSetState();
    }, [props.applicationId]);

    return (
        <>
            <Row gutter={24} align="stretch">
                <Col span={24}>
                    {!selectedOffer ? (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <Typography.Text className={styles.faintText} style={{ textAlign: "center" }}>
                                    Für diesen antrag wurde noch keine Angebote eingeholt.
                                </Typography.Text>
                            }
                        />
                    ) : (
                        <Card
                            style={{ height: "100%" }}
                            title={
                                <Typography.Title className={styles.clientTitle} level={5}>
                                    {selectedOffer.partner_bank_name}
                                </Typography.Title>
                            }
                        >
                            <Row gutter={12} justify={"space-between"}>
                                <Col span={4}>
                                    <span className={styles.faintText}>{t("tables:isSelected")} :</span>
                                </Col>
                                <Col span={20}>
                                    <span>{selectedOffer.is_selected ? "Ja" : "Nein"}</span>
                                </Col>
                            </Row>
                            <Row gutter={12} justify={"space-between"}>
                                <Col span={4}>
                                    <span className={styles.faintText}>{t("tables:loanAmount")} :</span>
                                </Col>
                                <Col span={20}>
                                    <span>{selectedOffer.loan_amount}</span>
                                </Col>
                            </Row>

                            <Row gutter={12} justify={"space-between"}>
                                <Col span={4}>
                                    <span className={styles.faintText}>{t("tables:totalAmount")} :</span>
                                </Col>
                                <Col span={20}>
                                    <span>{selectedOffer.total_amount}</span>
                                </Col>
                            </Row>

                            <Row gutter={12} justify={"space-between"}>
                                <Col span={4}>
                                    <span className={styles.faintText}>{t("tables:duration_months")} :</span>
                                </Col>
                                <Col span={20}>
                                    <span>{selectedOffer.duration_months}</span>
                                </Col>
                            </Row>
                            <Row gutter={12} justify={"space-between"}>
                                <Col span={4}>
                                    <span className={styles.faintText}>{t("tables:interest_rate")} :</span>
                                </Col>
                                <Col span={20}>
                                    <span>{selectedOffer.interest_rate}</span>
                                </Col>
                            </Row>

                            <Row gutter={12} justify={"space-between"}>
                                <Col span={4}>
                                    <span className={styles.faintText}>{t("tables:repaymentMonths")} :</span>
                                </Col>
                                <Col span={20}>
                                    <span>{selectedOffer.repayment_period_months}</span>
                                </Col>
                            </Row>

                            <Row gutter={12} justify={"space-between"}>
                                <Col span={4}>
                                    <span className={styles.faintText}>{t("tables:interest_rate")} :</span>
                                </Col>
                                <Col span={20}>
                                    <span>{selectedOffer.installment_rate}</span>
                                </Col>
                            </Row>
                            {selectedOffer.bank_specific_data && (
                                <>
                                    <Row gutter={12} justify={"space-between"}>
                                        <Col span={4}>
                                            <span
                                                className={styles.strongText}
                                                style={{ color: "rgb(0,0,0) !important" }}
                                            >
                                                {t("tables:bankSpecificData")} :
                                            </span>
                                        </Col>
                                    </Row>

                                    <Row gutter={12} justify={"space-between"}>
                                        <Col span={4}>
                                            <span className={styles.faintText}>{t("tables:payeverStatus")} :</span>
                                        </Col>
                                        <Col span={20}>
                                            <span>{selectedOffer.bank_specific_data.payever_status}</span>
                                        </Col>
                                    </Row>

                                    <Row gutter={12} justify={"space-between"}>
                                        <Col span={4}>
                                            <span className={styles.faintText}>{t("tables:santanderStatus")} :</span>
                                        </Col>
                                        <Col span={20}>
                                            <span>{selectedOffer.bank_specific_data.stantander_status}</span>
                                        </Col>
                                    </Row>
                                    <Row gutter={12} justify={"space-between"}>
                                        <Col span={4}>
                                            <span className={styles.faintText}>{t("tables:paymentId")} :</span>
                                        </Col>
                                        <Col span={20}>
                                            <span>{selectedOffer.bank_specific_data.payment_id}</span>
                                        </Col>
                                    </Row>

                                    <Row gutter={12} justify={"space-between"}>
                                        <Col span={4}>
                                            <span className={styles.faintText}>{t("tables:financeId")} :</span>
                                        </Col>
                                        <Col span={20}>
                                            <span>{selectedOffer.bank_specific_data.finance_id}</span>
                                        </Col>
                                    </Row>

                                    <Row gutter={12} justify={"space-between"}>
                                        <Col span={4}>
                                            <span className={styles.faintText}>{t("tables:signingLink")} :</span>
                                        </Col>
                                        <Col span={20}>
                                            <a href={selectedOffer.bank_specific_data.signing_center_link}>
                                                {selectedOffer.bank_specific_data.signing_center_link}
                                            </a>
                                        </Col>
                                    </Row>

                                    <Row gutter={12} justify={"space-between"}>
                                        <Col span={4}>
                                            <span className={styles.faintText}>{t("tables:bankMessage")} :</span>
                                        </Col>
                                        <Col span={20}>
                                            <span style={{ width: "50%" }}>
                                                {selectedOffer.bank_specific_data.bank_message}
                                            </span>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Card>
                    )}
                </Col>
            </Row>
        </>
    );
};
