import { Col, Row, Table } from "antd";
import Search from "antd/lib/input/Search";
import { adminGetMerchantUsers } from "api/admin/merchants";
import { Merchant } from "api/types";
import { columns } from "components/admin/presenters/admin-home-tabs/merchant-users-list-tab/merchantsUsersListColumns";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PaginationType } from "types/adminPanel";
import styles from "./MerchantUsersListTab.module.scss";

export const MerchantUsersListTab = () => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState<Merchant[]>([]);
    const [pagination, setPagination] = React.useState<PaginationType>({});

    const [searchStr, setSearchStr] = useState<string>("");

    const getMerchantUsers = async (page: number, searchStr?: string) => {
        try {
            setLoading(true);
            const getMerchantsReq = await adminGetMerchantUsers(page, searchStr);

            if (getMerchantsReq && getMerchantsReq?.status == 200) {
                setData(getMerchantsReq?.data?.result);
                setPagination(getMerchantsReq?.data?.pagination);
            } else {
                setData([]);
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        getMerchantUsers(1);
    }, []);

    const handleSearch = () => {
        try {
            if (searchStr && searchStr?.length > 2) {
                getMerchantUsers(1, searchStr.trim());
            } else if (searchStr === "") {
                getMerchantUsers(1);
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className={styles.adminHomeTab}>
            <Spacer hx={1} />
            <Row>
                <Col span={24}>
                    <div className="row-flex-end">
                        <Search
                            className="search-bar"
                            placeholder={t("freeTextSearch")}
                            value={searchStr}
                            onChange={(e) => {
                                setSearchStr(e.target.value);
                            }}
                            onSearch={(value) => {
                                handleSearch();
                            }}
                        />
                    </div>
                </Col>
                <Col>
                    <p
                        className="clearSearch"
                        onClick={() => {
                            setSearchStr("");
                            getMerchantUsers(1);
                        }}
                    >
                        Suche zurücksetzen
                    </p>
                </Col>
            </Row>

            <Table
                loading={loading}
                columns={columns}
                dataSource={data}
                onChange={(e) => getMerchantUsers(e.current)}
                pagination={{
                    position: ["bottomCenter"],
                    current: pagination.current_page,
                    total: pagination.total_rows,
                    pageSize: pagination.items_per_page,
                    showSizeChanger:false,
                }}
                scroll={{ x: true }}
            />
        </div>
    );
};
