import { ApiWrapper } from "api/ApiWrapper";
import { ApiResponse } from "api/types";
import { PostUser } from "api/types/auth";

export const clientConfirmEmail = async (confirmEmailToken: string): Promise<ApiResponse<void>> => {
    const path = `/client/account/confirm_email`;

    const body = {
        token: confirmEmailToken,
    };

    return ApiWrapper.post(path, body, true);
};

export const registerClientUser = async (data: PostUser): Promise<any> => {
    const path = `/client/account/register`;

    return ApiWrapper.post(path, data) as Promise<any>;
};

export const loginClientUser = async (email: string, password: string): Promise<any> => {
    const path = `/client/account/login`;

    const body = {
        email: email,
        password: password
    };

    return ApiWrapper.post(path, body) as Promise<any>;
};

/**
 * Call this to refresh the session and to check if the user's logged in.
 */
export const refreshClientSession = async (manuallyHandleError?: boolean) => {
    const path = `/client/account/refresh_session`;

    return ApiWrapper.post(path, {}, manuallyHandleError);
};

export const logoutClient = async (): Promise<any> => {
    const path = `/client/account/logout`;

    return ApiWrapper.post(path, {});
};

export const clientGrantMerchantAccess = async (token: string): Promise<any> => {
    const path = `/client/grant_merchant_access`;

    const body = {
        token: token
    };

    return ApiWrapper.put(path, body);
};

export const passwordResetClient = async (username: string): Promise<any> => {
    const path = `/client/account/reset`;
    const body = {
        email: username,
    };

    return ApiWrapper.post(path, body, true);
};
