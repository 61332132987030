import React, { createContext, useReducer } from "react";
import { appReducer, appState } from "storage/reducers/appReducer";

export const AppContext = createContext(null);

const initialState: appState = {
    currentPage: "/",
    lastSuccessPage: "/",
};
function loadFromSessionStorage() {
    const parse = (key: string) => (sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : undefined);

    try {
        const currentPage = parse("session:currentPage");
        const { lastSuccessPage } = parse("session:lastSuccessPage") || initialState;

        return {
            currentPage,
            lastSuccessPage,
        };
    } catch (e) {
        console.error(e);
        return undefined;
    }
}

export const AppProvider = (props) => {
    // initial state here
    const [appState, dispatch] = useReducer(appReducer, initialState,
        loadFromSessionStorage);

    return <AppContext.Provider value={[ appState, dispatch]}>{props.children}</AppContext.Provider>;
};
