import {
    CheckOutlined,
    CloseOutlined,
    CloudUploadOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    FileAddOutlined,
    FileOutlined,
} from "@ant-design/icons";
import {
    Checkbox,
    Col,
    ConfigProvider,
    Input,
    Layout,
    message,
    Popconfirm,
    Row,
    Space,
    Spin,
    Table,
    Typography,
} from "antd";
import styles from "./DocumentsSection.module.scss";
import * as React from "react";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { GetDocumentsListResponse, RetrieveDocumentUploadLinkResponse } from "../../../../api/types";
import { DocumentCategorySelector } from "./DocumentCategorySelector";
import { DocumentCategory, DocumentType } from "../../../../types/types";
import { openUrlInNewTab } from "../../../../helpers/uiHelpers";
import {
    adminDeleteClientDocument,
    adminGetClientDocumentURL,
    adminRetrieveClientDocumentUploadLink,
    adminSetClientDocumentUploadedSuccessfully,
    adminUpdateClientDocument,
} from "../../../../api/document-service/adminOnClientDocuments";
import { uploadFileToURL } from "../../../../api/document-service/upload";
import {
    adminDeleteApplicationDocument,
    adminDeleteMerchantDocument,
    adminGetApplicationDocumentURL,
    adminGetMerchantDocumentURL,
    adminRetrieveApplicationDocumentUploadLink,
    adminRetrieveMerchantDocumentUploadLink,
    adminSetApplicationDocumentUploadedSuccessfully,
    adminSetMerchantDocumentUploadedSuccessfully,
    adminUpdateApplicationDocument,
    adminUpdateMerchantDocument,
} from "../../../../api/document-service/adminOnApplicationDocuments";
import {
    cpDeleteApplicationDocument,
    cpGetApplicationDocumentURL,
    cpRetrieveApplicationDocumentUploadLink,
    cpSetApplicationDocumentUploadedSuccessfully,
    cpUpdateApplicationDocument,
} from "../../../../api/document-service/contactPersonOnApplicationDocuments";
import {
    cpDeleteClientDocument,
    cpGetClientDocumentURL,
    cpRetrieveClientDocumentUploadLink,
    cpSetClientDocumentUploadedSuccessfully,
    cpUpdateClientDocument,
} from "../../../../api/document-service/contactPersonOnClientDocuments";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { DocumentsChecklist } from "components/common/presenters/documents-section/DocumentsChecklist";

export enum DocumentSectionActor {
    Admin,
    ContactPerson,
}

export interface DocumentsSectionProps {
    // TODO mandatory params
    showDocList?: boolean
    companyCountry?: string
    noDivider?: boolean
    actor: DocumentSectionActor
    entityType: DocumentType
    entityId: string // clientId, applicationId, ...
    documents: GetDocumentsListResponse[]
    onDataChanged: (pageNumber: number) => Promise<void>
    pagination: {
        current: number
        total: number
        pageSize: number
        showSizeChanger: boolean
    }
}

const { Content } = Layout;

export const DocumentsSection = (props: DocumentsSectionProps) => {
    const { t } = useTranslation();

    const [isUploading, setIsUploading] = useState(false);

    const [editingId, setEditingId] = useState(null as string);
    const [editingName, setEditingName] = useState(null as string);
    const [editingCategory, setEditingCategory] = useState(null as DocumentCategory);
    const [editingClientVisible, setEditingClientVisible] = useState(null as boolean);

    const [stateDocs, setStateDocs] = useState<GetDocumentsListResponse[]>(props.documents);

    React.useEffect(() => {
        setStateDocs(props.documents);
    }, [props.documents]);

    const handleEditDocument = async () => {
        try {
            if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Client) {
                await adminUpdateClientDocument(
                    props.entityId,
                    editingId,
                    editingName,
                    editingCategory,
                    editingClientVisible
                );
            } else if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Application) {
                await adminUpdateApplicationDocument(
                    props.entityId,
                    editingId,
                    editingName,
                    editingCategory,
                    editingClientVisible
                );
            } else if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Merchant) {
                await adminUpdateMerchantDocument(props.entityId, editingId, editingName, editingCategory);
            } else if (props.actor === DocumentSectionActor.ContactPerson && props.entityType === DocumentType.Client) {
                await cpUpdateClientDocument(props.entityId, editingId, editingName, editingCategory);
            } else if (
                props.actor === DocumentSectionActor.ContactPerson &&
                props.entityType === DocumentType.Application
            ) {
                await cpUpdateApplicationDocument(props.entityId, editingId, editingName, editingCategory);
            } else {
                return;
            }

            setEditingId(null);
            setEditingName(null);
            setEditingCategory(null);
            setEditingClientVisible(null);

            props.onDataChanged(1);
            message.success(t("messages:dataSaved"), 2);
        } catch (e) {
            console.error(e);
            message.error(t("messages:couldNotSave"), 2);
        }
    };

    const handleOpenEditing = (record: GetDocumentsListResponse) => {
        setEditingId(record.document_id);
        setEditingName(record.document_name);
        setEditingCategory(record.document_category);
        setEditingClientVisible(record.is_client_visible);
    };

    const handleCloseEditing = () => {
        setEditingId(null);
        setEditingName(null);
        setEditingCategory(null);
        setEditingClientVisible(null);
    };

    const handleViewDocument = async (documentId: string) => {
        try {
            let documentUrl: string;

            if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Client) {
                documentUrl = (await adminGetClientDocumentURL(props.entityId, documentId)).data;
            } else if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Application) {
                documentUrl = (await adminGetApplicationDocumentURL(props.entityId, documentId)).data;
            } else if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Merchant) {
                documentUrl = (await adminGetMerchantDocumentURL(props.entityId, documentId)).data;
            } else if (props.actor === DocumentSectionActor.ContactPerson && props.entityType === DocumentType.Client) {
                documentUrl = (await cpGetClientDocumentURL(props.entityId, documentId)).data;
            } else if (
                props.actor === DocumentSectionActor.ContactPerson &&
                props.entityType === DocumentType.Application
            ) {
                documentUrl = (await cpGetApplicationDocumentURL(props.entityId, documentId)).data;
            } else {
                return;
            }

            openUrlInNewTab(documentUrl);
        } catch (e) {
            console.error(e);
            message.error(t("messages:errorEncountered"), 2);
        }
    };

    const handleDeleteDocument = async (documentId: string) => {
        try {
            if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Client) {
                await adminDeleteClientDocument(props.entityId, documentId);
            } else if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Application) {
                await adminDeleteApplicationDocument(props.entityId, documentId);
            } else if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Merchant) {
                await adminDeleteMerchantDocument(props.entityId, documentId);
            } else if (props.actor === DocumentSectionActor.ContactPerson && props.entityType === DocumentType.Client) {
                await cpDeleteClientDocument(props.entityId, documentId);
            } else if (
                props.actor === DocumentSectionActor.ContactPerson &&
                props.entityType === DocumentType.Application
            ) {
                await cpDeleteApplicationDocument(props.entityId, documentId);
            } else {
                return;
            }

            props.onDataChanged(1);
            message.success(t("messages:documentDeleted"), 2);
        } catch (e) {
            console.error(e);
            message.error(t("messages:errorEncountered"), 2);
        }
    };

    const columns = [
        {
            render: () => <FileOutlined className={styles.iconInRow} />,
        },
        {
            title: t("name"),
            width: 300,
            dataIndex: "document_name",
            render: (value, record) => renderDocumentName(record),
        },
        {
            title: t("type"),
            width: 300,
            dataIndex: "document_category",
            render: (value, record) => renderDocumentCategory(record),
        },
        props.actor === DocumentSectionActor.Admin
            ? {
                title: t("visibleToClient"),
                dataIndex: "is_client_visible",
                render: (value, record) => renderVisibilityToggle(record),
            }
            : {
                render: () => null,
            },
        {
            title: t("action"),
            dataIndex: "document_id",
            render: (value, record) => renderRowIcons(record),
        },
    ];

    const renderDocumentName = (record: GetDocumentsListResponse) => {
        return editingId === record.document_id ? (
            <Input
                defaultValue={record.document_name}
                minLength={1}
                maxLength={255}
                onChange={(e) => setEditingName(e.target.value)}
            />
        ) : (
            <p>{record.document_name}</p>
        );
    };

    const renderDocumentCategory = (record: GetDocumentsListResponse) => {
        const uploadOnCategoryChange = async (newCategory: string) => {
            try {
                const retrieveLinkResponse = (
                    await adminRetrieveMerchantDocumentUploadLink(props.entityId, record.fileToUpload.name, newCategory)
                )?.data;
                await uploadFileToURL(record.fileToUpload, retrieveLinkResponse.upload_url);

                await adminSetMerchantDocumentUploadedSuccessfully(props.entityId, retrieveLinkResponse.document_id);
                props.onDataChanged(1);
            } catch (e) {
                console.error(e);
                message.error(t("messages:errorEncountered"), 2);
            }
        };

        if (
            props.actor === DocumentSectionActor.Admin &&
            props.entityType === DocumentType.Merchant &&
            record.fileToUpload
        ) {
            // category selector which uploads on change
            return (
                <DocumentCategorySelector
                    category={record.document_category}
                    onChange={(newCategory) => {
                        uploadOnCategoryChange(newCategory);
                    }}
                />
            );
        }
        return editingId === record.document_id ? (
            <DocumentCategorySelector
                category={record.document_category}
                onChange={(newCategory) => {
                    setEditingCategory(newCategory);
                }}
            />
        ) : (
            <p>{t(`documentCategories:${record.document_category}`)}</p>
        );
    };

    const renderVisibilityToggle = (record: GetDocumentsListResponse) => {
        return (
            <Checkbox
                disabled={editingId !== record.document_id}
                checked={editingId === record.document_id ? editingClientVisible : record.is_client_visible}
                onChange={(e) => {
                    setEditingClientVisible(e.target.checked);
                }}
            />
        );
    };

    const renderRowIcons = (record: GetDocumentsListResponse) => {
        if (record.noIcons) {
            return null;
        }
        return editingId === record.document_id ? (
            <Space direction={"horizontal"} align={"start"} size={12}>
                <CheckOutlined className={styles.iconInRow} onClick={handleEditDocument} />
                <CloseOutlined className={styles.iconInRow} onClick={handleCloseEditing} />
            </Space>
        ) : (
            <Space direction={"horizontal"} align={"start"} size={12}>
                {editingId ? (
                    <EditOutlined className={styles.inactiveIconInRow} />
                ) : (
                    <EditOutlined className={styles.iconInRow} onClick={() => handleOpenEditing(record)} />
                )}
                <EyeOutlined className={styles.iconInRow} onClick={() => handleViewDocument(record.document_id)} />
                <Popconfirm
                    title={t("confirmDocumentDelete")}
                    onConfirm={() => handleDeleteDocument(record.document_id)}
                    okText={t("yes")}
                    cancelText={t("no")}
                >
                    <DeleteOutlined className={styles.iconInRow} />
                </Popconfirm>
            </Space>
        );
    };

    const onDropFiles = async (acceptedFiles: any) => {
        setIsUploading(true);

        if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Merchant) {
            const fileBeforeUpload = {
                document_name: acceptedFiles[0].name,
                document_category: null,
                document_id: null,
                fileToUpload: acceptedFiles[0],
                noIcons: true,
            };

            setStateDocs([fileBeforeUpload, ...stateDocs]);
        }

        await Promise.all(
            acceptedFiles?.map(async (file) => {
                try {
                    let retrieveLinkResponse: RetrieveDocumentUploadLinkResponse;

                    if (props.actor === DocumentSectionActor.Admin && props.entityType === DocumentType.Client) {
                        retrieveLinkResponse = (
                            await adminRetrieveClientDocumentUploadLink(
                                props.entityId,
                                file.name,
                                DocumentCategory.Other
                            )
                        )?.data;
                        await uploadFileToURL(file, retrieveLinkResponse.upload_url);
                        await adminSetClientDocumentUploadedSuccessfully(
                            props.entityId,
                            retrieveLinkResponse.document_id
                        );
                        props.onDataChanged(1);
                        return Promise.resolve();
                    } else if (
                        props.actor === DocumentSectionActor.Admin &&
                        props.entityType === DocumentType.Application
                    ) {
                        retrieveLinkResponse = (
                            await adminRetrieveApplicationDocumentUploadLink(
                                props.entityId,
                                file.name,
                                DocumentCategory.Other
                            )
                        )?.data;
                        await uploadFileToURL(file, retrieveLinkResponse.upload_url);
                        await adminSetApplicationDocumentUploadedSuccessfully(
                            props.entityId,
                            retrieveLinkResponse.document_id
                        );
                        props.onDataChanged(1);
                        return Promise.resolve();
                    } else if (
                        props.actor === DocumentSectionActor.ContactPerson &&
                        props.entityType === DocumentType.Client
                    ) {
                        retrieveLinkResponse = (
                            await cpRetrieveClientDocumentUploadLink(props.entityId, file.name, DocumentCategory.Other)
                        )?.data;
                        await uploadFileToURL(file, retrieveLinkResponse.upload_url);
                        await cpSetClientDocumentUploadedSuccessfully(props.entityId, retrieveLinkResponse.document_id);
                        props.onDataChanged(1);
                        return Promise.resolve();
                    } else if (
                        props.actor === DocumentSectionActor.ContactPerson &&
                        props.entityType === DocumentType.Application
                    ) {
                        retrieveLinkResponse = (
                            await cpRetrieveApplicationDocumentUploadLink(
                                props.entityId,
                                file.name,
                                DocumentCategory.Other
                            )
                        )?.data;
                        await uploadFileToURL(file, retrieveLinkResponse.upload_url);
                        await cpSetApplicationDocumentUploadedSuccessfully(
                            props.entityId,
                            retrieveLinkResponse.document_id
                        );
                        props.onDataChanged(1);
                        return Promise.resolve();
                    } else {
                        return null;
                    }
                } catch (e) {
                    message.error(t("messages:errorEncountered"), 2);
                    console.error(t("failedUpload"), file?.name, e);
                }

                return null;
            })
        );

        setIsUploading(false);
    };

    const addDocs = (
        <Row gutter={24}>
            <Col span={16}>
                {isUploading ? (
                    <section>
                        <Spin className={styles.dropzone} size={"large"} />
                    </section>
                ) : (
                    <Dropzone onDrop={onDropFiles}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div className={styles.dropzone} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>
                                        <CloudUploadOutlined className={styles.iconAdd} />
                                        Dokument hochladen
                                    </p>
                                    <Spacer />
                                </div>
                            </section>
                        )}
                    </Dropzone>
                )}
            </Col>
        </Row>
    );

    const empty = () => {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <FileAddOutlined className={styles.emptyNoteIcon} />
                <Spacer />
                <Typography.Title level={5}>Drag & Drop für Dokumente</Typography.Title>
                <p className={styles.emptyNoteText}>Oder den Button klicken.</p>
                {addDocs}
            </div>
        );
    };
    return (
        <Content className={styles.tab}>
            {props.showDocList ? <DocumentsChecklist companyCountry={props.companyCountry} /> : null}

            <Space direction="vertical" size="large" style={{ width: "100%" }}>
                {stateDocs?.length ? addDocs : null}
                <Row>
                    <Col span={24}>
                        <ConfigProvider renderEmpty={() => empty()}>
                            <Table
                                rowKey={(record) => record.document_id}
                                columns={columns}
                                dataSource={stateDocs}
                                pagination={{
                                    position: ["bottomCenter"],
                                    current: props.pagination.current,
                                    total: props.pagination.total,
                                    pageSize: props.pagination.pageSize,
                                    showSizeChanger:false,
                                }}
                                onChange={(e) => props.onDataChanged(e.current)}
                            />
                        </ConfigProvider>
                    </Col>
                </Row>
            </Space>
        </Content>
    );
};
