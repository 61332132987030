import { Button, Card, Col, message, Row, Typography } from "antd";
import {
    adminChangeApplicationStates,
    adminGetApplication,
    adminGetApplicationPaymentLink,
    adminGetClient,
} from "api/admin/admin";
import { adminGetMerchant } from "api/admin/merchants";
// import { merchantConfirmShipping } from "api/merchant";
import { ApplicationAction, ApplicationObject } from "api/types";
import { GetClientObject } from "api/types/client";
import styles from "components/admin/pages/application-drilldown/ApplicationDrilldownPage.module.scss";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { currencyLocaleFormatter } from "formatters/currencyFormatters";
// import { isAdminApp, isMerchantApp } from "helpers/appHelpers";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Application, ApplicationStates } from "types/types";

export interface ApplicationDetailsTabProps {
    application: Application
    applObject: ApplicationObject
    appId?: string
    updateStatus?: React.Dispatch<React.SetStateAction<ApplicationStates>>
}

export const ApplicationDetailsTab = (props: ApplicationDetailsTabProps) => {
    const { t } = useTranslation();
    const [clientData, setClientData] = React.useState({} as GetClientObject);
    const [merchantData, setMerchantData] = React.useState({} as any);
    const [applActions, setApplActions] = React.useState([]);
    const [updatedAppl, setUpdatedAppl] = React.useState<null | Application>();

    const [paymentLink, setApplPaymentLink] = React.useState<string>("");
    // for scraper reruns
    const [isRerunning, setIsRerunning] = React.useState<boolean>(false);

    const getDataAndSetState = async (): Promise<any> => {
        try {
            const { data } = await adminGetApplication(props.appId);
            setApplActions(data.actions);

            if (
                [
                    ApplicationStates.Open,
                    ApplicationStates.AcquiringOffers,
                    ApplicationStates.AcquiringOffersError,
                ].includes(data.status)
            ) {
                const { data } = await adminGetApplicationPaymentLink(props.appId);
                setApplPaymentLink(data.payment_link || "");
            }
        } catch (error) {
            console.error(error);
        }

        // separate try-catch block for each req group, because otherwise if 1 fails, the rest don't get executed
        try {
            if (props.application.client_id) {
                const result = await adminGetClient(props.application.client_id);

                setClientData(result.data);
            }
            if (props.application.merchant_id) {
                const merchant = await adminGetMerchant(props.application.merchant_id);

                setMerchantData(merchant.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        getDataAndSetState();
    }, [props.application.client_id]);

    const rerunAction = async (action) => {
        try {
            setIsRerunning(true);
            await adminChangeApplicationStates(props.application.application_id, action);
        } catch (err) {
            console.error(err);
            void message.error(t("errors:generalError"));
        } finally {
            setIsRerunning(false);
            updateApplication();
        }
    };

    const updateApplication = async () => {
        try {
            const { data } = await adminGetApplication(props.appId);
            setUpdatedAppl(data);
            // also update actions
            setApplActions(data.actions);
            // update the header status
            props.updateStatus(data.status);
        } catch (error) {
            console.error(error);
            void message.error(t("errors:generalError"));
        }
    };

    const handleApplicationStatusChange = async (appId: string, action: ApplicationAction) => {
        try {
            await adminChangeApplicationStates(appId, action);
        } catch (error) {
            console.error(error);
            void message.error(t("errors:generalError"));
        } finally {
            updateApplication();
        }
    };

    // if application was updated from this page, fresh data takes presedence
    const appl = updatedAppl || props.application;

    return (
        <>
            <Row gutter={24} align="stretch">
                <Col span={12}>
                    <Card
                        style={{ height: "100%" }}
                        title={
                            <Typography.Title className={styles.clientTitle} level={5}>
                                Antragsinformationen
                            </Typography.Title>
                        }
                    >
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Antrags ID :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.short_id ? appl.short_id : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>{t("handler")} :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.merchant_name ? appl.merchant_name : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>{t("labels:amount")} :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.amount ? currencyLocaleFormatter(appl.amount) : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>{t("currency")} :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.currency ? appl.currency : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>{t("status")} :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.status ? t(`applicationStates:${appl.status}`) : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Letzte Statusänderung :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.created_at ? appl.created_at.split("T")[0] : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>{t("loanDuration")} :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.duration_months ? appl.duration_months : "-"}</span>
                            </Col>
                        </Row>
                        {appl.status === ApplicationStates.AcquiringOffersError && (
                            <Row gutter={12} justify={"space-between"}>
                                <Col span={8}>
                                    <span className={styles.faintText}>Santander Session Link :</span>
                                </Col>
                                <Col span={16}>
                                    <a href={paymentLink}>{paymentLink}</a>
                                </Col>
                            </Row>
                        )}
                    </Card>
                </Col>

                <Col span={12}>
                    <Card
                        style={{ height: "100%" }}
                        title={
                            <Typography.Title className={styles.clientTitle} level={5}>
                                Verfügbare Aktionen
                            </Typography.Title>
                        }
                    >
                        <Row justify="space-between">
                            {applActions ? (
                                applActions.map((action, idx) => {
                                    if (action.params) {
                                        return (
                                            <Button
                                                key={`action-${idx}`}
                                                type="primary"
                                                className={`btn-${action.color}`}
                                                onClick={() => {
                                                    handleApplicationStatusChange(
                                                        props.application.application_id,
                                                        action
                                                    );
                                                }}
                                            >
                                                {t(`buttons:${action.params.new_status}`)}
                                            </Button>
                                        );
                                    } else if (action.name === "rerun_scraper") {
                                        return (
                                            <Row justify="space-between" key={`row-${idx}`}>
                                                {!isRerunning && (
                                                    <Col span={6}>
                                                        <Button
                                                            key={`retry-action-${idx}`}
                                                            type="primary"
                                                            className={`btn-${action.color}`}
                                                            onClick={() => {
                                                                rerunAction(action);
                                                            }}
                                                        >
                                                            {t(`buttons:${action.name}`)}
                                                        </Button>
                                                    </Col>
                                                )}

                                                <Col span={6}>
                                                    <Button
                                                        type="primary"
                                                        className={`btn-primary`}
                                                        onClick={updateApplication}
                                                    >
                                                        {t("buttons:refresh")}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        );
                                    } else {
                                        return null;
                                    }
                                })
                            ) : (
                                <Row>
                                    <Col span={6}>
                                        <Button type="primary" className={`btn-primary`} onClick={updateApplication}>
                                            {t("buttons:refresh")}
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Spacer hx={2} />

            <Row gutter={24} align="stretch">
                <Col span={12}>
                    <Card
                        style={{ height: "100%" }}
                        title={
                            <Typography.Title className={styles.clientTitle} level={5}>
                                Kundeninformationen
                            </Typography.Title>
                        }
                    >
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>{t("title")} :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_title ? appl.client_title : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}> Vorname :</span>
                            </Col>
                            <Col span={16}>
                                <span>{clientData.first_name ? clientData.first_name : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}> Nachname :</span>
                            </Col>
                            <Col span={16}>
                                <span>{clientData.last_name ? clientData.last_name : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}> Geburtsdatum :</span>
                            </Col>
                            <Col span={16}>
                                <span>{clientData.birth_date ? clientData.birth_date : "-"}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}> E-Mail :</span>
                            </Col>
                            <Col span={16}>
                                <span>{clientData.email ? clientData.email : "-"}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Handynummer :</span>
                            </Col>
                            <Col span={16}>
                                <span>{clientData.mobile_number ? clientData.mobile_number : "-"}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Familienstand :</span>
                            </Col>
                            <Col span={16}>
                                <span>
                                    {clientData.family_status
                                        ? clientData.family_status.charAt(0).toUpperCase() +
                                          clientData.family_status.slice(1)
                                        : "-"}
                                </span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"} align="top">
                            <Col span={8}>
                                <span className={styles.faintText}>Adresse :</span>
                            </Col>
                            <Col span={16}>
                                <Typography.Text style={{ display: "inline-block", verticalAlign: "top" }}>
                                    <Typography.Text>
                                        {clientData.address_street}, {clientData.address_house_number}
                                    </Typography.Text>
                                    <br />
                                    <Typography.Text>
                                        {clientData.address_post_code},{clientData.address_city}
                                    </Typography.Text>
                                    <br />
                                    <Typography.Text>
                                        {t(clientData.address_country_code).toUpperCase()}
                                    </Typography.Text>
                                    <br />
                                </Typography.Text>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>{t("residentSince")} :</span>
                            </Col>
                            <Col span={16}>
                                <span>
                                    {appl.client_address_resident_since ? appl.client_address_resident_since : "-"}
                                </span>
                            </Col>
                        </Row>
                        {appl.client_prev_address_country_code && (
                            <Row gutter={12} justify={"space-between"} align="top">
                                <Col span={8}>
                                    <span className={styles.faintText}>Vorherige Addresse :</span>
                                </Col>
                                <Col span={16}>
                                    <Typography.Text style={{ display: "inline-block", verticalAlign: "top" }}>
                                        <Typography.Text>
                                            {appl.client_prev_address_street}, {appl.client_prev_address_house_number}
                                        </Typography.Text>
                                        <br />
                                        <Typography.Text>
                                            {appl.client_prev_address_post_code},{appl.client_prev_address_city}
                                        </Typography.Text>
                                        <br />
                                        <Typography.Text>
                                            {t(appl.client_prev_address_country_code).toUpperCase()}
                                        </Typography.Text>
                                        <br />
                                    </Typography.Text>
                                </Col>
                            </Row>
                        )}
                    </Card>
                </Col>

                <Col span={12}>
                    <Card
                        style={{ height: "100%" }}
                        title={
                            <Typography.Title className={styles.clientTitle} level={5}>
                                Beruf & Haushalt
                            </Typography.Title>
                        }
                    >
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Beruf :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_job_title ? appl.client_job_title : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Branche :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_job_branch ? appl.client_job_branch : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Berufsgruppe :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_job_type ? t(`occupationGroups:${appl.client_job_type}`) : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>{t("employedSince")} :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_employed_since ? appl.client_employed_since : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>{t("employer")} :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_employer_name ? appl.client_employer_name : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>{t("employerLocation")} :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_employer_location ? appl.client_employer_location : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>{t("prev_employed_since")} :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_prev_employed_since ? appl.client_prev_employed_since : "-"}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>{t("prev_employer")} :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_prev_employer_name ? appl.client_prev_employer_name : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>{t("nettoIncome")} :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_net_income ? appl.client_net_income : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Wohnt :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_type_of_residence ? appl.client_type_of_residence : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Wohnkosten:</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_living_costs ? appl.client_living_costs : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"} align="middle">
                            <Col span={8}>
                                <span className={styles.faintText}>Fahrzeug :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_has_vehicle ? "Ja" : "Nein"}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Kinder :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_num_children ? appl.client_num_children : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Geburtsort :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_birth_city ? appl.client_birth_city : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Nationalität :</span>
                            </Col>
                            <Col span={16}>
                                <span>
                                    {appl.client_nationality_country_code
                                        ? appl.client_nationality_country_code.toUpperCase()
                                        : "-"}
                                </span>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Spacer hx={1} />
            <Row gutter={24}>
                <Col span={12}>
                    <Card
                        title={
                            <Typography.Title className={styles.clientTitle} level={5}>
                                Legitimationsinformationen
                            </Typography.Title>
                        }
                    >
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Dokumentenart : </span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_id_doc_type ? t(`idDocTypes:${appl.client_id_doc_type}`) : "-"}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Ausstellungsort : </span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_id_doc_issued_place ? appl.client_id_doc_issued_place : "-"}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Ausweisnummer : </span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_id_doc_number ? appl.client_id_doc_number : "-"}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Ausstellungsdatum : </span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_id_doc_issued_at ? appl.client_id_doc_issued_at : "-"}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Ablaufdatum : </span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_id_doc_expires_at ? appl.client_id_doc_expires_at : "-"}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>IBAN : </span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_iban ? appl.client_iban : "-"}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Bank Name : </span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_bank_name ? appl.client_bank_name : "-"}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>BIC : </span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_bic ? appl.client_bic : "-"}</span>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col span={12}>
                    <Card
                        style={{ height: "100%" }}
                        title={
                            <Typography.Title className={styles.clientTitle} level={5}>
                                Händlerinformationen
                            </Typography.Title>
                        }
                    >
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Händler ID : </span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.merchant_id}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Händlername :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.merchant_name ? appl.merchant_name : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}> Warenkorb ID :</span>
                            </Col>
                            <Col span={16}>
                                <span> {merchantData.short_id} </span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}> Order ID :</span>
                            </Col>
                            <Col span={16}>
                                <span> {appl.order_id} </span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}> Session ID :</span>
                            </Col>
                            <Col span={16}>
                                <span> {appl.session_id} </span>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
