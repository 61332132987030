import { ApiWrapper } from "api/ApiWrapper";
import { MerchantCreateApplicationForClient, MerchantCreateApplicationWithoutClient } from "api/types/merchant";

export const merchantCreateApplicationWithoutClient = (application: MerchantCreateApplicationWithoutClient): Promise<any> => {
    const path = `/merchant/applications`;

    return ApiWrapper.post(path, application);
};

export const merchantCreateClientApplication = async (clientId: string, application: MerchantCreateApplicationForClient): Promise<any> => {
    const path = `/merchant/clients/${clientId}/new_application`;

    return ApiWrapper.post(path, application);
};
