import * as React from "react";
import styles from './DataItemsColumn.module.scss';
import {Col, Typography} from "antd";

export interface DataItemProps {
    dataItems: ItemNameValue[]
}

export interface ItemNameValue {
    itemName?: string
    itemValue?: string | any
    isEmptyLine?: boolean
    isBoldValue?: boolean
}

export const DataItemsColumn = (props: DataItemProps) => {
    return (
        <Col span={24}>
            {props.dataItems?.map((item, i) => {
                return (
                    <React.Fragment key={i}>
                        <Typography.Paragraph className={styles.dataItem}>
                            <Typography.Text className={styles.dataItemName} strong>{item.itemName}:</Typography.Text>
                            <Typography.Text className={styles.dataItemValue} strong={item.isBoldValue}>
                                {item.itemValue ? item.itemValue : "-"}
                            </Typography.Text>
                        </Typography.Paragraph>
                    </React.Fragment>
                );
            })}
        </Col>
    );
};
