import { Card, Col, Row, Typography } from "antd";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { currencyLocaleFormatter } from "helpers/formatters";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "components/common/pages/application-flow/ApplicationFlow.module.scss";

interface OrderProps {
    draftData: {
        logo_url?: string
        amount?: string
        merchant_name?: string
    }
}

const OrderSummary = ({ draftData }: OrderProps) => {
    const { t } = useTranslation();
    return (
        <>
            <Card
                className={styles.summaryCard}
                title={
                    draftData?.logo_url ? (
                        <>
                            <Row>
                                <Col span={16}>
                                    <img alt="Store logo" src={draftData?.logo_url} />
                                </Col>
                            </Row>
                            <Spacer />
                            <Row>
                                <Col>
                                    <Typography.Text>{draftData?.merchant_name}</Typography.Text>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            <Row>
                                <Col span={14}>Logo</Col>
                                <Col span={4}>
                                    <Typography.Text>{draftData?.merchant_name}</Typography.Text>            
                                </Col>
                            </Row>
                        </>
                    )
                }
            >
                <Typography.Title level={5}>{t("orderSummary")}</Typography.Title>
                <Spacer />
                <Row>
                    <Col span={14}>{t("subtotal")}</Col>
                    <Col span={10}>EUR {draftData?.amount ? currencyLocaleFormatter(draftData?.amount) : "EUR 0,00"}</Col>      
                </Row>
                <Row>
                    <Col span={14}>{t("delivery")}</Col>

                    <Col span={10}>EUR 0,00</Col>
                </Row>
                <hr />
                <Row>
                    <Col span={14}>
                        <Typography.Title level={5}>{t("total")}</Typography.Title>
                    </Col>
                    <Col span={10}>EUR {draftData ? currencyLocaleFormatter(draftData.amount) : "EUR 0,00"}</Col>
                </Row>
            </Card>
            <Card
                className={styles.summaryCard}
                title={<Typography.Title level={5}>Haben Sie Fragen zu Ihrem Antrag?</Typography.Title>}
            >
                <Row>
                    <Col>
                        <Typography.Text>Wir helfen Ihnen gerne weiter:</Typography.Text>
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col>
                        <Typography.Text>Telefon (Mon-Fri, 9:00 - 17:00):</Typography.Text>
                    </Col>
                    <Col>
                        <Typography.Text>+49 7531 9573022</Typography.Text>
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Col>
                        <Typography.Text>E-Mail:</Typography.Text>
                    </Col>
                    <Col>
                        <Typography.Text>service@liquitree.com</Typography.Text>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default OrderSummary;
