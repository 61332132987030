import { ShoppingCartOutlined } from "@ant-design/icons";
import { Button, Card, Col, Layout, message, Row, Typography } from "antd";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import React, { useContext } from "react";
import styles from "./MockShop.module.scss";
import { ShopContext } from "../storage/shopContext";
import { Link } from "react-router-dom";

const { Header, Content, Footer } = Layout;
const MockShop = () => {
    const [shopState, dispatch] = useContext(ShopContext);

    const addToCart = (newItem) => {
        dispatch({
            type: "ADD_CART_ITEM",
            payload: {
                newItem,
            },
        });
        void message.success(`${newItem} Added to cart`);
    };

    const renderCard = (item, idx) => {
        return (
            <Col span={6} key={idx}>
                <Card cover={<img alt="product" src={item.img} style={{ height: "250px", cursor: "pointer" }} />}>
                    <Spacer />
                    <Typography.Title level={4}>{item.desc}</Typography.Title>
                    <Typography.Text>{item.price} EUR </Typography.Text>
                    <br />
                    <Spacer hx={2} />
                    <Row justify="center">
                        <Button
                            onClick={() => {
                                addToCart(item.name);
                            }}
                        >
                            Add to cart
                        </Button>
                    </Row>
                </Card>
            </Col>
        );
    };
    return (
        <Layout>
            <Header style={{ background: "transparent", borderBottom: "1px solid #9fd7ee" }}>
                <Row justify="space-between" style={{ transform: "translateY(-25px)" }}>
                    <Col span={2}>
                        <span className={styles.title}> Test Shop</span>
                    </Col>
                    <Col span={2}>
                        <Link to="/mock/cart">
                            <ShoppingCartOutlined className={styles.cart} />
                        </Link>
                    </Col>
                </Row>
            </Header>
            <Content>
                <Typography.Paragraph style={{ textAlign: "center" }}>
                    Some selection of items for the mock webshop{" "}
                </Typography.Paragraph>
                <Row gutter={24} style={{ marginTop: "100px" }} justify={"space-around"}>
                    {shopState.stock.map((item, idx) => {
                        return renderCard(item, idx);
                    })}
                </Row>
            </Content>
            <Footer style={{ textAlign: "center" }}>&copy; fake webshop</Footer>
        </Layout>
    );
};
export default MockShop;
