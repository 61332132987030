export interface appState {
    currentPage: string
    lastSuccessPage: string
}

export enum appActions {
    SET_PAGE = "SET_PAGE",
    SET_SUCCESSFUL_PAGE = "SET_SUCCESSFUL_PAGE",
}

export const appReducer = (state, action) => {
    switch (action.type) {
    case appActions.SET_PAGE:
        return {
            ...state,
            currentPage: action.payload.newPage,
        };
    case appActions.SET_SUCCESSFUL_PAGE:
        return {
            ...state,
            lastSuccessPage: action.payload.lastPage,
        };
    default:
        return state;
    }
};
