import * as React from "react";
import { Form, Input, message, Modal, Radio, Select } from "antd";
import { useTranslation } from "react-i18next";
import { AnchoredSelect } from "components/common/presenters/anchored-select/AnchoredSelect";
import { MerchantUserRole } from "types/types";
import { PostMerchantUser } from "api/types/merchant";
import { merchantCreateMerchantUser } from "api/merchant";
import { Salutations } from "types/applicationProcess";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { isAdminApp } from "helpers/appHelpers";
import { adminCreateMerchantUser } from "api/admin/merchants";
import { ConfirmModalClose } from "components/common/presenters/confirm-modal-close/ConfirmModalClose";
import { AxiosError } from "axios";

export interface AddMerchantUserModalProps {
    merchantId?: string
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    onDataChanged: () => void
}

export const AddMerchantUserModal = (props: AddMerchantUserModalProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [secondaryModalOpen, setSecondaryModalOpen] = React.useState(false);

    const checkForm = () => {
        // check if at least one value is filled in a form.
        const vals = Object.values(form.getFieldsValue());
        const notEmpty = vals.some((value) => value !== undefined);
        return notEmpty;
    };

    const onFormFinish = async (values) => {
        const req: PostMerchantUser = {
            email: values.email,
            title: values.title,
            first_name: values.first_name,
            last_name: values.last_name,
            telephone_number: values.telephone_number,
            location: values.location,
            role_id: values.role,
        };

        try {
            if (isAdminApp()) {
                await adminCreateMerchantUser(props.merchantId, req);
            } else {
                await merchantCreateMerchantUser(req);
            }

            message.success(t("messages:dataSaved"), 2);
            props.onDataChanged();
            props.setVisible(false);
        } catch (e) {
            const error = e as AxiosError;
            if (error.response?.status === 409) {
                message.error(t("messages:userWithEmailAlreadyExists"), 2);
            } else {
                message.error(t("messages:couldNotSave"), 2);
                console.error(e);
            }
        }
    };
    const secondaryModalOnCancel = () => {
        setSecondaryModalOpen(false);
    };
    const secondaryModalOnOk = () => {
        setSecondaryModalOpen(false);
        props.setVisible(false);
    };
    return (
        <>
            <ConfirmModalClose
                visible={secondaryModalOpen}
                onCancel={secondaryModalOnCancel}
                onOk={secondaryModalOnOk}
            />
            <Modal
                visible={props.visible}
                width={1000}
                maskClosable
                title={t("modals:merchantAccountTitle")}
                onOk={() => form.submit()}
                onCancel={() => {
                    if (!checkForm()) {
                        props.setVisible(false);
                    } else {
                        setSecondaryModalOpen(true);
                    }
                }}
            >
                <h4>{t("modals:merchantAccountInfo")}</h4>

                <Spacer />

                <Form form={form} onFinish={onFormFinish} layout={"vertical"} scrollToFirstError>
                    <Form.Item name={`title`}>
                        <Radio.Group data-cy="title">
                            <Radio value={Salutations.Herr}>{t("mr")}</Radio>
                            <Radio value={Salutations.Frau}>{t("ms")}</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={t("firstName")}
                        name="first_name"
                        rules={[{ required: true, message: t("errors:invalidData") }]}
                    >
                        <Input data-cy={"first_name"} />
                    </Form.Item>

                    <Form.Item
                        label={t("lastName")}
                        name="last_name"
                        rules={[{ required: true, message: t("errors:invalidData") }]}
                    >
                        <Input data-cy={"last_name"} />
                    </Form.Item>

                    <Form.Item
                        label={t("email")}
                        name="email"
                        rules={[{ required: true, type: "email", message: t("errors:emailInvalid") }]}
                    >
                        <Input data-cy={"email"} />
                    </Form.Item>

                    <Form.Item
                        label={t("phone")}
                        name="phone"
                        rules={[{ required: true, message: t("errors:invalidData") }]}
                    >
                        <Input data-cy={"phone"} />
                    </Form.Item>

                    <Form.Item
                        label={t("role")}
                        name="role"
                        rules={[{ required: true }]}
                        initialValue={MerchantUserRole.ADMIN}
                    >
                        <AnchoredSelect data-cy="select_bo_role">
                            {Object.keys(MerchantUserRole).map((k, i) => {
                                const enumValue = MerchantUserRole[k];
                                return (
                                    <Select.Option
                                        data-cy={`select_merchant_role_${i}`}
                                        key={enumValue}
                                        value={enumValue}
                                    >
                                        {t(`merchant_user_roles:${enumValue}`)}
                                    </Select.Option>
                                );
                            })}
                        </AnchoredSelect>
                    </Form.Item>

                    <Form.Item
                        label={t("location")}
                        name="location"
                        rules={[{ required: true, message: t("errors:invalidData") }]}
                    >
                        <Input data-cy={"location"} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
