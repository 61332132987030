export interface shopState {
    session_id?: string
    cartItems: any[]
    stock: any[]
}

export enum shopActions {
    ADD_CART_ITEM = "ADD_CART_ITEM",
    REMOVE_CART_ITEM = "REMOVE_CART_ITEM",
    CHANGE_SESSION = "CHANGE_SESSION",
}

export const shopReducer = (state, action) => {
    switch (action.type) {
    case shopActions.ADD_CART_ITEM:
        // find corrsponding item and increase its quantity.
        const newState = [...state.cartItems];

        const idx = state.cartItems.findIndex((el) => {
            return el.item === action.payload.newItem;
        });
        newState[idx].quantity++;

        return {
            ...state,
            cartItems: newState,
        };
    case shopActions.REMOVE_CART_ITEM:
        // find corrsponding item and decrease its quantity.

        const oldState = [...state.cartItems];

        const idx2 = state.cartItems.findIndex((el) => {
            return el.item === action.payload.oldItem;
        });
        oldState[idx2].quantity = oldState[idx2].quantity - 1 ;

        return {
            ...state,
            cartItems: oldState,
        };
    case shopActions.CHANGE_SESSION:
        return {
            ...state,
            session_id: action.payload.newSession,
        };
    default:
        return state;
    }
};
