export const de = {
    "de": {
        "common": {
            "contactPhoneNumber": "+41 844 823 824",
            "contactEmail": "contact@Liquitree.com",
            "messages": {
                "dataSaved": "Daten gespeichert",
                "couldNotSave": "Konnte nicht speichern",
                "errorEncountered": "Fehler aufgetreten",
                "registrationSuccessful": "Registrierung erfolgreich",
                "registrationSuccess2":"Ihre Konto wurde erstellt.",
                "passwordResetSuccessful": "Passwort zurücksetzen erfolgreich",
                "passwordChanged": "Passwort erfolgreich geändert",
                "emailSent": "E-Mail gesendet",
                "userWithEmailAlreadyExists": "Benutzer mit E-Mail existiert bereits",
                "documentDeleted": "Dokument gelöscht",
                "accessGranted": "Zugang erfolgreich gewähren."
            },
            "errors": {
                "generalError": "Etwas ist schief gelaufen",
                "requestError": "Der Vorgang kann nicht ausgeführt werden",
                "postContactPerson": "Der Vorgang kann nicht ausgeführt werden",
                "bankInfoInvalidBankName": "Bitte geben Sie einen gültigen Banknamen ein.",
                "bankInfoInvalidIBAN": "Bitte geben Sie eine gültige IBAN ein.",
                "postApplicationScore": "Der Application-Score kann nicht erstellt werden",
                "putApplicationScore": "Der Application-Score kann nicht aktualisiert werden",
                "postClientScore": "Der Kunden-Score kann nicht erstellt werden",
                "putClientScore": "Der Kunden-Score kann nicht aktualisiert werden",
                "postApplicationReview": "Der Application-Review kann nicht erstellt werden",
                "putApplicationReview": "Der Application-Review kann nicht aktualisiert werden",
                "maxNumContactPersons": "Sie können mehr als 3 Kontaktpersonen nicht hinzufügen",
                "bankInfo": "Bank Info kann nicht gespeichert werden",
                "submitApplication": "Der Antrag kann nicht abgeschickt werden",
                "getApplication": "Der Antrag kann nicht angefordert werden",
                "invalidToken": "Token ist ungültig",
                "invalidTokenDetails": "Das Token ist entweder ungültig oder abgelaufen.",
                "cantProceed": "Konto konnte nicht erstellt werden. Bitte überprüfen Sie die Daten oder loggen Sie sich ein.",
                "incorrectCredentials": "Falsche Anmeldeinformationen",
                "emailNotConfirmed": "E-Mail Adresse nicht bestätigt",
                "loginError": "Fehler beim Anmelden",
                "confirmPassword": "Passwort nicht bestätigt",
                "acceptAGB": "Bitte die AGB akzeptieren",
                "passwordsDoNotMatch": "Die Passwörter müssen übereinstimmen.",
                "emailInvalid": "Die Eingabe ist keine gültige E-Mail",
                "passwordsDontMatch": "Die Passwörter stimmen nicht überein!",
                "passwordInvalid": "Ihr Passwort muss mindestens 8 Zeichen lang sein, und mindestens 1 Buchstaben, 1 Zahl und 1 Sonderzeichen beinhalten",
                "invalidData": "Bitte gültige Daten eingeben.",
                "inviteClient": "Der Klient kann nicht eingeladen werden.",
                "clientGrantMerchantAccess": "Zugang kann nicht gewähren sein."
            },
            "formErrors": {
                "fullName": "Please enter full name!",
                "invalidPostCode": "Ungültige PLZ",
                "pleaseSelectOption": "Bitte wählen Sie eine Option",
            },
            "buttons": {
                "newLead": "Neuer Lead",
                "newClient": "Neuer Klient",
                "newNote": "Neue Notiz",
                "continue": "Weiter",
                "continue2": "Fortfathren",
                "continue2AsGuest": "Als Gast fortfahren",
                "continueNoEmail": "ohne E-Mail fortfahren",
                "continueNoEmail2": "Ohne E-Mail-Adresse fortfahren",
                "continueWithApplication": "Mit dem Antrag fortfahren",
                "save": "Speichern",
                "downloadPdf": "PDF herunterladen",
                "cancel": "Abbrechen",
                "ok": "OK",
                "login": "Anmelden",
                "login2":"Login",
                "edit": "Ändern",
                "editData": "Daten bearbeiten",
                "editLeadStatus": "Lead Status bearbeiten",
                "resendEmail": "E-Mail zurücksenden",
                "goToApplications": "zu Anträge",
                "createApplication": "Antrag erstellen",
                "createAnotherApplication": "Ein Anderes Anträg erstellen",
                "addDebitor": "+ Debitor hinzufügen",
                "yes": "Ja",
                "no": "Nein",
                "submit": "Weiter",
                "addTransaction": "Add Transaction",
                "addPerson": "Person hinzufügen",
                "myLeads": "My Leads",
                "clear": "Clear",
                "toLogin": "Zum Login",
                "toLogin2":"Weiter zum Login",
                "toLogin3":"Zurück zum Login",
                "register": "Register",
                "register2": "Konto erstellen",
                "reset": "Zurücksetzen",
                "resetPassword": "Passwort zurücksetzen",
                "resetFilter": "Filter Zurücksetzen",
                "resetSearch": "Suche Zurücksetzen",
                "downloadQuestionnaire": "Fragebogen herunterladen",
                "createOffer": "Angebot Erstellen",
                "view": "Einsehen",
                "viewOffer": "Angebot einsehen",
                "expand": "Expand",
                "collapse": "Collapse",
                "changePassword": "Passwort ändern",
                "addEmployee": "Mitarbeiter hinzufügen",
                "toHome": "Weiter zur Homepage",
                "toClientPortal": "Zum Kundenportal",
                "toMerchantPortal": "Zum Händlerportal",
                "back": "Zurück",
                "adjustData": "Daten Anpassen",
                "adjust": "Anpassen",
                "toAppointmentScheduling": "Zur Terminvereinbarung",
                "startIdNow": "Video-Legitimation starten",
                "newKSV": "Neue KSV",
                "newCRIF": "Neue CRIF",
                "refresh": "Aktualisieren",
                "readByOCR": "Per OCR Auslesen",
                "inputManually": "Manuelle BILA Auswertung anfragen",
                "requestOffer": "Finanzierungsangebot einholen",
                "perVideochat": "per Videochat*",
                "perPostident": "per Postident",
                "startIdentification": "Identifikation Starten",
                "proceedToIdentification": "Weiter zur Identifikation",
                "backToOnlineShop": "Zurück zum Onlineshop",
                "checkData": "Angaben überprüfen",
                "backToVideoIdent": "Zurück zur Videoidentifizierung",
                "allowAccess": "Freigabe anfordern",
                "sendRegistrationEmail": "Registrierungsmail versenden",
                "addMerchant": "Händler hinzufügen",
                "end": "Abschliessen",
                "newInquiry": "Neuen Antrag",
                "closeApplication": "Antrag stornieren",
                "printCustomer": "Kunden Drucken",
                "newToDo": "Neues To-Do",
                "newOffer": "Neues Angebot",
                "dsgvo": "DSGVO",
                "grantAccess": "Zugang gewähren",
                "logout": "Abmelden",
                "toggle": "Toggle",
                "contactCustomerSupport": "Kundenberater kontaktieren",
                "confirm_shipping": "Lieferung bestätigen",
                "open":"Open",
                "rejected":"Rejected",
                "complete":"Complete",
                "acquiring_offers":"Acquiring offers",
                "in_manual_check":"In manual check",
                "approved_waiting_kyc":"Approved - waiting KYC",
                "approved_waiting_shipping":"Approved - awaiting shipping",
                "approved_booked": "Approved - booked",
                "approved_waiting_documents":"Approved - awaiting docs",
                "in_cancellation":"In cancellation",
                "cancelled_by_bank":"Cancelled by bank",
                "cancelled_by_merchant":"Antrag stornieren",
                "expired":"Expired",
                "closed":"Closed",
                "rerun_scraper":"Erneut Einreichen",
            },
            "headings": {
                "processStepTwo": "Wofür möchten Sie einen Antrag stellen?",
                "processCreateAccount": "Bitte erstellen Sie ein Benutzerkonto um Ihren Antrag einzureichen",
                "processResult": "Ihr Konto wurde erfolgreich erstellt.",
                "processResultRejected": "Wir können Ihnen kein Onlineangebot machen.",
                "processResultRejectedText": "Leider können wir Ihnen kein direktes Angebot Online machen. Gerne können Sie einen Termin mit uns vereinbaren um alternative Finanzierungsmethoden zu besprechen. Gerne können wir Sie im Büro oder zu Hause besuchen, oder in einer Liquitree Bank-Filiale treffen.",
                "processResultLoggedIn": "Ihr Antrag wurde erfolgreich erstellt.",
                "processSuccess1": "Ihr Benutzerkonto wurde erfolgreich erstellt. Wir haben Ihnen eine E-Mail geschickt mit der Sie Ihre E-Mail Adresse bestätigen und Ihr Nutzerkonto aktivieren können. ",
                "processSuccess2": "Nachdem Sie Ihr Nutzerkonto aktiviert haben, können Sie sich einloggen und Ihren Antrag weiter bearbeiten.",
                "processSuccess1LoggedIn": "Als nächsten Schritt benötigen wir weitere Informationen zu Ihrem Unternehmen und Ihrem Finanzierungsvorhaben.",
                "processSuccess1LoggedIn2": "Diese Informationen können Sie bequem Online in Ihrem persönlichen Antragsbereich bereitstellen.",
                "processRejection1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                "processRejection2": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
                "debitorCheck": "Debitor Prüfung Beantragen",
                "processResultLoanSmall": "Ihre unverbindlichen Kreditkonditionen",
                "processResultLoanSmallText1": "Um Ihren Kreditantrag abzuschliessen, müssen Sie nur noch wichtigsten Unterlagen zu ihrem Unternehmen hochladen, sich mittels Video-Chat identifizieren und den Vertrag digital unterzeichnen! Diese Schritte können komplett von zu Hause aus erledigt werden und nehmen nur wenige Minuten in Anspruch.",
                "processResultLoanSmallText2": "Nach Abschluss des Antrags, werden die Unterlagen nochmals final überprüft. Ist die Prüfung erfolgreich, wird der Kredit innerhalb von 48 Stunden ausbezahlt. Falls Sie Fragen haben, können Sie uns jederzeit gerne kontaktieren.",
                "processResultAltOffer": "Wir haben alternative Finanzierungsangebote für Sie.",
                "processResultAltOfferText1": "Leider könnnen wir Ihnen kein Onlineangebot für einen Betriebsmittelkredit machen. Die Liquitree bietet jedoch eine Reihe an alternativen Fiannzierungsmethoden für Unternehmen wie Ihres an.",
                "processResultAltOfferText2": "Ähnliche Unternehmen nutzen zum Beispiel das Factoring angebot der Liquitree um Liquiditätsengpässe zu vermeiden. Wenn Sie al solchen Produkten Interesse haben, nehmen Sie mit Ihrem Kundenberater Kontakt auf.",
                "processTitleLoading": "Wir berechnen Ihre Konditionen"
            },
            "placeholders": {
                "filterByCountry": "Filter by country...",
                "filterByContactName": "Filter by contact name...",
                "filterByStatus": "Filter by status...",
                "phone": "+49 844 823 824",
                "mobileNumber": "+49 844 823 824",
                "idDocNumber": "0123456789",
                "iban": "DE 0123456789",
                "email": "example@example.com",
                "bankName": "",
                "bic": "",
            },
            "applProcess": {
                "titleStepOne": "Wilkommen bei Liquitree",
                "subTitleStepOne": "Erstellen Sie ein Konto um eine Finanzierung zu beantragen",
                "titleStepTwo": "Überprüfen und vervollständigen Sie Ihre Angaben",
                "subtitlePersonalDataStepTwo": "Persönliche Angaben",
                "subtitleAdressDataStepTwo": "Ihre Adressdaten",
                "subtitleAdressData2StepTwo": "Ihre vorherige Adresse",
                "subtitleWorkDataStepThree": "Angaben zum Ihren Beruf",
                "subtitleWorkData2StepThree": "Angaben zu Ihrem vorherigen Beruf",
                "subtitleHomeDataStepThree": "Angaben zu Ihrem Haushalt",
                "titleStepThree": "Für ein Angebot benötigen wir noch einige Angaben zu Ihrem Einkommen",
                "titleStepFour": "Überprüfen Sie Ihre Angaben",
                "infoStepFour": "Wenn Sie die obigen Daten überprüft haben stimmen werden wir Ihnen ein persönliches Finanzierungsangebot bei unseren Partnern einholen.",
                "titleStepFive": "Um den Kauf abzuschliessen müssen Sie sich noch identifizieren.",
                "info1StepFive": "Ihre bestellten Waren werden erst verschickt nachdem die Bank Ihren Vertrag erhalten hat und Sie sich an einer Poststelle identifiziert haben.",
                "info2StepFive": "Um eine schnelle Abwicklung Ihres Kaufs zu garantieren, empfehlen wir Ihnen den Videochat.",
                "info3StepFive": 'Weitere Informationen zur Videoidentifizierung finden Sie unter folgendem ',
                "titleStepLoan": "Bitte einen Betrag und Laufzeit wählen",
                "titleStepCompany": "Bitte geben Sie die Angaben zu Ihrem Unternehmen ein",
                "titleStepContact": "Geben Sie bitte Ihre persönlichen Kontaktdaten ein",
                "titleStepLoanInfo": "Wilkommen bei Liquitree",
                "subtitleStepLoanInfo": "Geben Sie hier den Finanzierungswusch des Kunden an:",
                "stepOffer": {
                    "titleLoading": "Finanzierungangebot einholen",
                    "infoLoading": "Wir fragen gerade ihre persönlichen Finanzierungsangebote bei unserem Partner ab. Dies kann bis zu einer Minute dauern.",
                    "infoLoading2":"Wir fragen Ihre persönlichen Finanzierungsangebote bei unseren Partnern ab. Dies kann bis zu 2 Minuten dauern. Bitte verlassen Sie diese Seite nicht.",
                    "titleSuccess": "Finanzierungsangebot",
                    "infoSuccess": "Um Ihren Finanzierungsantrag und den Warenkauf abzuschliessen müssen Sie sich lediglich noch identifizieren und den Kreditvertrag unterschreiben. Dies können Sie direkt Online über unseren Partner WebID machen, oder klassisch per Postident. Beachten Sie das Ihre Ware erst verschickt wird, wenn die Bank Ihren Vertrag erhält, deshalb empfehlen wir die Online Identifizierung für einen schnelleren Versand.",
                    "titleRejection": "Keine Finanzierungsangebote von unseren Partnern verfügbar.",
                    "info1Rejection": "Leider können unsere Bankpartner Ihnen kein Finanzierungsangebot machen. Ihre Bestellung ist noch nicht abgeschlossen.",
                    "info2Rejection": "Kehren Sie zum Onlineshop zurück und wählen Sie eine andere Bezahlungsmethode um den Einkauf abzuschliessen.",
                    "info1Manual":"Ihr Antrag ist bei einem unserer Bankpartner in Prüfung, welche bis zu 24 Stunden in Anspruch nehmen kann. Ihre Bestellung wurde reserviert und der Händler wurde informiert.",
                    "info2Manual":"Sobald wir Neuigkeiten zu Ihrem Antrag haben, werden wir Sie kontaktieren. Sie können den Status auch jederzeit in Ihrem Kundenbereich einsehen.",
                    "titleAddressNotFound": "Ihre Adresse konnte nicht gefunden werden.",
                    "info1AddressNotFound": "Lieder konnten wir Ihre Adresse nicht überprüfen und Ihnen somit kein Angebot machen.",
                    "info2AddressNotFound": "Bitte überprüfen Sie Ihre Angaben und versuchen Sie es noch einmal."
                },
                "stepFive": {
                    "subtitleInfo": "Ihre Persönlichen Angaben",
                    "subtitleAdress": "Ihre Adressdaten",
                    "subtitlePrevAdress": "Ihre vorherige Adresse",
                    "subtitleWork": "Ihre Beruflichen Angaben",
                    "subtitleHousehold": "Ihre Haushalt",
                },
                "stepSix": {
                    "titleVideo": "Ihr Kauf wurde erfolgreich abgeschlossen.",
                    "infoVideo": "Mit dem Button können Sie zum Onlineshop des Hänlders zurückkehren.",
                    "titlePost": "Ihr Vertrag und Postident-Coupon",
                    "info1Post": "Hier können Sie ihren Kreditvertrag und Ihrem Postident-Coupon herunterladen:",
                    "link1Post": "Vertrag und Postidentcoupon",
                    "info2Post": "Um den Kauf abzuschliessen, müssen Sie mit dem unterschriebenen Vertrag, dem Postident-Coupon und Ihrem Personalausweis zu einer Postfiliale. ",
                    "link2Post": "Nächstgelege Postfiliale suchen",
                    "info3Post": "Nachdem Sie sich identifizert haben, dauert es in der Regel 2-4 Tage bis Ihre Ware verschickt wird. ",
                    "info4Post": "Sie möchten Ihre Ware schneller erhalten? ",
                    "info5Post": "Hier geht’s zurück zum Onlineshop:",
                },
                "step": "Schritt",
                "stepCompanyName": "Firmenname",
                "stepLoanType": "Antragsart",
                "stepLoanDetails": "Kredit Details",
                "stepCompanyInfo": "Firmeninformationen",
                "stepContactInfo": "Kontaktinformationen",
                "stepRegistration": "Registrierung",
                "stepResult": "Antrag abschicken",
                "stepPersonalData": "Persönliche Angaben",
                "stepIncomeData": "Einkommensdaten",
                "stepFinancingOffer": "Finanzierungsangebot",
                "stepSummary": "Zusammenfassung",
                "stepEnd": "Abschluss",
                "acceptTerms": "Ich akzeptiere die Allgemeinen Geschäftsbedingungen und Datenschutzhinweise und bestätige die Vorvertragliche Informationen erhalten zu haben.",
            },
            "merchantApplProcess": {
                "titleStepOne": "Wilkommen bei NicerPay",
                "subtitleStepOne": "Geben Sie die E-Mail Addresse des Kunden an, um ein Angebot zu erstellen.",

                "title_EmailFound": "Freigabe durch den Kunden benötigt.",
                "subtitle_EmailFound": "Es wurde ein Nicerpay Konto für diesen Kunden gefunden. Um mit diesem Konto fortzufahren, muss der Kunde die Daten freigeben.",

                "titleFaq_EmailFound": "Wie funktioniert der Freigabeprozess?",
                "subtitleFaq_EmailFound": "Der Freigabeprozess funktioniert wie folgt:",
                "item1Faq_EmailFound": "1. Fordern des Zugriffs auf die Kundendaten mit dem obigen Button an.",
                "item2Faq_EmailFound": "2. Der Kunde erhält dann eine E-Mail mit einem Link zur Freigabe des Zugriffs.",
                "item3Faq_EmailFound": "3. Der Kunde muss den Link in der E-Mail anklicken und sich in sein Konto einloggen.",
                "item4Faq_EmailFound": "4. Er wird dann aufgefordert, Ihre Zugriffsanfrage zu genehmigen.",
                "item5Faq_EmailFound": "5. Sobald die Anfrage genehmigt wurde, können Sie die Daten des Kunden für diese Transaktion wiederverwenden.",

                "title_EmailNotFound": "Kein Kundenkonto gefunden.",
                "subtitle_EmailNotFound": "Es wurde kein Konto mit dieser E-Mail-Adresse gefunden. Um mit dem Antrag fortzufahren muss der Kunde ein Konto erstellen.",

                "titleFaq_EmailNotFound": "Wie funktioniert der Registrierungsprozess?",
                "subtitleFaq_EmailNotFound": "Der Registrierungsprozess funktioniert wie folgt:",
                "item1Faq_EmailNotFound": "1. Senden Sie über die Schaltfläche oben eine E-Mail-Einladung an den Kunden.",
                "item2Faq_EmailNotFound": "2. Der Kunde erhält dann eine E-Mail mit einem Link, den er anklicken muss. ",
                "item3Faq_EmailNotFound": "3. Sobald er den Link geöffnet hat, muss er ein Passwort für sein Konto festlegen und die AGB und Datenschutzerklärung akzeptieren. ",
                "item4Faq_EmailNotFound": "4. Sobald das Konto erstellt wurde, werden Sie automatisch in den Antragsprozess weitergeleitet, um die notwendigen Kundendaten zu erfassen. ",

                "title_NoEmail": "Ohne E-Mail fortfahren",
                "subtitle_NoEmail": "Sie können auch einen Antrag ohne E-Mail Adresse einreichen. Bitte Beachten Sie das dies nur in Ausnahmefällen gmeacht werden sollte, da so die erneute Kundenansprache erschwert wird.",

                "titleFaq_NoEmail": "Wie funktioniert ein Antrag ohne E-Mail-Adresse?",
                "subtitleFaq_NoEmail": "",
                "item1Faq_NoEmail": "Der Registrierungsprozess funktioniert wie folgt ähnlich wie auch bei Kundenanträgen mit E-Mail-Adresse. Es muss jedoch sichergestellt werden dass der Kunde die Datenschutzerklärungen, Allgemeinen Geschäftsbedingungen sowie die Vorevertraglichen Informationen zur Kenntnis und angenommen hat. ",
                "item2Faq_NoEmail": "Diese sind im nächsten Schritt in der jeweils aktuellen Fassung runterzuladen und vom Kunden unterschreiben zu lassen. ",
                "item3Faq_NoEmail": "Nachdem der Antrag gestellt wurde, muss in jedem Fall eine eingescannte, vom Kunden unterschriebene Kopie hochgeladen werden. Beachten Sie das dies auch für Fälle gilt in welchen der Kunde abgeleht wurde. ",

                "title_EmailFound_AwaitingApproval": "Warten auf Freigabe des Kunden",
                "item1_EmailFound_AwaitingApproval": "Sobald der Kunde die Freigabe erteilt hat, werden Sie automatisch in den Antragsprozess weitergeleitet. ",
                "item2_EmailFound_AwaitingApproval": "Die Zugriffsanfrage wird nach 15 Minuten auslaufen.",
                "item3_EmailFound_AwaitingApproval": "Der Kunde hat die Zugriffsanfrage nicht erhalten?",
                "link1_EmailFound_AwaitingApproval": "Senden Sie die E-Mail erneut.",

                "title_EmailNotFound_AwaitingRegistration": "Warten auf Registrierung des Kunden",
                "item1_EmailNotFound_AwaitingRegistration": "Soblad der Kunde ein Konto erstellt hat, werden Sie automatisch in den Antragsprozess weitergeleitet.",
                "item2_EmailNotFound_AwaitingRegistration": "Der Kunde hat die Registrierungsemail nicht erhalten?",
                "link1_EmailNotFound_AwaitingRegistration": "Senden Sie die E-Mail erneut.",

                "title_NoEmail_DownloadDocs": "Kundeneinwilligung benötigt",
                "subtitle_NoEmail_DownloadDocs": "Für den Antrag und die Datenverarbeitung ist die Einwillgung des Kunden in jedem Fall einzuholen. Da der Antrag ohne E-Mail gestellt wird, muss dies in Papierform erfolgen. Folgende Dokumente sind vom Kunden zu unterschreiben und hochzuladen:",
                "link1_NoEmail_DownloadDocs": "Allgemeine Geschäftsbedinugungen",
                "link2_NoEmail_DownloadDocs": "Datenschutzerklärung",
                "link3_NoEmail_DownloadDocs": "Vorvertragliche Informationen",

                "titleAccessExpired": "Ihre Zugangsanfrage ist abgelaufen",
                "subtitleAccessExpired": "Sie können nochmals eine Anfrage erstellen.",
            },
            "signatories": {
                "title": "Personen",
                "person": "Person",
                "removeSignatory": "Person entfernen",
                "addSignatory": "Weitere Person hinzufügen",
                "confirmAllSignatories": "Ich bestätige, dass jede Person, die den Vertrag unterschreibt und jede Person die über 25% des Unternehmens besitzt, hinzugefügt wurde.",
                "finalConfirmation": "Bitte stellen Sie sicher, dass genügend Personen hinzugefügt wurden, um den Kreditvertrag zu unterzeichnen.",
                "legalSignatory": "Ist diese Person zur Unterschrift berechtigt?",
                "soleSignatory": "Darf diese Person alleine für das Unternehmen unterschreiben?",
                "willSignLoanContract": "Wird diese Person den Kreditantrag unterschreiben?",
                "doesOwnMoreThan25": "Besitzt diese Person mindestens 25% des Unternehmens?",
            },
            "confirmEmail": {
                "title": "Ihre E-Mail-Adresse wurde erfolgreich bestätigt.",
                "title2":"Ihre E-Mail wurde bestätigt.",
                "body": "Bitte melden Sie sich an, um fortzufahren.",
                "body2":"Sie können sich nun einloggen und mit den Antrag fortfahren."
            },
            "docLists": {
                "heading": "Bitte laden Sie folgende Dokumente hoch:",
                "de": "1. Letzter Jahresabschluss \n 2. Aktuellen Handelsregisterauszug \n 3. Aktuelle Debitoren und Kreditorenliste mit Fälligkeitsdatum \n 4. Aktuelle BWA und Saldenliste \n 5. Kopie eines Lichtbildausweises sämtlicher Bevollmächtigten \n 6. Nachweis der wirtschaftlichen Eigentümer mit Privatadresse und Firmenanteile \n 7. Unterschriftenverzeichnis \n 8. Negativerklärung der Bank(en) \n",
                "at": "1. Letzter Jahresabschluss \n 2. Aktuelle Debitoren und Kreditorenliste im Excelformat \n 3. Aktuelle Saldenliste \n 4. Firmenbuchauszug und/oder Gewerbeanmeldung \n 5. Auszug Finanzamt \n 6. Auszug Gebietskrankenkasse \n 7. Kopie eines Lichtbildausweises / Reisepass sämtlicher Bevollmächtigten \n 8. Nachweis der wirtschaftlichen Eigentümer mit Privatadresse und Firmenanteile \n 9. Unterschriftenverzeichnis\n",
                "ch": "1. Letzter Jahresabschluss \n 2. Aktuellen Handelsregisterauszug \n 3. Aktuelle Debitoren und Kreditorenliste mit Fälligkeitsdatum \n 4. Betreibungsregisterauszug \n 5. Kopie eines Lichtbildausweises sämtlicher Bevollmächtigten \n 6. Nachweis der wirtschaftlichen Eigentümer mit Privatadresse und Firmenanteile \n"
            },
            "confirm:": "Factoring Betrag bestätigen",
            "confirmDocumentDelete": "Möchten Sie dieses Dokument löschen?",
            "modals": {
                "confirmFactoring:": "Factoring Betrag bestätigen",
                "addDebitor": "Neuer Debitor",
                "addLead": "Lead hinzufügen",
                "editLead": "Lead bearbeiten",
                "addClient": "Klient hinzufügen",
                "editClient": "Klient bearbeiten",
                "editLeadStatus": "Lead Status bearbeiten",
                "addNote": "Notiz hinzufügen",
                "addEmployee": "Mitarbeiter hinzufügen",
                "createApplForLeadConfirmationTitle:": "Antrag bestätigen",
                "createApplForLeadConfirmationText": "Möchten Sie für diesen Lead einen Antrag erstellen?",
                "confirmCloseTitle": "Prozess abbrechen?",
                "confirmCloseText": "Daten die Sie bereits eingegeben haben, gehen verloren",
                "createFinancingOffer": "Finanzierungsangebot Erstellen",
                "infoFinancingOffer": "Bitte füllen Sie die folgenden Angaben aus um ein Angebot zu erstellen:",
                "adminCreateOffer_Loading": "Ihre Anfrage wird gerade von der Bank bearbeitet. Dies kann bis zu einer Minute dauern.",
                "adminCreateOffer_Success_info1": "Die Bank kann dem Kunden folgendes Angebot machen:",
                "adminCreateOffer_Success_info2": "Das Angebot wurde erstellt und der Kunde muss sich nun identifizieren.",
                "adminCreateOffer_NotFound": "Die Adresse konnte nicht überprüft werden. Bitte überprüfen Sie die Kundendaten und versuchen Sie es nochmals.",
                "createToDo": "To-Do Erstellen",
                "adminCreateToDo_Loading": "Ihre Anfrage wird gerade bearbeitet.",
                "adminCreateToDo_Success_info1": "To-Do erfolgreich erstellt",
                "adminCreateToDo_NotFound": "To-Do kann nicht erstellt werden.",
                "addMerchant": "Händler hinzufügen",
                "editMerchant": "Händler bearbeiten",
                "merchantModal_Step1": "Händler Informationen",
                "merchantModal_Step2": "Kontaktpersonen",
                "merchantModal_Step3": "Bestätigen",
                "merchantModal_title_success": "Der Händler wurde erfolgreich angelegt.",
                "merchantModal_info_success": "Die Ausgewählten Admin Nutzer wurden eingeladen um Ihre Konto zu erstellen. Falls keine Registiereung erfolgt, kann die Einladung erneut über das Profil des Händlers verschickt werden. ",
                "merchantAccountTitle": "Nutzerkonto hinzufügen",
                "merchantAccountInfo": "Folgende Angaben werden für ein Nutzerkonto benötigt:",
            },
            "topBar": {
                "userAppl": "Ihr Kreditantrag",
                "userApplList": "Ihre Kreditanträge",
                "settings": "Kontoeinstellungen",
            },
            "invoiceListPage": {
                "sumOpen": "Offene Rechnungen:",
                "sumFinanced": "Finanzierte Rechnungen:",
                "sumInReview": "In Bearbeitung:",
                "sumNotFinanced": "Nicht Finanziert:",
                "sumDepot": "Depot:"
            },
            "userInvoiceInfoPage": {
                "debitor": "Debitor:",
                "applicationId": "Antrags ID:",
                "factoring": "Factoring:",
                "status": "Status:",
                "currency": "Währung:"
            },
            "userDebitorInfoPage": {
                "debitor": "Debitor:",
                "debitorId": "Debitor ID:",
                "invoices": "Rechnungen:",
                "saldo": "Saldo:"
            },
            "userApplPage": {
                "noContactPersons": "Sie haben keine Kontaktpersonen hinzugefügt.",
                "submitForReviewTitle": "Antrag abschicken",
                "submitForReviewText": ""
            },
            "leadNextSteps": {
                "labelStepContacted": "Der Kunde muss den Fragebogen ausfüllen. Sie können die neueste Version unten herunterladen.",
                "labelStepQuestionnaireSent": "Bitte laden Sie den unten stehenden Kundenfragebogen hoch.",
                "labelStepQuestionnaireReturned": "Bitte füllen Sie die folgenden Informationen aus, um ein Angebot zu erstellen:",
                "labelStepProposalSent": "Laden Sie unten die Vertragsvorlage für den Kunden herunter.",
                "labelStepContractSent": "Bitte laden Sie den unten unterzeichneten Kundenvertrag hoch.",
                "labelStepSignedOrWon": "Der Kunde hat den Vertrag unterschrieben. Sie können sie jetzt in eine Anwendung konvertieren und verwalten die weiteren Schritte auf der Registerkarte Anwendungsverwaltung.",
                "labelStepHasApplication": "Dieser Lead hat eine Anwendung. Sie können unten dorthin navigieren oder eine neue Anwendung starten.",
            },
            "admin": {
                "repaymentTab": {
                    "titleOne": "Rechnungszahlung eingeben",
                    "titleTwo": "Erfolgte Zahlungen",
                },
                "tabs": {
                    "companyInfo": "Firmendetails",
                    "clientInfo": "Kundendetails",
                    "contactPersons": "Kontaktpersonen",
                    "scoring": "Scoring",
                    "docs": "Dokumente",
                    "invoices": "Rechnungen",
                    "applications": "Anträge",
                    "debitors": "Debitoren",
                    "notes": "Notizen",
                    "payments": "Zahlungen",
                    "logs": "Logs",
                    "conversations": "Unterhaltungen"
                },
                "scoringTab": {
                    "hasIncomeStatementPY": "Bilanz & Erfolgsrechnung vom Vorjahr",
                    "hasIncomeStatementCurrentYear": "(Prov.) Bialanz & Erfolgsrechnung vom aktuellen Jahr",
                    "hasDebitorList": "Aktuelle offene Debitorenliste überprüft",
                    "hasCreditorList": "Aktuelle offene Kreditorenliste überprüft",
                    "hasContractDocuments": "Vertragsunterlagen überprüft",
                    "hasCompletedRiskCheck": "Risikoprüfung ist abgeschlossen und den Kreditgenehmigen"
                },
                "toEditGoToScoring": "Diese Liste können Sie im Scoring Bereich bearbeiten."
            },
            "labels": {
                "invoiceNumber": "Rechnungsnummer",
                "loanAccountNumber": "Kreditkontonummer",
                "dateOfReceipt": "Eingangsdatum",
                "bankAccount": "Bank Konto",
                "amount": "Betrag",
                "paymentType": "Art der Zahlung",
                "transactionCode": "Transaktionscode",
                "chooseBilanz": "Bilanz Auswählen"
            },
            "notFoundPage": {
                "text": "Ooops... diese Seite existiert nicht!"
            },
            "checklistItems": {
                "LF_statement_and_income_check_previous_year": "Bilanz & Erfolgsrechnung vom Vorjahr",
                "LF_statement_and_income_check_current_year": "(Prov.) Bialanz & Erfolgsrechnung vom aktuellen Jahr",
                "LF_debitor_list_checked": "Aktuelle offene Debitorenliste überprüft",
                "LF_creditor_list_checked": "Aktuelle offene Kreditorenliste überprüft",
                "LF_contract_documents_checked": "Vertragsunterlagen überprüft",
                "CLI_statement_and_income_check_previous_year": "Bilanz & Erfolgsrechnung vom Vorjahr",
                "CLI_statement_and_income_check_current_year": "(Prov.) Bialanz & Erfolgsrechnung vom aktuellen Jahr",
                "CLI_debitor_list_checked": "Aktuelle offene Debitorenliste überprüft",
                "CLI_creditor_list_checked": "Aktuelle offene Kreditorenliste überprüft",
                "CLI_contract_documents_checked": "Vertragsunterlagen überprüft",
            },
            "emptyPreview":"Bitte wählen Sie einen Antrag aus, um die Informationen anzuzeigen.",
            "applicationDetails": "Antragsdetails",
            "offerTab":"Angebote",
            "clientInfo": "Kundeninformationen",
            "applicationInfo": "Antragsinformationen",
            "born": "geb.",
            "livesThereSince": "Wohnhaft seit",
            "worksAs": "Angestellt als",
            "sinceDate": "seit dem",
            "worksAt": "bei",
            "homePropertyType": "Wohnhaft im",
            "in": "in",
            "with": "mit",
            "withCapitalized": "Mit",
            "utilities": "Nebenkosten",
            "mr": "Herr",
            "ms": "Frau",
            "noKids": "Keine Kinder",
            "kids": "Kinder",
            "noVehicles": "Keine Fahrzeuge",
            "hasVehicles": "Besitzt mindestens 1. Fahrzeug",
            "showOnlyClosed": "Abgeschlossene anzeigen",
            "showOnlyMine": "Nur meine anzeigen",
            "branches": "Filialen",
            "channel": "Kanal",
            "dragAndDrop": "Dokumente zum Hochladen hierher ziehen oder",
            "clickHere": "hier klicken",
            "clickHere2":"Klicken Sie hier",
            "searchResults": "Suchergebnisse",
            "companySearchNotFoundInfo": "Nicht gefunden? Kein Problem! Hier klicken und dann die Unternehmensdaten manuell eingeben.",
            "prevYear": "Vorjahr",
            "currentYear": "Jahr",
            "hasAccount": "Sie haben schon ein Konto? Klicken Sie hier um sich anzumelden.",
            "hasNoAccount": "Sie haben noch kein Konto? Klicken Sie hier um ein Konto zu erstellen.",
            "hasNoAccountPart1": "Sie haben noch kein Konto?",
            "hasNoAccountPart2": "Klicken Sie hier um ein Konto zu erstellen.",
            "clickHereToCreateApplication": "Klicken Sie hier um einen Antrag einzureichen.",
            "companyInfo": "Firmendetails",
            "contactPersons": "Kontaktpersonen",
            "gender": "Geschlecht",
            "placeOfBirth": "Geburtsort",
            "nationality": "Nationalität",
            "maritalStatus": "Familienstand",
            "isSignatory": "Unterschriftenberechtigt",
            "isSoleSignatory": "Alleine Unterschriftenberechtigt",
            "isBeneficialOwner": "Besitzt mehr als 25%",
            "pctShares": "Anteile an dem Unternehmen",
            "contactPersonsTitle": "Kontaktpersonen",
            "additionalInfo": "Zusätzliche Informationen",
            "leadStatus": "Lead Status",
            "currentleadStatus": "Aktuell Lead Status",
            "leadStatuses": {
                "backlog": "Verloren (0%)",
                "in_bearbeitung": "In Bearbeitung (2%)",
                "lead_contacted": "Lead kontaktiert (5%)",
                "project_identified_questionnaire_sent": "Lead identifiziert, Fragebogen gesendet (10%)",
                "questionnaire_returned": "Fragebogen zurückerhalten (25%)",
                "proposal_sent": "Angebot, Offerte gesendet (35%)",
                "contract_sent": "Vertrag gesendet, in Verhandlung (50%)",
                "contract_signed": "Vertrag unterschrieben (75%)",
                "won": "Gewonnen (100%)",
                "lost": "Verloren",
            },
            "sectionTitles": {
                "companyInfo": "Firmeninformationen",
                "status": "Status",
                "leadInfo": "Lead Informationen",
                "bankInfo": "Bank Informationen",
                "leadStatusNextSteps": "Lead Status - Next Steps",
                "manageEmployees": "Mitarbeiter Verwalten",
                "finishRegistration": "Registrierung beenden",
                "createPassword": "Passwort erstellen",
                "createPassword2":"Konto erstellen",
                "resetPassword": "Liquitree Passwort zurücksetzen",
                "resetPassword2":"Passwort zurücksetzen",
                "nextSteps": "Die nächsten Schritte",
                "applicationInReview": "Ihr Antrag ist in Prüfung",
                "legitimierung": "Legitimierung",
                "creditScoreInfo": "Bonitätsinformationen",
                "currentObligo": "Bestehende Obligo",
                "scoreCard": "Scorekarte",
                "bilanzAnalyse": "Bilanzanalyse",
                "offerCreation": "Angebot Erstellung",
                "createdOffers": "Erstellte Angebote",
                "listManagement": "Listen Verwalten",
                "objectInfo": "Objektinformationen"
            },
            "yourAccountInfo": "Ihre Kontoinformationen",
            "clientIdIssuedAt": "Ausstellungsdatum",
            "clientIdExpiresAt": "Ablaufdatum",
            "clientPrevAddressResidentSince": "Resided at Last Address Since",
            "merchantsCreatedUserAccounts": "Erstellte Nutzerkonten für diesen Händler",
            "merchantsCreatedApplications": "Erstellte Anträge durch diesen Händler",
            "merchantContactCreatedApplications": "Erstellte Anträge durch diesen Nutzer",
            "freeTextSearch": "Suchen nach Name, E-Mail, Addresse oder Antragsnummer...",
            "newReminder": "Neue Erinnerung",
            "newToDo": "Neues To-Do",
            "newApplication": "Neuer Antrag",
            "application": "Antrag",
            "interestInProduct": "Produktinteresse",
            "broker": "Makler / Kunden-Owner",
            "merchant": "Händler",
            "orderNumber": "Bestellnummer",
            "deliveryDate": "Lieferdatum",
            "lastStatusUpdate": "Letzte Statusänderung",
            "bankNotes": "Notizen der Bank",
            "howWouldYouLikeToIdentify": "Wie möchten Sie sich Identifizieren?",
            "idDocInfo": "Angaben zu Ihrem Ausweisdokument",
            "idDocType": "Dokumentenart",
            "placeOfIssue": "Ausstellungsort",
            "idDocNumber": "Ausweisnummer",
            "yourIban": "Ihre IBAN",
            "ibanInfo": "Die monatlichen Raten werden von diesem Konto eingezogen.",
            "yourMobileNumber": "Ihre Mobilfunknummer",
            "mobileNumberInfo": "Während des Identifikationsprozesses erhalten Sie von uns eine SMS TAN zur Unterschrift. Dieser Service ist für Sie kostenlos.",
            "createPasswordInfo": "Bitte geben Sie ein neues Passwort für ihr Liquitree Konto ein.",
            "createPasswordInfo2":"Um mit den Antrag fortzufahren, erstellen Sie ein Liquitree Kundenkonto.",
            "applInfo": "Antragsdaten",
            "applDate": "Antragsdatum",
            "applId": "Antragsnummer",
            "bankInfo": "Bank Informationen",
            "personTitle": "Titel",
            "data": "Daten",
            "message": "Nachricht",
            "paymentMenu": "Zahlungsmenü",
            "createPayment": "Zahlung Erfassen",
            "yes": "Ja",
            "no": "Nein",
            "view": "Einsehen",
            "username": "Benutzername",
            "newPassword": "Neues Passwort",
            "password": "Passwort",
            "passwordRepeat": "Passwort wiederholen",
            "forgotPassword": "Passwort vergessen?",
            "forgotPassword2":"Sie haben Ihr Passwort vergessen?",

            "forgotPasswordInfo": "Geben Sie Ihre E-Mail Adresse ein, und Sie erhalten eine E-Mail mit einem Link um ein neues Passwort zu erstellen.",
            "currency": "Währung",
            "months": "Monate",
            "loan": "Darlehen",
            "factoring": "Factoring",
            "expectedVolume": "Expected Volume",
            "adminHomeTitle": "Administrationsmenü",
            "userHomeTitle": "Kredite und Factoring",
            "userDebitorsTitle": "Debitoren",
            "userMyContractTitle": "Mein Vertrag",
            "userDashboardInfo": "Hier können Sie dem Händler Zugang zu Ihren Daten gewähren",
            "applications": "Anträge",
            "clients": "Kunden",
            "statistics": "Statistiken",
            "dashboard": "Dashboard",
            "merchants": "Händler",
            "merchantInfo": "Händlerinformationen",
            "userAccounts": "Nutzerkonten",
            "exampleCalculator": "Beispielrechner",
            "clientId": "Client ID",
            "hasApplication": "Has application",
            "leadId": "Lead ID",
            "uniqueId": "Unique ID",
            "settings": "Einstellungen",
            "beraterPortal": "Beraterportal",
            "myAccounts": "Meine Konten",
            "addBankAccount": "Bankkonto hinzufügen (EBICS)",
            "myInsurances": "Meine Versicherungen",
            "contracts": "Mein Vertrag",
            "debitors": "Debitoren",
            "debitorCheck": "Debitor Prüfung",
            "creditScoreInfo": "Bonitätsinformationen",
            "loans": "Anträge",
            "invoices": "Rechnungen",
            "applicationNumber": "Antrags-Nr.",
            "companyData": "Firmeninformationen",
            "companyContactInfo": "Kontaktinformationen der Firma",
            "employeeDetails": "Mitarbeiterdetails",
            "company": "Firmenname",
            "email": "E-Mail-Addresse",
            "email2": "E-Mail",
            "email3": "E-mail Addresse",
            "flags": "Flags",
            "clientName": "Vorname",
            "clientLastName":"Nachname",
            "clientTitle": "Ansprache",
            "dateOfBirth":"Geburtsdatum",
            "jobType":"Beruf",
            "jobBranch":"Branche",
            "jobTitle":"Berufsgruppe",
            "handler":"Händler",

            "title":"Anrede",
            "website": "Website",
            "created": "Erstellungsdatum",
            "updated": "Aktualisierungsdatum",
            "loanAmount": "Kreditbetrag",
            "loanDuration": "Laufzeit",
            "interestRate": "Zinsrate",
            "monthlyRate": "Monatliche Rate",
            "installements": "Zahlungen",
            "status": "Status",
            "notes": "Notizen",
            "conversations": "Unterhaltungen",
            "reason": "Grund",
            "bankContact": "Liquitree Contact",
            "logs": "Logs",
            "save": "Speichern",
            "assignee": "Bevollmächtigte(r)",
            "unassigned": "Nicht zugeordnet",
            "loanInformation": "Antragsinformationen",
            "loanPurpose": "Verwendungszweck",
            "largestDebitors": "Welches sind Ihre 3 größten Debitoren?",
            "largestDebitors2": "Grösste Debitoren",
            "legalForm": "Unternehmensform",
            "postCode": "PLZ",
            "foundationDate": "Gründungsdatum",
            "merchantType":"Händlertyp",
            "webshop":"Webshop",
            "directSales":"Direktvertrieb",
            "pointOfSale":"Point of Sale",
            "country": "Land",
            "numberOfEmployees": "Anzahl Mitarbeiter",
            "name": "Name",
            "bankName": "Bank Name",
            "iban": "IBAN",
            "bic": "BIC",
            "brokerName": "Broker Name",
            "contactName": "Kontaktname",
            "contactPerson": "Kontaktperson",
            "applicationState": "Antragsstatus",
            "submitToSignature": "Einreichen zur Unterschrift/KYC",
            "setFinalReview": "Letzte Prüfung",
            "setPaidOut": "Auszahlung",
            "rejectUser": "Nutzer Ablehnen",
            "hideApplication": "Antrag Verstecken",
            "reopenApplication": "Antrag wiederöffnen",
            "owner": "Verantwortliche(r)",
            "signatoriesLabel": "Personen",
            "docs": "Unterlagen",
            "id": "ID",
            "salutation": "Ansprache",
            "firstName": "Vorname",
            "lastName": "Nachname",
            "occupation": "Ausgeübter Beruf",
            "occupationBranche": "Ihre Branche",
            "occupationGroup": "Berufsgruppe",
            "employedSince": "Angestellt seit",
            "prev_employed_since": "Vorherig Angestellt seit",
            "employer": "Arbeitgeber",
            "prev_employer": "Vorheriger Arbeitgeber",
            "prev_employer_location":"Vorheriger Ort des Arbeitgebers",
            "employerLocation": "Ort des Arbeitgebers",
            "nettoIncome": "Nettoeinkommen (p.m.)",
            "residenceType": "Wohnart",
            "residenceMonthlyCost": "Wohnkosten pro Monat",
            "numberOfChildren": "Anzahl Kinder",
            "hasVehicle": "Besitzen Sie ein Fahrzeug oder Motorrad?",
            "surname": "Nachname",
            "addition": "Zusatz",
            "street": "Strasse",
            "streetName": "Strasse",
            "streetNumber": "Hausnummer",
            "houseNumber": "Hausnummer",
            "dateOfBirth2": "Geburtsdatum",
            "dueDate": "Due Date",
            "details": "Details",
            "address": "Adresse",
            "sendEmail": "Send email",
            "addressData": "Adressdaten",
            "bankData": "Bankverbindung",
            "addressAddition": "Adresszusatz",
            "professionData": "Berufliche Angaben",
            "householdData": "Haushaltangaben",
            "residentSince": "Wohnhaft seit",
            "number": "Nr.",
            "city": "Ort",
            "city2": "Stadt",
            "location": "Standort",
            "region": "Region",
            "language": "Sprache",
            "telNumber": "Telefonnummer",
            "phone": "Telefonnummer",
            "mobileNumber": "Mobilnummer",
            "faxNumber": "Faxnummer",
            "vatNumber": "UID-Nr.",
            "vatNumberLong": "Umsatzsteuer No.",
            "documents": "Dokumente",
            "uploadDocuments": "Dokumente Hochladen",
            "uploadedDocuments": "Hochgeladene Dokumente",
            "paymentNotifications": "Mahnung",
            "sendPaymentNotifications": "Versendete Mahnungen",
            "scoring": "Scoring",
            "review": "Review",
            "analysis": "Analyse",
            "author": "Autor",
            "applicationId": "Antrags ID",
            "applicationType": "Produkt",
            "purpose": "Verwendungszweck",
            "detailedPurpose": "Verwendungszweck für den Kredit",
            "detailedPurposeNoHint": "Genaue Verwendungszweck",
            "detailedPurposePlaceholder": "Wir brauchen ein Kredit damit wir....",
            "largestDebitorsPlaceholder": "Muster GmbH, Beispiel Firma AG, Muster Unternehmen KG",
            "companyDesc": "Unternehmenszweck",
            "hrNumber": "HR Nummer",
            "taxIdNumber": "Steuernummer (TIN bzw. SteuerIdNr.) (Optional)",
            "taxIdNumberShort": "Steuernummer",
            "founded": "Gegründet",
            "hrNumberShort": "HR Nr.",
            "idOrPassportNumber": "Ausweiss oder Pass Nummer",
            "companyAddress": "Firmenaddresse",
            "example": "Beispiel:",
            "uploadLogo": "Logo hochladen",
            "editLogo": "Logo ändnern",
            "merchantLogo":"Händler Logo",
            "logoInfo":"Hier wird Ihr Logo angezeigt",
            "yourLogo":"Ihr Logo",
            "appearance":"Appearance",
            "logoUpdated":"Logo aktualisiert",
            "wrongType":"Falscher Dateityp",
            "errorAPIRetrieve": "Fehler beim Abrufen des API-Schlüssels",
            "errorAPIGeneration": "Fehler beim Erstellen des API-Schlüssels",
            "generatedAPI": "API-Schlüssel erstellt",
            "regenerateAPI" :"API-Schlüssel neu erstellten",
            "passwordsMatchError" : "Passwörter stimmen nicht über ein.",
            "failedUpload" : "Fehler beim hochladen der Datei.",
            "notLoggedIn": " Sie sind nicht eingeloggt.",
            "noToken": "Kein gültiger Token.",
            "toDoError": "Konnte To-Do nicht erstellen.",
            "passChangeSuccess": "Password wurde erfolgreich geändert.",
            "genericError": "Es ist etwas fehlgeschlagen.",
            "toggle": "Zuklappen",
            "orderSummary": "Bestellung",
            "subtotal": "Zwischensumme",
            "delivery": "Lieferung",
            "total": "Gesamt",
            "customizeLogo":"Gestalten Sie das Erscheinungsbild des Kreditantragsformulars mit Ihrem Branding.",
            "logoExtra": "Am besten eignen sich quadratische oder querformatige Bilder mit einer Breite von mehr als 250 Pixeln.",
            "adminFeePerInvoice": "Admin Gebühr pro Rechnung",
            "maxAdvancePayment": "Maximale Bevorschussung",
            "withGlobalAssignment": "mit Globalzession",
            "selectiveFactoring": "selektives Factoring",
            "riskAssessmentCompleted": "Risikoprüfung abgeschlossen",
            "inviteAsAdmin": "Diese Person als Admin einladen",
            "isInvitedAsAdmin": "Wird als Admin eingeladen",
            "isNotInvitedAsAdmin": "Wird nicht als Admin eingeladen",
            "addAdditionalContactPerson": "Weitere Kontaktperson hinzufügen",
            "role": "Rolle",
            "bo_user_roles": {
                "role_bo_admin": "Admin",
                "role_bo_user": "User",
            },
            "merchant_user_roles": {
                "role_merchant_admin": "Admin",
                "role_merchant_user": "User",
            },
            "applicationStates": {
                "open": "Offen",
                "rejected": "Abgelehnt",
                "complete": "Abgeschlossen",
                "acquiring_offers": "Angebote einholen",
                "in_manual_check": "Manuelle Prüfung",
                "approved_waiting_kyc": "Warten auf KYC / Vertrag",
                "approved_waiting_shipping" :"Angenommen - wartet auf Lieferbestätigung",
                "approved_booked": "Angenommen - verbucht",
                "approved_waiting_documents": "Angenommen - wartet auf Dokumente",
                "in_cancellation": "In Stornierung",
                "cancelled_by_bank": "Durch Bank storniert",
                "cancelled_by_merchant": "Durch Händler storniert",
                "expired": "Abgelaufen",
                "closed": "Abgeschlossen",
                "acquiring_offers_error":"Fehler bei Angebotserstellung",
            },
            "applicatioNextSteps": {
                "open": "Der Antrag ist genehmigt. Die Bank wartet auf eine Lieferbestätigung.",
                "approved_booked": "Lieferung bestätigt"
            },
            "applicationStatuses": {
                "open": "Offen",
                "rejected": "Abgelehnt",
                "complete": "Abgeschlossen",
                "acquiring_offers": "Angebote einholen",
                "in_manual_check": "Manuelle Prüfung",
                "approved_waiting_kyc": "Warten auf KYC / Vertrag",
                "approved_waiting_shipping" :"Angenommen - wartet auf Lieferbestätigung",
                "approved_booked": "Angenommen - verbucht",
                "approved_waiting_documents": "Angenommen - wartet auf Dokumente",
                "in_cancellation": "In Stornierung",
                "cancelled_by_bank": "Durch Bank storniert",
                "cancelled_by_merchant": "Durch Händler storniert",
                "expired": "Abgelaufen",
                "closed": "Abgeschlossen",
                "acquiring_offers_error":"Fehler bei Angebotserstellung",
            },
            "inReview": "In Bearbeitung",
            "applicationTypes": {
                "loan": "Kredit",
                "factoring": "Factoring Onboarding",
                "factoring_onboarding": "Factoring Onboarding",
            },
            "reopen": "Wieder Öffnen",
            "industry_type": "Branche",
            "leadType": "Lead Type",
            "type": "Typ",
            "product": "Produkt",
            "financingTypes": {
                "loan": "Loan",
                "factoring": "Factoring",
            },
            "documentCategoryGroups": {
                "finances": "Finanzen",
                "legitimation": "Legitimation",
                "information": "Auskünfte",
                "correspondence": "Korrespondenz",
            },
            "documentCategories": {
                "annual_statement": "Jahresabschluss",
                "bwa": "BWA",
                "susa": "SuSa",
                "health_insurance_extract": "Auszug Gebietskrankenkasse",
                "finance_ministry_extract":"Auszug Finanzamt",
                "negative_bank_declaration": "Negativerklärung der Bank(en)",
                "debtor_and_creditor_list": "Debitoren und Kreditorenliste",
                "asset_documents": "Vermögensunterlagen",
                "id_copy": "Ausweiskopie",
                "other_documents": "Sonstige Dokumente",
                "commercial_registration": "Gewerbeanmeldung",
                "company_register_extract": "Firmenbuchauszug ",
                "commercial_register_extract": "Handelsregisterauszug",
                "signatory_directory": "Unterschriftenverzeichnis",
                "ubo_declaration": "Nachweis der wirt. Eigentümer",
                "passport_copy": "Reisepasskopie",
                "schufa": "SCHUFA",
                "debt_registry_extract": "Betreibungsregisterauszug",
                "bisnode": "Bisnode",
                "creditreform": "Creditreform",
                "e_mail": "E-Mail",
                "other_correspondence": "Sonstige Korrespondenz",
                "contract": "Vertrag",
                "offer": "Angebot",
                "fax": "Fax",
                "letter": "Brief",
                "terms_and_conditions": "AGB",
                "data_protection_policy": "Datenschutzhinweise",
                "pre_contractual_information": "Vorvertragliche Informationen",
                "logo": "Logo"
            },
            "company_industries": {
                "A": "Land- und Forstwirtschaft, Fischerei",
                "B": "Bergbau und Gewinnung von Steinen und Erden",
                "C": "Verarbeitendes Gewerbe",
                "D": "Energieversorgung",
                "E": "Wasserversorgung; Abwasser- und Abfallentsorgung und Beseitigung von Umweltverschmutzungen",
                "F": "Baugewerbe",
                "G": "Handel; Instandhaltung und Reparatur von Fahrzeugen",
                "H": "Verkehr und Lagerei",
                "I": "Gastgewerbe",
                "J": "Information und Kommunikation",
                "K": "Erbringung von Finanz- und Versicherungsdienstleistungen",
                "L": "Grundstücks- und Wohnungswesen",
                "M": "Erbringung von freiberuflichen, wissenschaftlichen und technischen Dienstleistungen",
                "N": "Erbringung von sonstigen wirtschaftlichen Dienstleistungen",
                "O": "Öffentliche Verwaltung, Verteidigung; Sozialversicherung",
                "P": "Erziehung und Unterricht",
                "Q": "Gesundheits- und Sozialwesen",
                "R": "Kunst, Unterhaltung und Erholung",
                "S": "Erbringung von sonstigen Dienstleistungen",
                "T": "Private Haushalte mit Hauspersonal; Herstellung von Waren und Erbringung von Dienstleistungen durch private Haushalte für den Eigenbedarf ohne ausgeprägten Schwerpunkt",
                "U": "Exterritoriale Organisationen und Körperschaften",
            },
            "currencySymbols": {
                "CHF": "CHF",
                "EUR": "€",
                "USD": "$",
            },
            "countryCodes": {
                "de": "Deutschland",
                "at": "Österreich",
                "ch": "Schweiz",
                "DE": "Deutschland",
                "AT": "Österreich",
                "CH": "Schweiz",
            },
            "languageCodes": {
                "de": "Deutsch",
                "en": "Englisch",
                "fr": "Französisch",
                "it": "Italienisch",
            },
            "maritalStatuses": {
                "married": "verheiratet",
                "single": "ledig",
                "separated": "getrennt lebend",
                "divorced": "geschieden",
                "widowed": "verwitwet",
                "cohabitation": "eheähnliche Gemeinschaft",
            },
            "residenceTypes": {
                "proprietary": "Haus-/Wohneigentum",
                "rent": "Miete",
                "parents": "wohnhaft bei Eltern",
                "no_rent": "Mietfrei"
            },
            "workBranches": {
                "banks": "Banken",
                "building_company": "Bauunternehmen",
                "mining": "Bergbau",
                "army": "Bundeswehr",
                "chemical_industry": "Chemieindustrie",
                "foreign_service": "Diplomatischer Dienst",
                "paper_industry": "Farb-, Druck-, Papierindustrie",
                "fishing": "Fischerei",
                "gastronomy": "Gastronomie",
                "wholesale_retail": "Groß und Einzelhandel",
                "handcraft": "Handwerk",
                "timber_industry": "Holzindustrie",
                "hotel_business": "Hotelgewerbe",
                "steel_industry": "Hütten und Stahlindustrie",
                "vehicle_manufacturer": "KFZ Hersteller",
                "kindergarden": "Kindergärten",
                "welfare_service": "Kirchliche und soziale Einrichtungen",
                "care_institution": "Kranken und Pflegeanstalten",
                "agriculture_forestry": "Land und Forstwirtschaft",
                "metal_industry": "Metallindustrie",
                "food_industry": "Nahrungs und Genussmittelindustrie",
                "public_service": "Öffentlicher Dienst",
                "travel_industry": "Reisewirtschaft",
                "broadcasting_institution": "Rundfunk und Fernsehanstalten",
                "sea_faring": "Seefahrt",
                "miscellaneous": "Sonstige",
                "freight_forwarding": "Speditionen",
                "petrol_stations": "Tankstellen",
                "textile_industry": "Textilindustrie",
                "press": "Verlags und Zeitungswesen",
                "insurances": "Versicherungen",
                "roller_mill": "Walzwerke",
            },
            "occupationGroups": {
                "employee": "Angestellte/r",
                "public_servant": "Angestellte/r im öffentlichen Dienst",
                "civil_servant": "Beamtin/er",
                "labourer": "Arbeiter/in",
                "homemaker": "Hausfrau/mann",
                "pensioner": "Rentner/in",
                "apprentice": "Auszubildende(r)",
                "student": "Student/in",
                "sales_representative": "Vertreter/in Außendienst",
                "sales_people": "Verkaufspersonal",
            },
            "idDocTypes": {
                "identity_card": "Personalausweis",
                "passport": "Reisepass"
            },
            "tables": {
                "applicationId": "Antrags Nr.",
                "createdAt": "Erstellt",
                "amount": "Betrag",
                "financedAmount": "Finanzierungssumme",
                "durationMonths": "Laufzeit",
                "interestRate": "Zinsrate",
                "type": "Typ",
                "status": "Status",
                "view": "Einsehen",
                "clientId": "User ID",
                "email": "E-Mail",
                "merchant": "Händler",
                "merchantId": "Händler ID",
                "merchantName": "Händlername",
                "merchantType": "Händlertyp",
                "userId": "Nutzer ID",
                "firstName": "Vorname",
                "lastName": "Nachname",
                "role": "Rolle",
                "registered": "Registered",
                "clientName": "Kundenname",
                "phone": "Telefon",

                "isSelected": "Angebot ausgewählt",
                "loanAmount": "Kreditbetrag (€)",
                "totalAmount": "Gesamtbtrag (€)",
                "duration_months": "Laufzeit (m)",
                "interest_rate": "Zinsrate (%)",
                "repaymentMonths": "Zahlweise",
                "installmentRate": "Monatliche Rate (€)",
                "bankSpecificData": "Bank Spezifische Daten",
                "payeverStatus": "Payever Status",
                "santanderStatus": "Santander Status",
                "paymentId": "Payment ID",
                "financeId": "Finance ID",
                "signingLink": "Signing Link",
                "bankMessage": "Bank Nachricht"


            },
            "tags": {
                "active": "Active",
                "notActive": "Not Active",
                "registered": "Registered",
                "not_registered": "Not Registered",
                "disabled": "Disabled",
                "deleted": "Deleted",
                "yes": "Yes",
                "no": "No",
            },
            "acceptInvitation_Client_1": "Vielen Dank, Ihre Registrierung war erfolgreich.",
            "acceptInvitation_Client_2": "Der Händler wird Sie weiter durch den Prozess begleiten. Sie können dieses Fenster schliessen.",
            "manageBOUsers": "Mitarbeiter Verwalten",
            "merchantLoginTitle": "Wilkommen im Liquitree Händlerportal",
            "merchantLoginInfo": "Loggen Sie sich ein um auf Ihre Konto zuzugreifen.",
            "financingPartner": "Finanzierungspartner",
            "financingAmount": "Finanzierungssumme",
            "numberOfInstallements": "Gewünschte Raten",
            "numberOfInstallements_2": "Anzahl der Raten",
            "installementAmount": "Höhe der Raten",
            "selectFinancingPartner": "Finanzierungspartner Auswählen",
            "currentStatus": "Aktueller Status",
            "nextSteps": "Nächste Schritte",
            "currency_sign": "€",
            "number_of_employees": "Mitarbeiteranzahl",
            "company_legal_form": "Rechtsform",
            "companyRegisterNumber": "Handelsregisternummer",
            "company_product": "Produkt / Service",
            "source_of_leads": "Leadsquelle",
            "action": "Aktion",
            "actions": "Aktionen",
            "visibleToClient": "Kunden sichtbar",
            "factoringFee30days": "Bei 30 Tagen Zahlungsziel Factoringgebühr",
            "factoringFee60days": "Bei 60 Tagen Zahlungsziel Factoringgebühr",
            "resultApplicationReview": "In Review",
            "resultApplicationReviewDetails1": "Ihr Antrag wird gerade von unseren Mitarbeitern geprüft. \nEin Liquitree Kundenberater wird sich in Kürze bei Ihnen melden. \nFalls Sie in der Zwischenzeit Fragen haben, erreichen Sie uns telefonisch unter",
            "resultApplicationReviewDetails2": "oder per E-Mail unter",
            "resultApplicationApproved": "Antrag genehmigt",
            "resultApplicationApprovedDetails1": `Ihr Antrag wurde genehmigt. Um Ihr Liquitree Konto in Zukunft nutzen zu können, loggen Sie sich bitte in unser Kundenportal ein.`,
            "resultApplicationApprovedDetails2": `Falls Sie noch keine E-Mail mit den Zugangsdaten erhalten haben, sollte diese in Kürze bei Ihnen Eintreffen.`,
            "acceptNewsletterCheckbox": "Bitte halten Sie mich per E-Mail über Liquitree Dienstleistungen, Services und Branchen-Insights auf dem Laufenden. Diese Einwilligung kann ich jederzeit widerrufen.",
            "newsletterSubscription": "Newsletter abbonniert",
            "subscribeNewsletter": "Newsletter bestellen",
            "subscribeNewsletter2": "Newsletter abbonnieren?",
            "unsubscribeNewsletter": "Newsletter abbestellen",
            "forgotPasswordPopover": "Ein Link zum Zurücksetzen des Passworts wird an die oben angegebene E-Mail-Adresse gesendet. Möchten Sie fortfahren?",
            "pleaseSelectOption": "Bitte wählen Sie eine Option",
            "lockAccount": "Konto Sperren",
            "unlockAccount": "Konto Entsperren",
            "confirmLockAccount": "Sind Sie sicher dass Sie das Konto Sperren möchten?",
            "confirmUnlockAccount": "Sind Sie sicher dass Sie das Konto Entsperren möchten?",
            "sendResetPassword": "Passwort zurücksetzen",
            "confirmSendResetPassword": "Sind Sie sicher dass Sie das Passwort zurücksetzen möchten?",
            "accountNotVerified": "Konto nicht bestätigt oder inaktive",
            "showInactiveUsers": "Inaktive / gelöschte Benutzer anzeigen",
            "cpApplicationNextSteps": "1. Überprüfen Sie die Angaben zu Ihrem Unternehmen.\n2. Vervollständigen Sie die Kontaktpersonen und fügen Sie alle zur Unterschrift berechtigten Personen hinzu.\n3. Geben Sie Ihre Bank Informationen an.\n4. Laden Sie die benötigten Dokumente hoch\n5. Schicken Sie die Antrag zur finalen Prüfung ab\n\nBei Fragen stehen wir Ihnen jederzeit zur Verfügung:",
            "factoringVolume": "Factoringvolumen",
            "cpApplicationInReview": "Ihr Antrag wird noch final überprüft und sollte innerhalb der nächsten 48 Stunden ausbezahlt werden. \n\n Vielen Dank für Ihr Vertrauen. Falls Sie weitere Fragen haben, stehen wir Ihnen gerne zur Verfügung.",
            "thousand_separator": ".",
            "decimal_separator": ",",
            "deleteAccountContactUs": "Bitte kontaktieren Sie uns, falls Sie Ihr Konto löschen möchten.",
            "userInfo": "Benutzerinformation",
            "userInfo2": "Nutzerinformation",
            "userInfo3": "Nutzer Information",
            "countries": {
                "DE": "Deutschland",
                "AT": "Österreich",
                "CH": "Schweiz",
                "AD": "Andorra",
                "AE": "Vereinigte Arabische Emirate",
                "AF": "Afghanistan",
                "AI": "Anguilla",
                "AM": "Armenien",
                "AO": "Angola",
                "AQ": "Antarktika",
                "AR": "Argentinien",
                "AU": "Australien",
                "AW": "Aruba",
                "BA": "Bangladesch",
                "BB": "Barbados",
                "BE": "Belgien",
                "BH": "Bahrein",
                "BM": "Bermuda",
                "BO": "Bolivien",
                "BR": "Brasilien",
                "BS": "Bahamas",
                "BT": "Bhutan",
                "BU": "Bulgarien",
                "BY": "Weißrussland",
                "BZ": "Belize",
                "CA": "Kanada",
                "CG": "Kongo",
                "CL": "Chile",
                "CM": "Kamerun",
                "CO": "Columbia",
                "CR": "Costa Rica",
                "CU": "Kuba",
                "CY": "Zypern",
                "DK": "Dänemark",
                "DM": "Dominica",
                "EC": "Ecuador",
                "EE": "Estland",
                "EG": "Ägypten",
                "ET": "Äthiopien",
                "FI": "Finnland",
                "FJ": "Fidschi",
                "FR": "Frankreich",
                "GB": "Vereinigtes Königreich",
                "GE": "Georgia",
                "GH": "Ghana",
                "GN": "Guinea",
                "GR": "Griechenland",
                "GY": "Guyana",
                "HK": "Hongkong",
                "HR": "Kroatien",
                "HU": "Ungarn",
                "ID": "Indonesien",
                "IE": "Irland",
                "IN": "Indien",
                "IQ": "Irak",
                "IR": "Iran",
                "IS": "Island",
                "IL": "Israel",
                "IT": "Italien",
                "JM": "Jamaika",
                "JO": "Jordanien",
                "JP": "Japan",
                "KE": "Kenia",
                "KO": "Korea",
                "KW": "Kuwait",
                "KZ": "Kasachstan",
                "LB": "Libanon",
                "LK": "Sri Lanka",
                "LT": "Litauen",
                "LU": "Luxemburg",
                "MA": "Marokko",
                "MC": "Monaco",
                "ME": "Mexiko",
                "MM": "Myanmar",
                "MN": "Mongolei",
                "MO": "Macau",
                "MU": "Mauritius",
                "MV": "Malediven",
                "MY": "Malaysia",
                "NA": "Namibia",
                "NG": "Nigeria",
                "NL": "Niederlande",
                "NO": "Norwegen",
                "NP": "Nepal",
                "NZ": "Neuseeland",
                "OM": "Oman",
                "PA": "Panama",
                "PE": "Peru",
                "PH": "Philippinen",
                "PK": "Pakistan",
                "PO": "Polen",
                "PT": "Portugal",
                "PY": "Paraguay",
                "QA": "Katar",
                "RO": "Rumänien",
                "RU": "Russland",
                "SA": "Saudi-Arabien",
                "SC": "Seychellen",
                "SE": "Schweden",
                "SG": "Singapur",
                "SK": "Slowakei",
                "SN": "Senegal",
                "SO": "Somalia",
                "SP": "Spanien",
                "TH": "Thailand",
                "TN": "Tunesien",
                "TR": "Türkei",
                "TW": "Taiwan",
                "TZ": "Tansania",
                "UA": "Ukraine",
                "UG": "Uganda",
                "US": "vereinigte Staaten von Amerika",
                "UY": "Uruguay",
                "UZ": "Usbekistan",
                "VE": "Venezuela",
                "VN": "Vietnam",
                "YE": "Jemen",
                "ZA": "Südafrika",
                "ZM": "Sambia",
                "ZW": "Zimbabwe",
            }
        },
    },
};
