import { FileTextOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Input, Layout, message, Modal, Table, Typography } from 'antd';
import { DATE_FORMAT } from 'api/apiConfig';
import { adminGetApplicationNotes, adminPostApplicationNote } from 'api/applicationService';
import { GetApplicationNotesListResponse } from 'api/types';
import styles from 'components/admin/pages/application-drilldown/ApplicationDrilldownPage.module.scss';
import { Spacer } from 'components/common/presenters/spacer/Spacer';
import moment from 'moment';
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { PaginationType } from 'types/adminPanel';
import { adminGetClientNotes, adminPostClientNote } from "../../../../../api/clientService";


const { TextArea } = Input;

export enum NotesTabType {
    Client,
    Application
}

const { Content } = Layout;
export interface NotesTabProps {
    entityId: string
    type: NotesTabType
}

export const NotesTab = (props: NotesTabProps) => {
    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = useState(false);
    const [note, setNote] = useState("");
    const [notes, setNotes] = useState([]);
    const [pagination, setPagination] = useState<PaginationType>({});
    const [loading, setLoading] = useState(false);

    const fetchNotes = async (page: number) => {
        try {
            setLoading(true);
            let result;

            switch (props.type) {
            case NotesTabType.Client:
                result = await adminGetClientNotes(props.entityId, page);
                break;
            case NotesTabType.Application:
                result = await adminGetApplicationNotes(props.entityId, page);
                
                break;
            default:
                break;
            }
            setNotes((result.data as any)?.result || []);
            setPagination((result.data as any)?.pagination);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const handleAddNote = async () => {
        try {
            switch (props.type) {
            case NotesTabType.Client:
                await adminPostClientNote(props.entityId, note);
                break;
            case NotesTabType.Application:
                await adminPostApplicationNote(props.entityId, note);
                break;
            default:
                break;
            }
            setModalOpen(false);
            setNote("");
            message.success(t('messages:dataSaved'), 2);
            fetchNotes(1);
        } catch (e) {
            console.error(e);
            message.error(t('messages:couldNotSave'), 2);
        }
    };

    const columns = [
        {
            title: t('created'),
            dataIndex: 'created_at',
            render: (_, note: GetApplicationNotesListResponse) => {
                return moment(note?.created_at).format(DATE_FORMAT.DE_HH_MM_SS);
            }
        },
        {
            title: t('author'),
            dataIndex: 'author_name',
        },
        {
            title: t('notes'),
            dataIndex: 'note_text',
        },
    ];

    useEffect(() => {
        fetchNotes(1);
    }, [props.entityId]);

    const addButton = (

        <Button
            className={styles.button}
            data-cy="button_add_note"
            onClick={() => {
                setModalOpen(true);
            }}
        >
            <PlusOutlined/>
            {t("buttons:newNote")}
        </Button>
        
    );

    const emptyNotes = () => {
        return (
            <>
                <FileTextOutlined className={styles.emptyNoteIcon} />
                <Typography.Title level={5}>Noch keine Notizen</Typography.Title>
                <p className={styles.emptyNoteText}>Klicken Sie den Button um neue Notizen zu erstellen.</p>
                {addButton}
            </>
        );
    };
    return (
        <Content className={styles.sectionRight}>
            {notes.length ? addButton : ""}

            <Modal
                title={t("modals:addNote")}
                closable={false}
                maskClosable
                visible={modalOpen}
                destroyOnClose={true}
                footer={[
                    <div key="fr" className={styles.footerRow}>
                        <Button
                            className={styles.buttonCancel}
                            key="cancel"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                        >
                            {t("buttons:cancel")}
                        </Button>
                        <Button
                            className={styles.buttonOk}
                            key="submit"
                            onClick={() => {
                                handleAddNote();
                            }}
                        >
                            {t("buttons:submit")}
                        </Button>
                    </div>,
                ]}
            >
                <h4>{t("notes")}</h4>
                <TextArea
                    maxLength={1000}
                    autoSize={{ minRows: 4, maxRows: 8 }}
                    value={note}
                    onChange={(e) => {
                        setNote(e.target.value);
                    }}
                />
                <Spacer />
            </Modal>
            <Spacer />
            <Spacer />
            <ConfigProvider renderEmpty={() => emptyNotes()}>
                <Table
                    style={{ whiteSpace: "pre" }}
                    rowKey={(record) => record.note_id}
                    columns={columns}
                    dataSource={notes}
                    loading={loading}
                    pagination={{
                        position: ["bottomCenter"],
                        current: pagination.current_page,
                        total: pagination.total_rows,
                        pageSize: pagination.items_per_page,
                        showSizeChanger:false,
                    }}
                    onChange={(e) => fetchNotes(e.current)}
                />
            </ConfigProvider>
        </Content>
    );
};
