import { Tag } from "antd";
import { formatDateForTable } from "helpers/timeHelpers";
import i18n from "i18n/i18n";
import * as React from "react";
import { Link } from "react-router-dom";
import { RouteStrings } from "routes/RouteStrings";

export const columns = [
    {
        title: i18n.t("tables:merchantId"),
        dataIndex: 'short_id',
        key: 'short_id',
    },
    {
        title: i18n.t("tables:merchantName"),
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: i18n.t("tables:merchantType"),
        dataIndex: 'kind',
        key: 'kind',
        render:(type)=>{
            return  type[0] ? <Tag color="pink">{type[0]}</Tag> : "";
        }
    },
    {
        title: i18n.t("tables:createdAt"),
        dataIndex: 'created_at',
        key: 'created_at',
        render: (_, merchant) => {
            return formatDateForTable(merchant?.created_at);
        }
    },
    {
        title: i18n.t("tables:status"),
        dataIndex: 'status',
        key: 'status',
        render: (status)=>{
            const renderItem = status === "active" ? <Tag color="green">{status[0].toUpperCase() + status.slice(1)}</Tag>: status;
            return renderItem;
        }
    },
    {
        title: '',
        dataIndex: 'view',
        key: 'view',
        render: (_, merchant) => {
            let link = RouteStrings.AdminMerchantDrilldown.replace(":merchantId", merchant?.merchant_id);

            return (
                <Link to={link}>{ i18n.t("buttons:view") }</Link>
            );
        }
    }
];
