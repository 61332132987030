import i18n from "i18n/i18n";
import moment from "moment";
import momenttz from 'moment-timezone';

(moment as any).suppressDeprecationWarnings = true;
/**
 * Formats the number based on selected UI language.
 */
export const currencyLocaleFormatter = (value: number | string, precision: number = 2): string => {
    try {
        const localeString = i18n.language;
		
        const numberValue: number = parseFloat(value as any);

        if (isNaN(numberValue)) {
            return "-";
        }
	
        return numberValue.toLocaleString(localeString, {minimumFractionDigits: precision, maximumFractionDigits: precision});
    } catch (err) {
        console.log(err);
        return null;
    }
};

export const formatDateWithSlashesToISO8601 = (slashesDate: string): string => {
    try {
        const splitDate = slashesDate.replace(/\//g, "-").split("-");

        let iso8601Date = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;

        return iso8601Date;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const formatDotSeparatedDateToISO8601 = (date: string): string => {
    try {
        const splitDate = date.split(".");

        let iso8601Date = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;

        return iso8601Date;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const formatISO8601DateToUtcTimestamp = (isoDate: string, isUtc?: boolean): string => {
    try {
        let date: any;

        if (isUtc) {
            date = moment.utc(isoDate, "YYYY-MM-DD").valueOf().toString();
        } else {
            date = moment(isoDate, "YYYY-MM-DD").valueOf().toString();
        }
		
        if (isNaN(date)) { // isNaN does parseInt, so a timestamp like "616798800000" is considered to be a number.
            date = null;
        }

        return date;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const formatDotSeparatedDateToUtcTimestamp = (isoDate: string, isUtc?: boolean): string => {
    try {
        let date: any;

        if (isUtc) {
            date = moment.utc(isoDate, "DD-MM-YYYY").valueOf().toString();
        } else {
            date = moment(isoDate, "DD-MM-YYYY").valueOf().toString();
        }

        if (isNaN(date)) { // isNaN does parseInt, so a timestamp like "616798800000" is considered to be a number.
            date = null;
        }

        return date;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const formatUtctimestampToDotSeparatedDate = (utcTimestamp: string, showAsUtc?: boolean, isDateOfBirth?: boolean): string => {
    try {
        let date: any = null;

        if (isDateOfBirth) {
            // we add an offset to date of births, to compensate for dates before a fix for the DoB UTC timestamps was introduced.
            date = moment(parseInt(utcTimestamp)).add(12, 'hours').format("DD.MM.YYYY");
        } else if (showAsUtc) {
            date = moment(parseInt(utcTimestamp)).format("DD.MM.YYYY");
        } else {
            date = moment(parseInt(utcTimestamp)).local().format("DD.MM.YYYY"); // converts to local timezone
        }

        return date;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const formatTimestampIntoZurichTime = (timestamp: number | string): string => {
    const zurichTimeFormatted = momenttz(timestamp).tz("Europe/Zurich").format("DD-MM-YYYY HH:mm:ss");

    return zurichTimeFormatted;
};

export const formatTimestampIntoZurichTimeNoHours = (timestamp: number | string): string => {
    // const zurichTimeFormatted = momenttz(timestamp).tz("Europe/Zurich").format("DD-MM-YYYY");

    // return zurichTimeFormatted;
    return null;
};

/**
 * Takes a search strings and cleans it up, so it's suitable for network requests.
 *
 * @param searchStr 
 */
export const formatSearchString = (searchStr: string): string => {
    try {
        return searchStr.trim();
    } catch (err) {
        console.error(err);
        // return an empty string, because returning null will require extra checks in the functions that invoke this one
        return "";
    }
};
