import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Row } from "antd";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./CreateEditMerchantModal.module.scss";

export interface CreateEditMerchantStepSuccessProps {
    onSubmit: () => void
}
    
export const CreateEditMerchantStepSuccess = (props: CreateEditMerchantStepSuccessProps) => {
    const {t} = useTranslation();

    return (
        <div className={styles.inputsContainerStepSuccess}>
            <h2 className={styles.processTitle}>{t("modals:merchantModal_title_success")}</h2>

            <Spacer/>

            <div className={styles.innerContainer}>
                <Row className={styles.rowCenterX} gutter={24}>
                    <CheckCircleOutlined className={styles.iconSuccess}/>
                </Row>

                <Spacer hx={3} />

                <Row className={styles.rowCenterX} gutter={24}>
                    <div>{t("modals:merchantModal_info_success")}</div>
                </Row>

                <Spacer hx={2} />
            </div>

            <Row className={styles.rowCenter}>
                <Button data-cy="button_continue"
                    type="primary"
                    onClick={props.onSubmit}>
                    {t("buttons:end")}
                </Button>    
            </Row>
        </div>
    );
};
