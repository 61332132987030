import * as React from "react";
import { useEffect, useState } from "react";
import {
    DocumentSectionActor,
    DocumentsSection,
} from "../../../../common/presenters/documents-section/DocumentsSection";
import { DocumentType } from "../../../../../types/types";
import { GetDocumentsListResponse } from "../../../../../api/types";
import {
    adminGetApplicationDocuments,
    adminGetMerchantDocuments,
} from "../../../../../api/document-service/adminOnApplicationDocuments";
import { PaginationType } from "types/adminPanel";

export interface DocumentsTabProps {
    type?: "application" | "merchant"
    applicationId?: string
    merchantId?: string
}

export const DocumentsTab = (props: DocumentsTabProps) => {
    const [docs, setDocs] = useState([] as GetDocumentsListResponse[]);
    const [pagination, setPagination] = useState<PaginationType>({});
    const fetchDocuments = async (page: number) => {
        try {
            const cb =
                props.type === "application"
                    ? () => {
                        return adminGetApplicationDocuments(props.applicationId,page);
                    }
                    : () => {
                        return adminGetMerchantDocuments(props.merchantId,page);
                    };

            const result = await cb();
            
            setDocs((result.data as any)?.result);
            setPagination((result.data as any).pagination);


        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchDocuments(1);
     
    }, [props.applicationId || props.merchantId]);

    return (
        <DocumentsSection
            actor={DocumentSectionActor.Admin}
            entityType={props.type === "merchant" ? DocumentType.Merchant : DocumentType.Application}
            entityId={props.applicationId ? props.applicationId : props.merchantId}
            documents={docs}
            onDataChanged={(pageNumber: number) => fetchDocuments(pageNumber)}
            pagination={{
                current: pagination?.current_page,
                total: pagination?.total_rows,
                pageSize: pagination?.items_per_page,
                showSizeChanger:false,
            }}
         
        />
    );
};
