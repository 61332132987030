import * as React from "react";
import styles from './DocumentCategorySelector.module.scss';
import {v4 as uuidv4} from 'uuid';
import {Cascader} from "antd";
import {DocumentCategory} from "../../../../types/types";
import {useTranslation} from "react-i18next";

export interface DocumentCategorySelectorProps {
    category?: DocumentCategory
    onChange: (category: DocumentCategory) => void
}

export const DocumentCategorySelector = (props: DocumentCategorySelectorProps) => {
    const {t} = useTranslation();

    const options = [
        {
            label: t(`documentCategories:${DocumentCategory.Other}`),
            value: DocumentCategory.Other,
        },
        {
            label: t('documentCategoryGroups:finances'),
            value: 'finances',
            children: [
                {
                    label: t(`documentCategories:${DocumentCategory.AnnualStatement}`),
                    value: DocumentCategory.AnnualStatement
                },
                {label: t(`documentCategories:${DocumentCategory.Bwa}`), value: DocumentCategory.Bwa},
                {label: t(`documentCategories:${DocumentCategory.Susa}`), value: DocumentCategory.Susa},
                {
                    label: t(`documentCategories:${DocumentCategory.HealthInsuranceExtract}`),
                    value: DocumentCategory.HealthInsuranceExtract
                },
                {
                    label: t(`documentCategories:${DocumentCategory.FinanceMinistryExtract}`),
                    value: DocumentCategory.FinanceMinistryExtract
                },
                {
                    label: t(`documentCategories:${DocumentCategory.NegativeBankDeclaration}`),
                    value: DocumentCategory.NegativeBankDeclaration
                },
                {
                    label: t(`documentCategories:${DocumentCategory.DebtorAndCreditorList}`),
                    value: DocumentCategory.DebtorAndCreditorList
                },
                {
                    label: t(`documentCategories:${DocumentCategory.AssetDocuments}`),
                    value: DocumentCategory.AssetDocuments
                },
            ],
        },
        {
            label: t('documentCategoryGroups:legitimation'),
            value: 'legitimation',
            children: [
                {label: t(`documentCategories:${DocumentCategory.IdCopy}`), value: DocumentCategory.IdCopy},
                {label: t(`documentCategories:${DocumentCategory.Other}`), value: DocumentCategory.Other},
                {
                    label: t(`documentCategories:${DocumentCategory.CommercialRegistration}`),
                    value: DocumentCategory.CommercialRegistration
                },
                {
                    label: t(`documentCategories:${DocumentCategory.CompanyRegisterExtract}`),
                    value: DocumentCategory.CompanyRegisterExtract
                },
                {
                    label: t(`documentCategories:${DocumentCategory.CommercialRegisterExtract}`),
                    value: DocumentCategory.CommercialRegisterExtract
                },
                {
                    label: t(`documentCategories:${DocumentCategory.SignatoryDirectory}`),
                    value: DocumentCategory.SignatoryDirectory
                },
                {
                    label: t(`documentCategories:${DocumentCategory.UboDeclaration}`),
                    value: DocumentCategory.UboDeclaration
                },
                {label: t(`documentCategories:${DocumentCategory.PassportCopy}`), value: DocumentCategory.PassportCopy},
            ],
        },
        {
            label: t('documentCategoryGroups:information'),
            value: 'information',
            children: [
                {label: t(`documentCategories:${DocumentCategory.Schufa}`), value: DocumentCategory.Schufa},
                {label: t(`documentCategories:${DocumentCategory.Creditreform}`), value: DocumentCategory.Creditreform},
                {label: t(`documentCategories:${DocumentCategory.Bisnode}`), value: DocumentCategory.Bisnode},
                {
                    label: t(`documentCategories:${DocumentCategory.DebtRegistryExtract}`),
                    value: DocumentCategory.DebtRegistryExtract
                },
            ],
        },
        {
            label: t('documentCategoryGroups:correspondence'),
            value: 'correspondence',
            children: [
                {label: t(`documentCategories:${DocumentCategory.Email}`), value: DocumentCategory.Email},
                {label: t(`documentCategories:${DocumentCategory.Letter}`), value: DocumentCategory.Letter},
                {label: t(`documentCategories:${DocumentCategory.Fax}`), value: DocumentCategory.Fax},
                {label: t(`documentCategories:${DocumentCategory.Offer}`), value: DocumentCategory.Offer},
                {label: t(`documentCategories:${DocumentCategory.Contract}`), value: DocumentCategory.Contract},
                {
                    label: t(`documentCategories:${DocumentCategory.OtherCorrespondence}`),
                    value: DocumentCategory.OtherCorrespondence
                },
            ],
        },
        {
            label: t(`documentCategories:${DocumentCategory.TermsAndConditions}`),
            value: DocumentCategory.TermsAndConditions,
        },
        {
            label: t(`documentCategories:${DocumentCategory.DataProtectionPolicy}`),
            value: DocumentCategory.DataProtectionPolicy,
        },
        {
            label: t(`documentCategories:${DocumentCategory.PreContractualInformation}`),
            value: DocumentCategory.PreContractualInformation,
        },
    ];

    // Display only the last item in the tree.
    const displayRender = (label) => {
        return label[label.length - 1];
    };

    const dropdownRender = (menus) => {
        return (
            <div className={styles.cascaderMenu}>
                {menus}
            </div>
        );
    };

    const getDefaultValue = () => {
        switch (props.category) {
        case DocumentCategory.Other:
            return [t(`documentCategories:${DocumentCategory.Other}`)];
        case DocumentCategory.AnnualStatement:
            return ["finances", t(`documentCategories:${DocumentCategory.AnnualStatement}`)];
        case DocumentCategory.Bwa:
            return ["finances", t(`documentCategories:${DocumentCategory.Bwa}`)];
        case DocumentCategory.Susa:
            return ["finances", t(`documentCategories:${DocumentCategory.Susa}`)];
        case DocumentCategory.HealthInsuranceExtract:
            return ["finances", t(`documentCategories:${DocumentCategory.HealthInsuranceExtract}`)];
        case DocumentCategory.FinanceMinistryExtract:
            return ["finances", t(`documentCategories:${DocumentCategory.FinanceMinistryExtract}`)];
        case DocumentCategory.NegativeBankDeclaration:
            return ["finances", t(`documentCategories:${DocumentCategory.NegativeBankDeclaration}`)];
        case DocumentCategory.DebtorAndCreditorList:
            return ["finances", t(`documentCategories:${DocumentCategory.DebtorAndCreditorList}`)];
        case DocumentCategory.AssetDocuments:
            return ["finances", t(`documentCategories:${DocumentCategory.AssetDocuments}`)];
        case DocumentCategory.IdCopy:
            return ["legitimation", t(`documentCategories:${DocumentCategory.IdCopy}`)];
        case DocumentCategory.CommercialRegistration:
            return ["legitimation", t(`documentCategories:${DocumentCategory.CommercialRegistration}`)];
        case DocumentCategory.CompanyRegisterExtract:
            return ["legitimation", t(`documentCategories:${DocumentCategory.CompanyRegisterExtract}`)];
        case DocumentCategory.CommercialRegisterExtract:
            return ["legitimation", t(`documentCategories:${DocumentCategory.CommercialRegisterExtract}`)];
        case DocumentCategory.SignatoryDirectory:
            return ["legitimation", t(`documentCategories:${DocumentCategory.SignatoryDirectory}`)];
        case DocumentCategory.UboDeclaration:
            return ["legitimation", t(`documentCategories:${DocumentCategory.UboDeclaration}`)];
        case DocumentCategory.PassportCopy:
            return ["legitimation", t(`documentCategories:${DocumentCategory.PassportCopy}`)];
        case DocumentCategory.Schufa:
            return ["information", t(`documentCategories:${DocumentCategory.Schufa}`)];
        case DocumentCategory.DebtRegistryExtract:
            return ["information", t(`documentCategories:${DocumentCategory.DebtRegistryExtract}`)];
        case DocumentCategory.Bisnode:
            return ["information", t(`documentCategories:${DocumentCategory.Bisnode}`)];
        case DocumentCategory.Creditreform:
            return ["information", t(`documentCategories:${DocumentCategory.Creditreform}`)];
        case DocumentCategory.Email:
            return ["correspondence", t(`documentCategories:${DocumentCategory.Email}`)];
        case DocumentCategory.Letter:
            return ["correspondence", t(`documentCategories:${DocumentCategory.Letter}`)];
        case DocumentCategory.Fax:
            return ["correspondence", t(`documentCategories:${DocumentCategory.Fax}`)];
        case DocumentCategory.Offer:
            return ["correspondence", t(`documentCategories:${DocumentCategory.Offer}`)];
        case DocumentCategory.Contract:
            return ["correspondence", t(`documentCategories:${DocumentCategory.Contract}`)];
        case DocumentCategory.OtherCorrespondence:
            return ["correspondence", t(`documentCategories:${DocumentCategory.OtherCorrespondence}`)];

        case DocumentCategory.TermsAndConditions:
            return [t(`documentCategories:${DocumentCategory.TermsAndConditions}`)];
        case DocumentCategory.DataProtectionPolicy:
            return [t(`documentCategories:${DocumentCategory.DataProtectionPolicy}`)];
        case DocumentCategory.PreContractualInformation:
            return [t(`documentCategories:${DocumentCategory.PreContractualInformation}`)];
        }
        
    };

    const containerId = uuidv4();

    return (
        <div style={{position: "relative"}} id={containerId}>
            <Cascader getPopupContainer={() => document.getElementById(containerId)}
                defaultValue={getDefaultValue()}
                options={options}
                displayRender={displayRender}
                dropdownRender={dropdownRender}
                onChange={(e) => {
                    const docCategory = e[e.length - 1];
                    props.onChange(docCategory as DocumentCategory);
                }}
            />
        </div>
    );
};
