import { Tag } from "antd";
import { formatDateForTable } from "helpers/timeHelpers";
import i18n from "i18n/i18n";
import * as React from "react";
import { Link } from "react-router-dom";
import { RouteStrings } from "routes/RouteStrings";

export const columns = [
    {
        title: i18n.t("tables:merchantId"),
        dataIndex: 'short_id',
        key: 'short_id',
    },
    {
        title: i18n.t("tables:firstName"),
        dataIndex: 'first_name',
        key: 'first_name',
    },
    {
        title: i18n.t("tables:lastName"),
        dataIndex: 'last_name',
        key: 'last_name',
    },
    {
        title: i18n.t("tables:email"),
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: i18n.t("tables:createdAt"),
        dataIndex: 'created_at',
        key: 'created_at',
        render: (_, merchant) => {
            return formatDateForTable(merchant?.created_at);
        }
    },
    {
        title: i18n.t("tables:registered"),
        dataIndex: 'is_registered',
        key: 'is_registered',
        render: (_, muser) => {
            const activeTag = <Tag color="success">{i18n.t("tags:yes")}</Tag>;
            const notRegisteredTag = <Tag color="error">{i18n.t("tags:no")}</Tag>;

            const renderTags = (): JSX.Element => {
                if (muser?.is_registered) {
                    return (
                        <React.Fragment>
                            { activeTag } 
                        </React.Fragment>
                    );
                }

                if (!muser?.is_registered) {
                    return (
                        <React.Fragment>
                            { notRegisteredTag } 
                        </React.Fragment>
                    );
                }
                return null;
            };

            return renderTags();
        }
    },
    {
        title: i18n.t("tables:status"),
        dataIndex: 'status',
        key: 'status',
        render: (_, muser) => {
            const activeTag = <Tag color="success">{i18n.t("tags:active")}</Tag>;
            const notActiveTag = <Tag color="success">{i18n.t("tags:notActive")}</Tag>;
            const deletedTag = <Tag color="error">{i18n.t("tags:deleted")}</Tag>;

            const renderTags = (): JSX.Element => {
                if (muser?.is_deleted) {
                    return (
                        <React.Fragment>
                            { deletedTag } 
                        </React.Fragment>
                    );
                }

                if (muser?.is_enabled) {
                    return (
                        <React.Fragment>
                            { activeTag } 
                        </React.Fragment>
                    );
                }
                if (!muser?.is_enabled) {
                    return (
                        <React.Fragment>
                            { notActiveTag } 
                        </React.Fragment>
                    );
                }
              
                return null;
            };

            return renderTags();
        }
    },
    {
        title: '',
        dataIndex: 'view',
        key: 'view',
        render: (_, merchant) => {
            let link = RouteStrings.AdminMerchantContactDrilldown.replace(":merchantUserId", merchant?.entity_id);

            return (
                <Link to={link}>{ i18n.t("buttons:view") }</Link>
            );
        }
    }
];
