import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Form, Input, message, Row, Spin } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { merchantGetClient, merchantInviteClient, merchantRequestAccess, merchantSearchClientByEmail } from 'api/merchant';
import styles from 'components/common/pages/application-flow/ApplicationFlow.module.scss';
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { CustomPageHeader } from "components/common/presenters/custom-page-header/CustomPageHeader";
import { Spacer } from 'components/common/presenters/spacer/Spacer';
import { checkAuthMerchant } from 'helpers/authHelpers';
import * as React from "react";
import { useTranslation } from 'react-i18next';
import { RouteStrings } from 'routes/RouteStrings';
import { getDummyPdfUrl } from 'storage/genericHelpers';
import { LocalApplicationFlowDataManager } from 'storage/LocalApplicationFlowDataManager';
import { MerchantStepOneStates, SelectedMerchantApplicationFlow } from 'types/applicationProcess';
import SignatureCanvas from 'react-signature-canvas';
import { MERCHANT_FLOW_POLLING_INTERVA_MS } from 'services/api/Globals';
import { AppContext } from 'storage/context/appContext';
import { pageLoad } from 'helpers/pageLoad';
import hashHistory from 'helpers/hashHistory';
import history from 'helpers/history';
import { setLastSuccessPage } from 'storage/actions/appActions';

export const MerchantApplicationStepOnePage = () => {
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const [checkingLoggedIn, setCheckingLoggedIn] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [generalError, setGeneralError] = React.useState("");
    const [email, setEmail] = React.useState<string>("");
    const [clientID, setClientID] = React.useState<string>(undefined);

    let pollingInterval;

    const [state, setState] = React.useState<MerchantStepOneStates>(MerchantStepOneStates.Initial);

    let sigCanvas;

    const [,dispatch] = React.useContext(AppContext);

    React.useEffect(() => {
        pageLoad(dispatch,RouteStrings.ApplicationFlowStepOne);
    }, []);

    // on finish is fired after succesful validation of ALL form fields
    const handleOnFinish = async (values: any) => {
        if (loading) {
            return;
        }

        setLoading(true);
        setGeneralError("");

        
        try {
            const req = await merchantSearchClientByEmail(encodeURIComponent(values?.email));            

            if (req?.data?.client_id) {
                setEmail(values.email);
                setClientID(req.data.client_id);
            
                const applDataManager = new LocalApplicationFlowDataManager();
                const appl = applDataManager.get();
                appl.email = values.email;
                applDataManager.update(appl);
    
                setState(MerchantStepOneStates.Email_Found);
                
            } else {
                setEmail(values.email);
                
                const applDataManager = new LocalApplicationFlowDataManager();
                const appl = applDataManager.get();
                appl.email = values.email;
                applDataManager.update(appl);
                
                setState(MerchantStepOneStates.Email_Not_Found);
            }

            setLoading(false);

        } catch (error) {
            console.error(error);
            setLoading(false);

            setGeneralError(t("errors:loginError"));
        }
    };

    const setSelectedFlow = (flow: SelectedMerchantApplicationFlow) => {
        const applDataManager = new LocalApplicationFlowDataManager();
        const appl = applDataManager.get();
        appl.selected_merchant_flow = flow;
        applDataManager.update(appl);
    };

    const handleContinueEmailFound = async () => {
        try {
            await merchantRequestAccess(clientID);
            
            setState(MerchantStepOneStates.Email_Found_Awaiting_Approval);
    
            setSelectedFlow(SelectedMerchantApplicationFlow.Client_Exists);
        } catch (err) {
            message.error(t("errors:inviteClient"));

            console.error(err);
        }
    };

    const resendRequestAccessEmail = async () => {
        try {
            await merchantRequestAccess(clientID);
        } catch (err) {
            message.error(t("errors:inviteClient"));
            console.error(err);
        }
    };


    const handleContinueEmailNotFound = async () => {
        try {
            await merchantInviteClient(email);
            setState(MerchantStepOneStates.Email_Not_Found_Awaiting_Registration);
    
            setSelectedFlow(SelectedMerchantApplicationFlow.New_Client);
        } catch (err) {
            message.error(t("errors:inviteClient"));

            console.error(err);
        }
    };

    const resendInviteEmail = async () => {
        try {
            await merchantInviteClient(email);
        } catch (err) {
            message.error(t("errors:inviteClient"));
            console.error(err);
        }
    };

    const handleContinueNoEmailToDocsList = async () => {
        setState(MerchantStepOneStates.No_Email_Download_Docs);

        setSelectedFlow(SelectedMerchantApplicationFlow.No_Email);
    };

    const handleContinueNoEmailFromDocsList = async () => {
        try {
            const applDataManager = new LocalApplicationFlowDataManager();
            const appl = applDataManager.get();
    
            const signature = sigCanvas.toDataURL("image/jpeg");
    
            appl.signature_jpg_data_uri = signature;
            applDataManager.update(appl);

            dispatch(setLastSuccessPage(RouteStrings.ApplicationFlowStepOne));

            hashHistory.push(RouteStrings.ApplicationFlowStepLoanInfo);
        } catch (err) {
            console.error(err);
            message.error(t("errors:generalError"));
        }
    };

    React.useEffect(() => {
        setCheckingLoggedIn(true);

        checkAuthMerchant(setCheckingLoggedIn, null, () => {
            history.push(RouteStrings.Login);
        });
    }, []);

    // Persist the client_id, so it can be used in the merchant's request to create an application
    React.useEffect(() => {
        const applDataManager = new LocalApplicationFlowDataManager();
        const appl = applDataManager.get();
        appl.client_id = clientID;
        applDataManager.update(appl);
    }, [clientID]);

    React.useEffect(() => {
        if (state === MerchantStepOneStates.Email_Not_Found_Awaiting_Registration) {
            if (pollingInterval != null) {
                return;
            }

            // Poll `GET /merchant/clients/search` until a `client_id` is returned
            pollingInterval = setInterval(async () => {
                try {
                    const req = await merchantSearchClientByEmail(encodeURIComponent(email));            
    
                    if (req?.data?.client_id) {
                        // - Then `GET /merchant/clients/:id` to get `is_email_confirmed` and continue if it's true
                        const clientReq = await merchantGetClient(req.data.client_id);
                        if (clientReq.is_email_confirmed) {
                            clearInterval(pollingInterval);

                            dispatch(setLastSuccessPage(RouteStrings.ApplicationFlowStepOne));
                            
                            hashHistory.push(RouteStrings.ApplicationFlowStepLoanInfo);
                        }
                    }
                } catch (err) {
                    console.error(err);

                    clearInterval(pollingInterval);
                }
            }, MERCHANT_FLOW_POLLING_INTERVA_MS);
        } else if (state === MerchantStepOneStates.Email_Found_Awaiting_Approval) {
            if (pollingInterval != null) {
                return;
            }
            
            // - Poll `GET /merchant/clients/:id` , you get a 403 forbidden until access is not granted.
            // It returns client data, if access is granted
            pollingInterval = setInterval(async () => {
                try {
                    const clientReq = await merchantGetClient(clientID);
    
                    if (clientReq?.client_id) {
                        clearInterval(pollingInterval);

                        dispatch(setLastSuccessPage(RouteStrings.ApplicationFlowStepOne));

                        hashHistory.push(RouteStrings.ApplicationFlowStepLoanInfo);
                    }
                } catch (error) {
                    // if access request has expired
                    if ((error as any).status === 401) {
                        setState(MerchantStepOneStates.Access_Request_Expired);
                    }
                        
                }
            }, MERCHANT_FLOW_POLLING_INTERVA_MS);
        }
    }, [state]);

    const renderEmailFound = (): JSX.Element => {
        return (
            <React.Fragment>
                <h2 className={styles.processTitle}>{t("merchantApplProcess:title_EmailFound")}</h2>
                <h4 className={styles.processSubTitle}>{t("merchantApplProcess:subtitle_EmailFound")}</h4>

                <div className={styles.innerContainer}>
                    <div className={styles.textCenter}>
                        { t("email") }: &nbsp;&nbsp;&nbsp; { email } 
                    </div>
                    
                    <Spacer hx={2}/>
                    
                    <Row>
                        <Col span={10}>
                            <Button type="primary"
                                loading={loading}
                                onClick={handleContinueEmailFound}>
                                {t("buttons:allowAccess")}
                            </Button>
                        </Col>
                        <Col span={4}>
                        </Col>
                        <Col span={10}>
                            <Button className={styles.buttonWhite}
                                type="default"
                                loading={loading}
                                onClick={() => {
                                    setState(MerchantStepOneStates.Initial);
                                }}>
                                {t("buttons:back")}
                            </Button>
                        </Col>
                    </Row>
                    <Spacer hx={2}/>

                    <Collapse>
                        <Collapse.Panel header={t("merchantApplProcess:titleFaq_EmailFound")} key="email_found">
                            <p>{t("merchantApplProcess:subtitleFaq_EmailFound")}</p>
                            <p>{t("merchantApplProcess:item1Faq_EmailFound")}</p>
                            <p>{t("merchantApplProcess:item2Faq_EmailFound")}</p>
                            <p>{t("merchantApplProcess:item3Faq_EmailFound")}</p>
                            <p>{t("merchantApplProcess:item4Faq_EmailFound")}</p>
                            <p>{t("merchantApplProcess:item5Faq_EmailFound")}</p>
                        </Collapse.Panel>
                    </Collapse>
                    <Spacer/>
                </div>
            </React.Fragment>
        );
    };

    const renderEmailFoundAwaitingApproval = (): JSX.Element => {
        return (
            <React.Fragment>
                <h2 className={styles.processTitle}>{t("merchantApplProcess:title_EmailFound_AwaitingApproval")}</h2>

                <div className={styles.innerContainer}>
                    <div className={styles.textCenter}>
                        { t("merchantApplProcess:item1_EmailFound_AwaitingApproval") }
                    </div>

                    <Spacer hx={2}/>

                    <Row className={styles.rowCenterX}>
                        <Spin size={"large"} delay={200}/>
                    </Row>
                    
                    <Spacer hx={2}/>

                    <div className={styles.textCenter}>
                        { t("merchantApplProcess:item2_EmailFound_AwaitingApproval") }
                    </div>

                    <Spacer hx={2}/>

                    <div className={styles.textCenter}>
                        { t("merchantApplProcess:item3_EmailFound_AwaitingApproval") }
                    </div>
                    <a className={styles.linkTextCenter} onClick={resendRequestAccessEmail}>
                        { t("merchantApplProcess:link1_EmailFound_AwaitingApproval") }
                    </a>
                    
                    <Spacer/>
                </div>
            </React.Fragment>
        );
    };

    const renderEmailNotFound = (): JSX.Element => {
        return (
            <React.Fragment>
                <h2 className={styles.processTitle}>{t("merchantApplProcess:title_EmailNotFound")}</h2>
                <h4 className={styles.processSubTitle}>{t("merchantApplProcess:subtitle_EmailNotFound")}</h4>

                <div className={styles.innerContainer}>
                    <div className={styles.textCenter}>
                        { t("email") }: &nbsp;&nbsp;&nbsp; { email } 
                    </div>
                    
                    <Spacer hx={2}/>
                    
                    <Row>
                        <Col span={10}>
                            <Button type="primary"
                                loading={loading}
                                onClick={handleContinueEmailNotFound}>
                                {t("buttons:sendRegistrationEmail")}
                            </Button>
                        </Col>
                        <Col span={4}>
                        </Col>
                        <Col span={10}>
                            <Button className={styles.buttonWhite}
                                type="default"
                                loading={loading} 
                                onClick={() => {
                                    setState(MerchantStepOneStates.Initial);
                                }}>
                                {t("buttons:back")}
                            </Button>
                        </Col>
                    </Row>
                    <Spacer hx={2}/>

                    <Collapse>
                        <Collapse.Panel header={t("merchantApplProcess:titleFaq_EmailNotFound")} key="email_found">
                            <p>{t("merchantApplProcess:subtitleFaq_EmailNotFound")}</p>
                            <p>{t("merchantApplProcess:item1Faq_EmailNotFound")}</p>
                            <p>{t("merchantApplProcess:item2Faq_EmailNotFound")}</p>
                            <p>{t("merchantApplProcess:item3Faq_EmailNotFound")}</p>
                            <p>{t("merchantApplProcess:item4Faq_EmailNotFound")}</p>
                        </Collapse.Panel>
                    </Collapse>
                    <Spacer/>
                </div>
            </React.Fragment>
        );
    };

    const renderEmailNotFoundAwaitingRegistration = (): JSX.Element => {
        return (
            <React.Fragment>
                <h2 className={styles.processTitle}>{t("merchantApplProcess:title_EmailNotFound_AwaitingRegistration")}</h2>

                <div className={styles.innerContainer}>
                    <div className={styles.textCenter}>
                        { t("merchantApplProcess:item1_EmailNotFound_AwaitingRegistration") }
                    </div>

                    <Spacer hx={2}/>

                    <Row className={styles.rowCenterX}>
                        <Spin size={"large"} delay={200}/>
                    </Row>
                    
                    <Spacer hx={2}/>

                    <div className={styles.textCenter}>
                        { t("merchantApplProcess:item2_EmailNotFound_AwaitingRegistration") }
                    </div>
                    <a className={styles.linkTextCenter} onClick={resendInviteEmail}>
                        { t("merchantApplProcess:link1_EmailNotFound_AwaitingRegistration") }
                    </a>
                    
                    <Spacer/>
                </div>
            </React.Fragment>
        );
    };

    const renderNoRegistration = (): JSX.Element => {
        return (
            <React.Fragment>
                <h2 className={styles.processTitle}>{t("merchantApplProcess:title_NoEmail")}</h2>
                <h4 className={styles.processSubTitle}>{t("merchantApplProcess:subtitle_NoEmail")}</h4>

                <div className={styles.innerContainer}>
                    <Spacer />
                    <Row>
                        <Col span={10}>
                            <Button type="primary"
                                loading={loading}
                                onClick={handleContinueNoEmailToDocsList}>
                                {t("buttons:continueNoEmail2")}
                            </Button>
                        </Col>
                        <Col span={4}>
                        </Col>
                        <Col span={10}>
                            <Button className={styles.buttonWhite}
                                type="default"
                                loading={loading}
                                onClick={() => {
                                    setState(MerchantStepOneStates.Initial); 
                                }}
                            >
                                {t("buttons:back")}
                            </Button>
                        </Col>
                    </Row>
                    <Spacer hx={2}/>

                    <Collapse>
                        <Collapse.Panel header={t("merchantApplProcess:titleFaq_NoEmail")} key="email_found">
                            <p>{t("merchantApplProcess:item1Faq_NoEmail")}</p>
                            <p>{t("merchantApplProcess:item2Faq_NoEmail")}</p>
                            <p>{t("merchantApplProcess:item3Faq_NoEmail")}</p>
                        </Collapse.Panel>
                    </Collapse>
                    <Spacer/>
                </div>
            </React.Fragment>
        );
    };

    const renderNoEmailDownloadDocs = (): JSX.Element => {
        return (
            <React.Fragment>
                <h2 className={styles.processTitle}>{t("merchantApplProcess:title_NoEmail_DownloadDocs")}</h2>
                <h4 className={styles.processSubTitle}>{t("merchantApplProcess:subtitle_NoEmail_DownloadDocs")}</h4>

                <div className={styles.innerContainerXL}>
                    <Spacer/>

                    <a className={styles.linkTextCenter}>
                        { t("merchantApplProcess:link1_NoEmail_DownloadDocs") }
                    </a>
                    <a className={styles.linkTextCenter}>
                        { t("merchantApplProcess:link2_NoEmail_DownloadDocs") }
                    </a>
                    {/* 
                    <a className={styles.linkTextCenter}>
                        { t("merchantApplProcess:link3_NoEmail_DownloadDocs") }
                    </a> */}

                    <Collapse className={styles.docCollapse}>
                        <Collapse.Panel header={t("merchantApplProcess:link3_NoEmail_DownloadDocs")} key="link3_NoEmail_DownloadDocs">
                            <iframe title="Contact" style={{ width: "100%", height: "1024px"}} src={getDummyPdfUrl()}></iframe>
                        </Collapse.Panel>
                    </Collapse>
                    
                    <Spacer hx={3}/>

                    <Row>
                        <Col span={20}>
                            <Checkbox>
                                { t("applProcess:acceptTerms")}
                            </Checkbox>
                        </Col>
                    </Row>

                    <Spacer hx={3}/>

                    <SignatureCanvas penColor='black'
                        canvasProps={{width: 429, height: 159, className: styles.sigCanvas}}
                        ref={(ref) => {
                            sigCanvas = ref; 
                        }}/>
                    <Spacer hx={3}/>
                    
                    <Row>
                        <Col span={10}>
                            <Button type="primary"
                                loading={loading}
                                onClick={handleContinueNoEmailFromDocsList}>
                                {t("buttons:continueWithApplication")}
                            </Button>
                        </Col>
                        <Col span={4}>
                        </Col>
                        <Col span={10}>
                            <Button className={styles.buttonWhite}
                                type="default"
                                loading={loading}
                                onClick={() => {
                                    setState(MerchantStepOneStates.No_Email); 
                                }}
                            >
                                {t("buttons:back")}
                            </Button>
                        </Col>
                    </Row>

                    <Spacer/>
                </div>
            </React.Fragment>
        );
    };

    const renderForm = (): JSX.Element => {
        return (
            <React.Fragment>
                <h2 className={styles.processTitle}>{t("merchantApplProcess:titleStepOne")}</h2>
                <h4 className={styles.processSubTitle}>{t("merchantApplProcess:subtitleStepOne")}</h4>
                <div className={styles.innerContainer}>
                    <Spacer/>

                    <Form
                        layout={"vertical"}
                        form={form}
                        onFinish={handleOnFinish}
                        scrollToFirstError
                    >
                        <Form.Item name="email"
                            label={t("email")}
                            hasFeedback
                            rules={[{required: true, message: t('errors:emailInvalid')}]}>

                            <Input data-cy="email"
                                className={styles.input}
                                placeholder={t("example") + " example@example.com"}
                            />
                        </Form.Item>
                        <Spacer/>

                        <div className={styles.error}>{generalError}</div>
                        <Spacer/>

                        <Form.Item>
                            <Row className={styles.rowCenterX}>
                                <Button type="primary" htmlType="submit"
                                    loading={loading}>{t("buttons:continue2")}</Button>
                            </Row>
                        </Form.Item>

                        <Row className={styles.rowCenterX}>
                            <Button className={styles.buttonWhite}
                                type="primary"
                                loading={loading}
                                onClick={() => {
                                    setState(MerchantStepOneStates.No_Email); 
                                }}
                            >{t("buttons:continueNoEmail")}</Button>
                        </Row>

                        <Spacer/>

                        <Spacer/>
                    </Form>
                </div>
            </React.Fragment>
        );
    };

    const renderAccessRequestExpired = (): JSX.Element => {
        return (
            <React.Fragment>
                <h2 className={styles.processTitle}>{t("merchantApplProcess:titleAccessExpired")}</h2>
                <h4 className={styles.processSubTitle}>{t("merchantApplProcess:subtitleAccessExpired")}</h4>
                <div className={styles.innerContainer}>
                    <Spacer/>

                    <Row className={styles.rowCenterX}>
                        <Button type="primary" htmlType="submit"
                            onClick={() => {
                                history.push(RouteStrings.MerchantHome);
                            }}>
                            {t("buttons:toMerchantPortal")}
                        </Button>
                    </Row>
                </div>
            </React.Fragment>
        );
    };

    // TODO: test rendering logic
    const render = (): JSX.Element => {
        if (checkingLoggedIn) {
            return <Spin className={styles.spin} indicator={<Loading3QuartersOutlined style={{ fontSize: 34 }} spin />} />;
        }

        if (state === MerchantStepOneStates.Initial) {
            return renderForm();
        }

        if (state === MerchantStepOneStates.Email_Found) {
            return renderEmailFound();
        }
        if (state === MerchantStepOneStates.Email_Found_Awaiting_Approval) {
            return renderEmailFoundAwaitingApproval();
        }

        if (state === MerchantStepOneStates.Email_Not_Found) {
            return renderEmailNotFound();
        }
        if (state === MerchantStepOneStates.Email_Not_Found_Awaiting_Registration) {
            return renderEmailNotFoundAwaitingRegistration();
        }

        if (state === MerchantStepOneStates.No_Email) {
            return renderNoRegistration();
        }
        if (state === MerchantStepOneStates.No_Email_Download_Docs) {
            return renderNoEmailDownloadDocs();
        }

        if (state === MerchantStepOneStates.Access_Request_Expired) {
            return renderAccessRequestExpired();
        }

        return null;
    };

    return (
        <div className={styles.page}>
            <ApplicationProcessHeader checkLoggedInStatus/>
            <CustomPageHeader />
            <div className={styles.container}>
                <Spacer/>
                { render() }
            </div >
        </div>
    );
};
