import { DATE_FORMAT } from "api/apiConfig";
import moment from "moment";

/**
 * Format examples: DD.MM.YYYY or DD-MM-YYYY
 */
export const isValidDateDDMMYYYY = (date: string, format: string): boolean => {
    return moment(date, format).isValid();
};

export const getUtcTimestampMs = (): number => {
    return moment().utc().valueOf();
};

export const getUtcUnixTimestampMs = (): number => {
    return moment().utc().valueOf();
};

export const getMonthAsMM = (): string => {
    const month = moment().month();

    const monthAsMM = moment(month).format("MM");

    return monthAsMM;
};

export const getYearAsYYYY = (): number => {
    const year = moment().year();

    return year;
};

export const getTodayinIsoFormat = (): string => {
    return moment().format("YYYY-MM-DD");
};

export const calcDifferenceInYearsBetweenDotSeparatedDateAndNow = (dotSeparatedDate: string): number => {
    try {
        return moment({ hours: 0 }).diff(moment(dotSeparatedDate, "DD.MM.YYYY"), 'years');
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const calcDifferenceInMonthsBetweenDotSeparatedDates = (start: string, end: string): number => {
    let diffInMonths = null;

    const format = "DD.MM.YYYY";

    try {
        if (isValidDateDDMMYYYY(start, format) && isValidDateDDMMYYYY(end, format)) {
            let dateA = moment(start, format),
                dateB = moment(end, format);

            diffInMonths = dateB.diff(dateA, "months");

            if (!isNaN(diffInMonths)) {
                diffInMonths += 1;
            }
        }
    } catch (err) {
        console.error(err);
    }

    return diffInMonths;
};

/**
 * Calculates difference between two UNIX millisecond timestamps in days.
 *
 * @param firstDate
 * @param secondDate 
 */
export const calcDiffInDaysBetweenTimestamps = (firstDate: number, secondDate: number): number => {
    try {
        const differenceInDays = firstDate - secondDate;

        return (differenceInDays / 86400000);
    } catch (err) {
        console.error(err);
        return null;
    }
};

/**
 * Calculates difference between current time and a timestamp in days.
 *
 * @param timestamp
 */
export const calcDiffInDaysFromNow = (timestamp: number): number => {
    const currentTime = getUtcTimestampMs();
    const timestampToUtc = moment(timestamp).utc().valueOf();

    const differenceInDays = moment(currentTime).diff(timestampToUtc, "days");

    return differenceInDays;
};

export const isDateOlderThanTwoYears = (date: string) => {
    const beginningDate = moment(date);
    const now = moment();

    return now.diff(beginningDate, "years") <= 2;
};

export const formatDateForAPI = (date: string): string => {
    try {
        return moment(date).format(DATE_FORMAT.API);
    } catch (error) {
        console.error(error);
        return undefined;
    }
};

export const formatDateForTable = (date: string): string => {
    try {
        return moment(date).format(DATE_FORMAT.DE);
    } catch (error) {
        console.error(error);
        return undefined;
    }
};
