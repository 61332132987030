import { URL_BASE } from "api/apiConfig";
import ApiManager from "api/ApiManager";
import { AxiosError } from "axios";
import { RouteStrings } from "routes/RouteStrings";
import { goTo } from "../navigation/navigationHelpers";

const axios = require('axios').default;
axios.defaults.withCredentials = true;

export enum ApiError {
    ErrorUnauthorized = "ErrorUnauthorized"
}

export class ApiWrapper {
    public static async get(path: string, manuallyHandleError?: boolean): Promise<any> {
        if (path == null) {
            return;
        }

        const modifiedPath = ApiWrapper.getModifiedPath(path);

        try {
            const apiCall = axios.get(modifiedPath);

            const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

            return ApiManager.handleNetworkCallResult(networkCall);
        } catch (error) {
            if ((error as AxiosError).response?.status === 401 || (error as AxiosError).response?.status === 403) {
                if (manuallyHandleError) {
                    throw error;
                }

                if (path.includes("/admin")) {
                    goTo(RouteStrings.AdminLogin);
                } else {
                    goTo(RouteStrings.Login);
                }
                throw new Error(ApiError.ErrorUnauthorized);
            }
            throw error;
        }
    }

    public static async post(path: string, body: any, manuallyHandleError?: boolean) {
        const modifiedPath = ApiWrapper.getModifiedPath(path);

        try {
            const apiCall = axios.post(modifiedPath, { ...body });

            const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

            return ApiManager.handleNetworkCallResult(networkCall);
        } catch (error) {
            if ((error as AxiosError).response?.status === 401 || (error as AxiosError).response?.status === 403) {
                // We want to manually handle the redirect in the application flow.
                if (manuallyHandleError) {
                    throw error;
                }

                if (path.includes("/admin")) {
                    goTo(RouteStrings.AdminLogin);
                } else {
                    goTo(RouteStrings.Login);
                }
                throw new Error(ApiError.ErrorUnauthorized);
            }
            throw error;
        }
    }

    public static async postWAuth(path: string, body: any, token: string, manuallyHandleError?: boolean){
        const modifiedPath = ApiWrapper.getModifiedPath(path);

        try {
            const apiCall = axios.post(modifiedPath, { ...body },{
                headers: {
                    "Authorization": `${token}`,
                }
            });
            const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

            return ApiManager.handleNetworkCallResult(networkCall);
        
        }catch(error){
            if ((error as AxiosError).response?.status === 401 || (error as AxiosError).response?.status === 403) {
                // We want to manually handle the redirect in the application flow.
                if (manuallyHandleError) {
                    throw error;
                }

                if (path.includes("/admin")) {
                    goTo(RouteStrings.AdminLogin);
                } else {
                    goTo(RouteStrings.Login);
                }
                throw new Error(ApiError.ErrorUnauthorized);
            }
            throw error;
        }
    }

    public static async postFormWithFile(path: string, formData: FormData) {
        const modifiedPath = ApiWrapper.getModifiedPath(path);

        try {
            const apiCall = axios.post(modifiedPath, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

            return ApiManager.handleNetworkCallResult(networkCall);
        } catch (error) {
            if ((error as AxiosError).response?.status === 401 || (error as AxiosError).response?.status === 403) {
                if (path.includes("/admin")) {
                    goTo(RouteStrings.AdminLogin);
                } else {
                    goTo(RouteStrings.Login);
                }
                throw new Error(ApiError.ErrorUnauthorized);
            }
            throw error;
        }
    }

    public static async put(path: string, body: any) {
        const modifiedPath = ApiWrapper.getModifiedPath(path);

        try {
            const apiCall = axios.put(modifiedPath, { ...body });

            const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

            return ApiManager.handleNetworkCallResult(networkCall);
        } catch (error) {
            const err = error as AxiosError;
            if (err?.response?.status === 401 || err?.response?.status === 403) {
                if (path.includes("/admin")) {
                    goTo(RouteStrings.AdminLogin);
                } else {
                    goTo(RouteStrings.Login);
                }
                throw new Error(ApiError.ErrorUnauthorized);
            }
            throw error;
        }
    }


    public static async patch(path: string, body: any) {
        const modifiedPath = ApiWrapper.getModifiedPath(path);

        try {
            const apiCall = axios.patch(modifiedPath, { ...body });

            const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

            return ApiManager.handleNetworkCallResult(networkCall);
        } catch (error) {
            if ((error as AxiosError).response?.status === 401 || (error as AxiosError).response?.status === 403) {
                if (path.includes("/admin")) {
                    goTo(RouteStrings.AdminLogin);
                } else {
                    goTo(RouteStrings.Login);
                }
                throw new Error(ApiError.ErrorUnauthorized);
            }
            throw error;
        }
    }

    public static async patchFormWithFile(path: string, formData: FormData) {
        const modifiedPath = ApiWrapper.getModifiedPath(path);

        try {
            const apiCall = axios.patch(modifiedPath, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

            return ApiManager.handleNetworkCallResult(networkCall);
        } catch (error) {
            if ((error as AxiosError).response?.status === 401 || (error as AxiosError).response?.status === 403) {
                if (path.includes("/admin")) {
                    goTo(RouteStrings.AdminLogin);
                } else {
                    goTo(RouteStrings.Login);
                }
                throw new Error(ApiError.ErrorUnauthorized);
            }
            throw error;
        }
    }

    public static async del(path: string, body: any = {}) {
        const modifiedPath = ApiWrapper.getModifiedPath(path);

        try {
            const apiCall = axios.delete(modifiedPath, { data: { ...body } });

            const networkCall = await Promise.race([apiCall, ApiManager.generateTimeout()]);

            return ApiManager.handleNetworkCallResult(networkCall);
        } catch (error) {
            if ((error as AxiosError).response?.status === 401 || (error as AxiosError).response?.status === 403) {
                if (path.includes("/admin")) {
                    goTo(RouteStrings.AdminLogin);
                } else {
                    goTo(RouteStrings.Login);
                }
                throw new Error(ApiError.ErrorUnauthorized);
            }
            throw error;
        }
    }

    public static getModifiedPath(path: string): any {
        return `${URL_BASE}${path}`;
    }
}
