import { Button, Col, Row, Table } from "antd";
import Search from "antd/lib/input/Search";
import { adminGetClients } from "api/admin/admin";
import styles from "components/admin/presenters/admin-home-tabs/AdminHomeTabs.module.scss";
import { CreateClientModal } from "components/admin/presenters/create-client-modal/CreateClientModal";
import { renderString } from "helpers/stringHelpers";
import { formatDateForTable } from "helpers/timeHelpers";
import i18n from "i18n/i18n";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RouteStrings } from "routes/RouteStrings";
import { PaginationType } from "types/adminPanel";
import { GetClientsListResponse } from "../../../../../api/types";
import { useDateRangeFilterOptions } from "../../../../../hooks/TableFilterOptions";
import { tableColumnSearchProps } from "../../../../../misc/antdTableSearch";
import { PreviewPane } from "../../preview-pane/PreviewPane";

export const ClientsTab = () => {
    const { t } = useTranslation();

    const [addClientModalOpen, setAddClientModalOpen] = useState(false);

    const [clients, setClients] = useState([] as GetClientsListResponse[]);

    const [searchStr, setSearchStr] = React.useState(null);

    const [selectedClient, setSelectedClient] = React.useState("");

    const [selectedRow, setSelectedRow] = React.useState(null);

    
    const [pagination, setPagination] = React.useState<PaginationType>({});

    const [filter] = useState(null);

    const getClients = async (page: number, searchStr?: string) => {
        try {
            const response = await adminGetClients(page, searchStr); 
            let clientsList: GetClientsListResponse[] = response.data.result;

            setPagination(response.data.pagination);

            setClients(clientsList);
        } catch (e) {
            console.error(e);
        }
    };

    const handleRowClicked = (client, rowIndex: number) => {
        setSelectedClient(client.client_id);
        setSelectedRow(rowIndex);
    };

    const handleSearch = () => {
        if (searchStr && searchStr?.length > 2) {
            getClients(1, searchStr);
        } else if (searchStr === "") {
            getClients(1);
        }
    };

    useEffect(() => {
        getClients(1);
    }, [filter]);

    const columns = [
        {
            title: i18n.t("tables:clientId"),
            dataIndex: "short_id",
            ...tableColumnSearchProps("short_id"),
        },
        {
            title: i18n.t("tables:clientName"),
            dataIndex: "first_name",
            ...tableColumnSearchProps("first_name"),
            render: (_, client) => {
                return `${renderString(client?.first_name)} ${renderString(client?.last_name)}`;
            },
        },
        {
            title: i18n.t("tables:email"),
            dataIndex: "email",
            ...tableColumnSearchProps("email"),
        },
        {
            title: t("created"),
            dataIndex: "created_at",
            render: (value) => (value ? formatDateForTable(value) : "-"),
            ...useDateRangeFilterOptions("created_at"),
        },
        {
            title: "",
            dataIndex: "client_id",
            render: (clientId) => (
                <Link to={RouteStrings.AdminClientDrilldown.replace(":clientId", clientId)}>
                    {t("view")}
                </Link>
            ),
        },
    ];

    return (
        <section className={styles.adminHomeTab}>
            <Button
                className={styles.addClientBtn}
                type="primary"
                onClick={() => {
                    setAddClientModalOpen(true);
                }}
            >
                {t("buttons:newClient")}
            </Button>
            <Row className={styles.tabRow}>
                <Col span={24}>
                    <div className="row-flex-end">
                        <Search
                            className="search-bar"
                            placeholder={t("freeTextSearch")}
                            value={searchStr}
                            onChange={(e) => {
                                setSearchStr(e.target.value);
                            }}
                            onSearch={(value) => {
                                handleSearch();
                            }}
                        />
                    </div>
                </Col>
                <Col>
                    <p
                        className="clearSearch"
                        onClick={() => {
                            setSearchStr("");
                            getClients(1);
                        }}
                    >
                        Suche zurücksetzen
                    </p>
                </Col>
            </Row>

            <Row gutter={12}>
                <Col span={18}>
                    <Table
                        rowKey={(record) => record.client_id}
                        columns={columns}
                        rowClassName={(_, idx) => (idx === selectedRow ? styles.selected : "")}
                        dataSource={clients}
                        scroll={{ x: true }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: () => handleRowClicked(record, rowIndex),
                            };
                        }}
                        pagination={{
                            position: ["bottomCenter"],
                            current: pagination.current_page,
                            total: pagination.total_rows,
                            pageSize: pagination.items_per_page,
                            showSizeChanger:false,
                        }}
                        onChange={(e) => getClients(e.current)}
                    />
                </Col>

                <Col span={6}>
                    <PreviewPane clientId={selectedClient} />
                </Col>
            </Row>

            <CreateClientModal
                existingClientData={null}
                modalOpen={addClientModalOpen}
                setModalOpen={setAddClientModalOpen}
                onDataChanged={() => getClients(1)}
            />
        </section>
    );
};
