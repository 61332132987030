import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import { ApplicationObject } from "api/types";
import { AdminCreateApplicationStates } from "components/admin/pages/application-drilldown/ApplicationDrilldownPage";
import { AnchoredSelect } from "components/common/presenters/anchored-select/AnchoredSelect";
import { ConfirmModalClose } from "components/common/presenters/confirm-modal-close/ConfirmModalClose";
import styles from "components/common/presenters/new-offer-modal/NewOfferModal.module.scss";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { goToAdminApplicationDrilldown } from "navigation/navigationHelpers";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { AdminCreateApplicationForm } from "types/applicationProcess";
const { Option } = Select;

export interface NewOfferModalProps {
    open: boolean
    state: AdminCreateApplicationStates
    error: string
    newAppl: ApplicationObject
    onOk: (values: any) => void
    onCancel: () => void
}

export const NewOfferModal = (props: NewOfferModalProps) => {
    const { t } = useTranslation();
	
    const [form] = Form.useForm();

    const [secondaryModalOpen, setSecondaryModalOpen] = React.useState(false);

    const handleOnFinish = (values: {
        financing_amount: string
        financing_partner: string
        installements: string
    }) => {

        const data: AdminCreateApplicationForm = {
            financing_amount: values.financing_amount ? parseInt(values.financing_amount) : undefined,
            financing_partner: values.financing_partner,
            installements: values.installements ? parseInt(values.installements) : undefined,
        };

        props.onOk(data);
    };

    const secondaryModalOnCancel = () => {
        setSecondaryModalOpen(false);
    };

    const secondaryModalOnOk = () => {
        setSecondaryModalOpen(false);
        form.resetFields();
        props.onCancel();
    };

    React.useEffect(() => {
        form.resetFields();
    }, [props]);

    const renderForm = () => {
        return (
            <div className={styles.inputsContainer}>
                <Form id="createOffer" className={styles.form}
                    layout={"vertical"}
                    form={form}
                    onFinish={handleOnFinish}
                    scrollToFirstError
                >
                    <Col>
                        <div>{ t("modals:infoFinancingOffer") }</div>
                        <Spacer/>

                        <Row>
                            <Col span={4}>
                                <div>
                                    {t("country")}
                                </div>
                                <div>
                                    {t("merchant")}
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>Schweiz</div>
                                <div>DOMIsense</div>
                            </Col>
                        </Row>

                        <Spacer/>

                        <Row gutter={24}>
                            <Col span={10}>
                                <Form.Item name="financing_amount" label={t("financingAmount")} hasFeedback
                                    rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                    <Input data-cy="financing_amount"
                                        className={styles.input}
                                        placeholder={t("example") + " 5000"}/>
                                </Form.Item>
                            </Col>
                            <Col></Col>
                            <Col span={10}>
                                <Form.Item name="installements" label={t("numberOfInstallements")} hasFeedback
                                    rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                                    <Input data-cy="installements"
                                        type="number"
                                        className={styles.input}
                                        placeholder={t("example") + " 12"}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item name="financing_partner" label={t("selectFinancingPartner")} hasFeedback
                            rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}>
                            <AnchoredSelect data-cy="financing_partner" className={styles.select} allowClear>
                                <Option value="test_bank">TestBank</Option>
                                <Option value="muster_bank">Musterbank</Option>
                            </AnchoredSelect>
                        </Form.Item>

                        <Spacer hx={1} />
                    </Col>
                </Form>
            </div>
        );
    };

    const renderLoading = () => {
        return (
            <React.Fragment>
                <div>{ t("modals:adminCreateOffer_Loading") }</div>

                <Spacer/>

                <Row>
                    
                </Row>

                <div className={styles.rowCenter}>
                    <Spin className={styles.spin} indicator={<Loading3QuartersOutlined style={{ fontSize: 54 }} spin />} />
                </div>
            </React.Fragment>
        );
    };

    const renderSuccess = () => {
        return (
            <React.Fragment>
                <div>{ t("modals:adminCreateOffer_Success_info1") }</div>

                <Spacer/>

                <Row>
                    <Col span={6}>
                        <Row>{t("financingAmount")}</Row>
                        <Row>{t("numberOfInstallements_2")}</Row>
                        <Row>{t("installementAmount")}</Row>
                    </Col>
                    <Col span={6}>
                        <Row>{ props.newAppl?.currency } { props.newAppl?.amount }</Row>
                        <Row>{ props.newAppl?.duration_months }</Row>
                        <Row>{ props.newAppl?.currency } 454,16</Row>
                    </Col>
                </Row>

                <Spacer/>

                <div>{ t("modals:adminCreateOffer_Success_info2") }</div>

            </React.Fragment>
        );
    };

    const renderAddressNotFound = () => {
        return (
            <React.Fragment>
                <div>{ t("modals:adminCreateOffer_NotFound") }</div>
            </React.Fragment>
        );
    };

    const render = (): React.ReactNode => {
        if (props.state === AdminCreateApplicationStates.Loading) {
            return renderLoading();
        } 
        
        if (props.state === AdminCreateApplicationStates.User_Accepted) {
            return renderSuccess();
        }
        
        if (props.state === AdminCreateApplicationStates.Address_Not_Found) {
            return renderAddressNotFound();
        }
            
        return renderForm();
    };

    const renderModalButton = () => {
        if (props.state === AdminCreateApplicationStates.User_Accepted ||
            props.state === AdminCreateApplicationStates.Bank_Rejection) {
            return (<Button key="view_offer" onClick={() => {
                goToAdminApplicationDrilldown(props.newAppl?.application_id);
            }}>
                { t("buttons:viewOffer") }
            </Button>);
        }

        if (props.state === AdminCreateApplicationStates.Address_Not_Found) {
            return (<Button key="view_offer" onClick={() => {
                goToAdminApplicationDrilldown(props.newAppl?.application_id);
            }}>
                { t("buttons:viewOffer") }
            </Button>);
        }

        if (props.state === AdminCreateApplicationStates.Loading) {
            return null;
        }

        return (
            <Button form="createOffer" key="submit" htmlType="submit">
                { t("buttons:createOffer") }
            </Button>
        );
    };

    return (
        <React.Fragment>
            <ConfirmModalClose visible={secondaryModalOpen} onCancel={secondaryModalOnCancel} onOk={secondaryModalOnOk}/>

            <Modal className={styles.modal}
                title={t("modals:createFinancingOffer")}
                visible={props.open}
                centered
                destroyOnClose={true}
                maskClosable
                keyboard={true}
                onCancel={(e: any) => {
                    if (e.code === "Escape") {
                        e.preventDefault();
                        e.stopPropagation();
                        setSecondaryModalOpen(true);
                    } else {
                        form.resetFields();
                        props.onCancel();
                    }
                }}
                footer={[
                    <div key="fr" className={styles.footerRow}>
                        <div key="footer_row_1" className={styles.error}>{props.error}</div>
                        <div key="footer_row_2" className={styles.rowCenterSpace}>
                            <Button form="createOffer" key="cancel" className={styles.buttonCancel} 
                                onClick={props.onCancel}>
                                { t("buttons:cancel") }
                            </Button>
                            {
                                renderModalButton()
                            }
                        </div>
                    </div>
                ]}>

                { render() }
                
            </Modal>
        </React.Fragment>
    );
};
