import { ApiWrapper } from "api/ApiWrapper";
import { CreateClientObject, CreateToDo, ToDoStatuses } from "api/types";
import { PostMerchantUser } from "api/types/merchant";
import { ApplicationStatuses } from "types/types";

export const refreshMerchantSession = async (manuallyHandleError?: boolean) => {
    const path = `/merchant/account/refresh_session`;

    return ApiWrapper.post(path, {}, manuallyHandleError);
};

export const logoutMerchant = async (): Promise<any> => {
    const path = `/merchant/account/logout`;

    return ApiWrapper.post(path, {});
};

export const merchantGetApplications = async (page: number, searchStr?: string, status?: ApplicationStatuses): Promise<any> => {
    let path = `/merchant/applications?page=${page}`;

    if (searchStr) {
        path += `&text=${searchStr}`;
    }

    if (status) {
        path += `&status=${status}`;
    }

    return ApiWrapper.get(path) as Promise<any>;
};

export const merchantGetApplication = async (id: string): Promise<any> => {
    const path = `/merchant/applications/${id}`;

    return ApiWrapper.get(path) as Promise<any>;
};

export interface Action {
    name: string
    color: string
    params: {
        new_status?: string
    }
}

export const merchantChangeApplicationStates = async (applicationId: string, action: Action): Promise<any> => {
    const path = `/merchant/applications/${applicationId}/action`;

    const body = {
        id: applicationId,
        action,
    };

    return ApiWrapper.post(path, body);
};

export const merchantGetClients = async (page: number, searchStr?: string): Promise<any> => {
    let path;

    if (searchStr) {
        path = `/merchant/clients?text=${searchStr}&page=${page}`;
    } else {
        path = `/merchant/clients?page=${page}`;
    }

    return ApiWrapper.get(path) as Promise<any>;
};

export const merchantGetClient = async (clientId: string): Promise<any> => {
    const path = `/merchant/clients/${clientId}`;

    return ApiWrapper.get(path, true);
};

export const merchantCreateClient = async (client: CreateClientObject): Promise<any> => {
    const path = `/merchant/clients`;

    return ApiWrapper.post(path, client);
};

/* To-Do-related methods */
export const merchantGetToDos = async (page: number): Promise<any> => {
    const path = `/merchant/todos?page=${page}`;

    return ApiWrapper.get(path) as Promise<any>;
};

export const merchantGetToDo = async (id: string): Promise<any> => {
    const path = `/merchant/todos/${id}`;

    return ApiWrapper.get(path) as Promise<any>;
};

export const merchantGetApiKey = async(): Promise<any> =>{
    const path = `/merchant/settings/apikey`;

    return ApiWrapper.get(path);
};

export const merchantGenerateApiKey = async(merchantId: string): Promise<any> =>{
    const path = `/merchant/settings/generate_apikey`;

    return ApiWrapper.post(path,{merchantid:merchantId});
};


export const merchantCreateToDo = async (todo: CreateToDo): Promise<any> => {
    const path = `/merchant/todos`;

    return ApiWrapper.post(path, todo);
};

export const merchantUpdateToDoStatus = async (id: string, status: ToDoStatuses): Promise<any> => {
    const path = `/merchant/todos/${id}/status`;

    const body = {
        status
    };

    return ApiWrapper.put(path, body);
};

export const merchantDeleteToDo = async (id: string): Promise<any> => {
    const path = `/merchant/todos/${id}`;

    return ApiWrapper.del(path) as Promise<any>;
};

export const merchantChangePassword = async (password: string): Promise<any> => {
    const path = `/merchant/account/change_password`;

    return ApiWrapper.post(path, { password });
};

export const merchantGetProfile = async (): Promise<any> => {
    const path = `/merchant/profile`;

    return ApiWrapper.get(path) as Promise<any>;
};

export const merchantSearchClientByEmail = async (email: string): Promise<any> => {
    const path = `/merchant/clients/search?email=${email}`;

    return ApiWrapper.get(path);
};

// Merchant settings - list of merchant accounts
export const merchantGetMerchantUsers = async (pageNum: number): Promise<any> => {
    const path = `/merchant/musers?page=${pageNum}`;

    return ApiWrapper.get(path);
};

// Merchant settings - list of merchant accounts
export const merchantDisableMerchantUser = async (userId: string): Promise<any> => {
    const path = `/merchant/musers/${userId}/enabled`;

    return ApiWrapper.post(path, { is_enabled: false });
};

// Merchant settings - list of merchant accounts
export const merchantEnableMerchantUser = async (userId: string): Promise<any> => {
    const path = `/merchant/musers/${userId}/enabled`;

    return ApiWrapper.post(path, { is_enabled: true });
};

// Merchant settings - list of merchant accounts
export const merchantSendResetForMerchant = async (merchantUserEmail: string): Promise<any> => {
    const path = `/merchant/account/reset`;

    return ApiWrapper.post(path, {
        email: merchantUserEmail
    });
};

export const merchantCreateMerchantUser = async (merchantUser: PostMerchantUser): Promise<any> => {
    const path = `/merchant/musers`;

    const body = {
        ...merchantUser
    };

    return ApiWrapper.post(path, body);
};

export const merchantInviteClient = async (clientEmail: string): Promise<any> => {
    const path = `/merchant/clients/invite`;

    const body = {
        email: clientEmail
    };

    return ApiWrapper.post(path, body);
};

export const merchantRequestAccess = async (clientId: string): Promise<any> => {
    const path = `/merchant/clients/${clientId}/request_access`;

    return ApiWrapper.post(path, {});
};


export const merchantAcquireOffers = async (applicationId: string): Promise<any> => {
    const path = `/merchant/applications/${applicationId}/acquire_offers`;

    const body = {

    };

    return ApiWrapper.post(path, body) as Promise<any>;
};

export const merchantGetSelectedOffer = async (applicationId: string): Promise<any> => {
    const path = `/merchant/applications/${applicationId}/selected_offer`;

    return ApiWrapper.get(path) as Promise<any>;
};

export const merchantConfirmShipping = async (applicationId: string): Promise<any> => {
    const path = `/merchant/applications/${applicationId}/confirm_shipping`;

    return ApiWrapper.post(path, {});
};

export const MerchantPutLogo = async (file: any): Promise<any> =>{
    const path = `/merchant/settings/logo`;

    return ApiWrapper.put(path,{image_data_uri:file});
};