import { Col, Row, Table } from "antd";
import Search from "antd/lib/input/Search";
import styles from "components/admin/presenters/admin-home-tabs/AdminHomeTabs.module.scss";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { ApplicationStatuses } from "types/types";
import { merchantGetApplications } from "api/merchant";
import { columns } from "components/merchant-panel/applications-tab/merchantApplicationsTabTableColumns";
import { PreviewPane } from "components/admin/presenters/preview-pane/PreviewPane";
import { PaginationType } from "types/adminPanel";

/**
 * Only used by the admin. It's actually more like its own page, so we should rename it.
 * It should also manage its data alone.
 */
export const MerchantApplicationsTab = () => {
    const { t } = useTranslation();

    const [loading, setLoading] = React.useState(false);
    const [appl, setAppl] = React.useState([]);
    const [merchantPagination,setMerchantPagination] = React.useState<PaginationType>({});
    const [searchStr, setSearchStr] = React.useState(null);

    const [selectedRow, setSelectedRow] = React.useState(null);
    const [selectedAppl, setSelectedAppl] = React.useState("");

    const getApplications = async (page: number, searchStr?: string, state?: ApplicationStatuses) => {
        if (loading) {
            return;
        }

        setLoading(true);

        setAppl([]);

        const getApplReq = await merchantGetApplications(page, searchStr, state);

        if (getApplReq && getApplReq.status == 200) {
            setAppl(getApplReq.data.result);
            setMerchantPagination(getApplReq.data.pagination);
        } else {
            setAppl([]);
        }

        setLoading(false);
    };

    const handleSearch = () => {
        if (searchStr && searchStr?.length > 2) {
            getApplications(1, searchStr.trim());
        } else if (searchStr === "") {
            getApplications(1);
        }
    };
    const handleRowClicked = (appl, rowIndex: number) => {
        setSelectedAppl(appl.application_id);
        setSelectedRow(rowIndex);
    };

    React.useEffect(() => {
        getApplications(1, null);
    }, [1]);

    return (
        <section className="admin-home-tab">
            <Row>
                <Col span={24}>
                    <Search
                        className="search-bar"
                        placeholder={t("freeTextSearch")}
                        value={searchStr}
                        onChange={(e) => {
                            setSearchStr(e.target.value);
                        }}
                        onSearch={(value) => {
                            handleSearch();
                        }}
                    />
                </Col>
                <Col>
                    <p
                        className="clearSearch"
                        onClick={() => {
                            setSearchStr("");
                            getApplications(1);
                        }}
                    >
                        Suche zurücksetzen
                    </p>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={18}>
                    <Table
                        className={styles.smallTable}
                        rowKey={(record) => record.id}
                        rowClassName={(_, idx) => (idx === selectedRow ? styles.selected : "")}
                        loading={loading}
                        columns={columns}
                        dataSource={appl}
                        scroll={{ x: true }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: () => handleRowClicked(record, rowIndex),
                            };
                        }}
                        pagination={{
                            position: ["bottomCenter"],
                            current: merchantPagination.current_page,
                            total: merchantPagination.total_rows,
                            pageSize: merchantPagination.items_per_page,
                            showSizeChanger:false,
                        }}
                        onChange={(e) => getApplications(e.current)}
                    />
                </Col>
                <Col span={6}>
                    <PreviewPane merchantApplId={selectedAppl} />
                </Col>
            </Row>
        </section>
    );
};
