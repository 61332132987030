import * as React from "react";
import { Form, Input, message, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";
import { PostBOUserRequest } from "../../../../../api/types";
import { AnchoredSelect } from "../../../../common/presenters/anchored-select/AnchoredSelect";
import { BoUserRoleID } from "../../../../../types/types";
import { adminCreateBOUser } from "api/admin/bo_users";
import { ConfirmModalClose } from "components/common/presenters/confirm-modal-close/ConfirmModalClose";
import { AxiosError } from "axios";

export interface AddBOUserModalProps {
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    onDataChanged: () => void
}

export const AddBOUserModal = (props: AddBOUserModalProps) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [secondaryModalOpen, setSecondaryModalOpen] = React.useState(false);

    const onFormFinish = async (values) => {
        const req: PostBOUserRequest = {
            name: values.name,
            email: values.email,
            role: values.role,
        };

        try {
            await adminCreateBOUser(req);
            message.success(t("messages:dataSaved"), 2);
            props.onDataChanged();
            props.setVisible(false);
        } catch (e) {
            const err = e as AxiosError;
            if (err.response?.status === 409) {
                message.error(t("messages:userWithEmailAlreadyExists"), 2);
            } else {
                message.error(t("messages:couldNotSave"), 2);
                console.error(err);
            }
        }
    };
    const checkForm = () => {
        // check if at least one value is filled in a form.
        const vals = Object.values(form.getFieldsValue());
        const notEmpty = vals.some((value) => value !== undefined);
        return notEmpty;
    };

    const secondaryModalOnCancel = () => {
        setSecondaryModalOpen(false);
    };
    const secondaryModalOnOk = () => {
        setSecondaryModalOpen(false);
        props.setVisible(false);
    };
    return (
        <>
            <ConfirmModalClose
                visible={secondaryModalOpen}
                onCancel={secondaryModalOnCancel}
                onOk={secondaryModalOnOk}
            />

            <Modal
                visible={props.visible}
                width={1000}
                maskClosable
                title={t("modals:addEmployee")}
                onOk={() => form.submit()}
                onCancel={() => {
                    if (!checkForm()) {
                        props.setVisible(false);
                    } else {
                        setSecondaryModalOpen(true);
                    }
                }}
            >
                <Form form={form} onFinish={onFormFinish} layout={"vertical"} scrollToFirstError>
                    <Form.Item
                        label={t("name")}
                        name="name"
                        rules={[{ required: true, message: t("formErrors:fullName") }]}
                    >
                        <Input data-cy={"input_bo_name"} />
                    </Form.Item>

                    <Form.Item
                        label={t("email")}
                        name="email"
                        rules={[{ required: true, type: "email", message: t("errors:emailInvalid") }]}
                    >
                        <Input data-cy={"input_bo_email"} />
                    </Form.Item>

                    <Form.Item
                        label={t("role")}
                        name="role"
                        rules={[{ required: true }]}
                        initialValue={BoUserRoleID.ADMIN}
                    >
                        <AnchoredSelect data-cy="select_bo_role">
                            {Object.keys(BoUserRoleID).map((k, i) => {
                                const enumValue = BoUserRoleID[k];
                                return (
                                    <Select.Option data-cy={`select_bo_role_${i}`} key={enumValue} value={enumValue}>
                                        {t(`bo_user_roles:${enumValue}`)}
                                    </Select.Option>
                                );
                            })}
                        </AnchoredSelect>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
