import {
    ApartmentOutlined,
    EditOutlined,
    MailOutlined,
    MobileOutlined,
    PhoneOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Form, Input, message, Modal, Popconfirm, Row, Select, Typography } from "antd";
import { Merchant } from "api/types";
import React, { Dispatch, SetStateAction } from "react";
import styles from "../../application-drilldown/ApplicationDrilldownPage.module.scss";
import { useTranslation } from "react-i18next";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { CreateEditMerchantModalSteps } from "components/admin/presenters/create-edit-merchant-modal/CreateEditMerchantModal";
import { adminDisableMerchant, adminEnableMerchant, adminUpdateMerchant } from "api/admin/merchants";
import { ConfirmModalClose } from "components/common/presenters/confirm-modal-close/ConfirmModalClose";
import { DATE_FORMAT } from "api/apiConfig";
import { CountryCodesList } from "types/applicationProcess";
import { AnchoredSelect } from "components/common/presenters/anchored-select/AnchoredSelect";
import { getPostCodeValidationPattern } from "validators/inputs";
import moment from "moment";
interface MerchantTabProps {
    merchantInfo: Merchant
    isSummary?: boolean
    setStep?: Dispatch<SetStateAction<CreateEditMerchantModalSteps>>
    refreshData?: () => Promise<any>
}

type formType = "user" | "contact" | "bank";

const { Text } = Typography;

const MerchantTab = (props: MerchantTabProps) => {
    const { t } = useTranslation();

    const [modalOpen, setModalOpen] = React.useState(false);
    const [secondaryModalOpen, setSecondaryModalOpen] = React.useState(false);
    const [currentForm, setCurrentForm] = React.useState<formType>();

    const [bankForm] = Form.useForm();
    const [contactForm] = Form.useForm();
    const [userForm] = Form.useForm();

    const secondaryModalOnCancel = () => {
        setSecondaryModalOpen(false);
    };

    const secondaryModalOnOk = () => {
        setSecondaryModalOpen(false);
        setModalOpen(false);
    };

    const [state] = React.useState({
        merchantInfo: {
            company_name: "",
            hr_number: "",
            website: "",
            vat_number: "",
            street: "",
            house_number: "",
            post_code: "",
            city: "",
            country: "",
            foundation_date: "",
            iban: "",
            bank: "",
            bic: "",
            company_email: "",
            phone_number: "",
        },
        contactPersons: [],
    });

    const checkForm = () => {
        // check if at least one value is filled in a form.
        const notEmpty = Object.values(state).some((value) => value !== undefined || "");
        return notEmpty;
    };

    const handleUserFinish = async(vals) => {
        const {company_name, hr_number, vat_number} = vals;

        const foundation_date = moment(vals.foundation_date).format(DATE_FORMAT.API);

        const body = {
            ...props.merchantInfo,
            name: company_name,
            founding_date: foundation_date,
            registration_number: hr_number,
            tax_id: vat_number,
        };

        try{
            await adminUpdateMerchant(props.merchantInfo.merchant_id,body);
            setModalOpen(false);
            void message.success(t("success"));
            props.refreshData();

        }catch(err){
            console.error(err);
            void message.error(t("errors:generalError"));
        }

    };  
  
    const handleContactFinish = async(vals) => {
        const {address_country_code,city,company_email,house_number,phone_number,post_code,street,website_url } = vals;
        const body = {
            ...props.merchantInfo,
            address_country_code: address_country_code.toLowerCase(),
            address_city: city,
            general_email: company_email,
            general_phone: phone_number,
            address_house_number: house_number,
            address_post_code: post_code,
            address_street:street,
            website_url,
        };


        try{
            await adminUpdateMerchant(props.merchantInfo.merchant_id,body);
            setModalOpen(false);
            void message.success(t("success"));
            props.refreshData();

        }catch(err){
            console.error(err);
            void message.error(t("errors:generalError"));
        }



    };
    const handleBankFinish = async(vals) => {
        const {iban, bic, bank} = vals;
        const body = {
            ...props.merchantInfo,
            iban,
            bank_name: bank,
            bic
        };

        try{
            await adminUpdateMerchant(props.merchantInfo.merchant_id,body);
            setModalOpen(false);
            void message.success(t("success"));
            props.refreshData();

        }catch(err){
            console.error(err);
            void message.error(t("errors:generalError"));
        }

    };

    const renderForm = () => {
        switch (currentForm) {
        case "user":
            return (
                <Form form={userForm} onFinish={handleUserFinish}>
                    <Row gutter={36}>
                        <Col span={12}>
                            <Form.Item
                                name="company_name"
                                label={t("company")}
                                hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                            >
                                <Input data-cy="company_name" className={styles.input} />
                            </Form.Item>

                            <Form.Item
                                name="hr_number"
                                label={t("hrNumber")}
                                hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 2, max: 100 }]}
                            >
                                <Input data-cy="hr_number" className={styles.input} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="foundation_date" label={t("foundationDate")} hasFeedback>
                                <DatePicker
                                    className={styles.datePicker}
                                    format={DATE_FORMAT.DE}
                                    allowClear={true}
                                />
                            </Form.Item>

                            <Form.Item
                                name="vat_number"
                                label={t("vatNumberLong")}
                                hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                            >
                                <Input data-cy="vat_number" className={styles.input} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className={styles.rowCenter} gutter={36}>
                        <Button data-cy="button_continue" type="primary" htmlType="submit">
                            {t("buttons:continue")}
                        </Button>
                    </Row>
                </Form>
            );
        case "contact":
            return (
                <Form form={contactForm} onFinish={handleContactFinish}>
                    <Row justify="end" align="top">
                        <Col span={12}>
                            <Row align="middle" gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        name="street"
                                        label={t("streetName")}
                                        hasFeedback
                                        rules={[{ required: true, message: t("errors:invalidData"), min: 1, max: 100 }]}
                                    >
                                        <Input data-cy="street" className={styles.input} />
                                    </Form.Item>
                                </Col>

                                <Col span={10}>
                                    <Form.Item
                                        name="house_number"
                                        label={t("streetNumber")}
                                        hasFeedback
                                        rules={[{ required: true, message: t("errors:invalidData"), min: 1, max: 100 }]}
                                    >
                                        <Input data-cy="house_number" className={styles.input} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row align="middle" gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        name="post_code"
                                        label={t("postCode")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("formErrors:invalidPostCode"),
                                                pattern: getPostCodeValidationPattern(),
                                            },
                                        ]}
                                    >
                                        <Input data-cy="post_code" className={styles.input} />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item
                                        name="city"
                                        label={t("city")}
                                        hasFeedback
                                        rules={[{ required: true, message: t("errors:invalidData"), min: 1, max: 100 }]}
                                    >
                                        <Input data-cy="city" className={styles.input} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row></Row>
                        </Col>

                        <Col span={12}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="company_email"
                                        label={t("email")}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                type: "email",
                                                message: t("errors:invalidData"),
                                                min: 5,
                                                max: 150,
                                            },
                                        ]}
                                    >
                                        <Input
                                            data-cy="company_email"
                                            className={styles.input}
                                            placeholder={`${t("example")} ${t("placeholders:email")}`}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="phone_number"
                                        label={t("phone")}
                                        hasFeedback
                                        rules={[{ required: true, message: t("errors:invalidData"), min: 5, max: 150 }]}
                                    >
                                        <Input
                                            data-cy="phone_number"
                                            className={styles.input}
                                            placeholder={`${t("example")}${t("placeholders:phone")}`}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="address_country_code"
                                        label={t("country")}
                                        hasFeedback
                                        rules={[{ required: true, message: t("errors:invalidData"), min: 1, max: 100 }]}
                                    >
                                        <AnchoredSelect
                                            data-cy="address_country_code"
                                            className={styles.select}
                                            allowClear
                                        >
                                            {CountryCodesList.map((val, i) => {
                                                return (
                                                    <Select.Option
                                                        data-cy={`address_country_option_${i}`}
                                                        key={i}
                                                        value={val}
                                                    >
                                                        {t(`countries:${val}`)}
                                                    </Select.Option>
                                                );
                                            })}
                                        </AnchoredSelect>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item name="website_url" label={t("website")} hasFeedback>
                                <Input data-cy="birth_city" className={styles.input} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Spacer />

                    <Row className={styles.rowCenter} gutter={36}>
                        <Button data-cy="button_continue" type="primary" htmlType="submit">
                            {t("buttons:continue")}
                        </Button>
                    </Row>
                </Form>
            );
        case "bank":
            return (
                <Form form={bankForm} onFinish={handleBankFinish}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                name="iban"
                                label={t("iban")}
                                hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                            >
                                <Input data-cy="iban" className={styles.input} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="bic"
                                label={t("bic")}
                                hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                            >
                                <Input data-cy="bic" className={styles.input} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="bank"
                                label={t("bankName")}
                                hasFeedback
                                rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                            >
                                <Input data-cy="bank" className={styles.input} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Spacer />

                    <Row className={styles.rowCenter} gutter={36}>
                        <Button data-cy="button_continue" type="primary" htmlType="submit">
                            {t("buttons:continue")}
                        </Button>
                    </Row>
                </Form>
            );
        }
    };

    const adminMerchantAction = async (type: string) => {
        if (type === "enable") {
            try {
                await adminEnableMerchant(props.merchantInfo.merchant_id);
            } catch (err) {
                void message.error(err);
            }
        } else {
            try {
                await adminDisableMerchant(props.merchantInfo.merchant_id);
            } catch (err) {
                void message.error(err);
            }
        }
        props.refreshData();
    };
    const renderAddress = () => {
        return (
            <Text style={{ display: "inline-block", verticalAlign: "top" }}>
                <Text>
                    {props.merchantInfo.address_street}, {props.merchantInfo.address_house_number}
                </Text>
                <br />
                <Text>
                    {props.merchantInfo.address_post_code},{props.merchantInfo.address_city}
                </Text>
                <br />
                <Text>{t(props.merchantInfo.address_country_code)}</Text>
                <br />
            </Text>
        );
    };

    const getModalTitle = () => {
        if (currentForm === "contact") {
            return "Kontaktinformationen";
        } else if (currentForm === "bank") {
            return "Bankinformationen";
        }
        return "Kundeninformationen";
    };
    const renderContacts = () => {
        if (!props.merchantInfo?.contacts?.length) return null;
        return props.merchantInfo.contacts.map((contact, idx) => {
            return (
                <Col span={12}>
                    <Card
                        title={
                            <Typography.Title className={styles.clientTitle} level={5}>
                                Kontaktperson {idx + 1}
                            </Typography.Title>
                        }
                        extra={
                            <Row style={{ cursor: "pointer" }} align="middle">
                                <EditOutlined />
                                &nbsp;
                                <div
                                    onClick={() => {
                                        if (props.isSummary) {
                                            props.setStep(CreateEditMerchantModalSteps.ContactPersonInfo);
                                        }
                                    }}
                                >
                                    {t("buttons:adjust")}
                                </div>
                            </Row>
                        }
                    >
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={2}>
                                <UserOutlined />
                            </Col>
                            <Col span={22}>
                                <span>
                                    {contact.title} {contact.first_name} {contact.last_name}
                                </span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={2}>
                                <PhoneOutlined />
                            </Col>
                            <Col span={22}>
                                <span>{contact.telephone_number}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={2}>
                                <MobileOutlined />
                            </Col>
                            <Col span={22}>
                                <span> - </span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={2}>
                                <MailOutlined />
                            </Col>
                            <Col span={22}>
                                <span>{contact.email}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={2}>
                                <ApartmentOutlined />
                            </Col>
                            <Col span={22}>
                                <span> - </span>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            );
        });
    };

    const renderContactInfo = () => {
        return (
            <Col span={12}>
                <Card
                    title={
                        <Typography.Title className={styles.clientTitle} level={5}>
                            Kontaktinformationen
                        </Typography.Title>
                    }
                    extra={
                        <Row style={{ cursor: "pointer" }} align="middle">
                            <EditOutlined />
                            &nbsp;
                            <div
                                onClick={() => {
                                    if (props.isSummary) {
                                        props.setStep(CreateEditMerchantModalSteps.MerchantInfo);
                                    } else {
                                        setModalOpen(true);
                                        setCurrentForm("contact");
                                    }
                                }}
                            >
                                {t("buttons:adjust")}
                            </div>
                        </Row>
                    }
                >
                    <Row gutter={12} justify={"space-between"}>
                        <Col span={8}>
                            <span className={styles.faintText}>Telefonnummer : </span>
                        </Col>
                        <Col span={16}>
                            <span>{props.merchantInfo.general_phone}</span>
                        </Col>
                    </Row>

                    <Row gutter={12} justify={"space-between"}>
                        <Col span={8}>
                            <span className={styles.faintText}>Website : </span>
                        </Col>
                        <Col span={16}>
                            <span>{props.merchantInfo.website_url}</span>
                        </Col>
                    </Row>

                    <Row gutter={12} justify={"space-between"}>
                        <Col span={8}>
                            <span className={styles.faintText}>E-mail Adresse : </span>
                        </Col>
                        <Col span={16}>
                            <span>{props.merchantInfo.general_email}</span>
                        </Col>
                    </Row>
                    <Row gutter={12} justify={"space-between"}>
                        <Col span={8}>
                            <span className={styles.faintText}>Adresse : </span>
                        </Col>
                        <Col span={16}>{renderAddress()}</Col>
                    </Row>
                </Card>
            </Col>
        );
    };

    const renderBankInfo = () => {
        return (
            <Row gutter={12} justify={"space-between"}>
                <Col span={8}>
                    <span className={styles.faintText}>IBAN : </span>
                </Col>
                <Col span={16}>
                    <span>{props.merchantInfo.iban}</span>
                </Col>

                <Col span={8}>
                    <span className={styles.faintText}>BIC : </span>
                </Col>
                <Col span={16}>
                    <span>{props.merchantInfo.bic}</span>
                </Col>

                <Col span={8}>
                    <span className={styles.faintText}>Bank Name : </span>
                </Col>
                <Col span={16}>
                    <span>{props.merchantInfo.bank_name}</span>
                </Col>
            </Row>
        );
    };
    return (
        <>
            <Row gutter={24} align="stretch">
                <Col span={12}>
                    <Card
                        title={
                            <Typography.Title className={styles.clientTitle} level={5}>
                                Kundeninformationen
                            </Typography.Title>
                        }
                        extra={
                            <Row style={{ cursor: "pointer" }} align="middle">
                                <EditOutlined />
                                &nbsp;
                                <div
                                    onClick={() => {
                                        if (props.isSummary) {
                                            props.setStep(CreateEditMerchantModalSteps.MerchantInfo);
                                        } else {
                                            setModalOpen(true);
                                            setCurrentForm("user");
                                        }
                                    }}
                                >
                                    {t("buttons:adjust")}
                                </div>
                            </Row>
                        }
                    >
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Händler ID : </span>
                            </Col>
                            <Col span={16}>
                                <span>{props.merchantInfo.short_id}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Estrellt am: </span>
                            </Col>
                            <Col span={16}>
                                <span>
                                    {props.merchantInfo.created_at
                                        ? props.merchantInfo.created_at.split("T")[0]
                                        : " - "}
                                </span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Firmenname: </span>
                            </Col>
                            <Col span={16}>
                                <span>{props.merchantInfo.name}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>HR Nummer: </span>
                            </Col>
                            <Col span={16}>
                                <span>{props.merchantInfo.registration_number}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Gründungsdatum: </span>
                            </Col>
                            <Col span={16}>
                                <span>{props.merchantInfo.founding_date}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Umsatzsteuer No: </span>
                            </Col>
                            <Col span={16}>
                                <span>{props.merchantInfo.tax_id}</span>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                {!props.isSummary ? (
                    <Col span={12}>
                        <Card
                            style={{ height: "100%" }}
                            title={
                                <Typography.Title className={styles.clientTitle} level={5}>
                                    Aktionen
                                </Typography.Title>
                            }
                        >
                            <Row gutter={24} justify={"start"}>
                                {props.merchantInfo?.status !== "active" ? (
                                    <Col span={12}>
                                        <Popconfirm
                                            title={t("confirmUnlockAccount")}
                                            onConfirm={() => {
                                                adminMerchantAction("enable");
                                            }}
                                            okText={t("buttons:yes")}
                                            cancelText={t("buttons:no")}
                                        >
                                            <Button type="primary">Enable Händler</Button>
                                        </Popconfirm>
                                    </Col>
                                ) : (
                                    <Col span={12}>
                                        <Popconfirm
                                            title={t("confirmLockAccount")}
                                            onConfirm={() => {
                                                adminMerchantAction("disable");
                                                adminDisableMerchant(props.merchantInfo.merchant_id);
                                            }}
                                            okText={t("buttons:yes")}
                                            cancelText={t("buttons:no")}
                                        >
                                            <Button style={{ background: "#FF4D4F" }}>Händler Sperren </Button>
                                        </Popconfirm>
                                    </Col>
                                )}
                            </Row>
                        </Card>
                    </Col>
                ) : (
                    renderContactInfo()
                )}
            </Row>
            <Spacer />

            {!props.isSummary && (
                <Row gutter={24} align="stretch">
                    {renderContactInfo()}

                    <Col span={12}>
                        <Card
                            title={
                                <Typography.Title className={styles.clientTitle} level={5}>
                                    Bankinformationen
                                </Typography.Title>
                            }
                            extra={
                                <Row style={{ cursor: "pointer" }} align="middle">
                                    <EditOutlined />
                                    &nbsp;
                                    <div
                                        onClick={() => {
                                            setModalOpen(true);
                                            setCurrentForm("bank");
                                        }}
                                    >
                                        {t("buttons:adjust")}
                                    </div>
                                </Row>
                            }
                        >
                            {renderBankInfo()}
                        </Card>
                    </Col>
                </Row>
            )}

            <Spacer />
            <Row gutter={24}>{renderContacts()}</Row>
            {props.isSummary && (
                <>
                    <Spacer />
                    <Card
                        title={
                            <Typography.Title className={styles.clientTitle} level={5}>
                                Bankinformationen
                            </Typography.Title>
                        }
                        extra={
                            <Row style={{ cursor: "pointer" }} align="middle">
                                <EditOutlined />
                                &nbsp;
                                <div
                                    onClick={() => {
                                        if (props.isSummary) {
                                            props.setStep(CreateEditMerchantModalSteps.MerchantInfo);
                                        }
                                    }}
                                >
                                    {t("buttons:adjust")}
                                </div>
                            </Row>
                        }
                    >
                        {renderBankInfo()}
                    </Card>
                </>
            )}

            <ConfirmModalClose
                visible={secondaryModalOpen}
                onCancel={secondaryModalOnCancel}
                onOk={secondaryModalOnOk}
            />

            <Modal
                width={1000}
                className={styles.modal}
                centered
                visible={modalOpen}
                destroyOnClose={true}
                maskClosable
                title={getModalTitle()}
                okText={t("buttons:ok")}
                cancelText={t("buttons:cancel")}
                onCancel={(e: any) => {
                    if (!checkForm()) {
                        setModalOpen(false);
                    } else {
                        setSecondaryModalOpen(true);
                    }
                }}
                footer={null}
            >
                {renderForm()}
            </Modal>

            {/* <CreateEditMerchantStepOne data={state} onSubmit={handleStepOneSubmitted}/>; */}
        </>
    );
};

export default MerchantTab;
