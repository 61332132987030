export class LocalToggleState {
    private readonly LOCAL_STORAGE_KEY: string = "toggleState";

    public constructor() {
        this.init();
    }
    public get() {
        try {
            const localData = localStorage.getItem(this.LOCAL_STORAGE_KEY);
            return JSON.parse(localData);
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    public update(data): void {
        try {
            localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(data));
        } catch (error) {
            console.error(error);
        }
    }

    public reset(): void {
        try {
            localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(this.getInitialStorageState()));
        } catch (error) {
            console.error(error);
        }
    }

    public getInitialStorageState() {
        return {
            isToggled:window.innerWidth <= 1300
           
        };
    }


    private init(): void {
        try {
            const localDataInitalized = this.getInitialStorageState();

            const localData = localStorage.getItem(this.LOCAL_STORAGE_KEY);

            if (localData == null) {
                localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(localDataInitalized));
            }
        } catch (error) {
            console.error(error);
        }
    }
}
