import * as React from "react";
import {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import styles from "components/common/pages/accept-invitaion-page/AcceptInvitationPage.module.scss";
import { Alert, Button, Card, Checkbox, Form, Input, Row, Spin, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {goTo} from "../../../../navigation/navigationHelpers";
import {Spacer} from "components/common/presenters/spacer/Spacer";
import {ApplicationProcessHeader} from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import {isValidPassword} from "../../../../helpers/inputValidationHelpers";
import { RouteStrings } from "routes/RouteStrings";
import { adminCheckRegisterTokenValid, adminRegisterAccount } from "api/admin/admin";
import { merchantCheckRegisterTokenValid, merchantRegisterAccount } from "api/admin/merchants";
import { isAdminApp, isClientApp, isMerchantApp } from "helpers/appHelpers";
import { clientCheckRegisterTokenValid, clientAcceptInvitation } from "api/client";
import { CheckCircleOutlined } from "@ant-design/icons";

export interface RegisterPageProps {

}

export const AcceptInvitationPage = (props: RegisterPageProps) => {
    const {t} = useTranslation();
    const {registerToken} = useParams<any>();
    const [form] = Form.useForm();

    const [isTokenValid, setIsTokenValid] = useState(null);
    const [registerLoading, setRegisterLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        checkTokenValidity();
    }, [registerToken]);

    const onFormFinish = async (values: any) => {
        try {
            setRegisterLoading(true);

            if (isAdminApp()) {
                await adminRegisterAccount(registerToken, values.password);
            }

            if (isMerchantApp()) {
                await merchantRegisterAccount(registerToken, values.password);
            }

            if (isClientApp()) {
                await clientAcceptInvitation(registerToken, values.password);
            }

            setSuccess(true);
        } catch (e: any) {
            if (e.response?.status === 401) {
                setIsTokenValid(false);
                return;
            }
        } finally {
            setRegisterLoading(false);
        }
    };

    const checkTokenValidity = async () => {
        try {
            if (isAdminApp()) {
                await adminCheckRegisterTokenValid(registerToken);
            }

            if (isMerchantApp()) {
                await merchantCheckRegisterTokenValid(registerToken);
            }

            if (isClientApp()) {
                await clientCheckRegisterTokenValid(registerToken);
            }

            setIsTokenValid(true);
        } catch (e: any) {
            if (e.response?.status === 401) {
                setIsTokenValid(false);
            }
        }
    };

    const renderLoading = () => {
        return (
            <Spin size={"large"} delay={200}/>
        );
    };

    const renderSetPassword = () => {
        return (
            <div className={styles.formContainer}>
                <h2> {t('sectionTitles:createPassword2')}</h2>
                <h4>{t('createPasswordInfo2')}</h4>

                <Spacer/>
                <Spacer/>

                <Form form={form}
                    className={styles.formCreatePassword}
                    name={"finishRegister"}
                    onFinish={onFormFinish}
                    layout={"vertical"}
                    scrollToFirstError
                >
                    <Form.Item
                        label={t('newPassword')}
                        name="password"
                        rules={[({getFieldValue}) => ({
                            validator(_, value) {
                                if (value && !isValidPassword(value)) {
                                    return Promise.reject(t('errors:passwordInvalid'));
                                }
                                return Promise.resolve();
                            },
                        })]}
                    >
                        <Input.Password data-cy={"input_password"}/>
                    </Form.Item>

                    <Form.Item
                        label={t('passwordRepeat')}
                        name="password_repeat"
                        dependencies={['password']}
                        rules={[
                            {
                                required: true,
                                message: t("errors:confirmPassword"),
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(t("errors:passwordsDoNotMatch"));
                                },
                            }),
                        ]}
                    >
                        <Input.Password data-cy={"input_password_repeat"}/>
                    </Form.Item>

                    <Spacer />

                    <Form.Item name="tc_accepted" valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(t("errors:acceptAGB")),
                            },
                        ]}>
                        <Checkbox>
                            Ich akzeptiere die <a className="link-red"
                                href=""
                                target="_blank" rel="noopener noreferrer">Allgemeinen
                            Geschäftsbedingungen</a> und <a className="link-red"
                                href=""
                                target="_blank" rel="noopener noreferrer">Datenschutzbedingungen</a>
                        </Checkbox>
                    </Form.Item>
                                            
                    <Spacer hx={2}/>

                    <Form.Item>
                        <Row className={styles.formButtonRow}>
                            <Button type="primary"
                                htmlType="submit"
                                loading={registerLoading}
                            >
                                {t('buttons:register2')}
                            </Button>
                        </Row>
                    </Form.Item>
                </Form>
            </div>
        );
    };

    const renderTokenInvalid = () => {
        return (
            <Alert type="warning"
                showIcon
                message={t('errors:invalidToken')}
                description={t('errors:invalidTokenDetails')}
            />
        );
    };

    const renderSuccess = () => {
        return (
            <>
                <Card bordered={false}>
                    <div className={styles.container}>
                        <Typography.Title level={2}>{t("messages:registrationSuccess2")}</Typography.Title>
                        <CheckCircleOutlined className={styles.success} />
                        <Spacer />
                        <Typography.Paragraph>
                            Der Händler wird Sie durch den Rest des Antragsprozesses führen. Sie können das Fenster
                            schliessen.
                        </Typography.Paragraph>
                    </div>

                    <Spacer />
                    <Row className={styles.rowCenter}>
                        <Button
                            type={"primary"}
                            onClick={() => {
                                let loginRoute;
                                if (isClientApp()) {
                                    loginRoute = RouteStrings.Login;
                                } else {
                                    loginRoute = isAdminApp() ? RouteStrings.AdminLogin : RouteStrings.Login;
                                }
                                goTo(loginRoute);
                            }}
                        >
                            {t("buttons:toLogin")}
                        </Button>
                    </Row>
                </Card>
            </>
        );
    };

    const renderContent = () => {
        switch (isTokenValid) {
        case true:
            return success ? renderSuccess() :
                renderSetPassword();
        case false:
            return renderTokenInvalid();
        case null:
        default:
            return renderLoading();
        }
    };

    return (
        <div className={styles.page}>
            <ApplicationProcessHeader hideNav={true} loginPage/>
            <div className={styles.info}>
                {
                    renderContent()
                }
            </div>
        </div>
    );
};
