import { ArrowLeftOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    message,
    Radio,
    RadioChangeEvent,
    Row,
    Select,
    Spin,
    Typography,
} from "antd";
import { DATE_FORMAT } from "api/apiConfig";
import { clientUpdateProfile } from "api/client";
import { AxiosError } from "axios";
import styles from "components/common/pages/application-flow/ApplicationFlow.module.scss";
import { AnchoredSelect } from "components/common/presenters/anchored-select/AnchoredSelect";
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { BackButton } from "components/common/presenters/back-button/BackButton";
import { CustomPageHeader } from "components/common/presenters/custom-page-header/CustomPageHeader";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { isAdminApp, isClientApp, isMerchantApp } from "helpers/appHelpers";
import { checkAuth, checkAuthMerchant } from "helpers/authHelpers";
// import { checkAuth, checkAuthMerchant } from 'helpers/authHelpers';
import hashHistory from "helpers/hashHistory";
import history from "helpers/history";
import { pageLoad } from "helpers/pageLoad";
import { preventStepSkip } from "helpers/preventStepSkip";
import moment from "moment";
// import { goTo } from 'navigation/navigationHelpers';
import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteStrings } from "routes/RouteStrings";
import { setLastSuccessPage } from "storage/actions/appActions";
import { AppContext } from "storage/context/appContext";
import { LocalApplicationFlowDataManager } from "storage/LocalApplicationFlowDataManager";
import {
    FlowStepThreeFormData,
    IdDocTypes,
    IdentificationInfo,
    OccupationGroupList,
    ResidenceTypesList,
    WorkBranchesList,
} from "types/applicationProcess";
import OrderSummary from "../summary/OrderSummary";
import { clientUpdateIdentificationData } from "../../../../../api/client";
import useMobileCheck from "helpers/useMobileCheck";
import { currencyLocaleFormatter } from "helpers/formatters";
import { isIbanValid } from "validators/iban-validators";

export const ApplicationStepThreePage = () => {
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const [idForm] = Form.useForm();
    const [loading, setLoading] = React.useState(false);
    const [checkingLoggedIn, setCheckingLoggedIn] = React.useState(false);
    const [generalError, setGeneralError] = React.useState("");
    const [{ lastSuccessPage }, dispatch] = React.useContext(AppContext);
    const [isIdentPage, setIsIdentPage] = React.useState<boolean>(false);

    const [enableCurrency, setEnableCurrency] = React.useState<boolean>(false);
    const editableRef = React.useRef(null);

    const [enableCost, setEnableCost] = React.useState<boolean>(false);
    const costRef = React.useRef(null);

    const [showPrevEmployerData, setShowPrevEmployerData] = React.useState<boolean>(false);

    const isMobile = useMobileCheck();

    // using 2 form inputs for income - this makes sure once the editable one is visible its also focused
    React.useEffect(() => {
        if (enableCurrency) {
            editableRef.current.focus();
        }
    }, [enableCurrency]);

    React.useEffect(() => {
        if (enableCost) {
            costRef.current.focus();
        }
    }, [enableCost]);


    const convertNumber = (num, locale) => {
        const { format } = new Intl.NumberFormat(locale);
        const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
        return +num.replace(new RegExp(`[^${decimalSign}\\d]`, "g"), "").replace(decimalSign, ".");
    };

    const [state, setState] = React.useState({
        occupation: "",
        branche: "",
        occupation_group: "",
        employed_since: "",
        employer: "",
        employer_location: "",
        prev_employed_since: "",
        prev_employer_name: "",
        net_income: "",
        residence_type: "",
        residence_monthly_cost: "",
        number_of_children: "",
        has_a_vehicle: null,
        id_doc_type: "",
        id_doc_issued_place: "",
        id_doc_number: "",
        id_doc_issued_at: "",
        id_doc_expires_at: "",
        iban: "",
        bank_name: "",
        bic: "",
        mobile_number: "",
    });

    const applDataManager = new LocalApplicationFlowDataManager();
    const appl = applDataManager.get();

    React.useEffect(() => {
        if (isClientApp() || isAdminApp()) {
            checkAuth(setCheckingLoggedIn, null, () => {
                hashHistory.push(RouteStrings.ApplicationFlowStepOne);
            });
        } else {
            checkAuthMerchant(setCheckingLoggedIn, null, () => {
                history.push(RouteStrings.Login);
            });
        }

        pageLoad(dispatch, RouteStrings.ApplicationFlowStepThree);

        preventStepSkip(lastSuccessPage, hashHistory);
    }, []);

    const persistData = (values: FlowStepThreeFormData) => {
        const applDataManager = new LocalApplicationFlowDataManager();

        const appl = applDataManager.get();

        appl.job_info.profession = values.profession;
        appl.job_info.branche = values.branche;
        appl.job_info.occupation_group = values.occupation_group;
        appl.job_info.employer = values.employer;
        appl.job_info.prev_employer_name = values.prev_employer_name;
        appl.job_info.employer_location = values.employer_location;
        appl.job_info.net_income = String(convertNumber(values.net_incomeVisible, "DE-de")) || state.net_income;
        appl.job_info.employed_since = values.employed_since || state.employed_since;
        appl.job_info.prev_employed_since = values.prev_employed_since || state.prev_employed_since;
        appl.household_info.residence_type = values.residence_type;
        appl.household_info.residence_monthly_cost =
            String(convertNumber(values.residence_monthly_costVisible, "DE-de")) || state.residence_monthly_cost;
        appl.household_info.number_of_children = values.number_of_children;
        appl.household_info.has_vehicle = values.has_vehicle;

        applDataManager.update(appl);
    };

    const handleContinue = async (values: any) => {
        if (isMerchantApp()) {
            dispatch(setLastSuccessPage(RouteStrings.ApplicationFlowStepThree));
            hashHistory.push(RouteStrings.ApplicationFlowStepConfirmation);

            return;
        }

        if (loading) {
            return;
        }

        setLoading(true);

        const applDataManager = new LocalApplicationFlowDataManager();

        const appl = applDataManager.get();
        try {
            // Note that the data fields have been renamed to the ones that the backend expects!
            const data = {
                title: appl.personal_info.salutation,
                first_name: appl.personal_info.first_name,
                last_name: appl.personal_info.last_name,
                family_status: appl.personal_info.marital_status,
                birth_date: appl.personal_info.date_of_birth.split("T")[0],
                birth_city: appl.personal_info.place_of_birth,
                nationality_country_code: appl.personal_info.nationality.toLowerCase(),
                address_country_code: appl.address_info.country.toLowerCase(),
                address_street: appl.address_info.street_name,
                address_house_number: appl.address_info.street_number,
                address_post_code: appl.address_info.post_code,
                address_city: appl.address_info.city,
                address_resident_since: appl.address_info.resident_since.split("T")[0],
                prev_address_country_code: appl.address_info.previous_address.country || null,
                prev_address_street: appl.address_info.previous_address.street_name || null,
                prev_address_house_number: appl.address_info.previous_address.street_number || null,
                prev_address_post_code: appl.address_info.previous_address.post_code || null,
                prev_address_city: appl.address_info.previous_address.city || null,
                job_title: appl.job_info.profession,
                job_branch: appl.job_info.branche,
                job_type: appl.job_info.occupation_group,
                employer_name: appl.job_info.employer,
                prev_employer_name: appl.job_info.prev_employer_name,
                employer_location: appl.job_info.employer_location,
                type_of_residence: appl.household_info.residence_type,
                has_vehicle: state.has_a_vehicle,
                // net_income: roundTo2DecimalPlaces(parseFloat(values.net_income)),
                // living_costs: roundTo2DecimalPlaces(parseFloat(appl.household_info.residence_monthly_cost)),
                net_income: Number(appl.job_info.net_income || state.net_income),
                living_costs: Number(appl.household_info.residence_monthly_cost || state.residence_monthly_cost),
                num_children: parseInt(values.number_of_children),
                employed_since: appl.job_info.employed_since.split("T")[0],
                prev_employed_since: appl.job_info.prev_employed_since.split("T")[0],
            };

            await clientUpdateProfile(data);

            // gets to IDENTIFICATION screen
            setIsIdentPage(true);
        } catch (error) {
            console.error(error);

            if ((error as AxiosError).response?.data === "ErrApplicationDemoRejected") {
                // appl.status = ApplicationStates.Rejected;
                // applDataManager.update(appl);

                // goTo(RouteStrings.ApplicationFlowStepResult);

                return;
            } else {
                setGeneralError(t("errors:generalError"));
            }
        }

        setLoading(false);
    };

    const persistIdData = (values: IdentificationInfo) => {
        const applDataManager = new LocalApplicationFlowDataManager();

        const appl = applDataManager.get();

        appl.id_info.id_doc_type = values.id_doc_type;
        appl.id_info.id_doc_issued_place = values.id_doc_issued_place;
        appl.id_info.id_doc_number = values.id_doc_number;
        appl.id_info.id_doc_issued_at = values.id_doc_issued_at;
        appl.id_info.id_doc_expires_at = values.id_doc_expires_at;
        appl.id_info.iban = values.iban;
        appl.id_info.bank_name = values.bank_name;
        appl.id_info.bic = values.bic;
        appl.id_info.mobile_number = values.mobile_number;

        applDataManager.update(appl);
    };

    const handleOnFinish = async (values: any) => {
        setGeneralError("");

        persistData(values);

        await handleContinue(values);
    };

    const handleIdentFinish = async (values: any) => {
        // persist id values
        persistIdData(values);
        const applDataManager = new LocalApplicationFlowDataManager();
        const appl = applDataManager.get();

        const idData = {
            id_doc_type: appl.id_info.id_doc_type,
            id_doc_issued_place: appl.id_info.id_doc_issued_place,
            id_doc_number: appl.id_info.id_doc_number,
            id_doc_issued_at: appl.id_info.id_doc_issued_at ? appl.id_info.id_doc_issued_at.split("T")[0] : null,
            id_doc_expires_at: appl.id_info.id_doc_expires_at ? appl.id_info.id_doc_expires_at.split("T")[0] : null,
            iban: appl.id_info.iban,
            bank_name: appl.id_info.bank_name,
            bic: appl.id_info.bic,
            mobile_number: appl.id_info.mobile_number,
        };
        try {
            const idReq = await clientUpdateIdentificationData(idData);
            if (idReq.status === 200) {
                dispatch(setLastSuccessPage(RouteStrings.ApplicationFlowStepThree));
                hashHistory.push(RouteStrings.ApplicationFlowStepConfirmation);
            }
        } catch (err) {
            void message.error(t("errors:generalError"));
        }
    };

    React.useEffect(() => {
        const applDataManager = new LocalApplicationFlowDataManager();
        const appl = applDataManager.get();

        if (appl == null) {
            return;
        }

        form.setFieldsValue({
            profession: appl.job_info.profession,
            branche: appl.job_info.branche,
            occupation_group: appl.job_info.occupation_group,
            employer: appl.job_info.employer,
            prev_employer_name: appl.job_info.prev_employer_name,
            employer_location: appl.job_info.employer_location,
            residence_type: appl.household_info.residence_type,
            number_of_children: appl.household_info.number_of_children,
            has_vehicle: appl.household_info.has_vehicle,
        });
        appl.job_info.employed_since &&
            form.setFieldsValue({
                employed_since: moment(appl.job_info.employed_since),
            });
        appl.job_info.prev_employed_since &&
        form.setFieldsValue({
            prev_employed_since: moment(appl.job_info.prev_employed_since),
        });

        idForm.setFieldsValue({
            id_doc_type: appl.id_info.id_doc_type,
            id_doc_issued_place: appl.id_info.id_doc_issued_place,
            id_doc_number: appl.id_info.id_doc_number,
            iban: appl.id_info.iban,
            bank_name: appl.id_info.bank_name,
            bic: appl.id_info.bic,
            mobile_number: appl.id_info.mobile_number,
        });
        appl.id_info.id_doc_issued_at &&
            idForm.setFieldsValue({
                id_doc_issued_at: moment(appl.id_info.id_doc_issued_at),
            });
        appl.id_info.id_doc_expires_at &&
            idForm.setFieldsValue({
                id_doc_expires_at: moment(appl.id_info.id_doc_expires_at),
            });
    }, []);

    React.useEffect(() => {
        const date = moment(form.getFieldValue("employed_since"));
        const now = moment();

        if (now.diff(date, "months") <= 6) {
            setShowPrevEmployerData(true);
        } else {
            setShowPrevEmployerData(false);
        }
    }, [form.getFieldValue("employed_since")]);

    // If the user puts a date for their current address that is newer than 2 years, they also need to enter their previous address.
    // The date entry field for the user input is only year and month, however, we save a full date with the first date of the month (i.e. I put 12.1992 and we save 01.12.1992 in the DB), as we may need this for some of the APIs
    // The fields for the previous address are automatically shown / hidden depending on the date difference.

    const renderPreviousEmployerData = () => {
        return (
            <React.Fragment>
                <Spacer hx={2}/>

                <h4 className={styles.bold}>{t("applProcess:subtitleWorkData2StepThree")}</h4>

                <Row gutter={24}>
                    <Col span={isMobile ? 24 : 12}>
                        <Form.Item
                            name="prev_employed_since"
                            label={t("prev_employed_since")}
                            rules={[
                                {
                                    required: true,
                                    message: t("errors:invalidData"),
                                },
                            ]}
                        >
                            <DatePicker
                                className={styles.datePicker}
                                format={DATE_FORMAT.DE}
                                allowClear={true}
                                disabledDate={(current) => {
                                    let customDate = moment("01-01-1980").format("YYYY-MM-DD");
                                    return current && current < moment(customDate, "YYYY.MM.DD");
                                }}
                                onChange={(value) => {
                                    setState({
                                        ...state,
                                        prev_employed_since: moment(value).format(
                                            DATE_FORMAT.API
                                        ),
                                    });
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={isMobile ? 24 : 12}>
                        <Form.Item
                            name="prev_employer_name"
                            label={t("prev_employer")}
                            rules={[
                                {
                                    required: true,
                                    message: t("errors:invalidData"),
                                    min: 1,
                                    max: 100,
                                },
                            ]}
                        >
                            <Input
                                data-cy="prev_employer_name"
                                className={styles.input}
                                placeholder={`${t("example")} Muster AG`}
                                onChange={(e) =>
                                    setState({
                                        ...state,
                                        prev_employer_name: e.target.value,
                                    })
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    return (
        <div className={styles.page}>
            <ApplicationProcessHeader checkLoggedInStatus />
            <CustomPageHeader small={isMobile} />
            <div className={styles.container}>
                {checkingLoggedIn ? (
                    <Spin
                        className={styles.spin}
                        indicator={<Loading3QuartersOutlined style={{ fontSize: 34 }} spin />}
                    />
                ) : !isIdentPage ? (
                    <React.Fragment>
                        <Row gutter={24} align={"top"}>
                            <Col span={isMobile ? 24 : 16}>
                                <Card>
                                    <div className={styles.innerContainer}>
                                        <BackButton
                                            route={RouteStrings.ApplicationFlowStepTwo}
                                            hashHistory={hashHistory}
                                        />

                                        <Spacer hx={2} />
                                        <h2 className={styles.processTitle}>{t("applProcess:titleStepThree")}</h2>

                                        <Form
                                            layout={"vertical"}
                                            form={form}
                                            onFinish={handleOnFinish}
                                            scrollToFirstError
                                        >
                                            <h4 className={styles.bold}>
                                                {t("applProcess:subtitleWorkDataStepThree")}
                                            </h4>

                                            <Form.Item
                                                name="profession"
                                                label={t("occupation")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 1,
                                                        max: 100,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    data-cy="profession"
                                                    className={styles.input}
                                                    placeholder={`${t("example")} Sacharbeiter`}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            occupation: e.target.value,
                                                        })
                                                    }
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="branche"
                                                label={t("occupationBranche")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 1,
                                                        max: 100,
                                                    },
                                                ]}
                                            >
                                                <AnchoredSelect
                                                    data-cy="branche"
                                                    className={styles.select}
                                                    allowClear
                                                    placeholder={t("pleaseSelectOption")}
                                                >
                                                    {WorkBranchesList.map((val, i) => {
                                                        return (
                                                            <Select.Option
                                                                data-cy={`address_country_option_${i}`}
                                                                key={i}
                                                                value={val}
                                                            >
                                                                {t(`workBranches:${val}`)}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </AnchoredSelect>
                                            </Form.Item>

                                            <Form.Item
                                                name="occupation_group"
                                                label={t("occupationGroup")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 1,
                                                        max: 100,
                                                    },
                                                ]}
                                            >
                                                <AnchoredSelect
                                                    data-cy="occupation_group"
                                                    className={styles.select}
                                                    allowClear
                                                    placeholder={t("pleaseSelectOption")}
                                                >
                                                    {OccupationGroupList.map((val, i) => {
                                                        return (
                                                            <Select.Option
                                                                data-cy={`address_country_option_${i}`}
                                                                key={i}
                                                                value={val}
                                                            >
                                                                {t(`occupationGroups:${val}`)}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </AnchoredSelect>
                                            </Form.Item>

                                            <Row gutter={24}>
                                                <Col span={isMobile ? 24 : 12}>
                                                    <Form.Item
                                                        name="employed_since"
                                                        label={t("employedSince")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t("errors:invalidData"),
                                                            },
                                                        ]}
                                                    >
                                                        <DatePicker
                                                            className={styles.datePicker}
                                                            format={DATE_FORMAT.DE}
                                                            allowClear={true}
                                                            onChange={(value) => {
                                                                setState({
                                                                    ...state,
                                                                    employed_since: moment(value).format(
                                                                        DATE_FORMAT.API
                                                                    ),
                                                                });
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={isMobile ? 24 : 12}>
                                                    <Form.Item
                                                        name="employer"
                                                        label={t("employer")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t("errors:invalidData"),
                                                                min: 1,
                                                                max: 100,
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            data-cy="employer"
                                                            className={styles.input}
                                                            placeholder={`${t("example")} Muster AG`}
                                                            onChange={(e) =>
                                                                setState({
                                                                    ...state,
                                                                    employer: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={24} align="middle">
                                                <Col span={isMobile ? 24 : 12}>
                                                    <Form.Item
                                                        name="employer_location"
                                                        label={t("employerLocation")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t("errors:invalidData"),
                                                                min: 1,
                                                                max: 100,
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            data-cy="employer_location"
                                                            className={styles.input}
                                                            placeholder={`${t("example")} Deutschland`}
                                                            onChange={(e) =>
                                                                setState({
                                                                    ...state,
                                                                    employer_location: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={isMobile ? 24 : 12}>
                                                    {!enableCurrency && (
                                                        <Form.Item
                                                            name="net_incomeVisible"
                                                            label={t("nettoIncome")}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t("errors:invalidData"),
                                                                },
                                                            ]}
                                                            initialValue={
                                                                "€ " + currencyLocaleFormatter(appl.job_info.net_income)
                                                            }
                                                        >
                                                            <Input
                                                                data-cy="net_income"
                                                                className={styles.input}
                                                                placeholder={`${t("example")} 1900`}
                                                                onFocus={() => {
                                                                    setEnableCurrency(true);
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    )}
                                                    {enableCurrency && (
                                                        <Form.Item
                                                            name="net_income"
                                                            label={t("nettoIncome")}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t("errors:invalidData"),
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                type="number"
                                                                ref={editableRef}
                                                                data-cy="net_income"
                                                                className={styles.input}
                                                                placeholder={`${t("example")} 1900`}
                                                                onChange={(e) => {
                                                                    setState({
                                                                        ...state,
                                                                        net_income: e.target.value,
                                                                    });
                                                                    form.setFieldsValue({
                                                                        net_incomeVisible: `${
                                                                            "€ " +
                                                                            currencyLocaleFormatter(e.target.value)
                                                                        }`,
                                                                    });
                                                                }}
                                                                onBlur={() => setEnableCurrency(false)}
                                                            />
                                                        </Form.Item>
                                                    )}
                                                </Col>
                                            </Row>

                                            {showPrevEmployerData && renderPreviousEmployerData()}

                                            <Spacer hx={2} />
                                            <h4 className={styles.bold}>
                                                {t("applProcess:subtitleHomeDataStepThree")}
                                            </h4>

                                            <Form.Item
                                                name="residence_type"
                                                label={t("residenceType")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 1,
                                                        max: 100,
                                                    },
                                                ]}
                                            >
                                                <AnchoredSelect
                                                    data-cy="residence_type"
                                                    className={styles.select}
                                                    allowClear
                                                >
                                                    {ResidenceTypesList.map((val, i) => {
                                                        return (
                                                            <Select.Option
                                                                data-cy={`residence_type_option_${i}`}
                                                                key={i}
                                                                value={val}
                                                            >
                                                                {t(`residenceTypes:${val}`)}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </AnchoredSelect>
                                            </Form.Item>

                                            <Row gutter={24}>
                                                <Col span={isMobile ? 24 : 12}>
                                                    {!enableCost && (
                                                        <Form.Item
                                                            name="residence_monthly_costVisible"
                                                            label={t("residenceMonthlyCost")}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t("errors:invalidData"),
                                                                    min: 1,
                                                                    max: 100,
                                                                },
                                                            ]}
                                                            initialValue={
                                                                "€ " +
                                                                currencyLocaleFormatter(
                                                                    appl.household_info.residence_monthly_cost
                                                                )
                                                            }
                                                        >
                                                            <Input
                                                                data-cy="residence_monthly_cost"
                                                                className={styles.input}
                                                                placeholder={`${t("example")} CHF 3,500`}
                                                                onFocus={() => {
                                                                    setEnableCost(true);
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    )}
                                                    {enableCost && (
                                                        <Form.Item
                                                            name="residence_monthly_cost"
                                                            label={t("residenceMonthlyCost")}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t("errors:invalidData"),
                                                                    min: 1,
                                                                    max: 100,
                                                                },
                                                            ]}
                                                        >
                                                            <Input
                                                                data-cy="residence_monthly_cost"
                                                                className={styles.input}
                                                                type="number"
                                                                placeholder={`${t("example")} CHF 3,500`}
                                                                ref={costRef}
                                                                onChange={(e) => {
                                                                    setState({
                                                                        ...state,
                                                                        residence_monthly_cost: e.target.value,
                                                                    });
                                                                    form.setFieldsValue({
                                                                        residence_monthly_costVisible: `${
                                                                            "€ " +
                                                                            currencyLocaleFormatter(e.target.value)
                                                                        }`,
                                                                    });
                                                                }}
                                                                onBlur={() => setEnableCost(false)}
                                                            />
                                                        </Form.Item>
                                                    )}
                                                </Col>

                                                <Col span={isMobile ? 24 : 12}>
                                                    <Form.Item
                                                        name="number_of_children"
                                                        label={t("numberOfChildren")}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: t("errors:invalidData"),
                                                            },
                                                            {
                                                                validator: (_, value) => {
                                                                    return +value % 1 === 0
                                                                        ? Promise.resolve()
                                                                        : Promise.reject(t("errors:invalidData"));
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            data-cy="number_of_children"
                                                            className={styles.input}
                                                            placeholder={`${t("example")} 2`}
                                                            min="0"
                                                            step="1"
                                                            type="number"
                                                            onChange={(e) =>
                                                                setState({
                                                                    ...state,
                                                                    number_of_children: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Form.Item
                                                name="has_a_vehicle"
                                                label={t("hasVehicle")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                    },
                                                ]}
                                            >
                                                <Radio.Group
                                                    data-cy="has_vehicle"
                                                    onChange={(e: RadioChangeEvent) =>
                                                        setState({
                                                            ...state,
                                                            has_a_vehicle: e.target.value,
                                                        })
                                                    }
                                                >
                                                    <Radio value={true}>{t("yes")}</Radio>
                                                    <Radio value={false}>{t("no")}</Radio>
                                                </Radio.Group>
                                            </Form.Item>

                                            <Spacer />

                                            {generalError ? (
                                                <>
                                                    <div className={styles.error}>{generalError}</div>
                                                    <Spacer />
                                                </>
                                            ) : null}

                                            <Spacer />
                                            <Spacer />
                                            <Spacer />

                                            <Form.Item>
                                                <Row className={styles.rowCenterX}>
                                                    <Button data-cy="button_continue" type="primary" htmlType="submit">
                                                        {t("buttons:submit")}
                                                    </Button>
                                                </Row>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={isMobile ? 24 : 8}>
                                <OrderSummary draftData={appl.draft_info} />
                            </Col>
                        </Row>
                        <Spacer />
                        <Row justify="start" gutter={24}>
                            <Col span={isMobile ? 24 : 16} style={{ textAlign: "center" }}>
                                <a href={appl?.draft_info?.cancel_url}>
                                    Cancel and return to {appl?.draft_info?.merchant_name}
                                </a>
                            </Col>
                        </Row>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Card>
                            <div className={styles.identContainer}>
                                <div
                                    className={styles.backButton}
                                    onClick={() => {
                                        setIsIdentPage(false);
                                    }}
                                >
                                    <ArrowLeftOutlined /> {t("buttons:back")}
                                </div>

                                <Spacer />
                                <h2 className={styles.processTitle}>Identifizierungsdaten</h2>
                                <Spacer />
                                <Typography.Text>
                                    Um den Kauf abzuschliessen müssen Sie sich noch identifizieren. Dazu benötigt unsere
                                    Partnerbank folgende Angaben:
                                </Typography.Text>
                                <Spacer />
                                <Form layout={"vertical"} form={idForm} onFinish={handleIdentFinish} scrollToFirstError>
                                    <h4 className={styles.bold}>Ihr Ausweisdokument</h4>
                                    <Row gutter={24}>
                                        <Col span={isMobile ? 20 : 24}>
                                            <Form.Item
                                                name="id_doc_type"
                                                label={t("idDocType")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 1,
                                                        max: 100,
                                                    },
                                                ]}
                                            >
                                                <AnchoredSelect
                                                    data-cy="branche"
                                                    allowClear
                                                    placeholder={t("pleaseSelectOption")}
                                                >
                                                    {Object.values(IdDocTypes).map((val, i) => {
                                                        return (
                                                            <Select.Option
                                                                data-cy={`id_doc_type_option${i}`}
                                                                key={i}
                                                                value={val.toLowerCase()}
                                                            >
                                                                {t(`idDocTypes:${val}`)}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </AnchoredSelect>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={isMobile ? 20 : 12}>
                                            <Form.Item
                                                name="id_doc_issued_place"
                                                label={t("placeOfIssue")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 1,
                                                        max: 100,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    data-cy="place_of_issue"
                                                    className={styles.input}
                                                    placeholder={`${t("example")} Berlin`}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            id_doc_issued_place: e.target.value,
                                                        })
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={isMobile ? 20 : 12}>
                                            <Form.Item
                                                name="id_doc_number"
                                                label="Ausweis / Passnummer"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 1,
                                                        max: 100,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    data-cy="pass_number"
                                                    className={styles.input}
                                                    placeholder={`${t("example")} L7HMVW420`}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            id_doc_number: e.target.value,
                                                        })
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={24}>
                                        <Col span={isMobile ? 20 : 12}>
                                            <Form.Item
                                                name="id_doc_issued_at"
                                                label={t("clientIdIssuedAt")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                    },
                                                ]}
                                            >
                                                <DatePicker
                                                    className={styles.datePicker}
                                                    format={DATE_FORMAT.DE}
                                                    allowClear={true}
                                                    disabledDate={(current) => {
                                                        let customDate = moment().format("YYYY.MM.DD");
                                                        return current && current > moment(customDate, "YYYY.MM.DD");
                                                    }}
                                                    onChange={(value) => {
                                                        setState({
                                                            ...state,
                                                            id_doc_issued_at: moment(value).format(DATE_FORMAT.API),
                                                        });
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={isMobile ? 20 : 12}>
                                            <Form.Item
                                                name="id_doc_expires_at"
                                                label={t("clientIdExpiresAt")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                    },
                                                ]}
                                            >
                                                <DatePicker
                                                    className={styles.datePicker}
                                                    format={DATE_FORMAT.DE}
                                                    disabledDate={(current) => {
                                                        let customDate = moment().format("YYYY.MM.DD");
                                                        return current && current < moment(customDate, "YYYY.MM.DD");
                                                    }}
                                                    onChange={(value) =>
                                                        setState({
                                                            ...state,
                                                            id_doc_expires_at: moment(value).format(DATE_FORMAT.API),
                                                        })
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <h4 className={styles.bold}>Ihre Kontoinformationen</h4>
                                    <Row>
                                        <Col span={isMobile ? 20 : 24}>
                                            <Form.Item
                                                name="iban"
                                                label="IBAN"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 1,
                                                        max: 100,
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!isIbanValid(value)) {
                                                                return Promise.reject(t("errors:invalidData"));
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input
                                                    data-cy="iban"
                                                    className={styles.input}
                                                    placeholder={`${t("example")} DE12 1234 1234 1234 1234 1234`}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            iban: e.target.value,
                                                        })
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={isMobile ? 20 : 12}>
                                            <Form.Item
                                                name="bank_name"
                                                label="Bank Name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 1,
                                                        max: 100,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    data-cy="bank_name"
                                                    className={styles.input}
                                                    placeholder={`${t("example")} Santander Bank`}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            bank_name: e.target.value,
                                                        })
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={isMobile ? 20 : 12}>
                                            <Form.Item
                                                name="bic"
                                                label="BIC"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 1,
                                                        max: 100,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    data-cy="bic"
                                                    className={styles.input}
                                                    placeholder={`${t("example")} GENODEM1X`}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            bic: e.target.value,
                                                        })
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <h5 className={styles.smallText}>
                                        Die monatlichen Raten werden von diesem Konto eingezogen.{" "}
                                    </h5>

                                    <Spacer />
                                    <h4 className={styles.bold}>Ihre Mobilfunknummer</h4>
                                    <Spacer />
                                    <Row>
                                        <Col span={isMobile ? 20 : 24}>
                                            <Form.Item
                                                name="mobile_number"
                                                label="Mobile"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("errors:invalidData"),
                                                        min: 1,
                                                        max: 100,
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    data-cy="mobile"
                                                    className={styles.input}
                                                    placeholder={`${t("example")} +41 78 690 5001`}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            mobile_number: e.target.value,
                                                        })
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <h5 className={styles.smallText}>
                                        Während der Identifikation erhalten Sie eine SMS, welche zur Identifizierung und
                                        Unterschrift benötigt wird. Dieser Service ist für Sie kostenlos.{" "}
                                    </h5>

                                    <Spacer />
                                    <Spacer />
                                    <Spacer />

                                    <Form.Item>
                                        <Row className={styles.rowCenterX}>
                                            <Button data-cy="button_continue" type="primary" htmlType="submit">
                                                {t("buttons:submit")}
                                            </Button>
                                        </Row>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Card>
                        <Spacer />
                        <Row gutter={24}>
                            <Col span={24} style={{ textAlign: "center" }}>
                                <a href={appl?.draft_info?.cancel_url}>
                                    Cancel and return to {appl?.draft_info?.merchant_name}
                                </a>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};
