import { EditOutlined } from "@ant-design/icons";
import { Card, Col, message, Popconfirm, Row, Typography } from "antd";
import { adminHandleMerchantUsers, adminResetMerchantUser } from "api/admin/merchants";
import { MerchantUser } from "api/types";
import { AxiosError } from "axios";
import styles from "components/admin/pages/application-drilldown/ApplicationDrilldownPage.module.scss";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface MerchantUserDetailsSectionProps {
    merchantUser: MerchantUser
    refreshData?: () => Promise<any>
}

export const MerchantUserDetailsSection = (props: MerchantUserDetailsSectionProps) => {
    const { t } = useTranslation();
    const { first_name, last_name, is_enabled, merchant_id, created_at, entity_id } = props.merchantUser;

    // adminHandleMerchantUsers()
    const handleMuAccount = async () => {
        try {
            await adminHandleMerchantUsers(entity_id, is_enabled);
            props.refreshData();
            void message.success("Success");
        } catch (err) {
            console.error(err);
            void message.error(t("errors:generalError"));
        }
    };
    const resetPw = async () => {
        try {
            await adminResetMerchantUser(props.merchantUser.entity_id);
            void message.success("Success");
        } catch (err) {
            const e = err as AxiosError;
            void message.error(e?.response?.data);
        }
    };
    return (
        <>
            <Row>
                <Col span={18}>
                    <Row align="middle">
                        <span className={styles.desc}>Händler / </span>
                        <Typography.Title className={styles.name}>
                            {first_name} {last_name}
                        </Typography.Title>
                    </Row>
                </Col>
            </Row>

            <Row justify="start" gutter={24}>
                <Col>
                    <span className={styles.descbottom}>Händler ID :</span>
                    <Typography.Text className={styles.topValue}>{merchant_id ? merchant_id : " - "}</Typography.Text>
                </Col>

                <Col>
                    <span className={styles.descbottom}>Erstellt :</span>
                    <Typography.Text className={styles.topValue}>
                        {created_at ? created_at.split("T")[0] : " - "}
                    </Typography.Text>
                </Col>

                <Col>
                    <span className={styles.descbottom}>{t("status")} :</span>
                    <Typography.Text className={styles.topValue}>{is_enabled ? "Aktiv" : " - "}</Typography.Text>
                </Col>
            </Row>
            <Spacer hx={2} />

            <Row gutter={24} align="stretch">
                <Col span={12}>
                    <Card
                        title={
                            <Typography.Title className={styles.clientTitle} level={5}>
                                Nutzerinformationen
                            </Typography.Title>
                        }
                        extra={
                            <Row align="middle">
                                <EditOutlined />
                                &nbsp;
                                <div>{t("buttons:adjust")}</div>
                            </Row>
                        }
                    >
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Nutzer ID : </span>
                            </Col>
                            <Col span={16}>
                                <span>{props.merchantUser.short_id}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Erstellt am : </span>
                            </Col>
                            <Col span={16}>
                                <span>
                                    {props.merchantUser.created_at
                                        ? props.merchantUser.created_at.split("T")[0]
                                        : " - "}
                                </span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Vorname : </span>
                            </Col>
                            <Col span={16}>
                                <span>{first_name}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Nachname : </span>
                            </Col>
                            <Col span={16}>
                                <span>{last_name}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Standort : </span>
                            </Col>
                            <Col span={16}>
                                <span>{props.merchantUser.location}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Rolle : </span>
                            </Col>
                            <Col span={16}>
                                <span> - </span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>E-mail : </span>
                            </Col>
                            <Col span={16}>
                                <span>{props.merchantUser.email}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Telefonnummer : </span>
                            </Col>
                            <Col span={16}>
                                <span>{props.merchantUser.telephone_number}</span>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col span={12}>
                    <Card
                        style={{ height: "100%" }}
                        title={
                            <Typography.Title className={styles.clientTitle} level={5}>
                                Aktionen
                            </Typography.Title>
                        }
                    >
                        <Row gutter={24}>
                            <Col span={12}>
                                <Popconfirm
                                    title={t("confirmSendResetPassword")}
                                    onConfirm={resetPw}
                                    okText={t("buttons:yes")}
                                    cancelText={t("buttons:no")}
                                >
                                    <button className={styles.mainBtn}>Passwort zurücksetzen</button>
                                </Popconfirm>
                            </Col>

                            <Col span={12}>
                                {!is_enabled ? (
                                    <Popconfirm
                                        title={t("confirmLockAccount")}
                                        onConfirm={() => {
                                            handleMuAccount();
                                        }}
                                        okText={t("buttons:yes")}
                                        cancelText={t("buttons:no")}
                                    >
                                        <button className={styles.secondaryBtn}>Konto Sperren</button>
                                    </Popconfirm>
                                ) : (
                                    <Popconfirm
                                        title={t("confirmLockAccount")}
                                        onConfirm={() => {
                                            handleMuAccount();
                                        }}
                                        okText={t("buttons:yes")}
                                        cancelText={t("buttons:no")}
                                    >
                                        <button className={styles.tertiaryBtn}>Enable User</button>
                                    </Popconfirm>
                                )}
                            </Col>
                        </Row>
                        <Spacer />
                        <Row gutter={24}>
                            <Col span={12}></Col>
                            <Col span={12}></Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
