import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    InfoCircleOutlined,
    Loading3QuartersOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Row, Spin } from "antd";
import {
    clientAcquireOffers,
    clientCreateApplication,
    clientGetApplicationById,
    clientGetApplicationDraft,
    clientGetSelectedOffer,

    // clientGetSelectedOffer,
} from "api/client";
import { merchantAcquireOffers, merchantGetApplication, merchantGetSelectedOffer } from "api/merchant";
import { merchantCreateApplicationWithoutClient, merchantCreateClientApplication } from "api/merchant/applications";
import { MerchantCreateApplicationForClient } from "api/types/merchant";
import styles from "components/common/pages/application-flow/ApplicationFlow.module.scss";
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { BackButton } from "components/common/presenters/back-button/BackButton";
import { CustomPageHeader } from "components/common/presenters/custom-page-header/CustomPageHeader";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { currencyLocaleFormatter } from "formatters/currencyFormatters";
import { isMerchantApp } from "helpers/appHelpers";
import { checkAuth } from "helpers/authHelpers";
import hashHistory from "helpers/hashHistory";
import { pageLoad } from "helpers/pageLoad";
import { preventStepSkip } from "helpers/preventStepSkip";
import useMobileCheck from "helpers/useMobileCheck";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { RouteStrings } from "routes/RouteStrings";
import { setLastSuccessPage } from "storage/actions/appActions";
import { AppContext } from "storage/context/appContext";
import { LocalApplicationFlowDataManager } from "storage/LocalApplicationFlowDataManager";
import {
    ApplicationTypes,
    FlowResultStates,
    SelectedMerchantApplicationFlow,
    SelectedOffer,
} from "types/applicationProcess";
import { Application, ApplicationStates, Currencies } from "types/types";
import { DraftInfo } from "../step-1/ApplicationStepOnePage";

export const ApplicationStepOfferPage = () => {
    const { t } = useTranslation();

    const [loading, setLoading] = React.useState(false);
    const [checkingLoggedIn, setCheckingLoggedIn] = React.useState(false);
    const [generalError, setGeneralError] = React.useState("");
    const [{ lastSuccessPage }, dispatch] = React.useContext(AppContext);
    const [state, setState] = React.useState<FlowResultStates>(FlowResultStates.Loading);
    const [draftInfo, setDraftInfo] = React.useState<DraftInfo>(null);
    const [showFinalSuccess, setShowFinalSuccess] = React.useState(false);

    const [KYC_URL, setKYCURL] = React.useState<string>(undefined);

    // offer states for polling
    // const [offerState,setOfferState] = React.useState<string | undefined>();
    const isMobile = useMobileCheck();

    const persistId = (application_id: string) => {
        const applDataManager = new LocalApplicationFlowDataManager();
        const appl = applDataManager.get();

        appl.application_id = application_id;
        applDataManager.update(appl);
    };

    const getDraftInfo = async () => {
        const applDataManager = new LocalApplicationFlowDataManager();
        const appl = applDataManager.get();
        const draftInfo = await clientGetApplicationDraft(appl.session_id);
        setDraftInfo(draftInfo.data);
    };

    React.useEffect(() => {
        getDraftInfo();
    }, []);

    const getApplStatus = async (appId: string, entity: string): Promise<{ data: Application; status: number }> => {
        const functionToCall = entity === "merchant" ? merchantGetApplication : clientGetApplicationById;

        return new Promise((resolve) => {
            try {
                functionToCall(appId).then((res) => {
                    if (["open", "acquiring_offers"].includes(res.data.status)) {
                        setTimeout(() => {
                            resolve(getApplStatus(appId, entity));
                        }, 5000);
                    } else {
                        resolve(res);
                    }
                });
            } catch (err) {
                console.error(err);
            }
        });
    };

    const createApplication = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        setState(FlowResultStates.Loading);

        try {
            const applDataManager = new LocalApplicationFlowDataManager();
            const appl = applDataManager.get();

            const createApplReq = await clientCreateApplication(appl.session_id, appl.duration_months);

            const createdApplId = createApplReq.data;

            persistId(createdApplId);

            // Initiate acquire_offers and then keep polling
            clientAcquireOffers(createdApplId);

            const { data } = await getApplStatus(createdApplId, "client");

            // if after polling we get "acquiring_offers_error" state, set the final screen to "manual review":
            // error during acquiring offers translates to manual review for users
            if (data.status === ApplicationStates.AcquiringOffersError) {
                setState(FlowResultStates.ManualReview);
            }

            // rejected response
            if (data.status === ApplicationStates.Rejected) {
                setState(FlowResultStates.Rejected);
            }

            // otherwise continue with the selected offer and the success screen

            if (~data.status.indexOf("approved")) { // eslint-disable-line no-bitwise
                const selectedOffer: SelectedOffer = await clientGetSelectedOffer(createdApplId);
                setKYCURL(selectedOffer?.kyc_url);
                setState(FlowResultStates.Success);
            }
        } catch (error: any) {
            console.error(error);

            setGeneralError(t("errors:generalError"));

            setState(FlowResultStates.Rejected);
        }

        setLoading(false);
    };

    const createMerchantApplication = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        setState(FlowResultStates.Loading);

        try {
            const applDataManager = new LocalApplicationFlowDataManager();
            const appl = applDataManager.get();

            const data: any = {
                application_type: ApplicationTypes.POS_LOAN,
                currency: Currencies.EUR,
                amount: appl.amount,
                duration_months: appl.duration_months,
                ...appl.personal_info,
                ...appl.address_info,
                ...appl.job_info,
                ...appl.household_info,
                employed_since: "2020-01-01",
                is_accepted_agb: true,
                is_accepted_privacy_policy: true,
                is_received_pre_contract_info: true,
            };

            let createdApplId;

            if (appl.selected_merchant_flow === SelectedMerchantApplicationFlow.No_Email) {
                const createApplReq = await merchantCreateApplicationWithoutClient(data);

                createdApplId = createApplReq.data;
            } else {
                const data: MerchantCreateApplicationForClient = {
                    application_type: ApplicationTypes.POS_LOAN,
                    currency: Currencies.EUR,
                    amount: appl.amount,
                    duration_months: appl.duration_months,
                };

                const createApplReq = await merchantCreateClientApplication(appl.client_id, data);

                createdApplId = createApplReq.data;
            }

            persistId(createdApplId);

            // Initiate acquire_offers
            merchantAcquireOffers(createdApplId);

            const merchantData = await getApplStatus(createdApplId, "merchant");

            // if after polling we get "acquiring_offers_error" state, set the final screen to "manual review":
            // error during acquiring offers translates to manual review for users
            if (merchantData.data.status === ApplicationStates.AcquiringOffersError) {
                setState(FlowResultStates.ManualReview);
            }

            // rejected response
            if (merchantData.data.status === ApplicationStates.Rejected) {
                setState(FlowResultStates.Rejected);
            }

            // otherwise continue with the selected offer and the success screen
            if (~merchantData.data.status.indexOf("approved")) {// eslint-disable-line no-bitwise
                const selectedOffer: SelectedOffer = await merchantGetSelectedOffer(createdApplId);
                setKYCURL(selectedOffer?.kyc_url);
                setState(FlowResultStates.Success);
            }
        } catch (error: any) {
            console.error(error);

            setGeneralError(t("errors:generalError"));

            setState(FlowResultStates.Rejected);
        }

        setLoading(false);
    };

    const handleContinueOnSuccess = async () => {
        if (KYC_URL) {
            console.warn(KYC_URL);
        }
        setShowFinalSuccess(true);

        dispatch(setLastSuccessPage(RouteStrings.ApplicationFlowStepOffer));
    };

    React.useEffect(() => {
        if (!isMerchantApp()) {
            setCheckingLoggedIn(true);

            const isAuth = checkAuth(setCheckingLoggedIn, null, () => {
                hashHistory.push(RouteStrings.ApplicationFlowStepOne);
            });

            if (isAuth) {
                createApplication();
            }
        } else {
            createMerchantApplication();
        }

        pageLoad(dispatch, RouteStrings.ApplicationFlowStepOffer);

        preventStepSkip(lastSuccessPage, hashHistory);
    }, []);

    const renderLoading = () => {
        return (
            <Card>
                <div className={styles.resultContainer}>
                    <h2 className={styles.processTitle}>{t("applProcess:stepOffer:titleLoading")}</h2>

                    <Spacer />
                    <div>{t("applProcess:stepOffer:infoLoading2")}</div>

                    <Spacer hx={3} />

                    <div className={styles.rowCenterX}>
                        <Spin
                            className={styles.spin}
                            indicator={<Loading3QuartersOutlined style={{ fontSize: 54 }} spin />}
                        />
                    </div>

                    <Spacer hx={3} />
                </div>
            </Card>
        );
    };

    const renderSuccess = () => {
        const applDataManager = new LocalApplicationFlowDataManager();
        const appl = applDataManager.get();

        const found = draftInfo?.repayment_options.find((item) => {
            return item.loan_duration === appl.duration_months;
        });
        return showFinalSuccess ? (
            <Row gutter={24}>
                <Col span={24}>
                    <Card>
                        <Spacer />

                        <div className={styles.approveContainer}>
                            <CheckCircleOutlined className={styles.approveIcon} />
                            <Spacer hx={2} />
                            <Row>
                                <div className={styles.approveTitle}>Your purchase is complete!</div>
                                <h5 className={styles.approveText}>
                                    Your user account has been created successfully. We have sent you an email with
                                    which you can confirm your email address and activate your user account. After you
                                    have activated your user account, you can log in and continue processing your
                                    application.
                                </h5>
                            </Row>

                            <Spacer hx={3} />

                            <Row className={styles.rowCenterX}>
                                <Button
                                    data-cy="button_back_to_shop"
                                    type="primary"
                                    onClick={() => {
                                        window.location.href = draftInfo.success_url;
                                    }}
                                >
                                    {t("buttons:backToOnlineShop")}
                                </Button>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        ) : (
            <Row gutter={24}>
                <Col span={24}>
                    <Card>
                        <Spacer />

                        <div className={styles.resultContainer}>
                            <BackButton route={RouteStrings.ApplicationFlowStepThree} hashHistory={hashHistory} />

                            <Spacer hx={2} />
                            <h2 className={styles.processTitle}>Ihr persönliches Angebot</h2>

                            <Row className={styles.rowCenterX} gutter={24}>
                                <Col span={10}>
                                    <div className={styles.card}>
                                        <p className={styles.cardLabel}>{t("monthlyRate")}</p>
                                        <p className={styles.cardValue}>
                                            € {currencyLocaleFormatter(found?.monthly_rate)}
                                        </p>
                                    </div>
                                </Col>
                                <Col span={10}>
                                    <div className={styles.card}>
                                        <p className={styles.cardLabel}>{t("installements")}</p>
                                        <p className={styles.cardValue}>{found?.loan_duration}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Spacer />
                            <Row>
                                <Col span={20} offset={2}>
                                    <h5 className={styles.smallText}>
                                        Dies ist ein Angebot der Santander Bank GmbH. Der effektive Jahreszins beträgt
                                        9.99%. Der Nettodarlehensbetrag ist €677,09, der Gesamtbetrag ist €724,00.
                                    </h5>
                                </Col>
                            </Row>
                            <Spacer hx={2} />

                            <Row className={styles.rowCenterX} gutter={24}>
                                <div>{t("applProcess:stepOffer:infoSuccess")}</div>
                            </Row>

                            <Spacer hx={2} />

                            <Row className={styles.rowCenterX}>
                                <Button
                                    data-cy="button_continue_to_ident"
                                    type="primary"
                                    onClick={handleContinueOnSuccess}
                                >
                                    {t("buttons:continue")}
                                </Button>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        );
    };
    const renderManualReview = () => {
        return (
            <Card>
                <div className={styles.resultContainer}>
                    <InfoCircleOutlined className={styles.manual} />

                    <Spacer hx={2} />
                    <Row className={styles.rowCenterX} gutter={24}>
                        {t("applProcess:stepOffer:info1Manual")}
                    </Row>

                    <Spacer hx={2} />

                    <Row className={styles.rowCenterX} gutter={24}>
                        {t("applProcess:stepOffer:info2Manual")}
                    </Row>

                    <Spacer hx={3} />

                    <Row className={styles.rowCenterX}>
                        <Button
                            data-cy="button_back_to_shop"
                            type="primary"
                            onClick={() => {
                                window.location.href = draftInfo.success_url;
                            }}
                        >
                            {t("buttons:backToOnlineShop")}
                        </Button>
                    </Row>
                </div>
            </Card>
        );
    };

    const renderRejection = () => {
        return (
            <Card>
                <div className={styles.resultContainer}>
                    <CloseCircleOutlined className={styles.rejectIcon} />

                    <Spacer hx={2} />
                    <Row className={styles.rowCenterX} gutter={24}>
                        {t("applProcess:stepOffer:info1Rejection")}
                    </Row>

                    <Spacer hx={2} />

                    <Row className={styles.rowCenterX} gutter={24}>
                        {t("applProcess:stepOffer:info2Rejection")}
                    </Row>

                    <Spacer hx={3} />

                    <Row className={styles.rowCenterX}>
                        <Button
                            data-cy="button_back_to_shop"
                            type="primary"
                            onClick={() => {
                                window.location.href = draftInfo.failure_url;
                            }}
                        >
                            {t("buttons:backToOnlineShop")}
                        </Button>
                    </Row>
                </div>
            </Card>
        );
    };

    const renderAddressNotFound = () => {
        return (
            <Card>
                <h2 className={styles.processTitle}>{t("applProcess:stepOffer:titleAddressNotFound")}</h2>

                <Spacer />

                <div className={styles.innerContainer}>
                    <Row className={styles.rowCenterX} gutter={24}>
                        {t("applProcess:stepOffer:info1AddressNotFound")}
                    </Row>

                    <Spacer hx={2} />

                    <Row className={styles.rowCenterX} gutter={24}>
                        {t("applProcess:stepOffer:info2AddressNotFound")}
                    </Row>

                    <Spacer hx={3} />

                    <Row className={styles.rowCenterX}>
                        <Button data-cy="button_back_to_shop" type="primary" onClick={() => {}}>
                            {t("buttons:checkData")}
                        </Button>
                    </Row>
                </div>
            </Card>
        );
    };

    const render = () => {
        if (state === FlowResultStates.Loading) {
            return renderLoading();
        } else if (state === FlowResultStates.Success) {
            return renderSuccess();
        } else if (state === FlowResultStates.Rejected) {
            return renderRejection();
        } else if (state === FlowResultStates.ManualReview) {
            return renderManualReview();
        } else if (state === FlowResultStates.Address_Not_Found) {
            return renderAddressNotFound();
        }

        return null;
    };
    return (
        <div className={styles.page}>
            <ApplicationProcessHeader checkLoggedInStatus />
            <CustomPageHeader small={isMobile} />

            <div className={styles.container}>
                {checkingLoggedIn ? (
                    <Spin
                        className={styles.spin}
                        indicator={<Loading3QuartersOutlined style={{ fontSize: 34 }} spin />}
                    />
                ) : (
                    render()
                )}
                {generalError ? (
                    <>
                        <Spacer hx={2} />
                        <div className={styles.error}>{generalError}</div>
                        <Spacer />
                    </>
                ) : null}
            </div>
        </div>
    );
};
