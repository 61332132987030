export const clientModalDefaultState = {
    bo_contact_id: "",
    title: "",
    first_name: "",
    last_name: "",
    family_status: "",
    birth_date: "",
    birth_city: "",
    nationality_country_code: "",
    language_code: "",
    address_country_code: "",
    address_street: "",
    address_house_number: "",
    address_post_code: "",
    address_city: "",
    address_resident_since: "",
    prev_address_country_code: "",
    prev_address_street: "",
    prev_address_house_number: "",
    prev_address_post_code: "",
    prev_address_city: "",
    prev_address_resident_since: "",
    job_title: "",
    job_branch: "",
    job_type: "",
    employed_since: "",
    employer_name: "",
    employer_location: "",
    net_income: "",
    type_of_residence: "",
    living_costs: undefined,
    num_children: undefined,
    has_vehicle: false,
    product_interest: "",
    id_doc_type: "",
    id_doc_issued_place: "",
    id_doc_number: "",
    id_doc_issued_at: "",
    id_doc_expires_at: "",
    iban: "",
    bic: "",
    bank_name: "",
    mobile_number: "",
    is_accepted_agb: false,
    is_accepted_privacy_policy: false,
    is_received_pre_contract_info: false
};
