import { Layout, Typography} from "antd";
import styles from "components/admin/pages/admin-home/AdminHomePage.module.scss";
import { MerchantsPage } from "components/admin/pages/merchants/MerchantsPage";
import { ApplicationsTab } from "components/admin/presenters/admin-home-tabs/applications-tab/ApplicationsTab";
import { ClientsTab } from "components/admin/presenters/admin-home-tabs/clients-tab/ClientsTab";
import { DashboardTab } from "components/admin/presenters/admin-home-tabs/dashboard-tab/DashboardTab";
import { SettingsTab } from "components/admin/presenters/admin-home-tabs/settings-tab/SettingsTab";
import { SideMenu } from "components/common/presenters/side-menu/SideMenu";
import { getAdminMenuItems } from "navigation/navigationHelpers";
import * as React from "react";
import { useState } from "react";


const { Header, Content } = Layout;
const { Title } = Typography;

export const AdminHomePage = () => {

    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [activeHash, setActiveHash] = useState(null);

    const hashes = ["#applications", "#clients", "#settings", "#dashboard", "#merchants"];

    React.useEffect(() => {
        const hash = window.location.hash;

        if (hash == null || hash == "") {
            return;
        }

        if (activeHash == null) {
            setActiveHash(hash);
        }

        if (activeTabIndex !== hashes.indexOf(hash)) {
            setActiveTabIndex(hashes.indexOf(hash));
        }
    }, [window.location.hash]);

    const tabsContent = [
        <ApplicationsTab />,
        <ClientsTab />,
        <SettingsTab />,
        <DashboardTab />,
        <MerchantsPage />,
    ];

    const adminMenuItems = getAdminMenuItems();

    return (
        <Layout className={styles.adminPage}>
            <SideMenu
                activeHash={window.location.hash}
                isLoggedIn={true}
                onItemClick={(index) => {
                    setActiveTabIndex(index);

                    window.location.hash = hashes[index];
                }}
            />

            <Layout className={styles.tabContainer}>
                <Header className={styles.headerRow}>
                    <Title level={2}>
                        {adminMenuItems[activeTabIndex]}
                    </Title>
                </Header>

                <Content className={styles.adminContent}>{tabsContent[activeTabIndex]}</Content>
            </Layout>
        </Layout>
    );
};
