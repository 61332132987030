import { DownOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, Row, Typography } from "antd";
import { Merchant } from "api/types";
import styles from "components/admin/pages/application-drilldown/ApplicationDrilldownPage.module.scss";
import { Spacer } from "components/common/presenters/spacer/Spacer";

import * as React from "react";
import { useTranslation } from "react-i18next";
import { MerchantContactFromFlow } from "types/adminPanel";

export interface MerchantDetailsSectionProps {
    merchant: Merchant
    contactPersons?: MerchantContactFromFlow[]
    showTitle?: boolean
    setMerchant?: (c: boolean) => void
}

export const MerchantDetailsSection = (props: MerchantDetailsSectionProps) => {
    const { t } = useTranslation();

    const menu = (
        <Menu>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="">
                    {t("buttons:printCustomer")}
                </a>
            </Menu.Item>
            <Menu.Item icon={<DownOutlined />} disabled>
                <a target="_blank" rel="noopener noreferrer" href="">
                    {t("buttons:newToDo")}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="">
                    {t("buttons:newOffer")}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" href="">
                    {t("buttons:dsgvo")}
                </a>
            </Menu.Item>
        </Menu>
    );

    const renderDropdown = (): JSX.Element => {
        if (props.setMerchant) {
            return (
                <>
                    <Button data-cy={"button_add_bo_user"} onClick={() => props.setMerchant(true)}>
                        {t("buttons:addEmployee")}
                    </Button>
                </>
            );
        } else {
            return (
                <Dropdown overlay={menu}>
                    <a className={styles.dropdownMenu} onClick={(e) => e.preventDefault()}>
                        <DownOutlined /> Aktionen
                    </a>
                </Dropdown>
            );
        }
    };

    const renderContactPersons = (): JSX.Element[] => {
        let elements = [];

        for (let i = 0; i < props.contactPersons.length; i++) {
            const cp = props.contactPersons[i];

            elements.push(
                <React.Fragment>
                    <Spacer hx={2} />

                    <Row className={styles.row}>
                        <Col span={6}>
                            <b>
                                {t("contactPerson")} {i + 1}:
                            </b>
                        </Col>
                        <Col span={6}>{cp?.title + " " + cp?.first_name + " " + cp?.last_name}</Col>
                    </Row>

                    <Row className={styles.row}>
                        <Col span={6}></Col>
                        <Col span={6}>{cp?.telephone_number}</Col>
                    </Row>

                    <Row className={styles.row}>
                        <Col span={6}></Col>
                        <Col span={6}>{cp?.email}</Col>
                    </Row>

                    <Row className={styles.row}>
                        <Col span={6}></Col>
                        <Col span={6}>{cp?.is_admin ? t("isInvitedAsAdmin") : t("isNotInvitedAsAdmin")}</Col>
                    </Row>

                    <Row className={styles.row}>
                        <Col span={6}></Col>
                        <Col span={6}>
                            <div className={styles.infoEdit} onClick={() => {}}>
                                {t("buttons:adjust")}
                            </div>
                        </Col>
                    </Row>
                </React.Fragment>
            );
        }

        return elements;
    };
    const merchant = props.merchant;
    return (
        <>
            <Row>
                <Col span={18}>
                    <Row align="middle">
                        <span className={styles.desc}>Händler / </span>
                        <Typography.Title className={styles.name}>{merchant.name}</Typography.Title>
                    </Row>
                </Col>
                <Col offset={1} span={4} >{renderDropdown()}</Col>
            </Row>

            <Row justify="start" gutter={24}>
                <Col>
                    <span className={styles.descbottom}>Händler ID :</span>
                    <Typography.Text className={styles.topValue}>
                        {merchant.short_id ? merchant.short_id : merchant.merchant_id}
                    </Typography.Text>
                </Col>

                <Col>
                    <span className={styles.descbottom}>{t("handler")} :</span>
                    <Typography.Text className={styles.topValue}>{merchant.founding_date}</Typography.Text>
                </Col>

                <Col>
                    <span className={styles.descbottom}>{t("status")} :</span>
                    <Typography.Text className={styles.topValue}>{merchant.status}</Typography.Text>
                </Col>
            </Row>

            <Spacer hx={2} />

            {props.contactPersons && renderContactPersons()}
        </>
    );
};
