import { Button, Col, DatePicker, Divider, Form, Input, message, Modal, Row, Select } from "antd";
import { adminCreateClient, adminUpdateClient } from "api/admin/admin";
import { DATE_FORMAT } from "api/apiConfig";
import { PostClient, UpdateClient } from "api/types/client";
import { clientModalDefaultState } from "components/admin/presenters/create-client-modal/defaultState";
import { AnchoredSelect } from "components/common/presenters/anchored-select/AnchoredSelect";
import { ConfirmModalClose } from "components/common/presenters/confirm-modal-close/ConfirmModalClose";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { formatDateForAPI } from "helpers/timeHelpers";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    CountryCodesList,
    MaritalStatusesList,
    OccupationGroupList,
    ResidenceTypesList,
    WorkBranchesList,
} from "types/applicationProcess";
import { countriesDe, countriesEn, LanguageCodes } from "types/countries";
import { getPostCodeValidationPattern } from "validators/inputs";
import styles from "./CreateClientModal.module.scss";

// interface ClientModalProps {
//     modalOpen: boolean
//     setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
//     existingClientData: GetClientResponse
//     onDataChanged: () => void
// }

export const CreateClientModal = (props: any) => {
    const { t, i18n } = useTranslation();
    const [form] = Form.useForm();

    const [secondaryModalOpen, setSecondaryModalOpen] = React.useState(false);

    const [state, setState] = React.useState({
        ...clientModalDefaultState,
    });

    const [note, setNote] = useState(null as string);

    const isUpdateOperation = props.existingClientData ? true : false;

    const checkForm = () => {
        // check if at least one value is filled in a form.
        const vals = Object.values(form.getFieldsValue());
        const notEmpty = vals.some((value) => value !== undefined);
        return notEmpty;
    };

    useEffect(() => {
        const cd = props.existingClientData;

        if (isUpdateOperation && cd) {
            const data = {
                ...cd,
            };

            if (cd.birth_date) {
                data.birth_date = moment(cd.birth_date);
            }
            if (cd.address_resident_since) {
                data.address_resident_since = moment(cd.address_resident_since);
            }
            if (cd.employed_since) {
                data.employed_since = moment(cd.employed_since);
            }

            form.setFieldsValue(data);
        }
    }, [props.modalOpen]);

    const secondaryModalOnCancel = () => {
        setSecondaryModalOpen(false);
    };

    const secondaryModalOnOk = () => {
        setSecondaryModalOpen(false);
        props.setModalOpen(false);
        form.resetFields();
    };

    const handleSubmit = async (values: any) => {
        try {
            let clientData: UpdateClient = {
                title: values.title,
                first_name: values.first_name,
                last_name: values.last_name,
                family_status: values.family_status,
                birth_city: values.birth_city,
                nationality_country_code: values.nationality_country_code,
                language_code: values.language_code?.toLowerCase(),
                address_country_code: values.address_country_code?.toLowerCase(),
                address_street: values.address_street,
                address_house_number: values.address_house_number,
                address_post_code: values.address_post_code,
                address_city: values.address_city,
                job_title: values.job_title,
                job_branch: values.job_branch,
                job_type: values.job_type,
                employer_name: values.employer_name,
                employer_location: values.employer_location,
                net_income: values.net_income,
                type_of_residence: values.type_of_residence,
                living_costs: values.living_costs,
                num_children: values.num_children,
                has_vehicle: values.has_vehicle,
                product_interest: values.product_interest,
                id_doc_type: values.id_doc_type,
                id_doc_issued_place: values.id_doc_issued_place,
                id_doc_number: values.id_doc_number,
                id_doc_issued_at: values.id_doc_issued_at,
                id_doc_expires_at: values.id_doc_expires_at,
                iban: values.iban,
                bic: values.bic,
                bank_name: values.bank_name,
                mobile_number: values.mobile_number,
            };

            if (values.birth_date) {
                clientData.birth_date = formatDateForAPI(values.birth_date);
            }
            if (values.address_resident_since) {
                clientData.address_resident_since = formatDateForAPI(values.address_resident_since);
            }
            if (values.employed_since) {
                clientData.employed_since = formatDateForAPI(values.employed_since);
            }

            if (isUpdateOperation) {
                await adminUpdateClient(props.existingClientData.client_id, clientData);
            } else {
                const clientDataCreate: PostClient = {
                    ...clientData,
                    email: values.email,
                };

                await adminCreateClient(clientDataCreate);
            }

            props.onDataChanged();
            props.setModalOpen(false);
            message.success(t("messages:dataSaved"), 2);
        } catch (error) {
            console.error(error);
            message.error(t("messages:couldNotSave"), 2);
        }
    };

    const countryListToMap = i18n.language === "de" ? countriesDe : countriesEn;
    return (
        <React.Fragment>
            <ConfirmModalClose
                visible={secondaryModalOpen}
                onCancel={secondaryModalOnCancel}
                onOk={secondaryModalOnOk}
            />

            <Modal
                width={1000}
                className={styles.modal}
                centered
                title={isUpdateOperation ? t("modals:editClient") : t("modals:addClient")}
                visible={props.modalOpen}
                destroyOnClose={true}
                maskClosable
                okText={t("buttons:ok")}
                cancelText={t("buttons:cancel")}
                onCancel={(e: any) => {
                    if (!checkForm()) {
                        props.setModalOpen(false);
                    } else {
                        setSecondaryModalOpen(true);
                    }
                }}
                footer={[
                    <div key="fr" className={styles.footerRow}>
                        <Button
                            className={styles.buttonCancel}
                            key="cancel"
                            onClick={(e: any) => {
                                props.setModalOpen(false);
                            }}
                        >
                            {t("buttons:cancel")}
                        </Button>
                        <Button
                            className={styles.buttonOk}
                            form="form_create_client"
                            key="submit"
                            htmlType="submit"
                            // onClick={() => { handleSave(); }}
                        >
                            {t("buttons:submit")}
                        </Button>
                    </div>,
                ]}
            >
                <Form id="form_create_client" layout={"vertical"} form={form} onFinish={handleSubmit}>
                    <div className={styles.inputsContainer}>
                        <h4 className={styles.boldText}>{t("companyData")}</h4>
                        <Row gutter={36}>
                            <Col span={12}>
                                <Form.Item
                                    name="email"
                                    label={t("email")}
                                    hasFeedback
                                    rules={[{ required: true, message: t("errors:invalidData"), min: 1, max: 150 }]}
                                >
                                    <Input
                                        data-cy="email"
                                        className={styles.input}
                                        placeholder={t("example") + " example@example.com"}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                // email: e.target.value
                                            })
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="title"
                                    label={t("salutation")}
                                    hasFeedback
                                    rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                                >
                                    <AnchoredSelect
                                        data-cy="title"
                                        className={styles.select}
                                        allowClear
                                        placeholder={t("pleaseSelectOption")}
                                    >
                                        <Select.Option value="Herr">Herr</Select.Option>
                                        <Select.Option value="Frau">Frau</Select.Option>
                                    </AnchoredSelect>
                                </Form.Item>

                                <Form.Item
                                    name="first_name"
                                    label={t("firstName")}
                                    hasFeedback={state?.first_name?.length >= 2}
                                    rules={[{ required: false, message: t("errors:invalidData"), min: 2, max: 100 }]}
                                >
                                    <Input
                                        data-cy="first_name"
                                        className={styles.input}
                                        placeholder={t("example") + " Hans"}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                first_name: e.target.value,
                                            })
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="last_name"
                                    label={t("lastName")}
                                    hasFeedback={state?.last_name?.length >= 2}
                                    rules={[{ required: false, message: t("errors:invalidData"), min: 2, max: 100 }]}
                                >
                                    <Input
                                        data-cy="last_name"
                                        className={styles.input}
                                        placeholder={`${t("example")} Mustermann`}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                last_name: e.target.value,
                                            })
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="family_status"
                                    label={t("maritalStatus")}
                                    hasFeedback
                                    rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                                >
                                    <AnchoredSelect
                                        data-cy="family_status"
                                        className={styles.select}
                                        allowClear
                                        placeholder={t("pleaseSelectOption")}
                                    >
                                        {MaritalStatusesList.map((val, i) => {
                                            return (
                                                <Select.Option
                                                    data-cy={`address_country_option_${i}`}
                                                    key={i}
                                                    value={val}
                                                >
                                                    {t(`maritalStatuses:${val}`)}
                                                </Select.Option>
                                            );
                                        })}
                                    </AnchoredSelect>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name="birth_date" label={t("dateOfBirth")} hasFeedback>
                                    <DatePicker
                                        className={styles.datePicker}
                                        defaultValue={undefined}
                                        format={DATE_FORMAT.DE}
                                        allowClear={true}
                                    />
                                </Form.Item>

                                <Form.Item name="birth_city" label={t("placeOfBirth")} hasFeedback>
                                    <Input
                                        data-cy="birth_city"
                                        className={styles.input}
                                        placeholder={`${t("example")} Berlin`}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                birth_city: e.target.value,
                                            })
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="nationality_country_code"
                                    label={t("nationality")}
                                    hasFeedback
                                    rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                                >
                                    <AnchoredSelect
                                        data-cy="nationality_country_code"
                                        className={styles.select}
                                        allowClear
                                        placeholder={t("pleaseSelectOption")}
                                    >
                                        {countryListToMap.map((country, idx) => {
                                            return (
                                                <Select.Option
                                                    key={`country-${idx}`}
                                                    value={country.code.toLowerCase()}
                                                >
                                                    {country.name}
                                                </Select.Option>
                                            );
                                        })}
                                    </AnchoredSelect>
                                </Form.Item>

                                <Spacer />
                                <Spacer />
                            </Col>
                        </Row>

                        <Spacer />

                        <h4 className={styles.boldText}>{t("addressData")}</h4>
                        <Row gutter={36}>
                            <Col span={12}>
                                <Form.Item
                                    name="address_country_code"
                                    label={t("country")}
                                    hasFeedback
                                    rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                                >
                                    <AnchoredSelect data-cy="address_country_code" className={styles.select} allowClear>
                                        {CountryCodesList.map((val, i) => {
                                            return (
                                                <Select.Option
                                                    data-cy={`address_country_option_${i}`}
                                                    key={i}
                                                    value={val}
                                                >
                                                    {t(`countries:${val}`)}
                                                </Select.Option>
                                            );
                                        })}
                                    </AnchoredSelect>
                                </Form.Item>

                                <Form.Item
                                    name="address_addition"
                                    label={t("addressAddition")}
                                    hasFeedback
                                    rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                                >
                                    <Input
                                        data-cy="address_addition"
                                        className={styles.input}
                                        placeholder={`${t("example")} A123`}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                // address_addition: e.target.value
                                            })
                                        }
                                    />
                                </Form.Item>

                                <Row>
                                    <Col span={11}>
                                        <Form.Item
                                            name="street_name"
                                            label={t("streetName")}
                                            hasFeedback
                                            rules={[
                                                { required: false, message: t("errors:invalidData"), min: 1, max: 100 },
                                            ]}
                                        >
                                            <Input
                                                data-cy="street_name"
                                                className={styles.input}
                                                placeholder={t("example") + " Musterstrasse"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            name="street_number"
                                            label={t("streetNumber")}
                                            hasFeedback
                                            rules={[
                                                { required: false, message: t("errors:invalidData"), min: 1, max: 100 },
                                            ]}
                                        >
                                            <Input
                                                data-cy="street_number"
                                                className={styles.input}
                                                placeholder={t("example") + " 123"}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={11}>
                                        <Form.Item
                                            name="address_post_code"
                                            label={t("postCode")}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: t("formErrors:invalidPostCode"),
                                                    pattern: getPostCodeValidationPattern(),
                                                },
                                            ]}
                                        >
                                            <Input
                                                data-cy="address_post_code"
                                                className={styles.input}
                                                placeholder={t("example") + " 12345"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item
                                            name="address_city"
                                            label={t("city")}
                                            hasFeedback
                                            rules={[
                                                { required: false, message: t("errors:invalidData"), min: 1, max: 100 },
                                            ]}
                                        >
                                            <Input
                                                data-cy="address_city"
                                                className={styles.input}
                                                placeholder={t("example") + " Berlin"}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Spacer />

                                <Spacer />
                            </Col>

                            <Col span={12}>
                                <Form.Item name="address_resident_since" label={t("residentSince")} hasFeedback>
                                    <DatePicker
                                        className={styles.datePicker}
                                        defaultValue={undefined}
                                        format={DATE_FORMAT.DE}
                                        allowClear={true}
                                        onChange={(value) => {
                                            setState({
                                                ...state,
                                                address_resident_since: moment(value).format(),
                                            });
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="language_code"
                                    label={t("language")}
                                    hasFeedback
                                    rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                                >
                                    <AnchoredSelect
                                        data-cy="language_code"
                                        className={styles.select}
                                        allowClear
                                        placeholder={t("pleaseSelectOption")}
                                    >
                                        {LanguageCodes.map((val, i) => {
                                            return (
                                                <Select.Option data-cy={`language_code_${i}`} key={i} value={val}>
                                                    {t(`languageCodes:${val}`)}
                                                </Select.Option>
                                            );
                                        })}
                                    </AnchoredSelect>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Spacer />
                        <Divider />

                        <h4 className={styles.boldText}>{t("professionData")}</h4>
                        <Row gutter={36}>
                            <Col span={12}>
                                <Form.Item
                                    name="job_title"
                                    label={t("occupation")}
                                    hasFeedback
                                    rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                                >
                                    <Input
                                        data-cy="job_title"
                                        className={styles.input}
                                        placeholder={`${t("example")} Sacharbeiter`}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                job_title: e.target.value,
                                            })
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="job_branch"
                                    label={t("occupationBranche")}
                                    hasFeedback
                                    rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                                >
                                    <AnchoredSelect
                                        data-cy="job_branch"
                                        className={styles.select}
                                        allowClear
                                        placeholder={t("pleaseSelectOption")}
                                    >
                                        {WorkBranchesList.map((val, i) => {
                                            return (
                                                <Select.Option
                                                    data-cy={`address_country_option_${i}`}
                                                    key={i}
                                                    value={val}
                                                >
                                                    {t(`workBranches:${val}`)}
                                                </Select.Option>
                                            );
                                        })}
                                    </AnchoredSelect>
                                </Form.Item>

                                <Form.Item
                                    name="job_type"
                                    label={t("occupationGroup")}
                                    hasFeedback
                                    rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                                >
                                    <AnchoredSelect
                                        data-cy="job_type"
                                        className={styles.select}
                                        allowClear
                                        placeholder={t("pleaseSelectOption")}
                                    >
                                        {OccupationGroupList.map((val, i) => {
                                            return (
                                                <Select.Option
                                                    data-cy={`address_country_option_${i}`}
                                                    key={i}
                                                    value={val}
                                                >
                                                    {t(`occupationGroups:${val}`)}
                                                </Select.Option>
                                            );
                                        })}
                                    </AnchoredSelect>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name="employed_since" label={t("employedSince")} hasFeedback>
                                    <DatePicker
                                        className={styles.datePicker}
                                        defaultValue={undefined}
                                        format={DATE_FORMAT.DE}
                                        allowClear={true}
                                    />
                                </Form.Item>

                                <Form.Item name="employer_name" label={t("employer")} hasFeedback>
                                    <Input
                                        data-cy="employer_name"
                                        className={styles.input}
                                        placeholder={`${t("example")} Muster AG`}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                employer_name: e.target.value,
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Spacer />
                        <Divider />

                        <h4 className={styles.boldText}>{t("householdData")}</h4>
                        <Row gutter={36}>
                            <Col span={12}>
                                <Form.Item
                                    name="type_of_residence"
                                    label={t("residenceType")}
                                    hasFeedback
                                    rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 100 }]}
                                >
                                    <AnchoredSelect data-cy="type_of_residence" className={styles.select} allowClear>
                                        {ResidenceTypesList.map((val, i) => {
                                            return (
                                                <Select.Option
                                                    data-cy={`residence_type_option_${i}`}
                                                    key={i}
                                                    value={val}
                                                >
                                                    {t(`residenceTypes:${val}`)}
                                                </Select.Option>
                                            );
                                        })}
                                    </AnchoredSelect>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Row gutter={24}>
                                    <Col span={11}>
                                        <Form.Item name="living_costs" label={t("residenceMonthlyCost")} hasFeedback>
                                            <Input
                                                data-cy="living_costs"
                                                className={styles.input}
                                                placeholder={`${t("example")} CHF 3,500`}
                                                // onChange={(e) => setState({
                                                //     ...state,
                                                //     living_costs: parseFloat(e.target.value)
                                                // })}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={2}></Col>

                                    <Col span={8}>
                                        <Form.Item name="num_children" label={t("numberOfChildren")} hasFeedback>
                                            <Input
                                                data-cy="num_children"
                                                className={styles.input}
                                                placeholder={`${t("example")} 2`}
                                                onChange={(e) =>
                                                    setState({
                                                        ...state,
                                                        num_children: parseInt(e.target.value),
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Spacer />
                        <Divider />
                        <Row gutter={36}>
                            <Col span={12}>
                                <Form.Item name="product_interest" label={t("interestInProduct")} hasFeedback>
                                    <Input
                                        data-cy="product_interest"
                                        className={styles.input}
                                        placeholder={`${t("example")} Coaching`}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                // number_of_children: e.target.value
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="broker" label={t("broker")} hasFeedback>
                                    <Input
                                        data-cy="broker"
                                        className={styles.input}
                                        placeholder={`${t("example")} Max Mustermann`}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                // number_of_children: e.target.value
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        {!isUpdateOperation ? (
                            <>
                                <Divider />

                                <Row gutter={36}>
                                    <Col span={12}>
                                        <h4>{t("notes")}</h4>
                                        <Input.TextArea
                                            data-cy="input_lead_notes"
                                            value={note}
                                            onChange={(e) => {
                                                setNote(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </>
                        ) : null}
                    </div>
                </Form>
            </Modal>
        </React.Fragment>
    );
};
