import { Button, Card, Col, Form, Input, message, Popconfirm, Row, Tabs, Typography } from "antd";
import { GetAdminProfileResponse } from "api/types";
import styles from "components/admin/presenters/admin-home-tabs/AdminHomeTabs.module.scss";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { isValidPassword } from "helpers/inputValidationHelpers";
import { ManageMerchantUsersSection } from "components/merchant-panel/settings-tab/ManageMerchantUsersSection";
import { merchantChangePassword, merchantGenerateApiKey, merchantGetApiKey, merchantGetProfile } from "api/merchant";
import { EyeOutlined, RedoOutlined } from "@ant-design/icons";
import ImageUpload from "components/common/presenters/image-upload/ImageUpload";
import { merchantUpdateMerchantDisplayName } from "api/admin/merchants";

const { TabPane } = Tabs;
export const MerchantSettingsTab = () => {
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const [nameForm] = Form.useForm();
    const [apiForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState({} as GetAdminProfileResponse);
    const [error, setError] = useState("");
    const [keyShowing, setKeyShowing] = useState<boolean>(false);

    

    const getData = async () => {
        try {
            const profileReq = await merchantGetProfile();

            setProfile(profileReq.data);
        } catch (error) {
            console.error(error);
            setError(t("messages:errorEncountered"));
        }
    };

    const changePassword = async (password) => {
        setError("");
        setLoading(true);
        try {
            await merchantChangePassword(password);

            message.success(t("passChangeSuccess"));
        } catch (error) {
            message.error(t("messages:couldNotSave"));
            console.error();
        }
        setLoading(false);
    };

    const handleOnFinish = (values: any) => {
        if (values.password !== values.password_repeat) {
            message.error(t("passwordsMatchError"));
        } else {
            changePassword(values.password);
        }
    };

    React.useEffect(() => {
        getData();
    }, []);

    const showApiKey = async () => {
        setKeyShowing(!keyShowing);
        //  get key
        try {
            const { data } = await merchantGetApiKey();
            !keyShowing
                ? apiForm.setFieldsValue({
                    apikey: data.api_key,
                })
                : apiForm.setFieldsValue({
                    apikey: "••••••••••••••••••••",
                });
        } catch {
            void message.error(t("errorAPIRetrieve"));
        }
    };

    const generateApiKey = async () => {
        //  reset key
        try {
            const response = await merchantGenerateApiKey(profile.merchant_id);
            keyShowing
                ? apiForm.setFieldsValue({
                    apikey: response.data.api_key,
                })
                : apiForm.setFieldsValue({
                    apikey: "••••••••••••••••••••",
                });

            void message.success(t("generatedAPI"));
        } catch {
            void message.error(t("errorAPIGeneration"));
        }
    };

    const updateMerchantDisplayName = async () => {
        const displayName = nameForm.getFieldValue("display_name");
        if (!displayName.length) return;
        try {
            await merchantUpdateMerchantDisplayName(displayName);
            void message.success(t("success"));
        } catch (err) {
            console.error(err);
            void message.error(t("errors:generalError"));
        }
    };
    
    return (
        <section style={{ height: "100%", background: "#FBFBFB" }}>
            <Tabs defaultActiveKey="profile" className="admin-home-tab">
                <TabPane tab="Profil" key="profile">
                    <Row gutter={20}>
                        <Col span={12}>
                            <Card title={<Typography.Title level={5}>Email</Typography.Title>}>
                                <span>E-Mail: &nbsp;</span>
                                {profile ? profile.email : ""}
                            </Card>
                        </Col>

                        <Col span={12}>
                            <Card title={<Typography.Title level={5}>Benutzerkonten</Typography.Title>}>
                                <Form
                                    className={styles.passwordForm}
                                    layout={"vertical"}
                                    form={form}
                                    onFinish={handleOnFinish}
                                    scrollToFirstError
                                >
                                    <Form.Item
                                        name="password"
                                        label={t("password")}
                                        hasFeedback
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (value && !isValidPassword(value)) {
                                                        return Promise.reject(t("errors:passwordInvalid"));
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password data-cy="input_password" className={styles.input} />
                                    </Form.Item>

                                    <Form.Item
                                        name="password_repeat"
                                        label={t("passwordRepeat")}
                                        dependencies={["password"]}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: t("errors:confirmPassword"),
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue("password") === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(t("errors:passwordsDoNotMatch"));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password data-cy="input_password_repeat" className={styles.input} />
                                    </Form.Item>

                                    <div className="custom-error">{error}</div>
                                    <Spacer />

                                    <Form.Item>
                                        <Row className={styles.rowCenterX}>
                                            <Button type="primary" htmlType="submit" loading={loading}>
                                                {t("buttons:changePassword")}
                                            </Button>
                                        </Row>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </TabPane>

                <TabPane tab="Benutzerkonten" key="manage">
                    <Row>
                        <React.Fragment>
                            <ManageMerchantUsersSection />
                        </React.Fragment>
                    </Row>
                </TabPane>
                <TabPane tab="Loan Application Portal" key="api">
                    <Row gutter={24} align="stretch">
                        <Col span={12}>
                            <Card style={{ height: "100%" }} title={<Typography.Title level={5}>API</Typography.Title>}>
                                <Typography.Paragraph className={styles.desc}>
                                    The API key allows you to make calls to the the Liquitree API.Only share this key
                                    with third-party services and applications that you trust.
                                </Typography.Paragraph>

                                <Form layout="vertical" form={apiForm}>
                                    <Row align="middle" justify="space-around" gutter={12}>
                                        <Col span={12}>
                                            <Form.Item
                                                name="apikey"
                                                initialValue="••••••••••••••••••••"
                                                label={<Typography.Text>API Key</Typography.Text>}
                                            >
                                                <Input
                                                    data-cy="input_api"
                                                    className={styles.input}
                                                    disabled={!keyShowing}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={4}>
                                            <div className={styles.actions} onClick={showApiKey}>
                                                <EyeOutlined /> {keyShowing ? "Hide Key" : "show key"}
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            <Popconfirm
                                                placement="topLeft"
                                                title={"Are you sure?"}
                                                onConfirm={generateApiKey}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <div className={styles.actions}>
                                                    <RedoOutlined /> {t("regenerateAPI")}
                                                </div>
                                            </Popconfirm>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card title={<Typography.Title level={5}>{t("appearance")}</Typography.Title>}>
                                <Typography.Paragraph className={styles.desc}>
                                    {t("customizeLogo")}
                                </Typography.Paragraph>
                                <Spacer />
                                <ImageUpload uploader="merchant" entityProps={profile} />
                                <Spacer />
                                <Typography.Paragraph className={styles.desc2}>{t("logoExtra")}</Typography.Paragraph>
                            </Card>
                        </Col>
                    </Row>
                    <Spacer />
                    <Row gutter={24}>
                        <Col span={12}>
                            <Card title={<Typography.Title level={5}>Display Name</Typography.Title>}>
                                <Form layout="vertical" form={nameForm}>
                                    <Row align="middle" justify="space-around" gutter={12}>
                                        <Col span={12}>
                                            <Form.Item
                                                name="display_name"
                                                initialValue={profile?.company?.display_name}
                                                label={<Typography.Text>Display Name</Typography.Text>}
                                            >
                                                <Input data-cy="input_api" className={styles.input} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Button
                                                style={{ marginTop: "8px" }}
                                                onClick={() => {
                                                    updateMerchantDisplayName();
                                                }}
                                            >
                                                Update
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
        </section>
    );
};
