import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Row } from "antd";
import { clientConfirmEmail } from "api/client/client";
import styles from 'components/common/pages/confirm-email-page/ConfirmEmailPage.module.scss';
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { goTo } from "navigation/navigationHelpers";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from 'react-router-dom';
import { RouteStrings } from "routes/RouteStrings";

export const ConfirmEmailPage = () => {
    const { t } = useTranslation();

    const location = useLocation();

    let {token} = useParams<any>();

    const isAdmin = location?.pathname?.includes(RouteStrings.AdminRouteBase);

    React.useEffect(() => {
        try {
            if (token) {
                clientConfirmEmail(token);
            } else {
                console.error(t("noToken"));
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    return (
        <div className={styles.page}>
            <ApplicationProcessHeader hideNav={true} loginPage/>
            <div className={styles.info}>
                <h2 className={styles.title}>{ t("confirmEmail:title2") }</h2>
                <Spacer/>
                <Row className={styles.rowCenterX}>
                    <CheckCircleOutlined className={styles.checkIcon}/>
                </Row>
                <Spacer hx={2}/>
                <p className={styles.text}>{ t("confirmEmail:body2") }</p>
                <Spacer/>
                <Row className={styles.rowCenterX}>
                    <Button type={"primary"}
                        onClick={() => goTo(isAdmin ? RouteStrings.AdminLogin : RouteStrings.Login)}
                    >
                        {t('buttons:toLogin2')}
                    </Button>
                </Row>
            </div>    
        </div>
    );
};
