import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from 'components/admin/pages/merchants/MerchantsPage.module.scss';
import { Tabs } from "antd";
import { MerchantsListTab } from "components/admin/presenters/admin-home-tabs/merchants-list-tab/MerchantsListTab";
import { MerchantUsersListTab } from "components/admin/presenters/admin-home-tabs/merchant-users-list-tab/MerchantUsersListTab";
import { CreateEditMerchantModal } from "components/admin/presenters/create-edit-merchant-modal/CreateEditMerchantModal";
import { adminGetMerchants } from "api/admin/merchants";
import { useState } from "react";
import { Merchant } from "api/types";
import { PaginationType } from "types/adminPanel";

const {TabPane} = Tabs;

export const MerchantsPage = () => {
    const { t } = useTranslation();

    const [ activeTab, setActiveTab ] = React.useState("0");
    const [ merchantModalOpen, setMerchantModalOpen ] = React.useState<boolean>(false);

    const [merchants, setMerchants] = useState<Merchant[]>([]);
    const [pagination, setPagination] = useState<PaginationType>({});

    const [loading, setLoading] = useState<boolean>(false);

    const onTabChange = (key) => {
        setActiveTab(key);
    };

    const toggleMerchantModal = (isOpen: boolean) => {
        setMerchantModalOpen(isOpen);   
        
        if (!isOpen) {
            getMerchants(1);
        }
    };

    const getMerchants = async (page: number, query?: string) => {
        try {
            setLoading(true);

            const getMerchantsReq = await adminGetMerchants(page, query);

            if (getMerchantsReq && getMerchantsReq?.status == 200) {
                setMerchants(getMerchantsReq?.data?.result);
                setPagination(getMerchantsReq?.data?.pagination);
            } else {
                setMerchants([]);
            }

        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    const onMerchantSearch = async (query: string) => {
        getMerchants(1, query);
    };

    React.useEffect(() => {
        getMerchants(1);
    }, []);

    return (
        <div className={styles.page}>
            <div className={styles.sectionRight}>
                <div className="tab-menu">
                    <Tabs className="tab-menu" defaultActiveKey="-1" activeKey={activeTab}  onChange={onTabChange}>
                        <TabPane tab={t("merchants")} key={`0`}>
                            <MerchantsListTab 
                                loading={loading}
                                merchants={merchants}
                                onAddMerchant={toggleMerchantModal.bind(this, true)}
                                getMerchants={getMerchants}
                                pagination={pagination}
                                handleMerchantsSearch={onMerchantSearch}/>
                        </TabPane>
            
                        <TabPane tab={t("userAccounts")} key={`1`}>
                            <MerchantUsersListTab/>
                        </TabPane>
                    </Tabs>
                </div>
            </div>

            <CreateEditMerchantModal modalOpen={merchantModalOpen}
                closeModal={toggleMerchantModal.bind(this, false)}
                onDataChanged={getMerchants.bind(this, 1)}/>
        </div>
    );
};
