import { ApplicationStates } from "../types/types";

export const isPaidOutState = (state: ApplicationStates | string): boolean => {
    return [
        ApplicationStates.Complete
    ].includes(ApplicationStates[state]);
};

export const generateRandomInteger = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getDummyPdfUrl = () => {
    return "https://file-examples-com.github.io/uploads/2017/10/file-example_PDF_1MB.pdf";
};

export const downloadDummyPdf = () => {
    window.open(getDummyPdfUrl(), "_blank");
};

/**
 * Removes object properties that are either null or undefined
 *
 * @param obj 
 */
export const deleteObjNullProps = (obj) => {
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop) && obj[prop] == null) {
            delete obj[prop];
        }
    }

    return obj;
};

export const castObjProps = (obj) => {
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            // booleans
            if (obj[prop] === "true") {
                obj[prop] = true;
            }
            if (obj[prop] === "false") {
                obj[prop] = true;
            }
        }
    }

    return obj;
};

export const parseToFloatObjNumProps = (obj) => {
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            if (obj[prop] === true || obj[prop] === false) {
                continue;
            }
            if (!isNaN(obj[prop])) {
                obj[prop] = parseFloat(obj[prop]);
            }
        }
    }

    return obj;
};
