import {Button, Input, Space} from "antd";
import {Spacer} from "../components/common/presenters/spacer/Spacer";
import {SearchOutlined} from "@ant-design/icons";
import {GetClientsListResponse} from "../api/types";
import * as React from "react";

export const tableColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
        <div style={{padding: 8}}>
            <Input placeholder={`Search`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={confirm}
            />
            <Spacer/>
            <Space>
                <Button
                    type="primary"
                    onClick={confirm}
                    icon={<SearchOutlined/>}
                    size="small"
                >
                    Search
                </Button>
                <Button onClick={clearFilters} size="small">
                    Reset
                </Button>
            </Space>
        </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
    onFilter: (value, record: GetClientsListResponse) =>
        record[dataIndex]
            ? record[dataIndex].toLowerCase().includes(value.toLowerCase())
            : false,
    render: text => text,
});
