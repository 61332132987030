import { ApiWrapper } from "api/ApiWrapper";
import {
    ApiResponse,
    GetClientEventsListResponse,
    GetClientNotesListResponse,
    GetClientResponse,
    GetClientsListResponse,
    NoteCategories,
    PatchClientBankDetailsRequest,
    PatchClientStatusRequest,
    PostGenerateOfferForClientRequest,
    PutClientRequest
} from "./types";

export const adminGetLeads = async (page: number = 1, limit: number = 20): Promise<ApiResponse<GetClientsListResponse[]>> => {
    const path = `/admin/clients?type=lead&page=${page}&limit=${limit}`;

    return ApiWrapper.get(path);
};

export const adminGetClients = async (page: number = 1, limit: number = 20): Promise<ApiResponse<GetClientsListResponse[]>> => {
    const path = `/admin/clients?type=client&page=${page}&limit=${limit}`;

    return ApiWrapper.get(path);
};

export const adminGetClient = async (clientId: string): Promise<ApiResponse<GetClientResponse>> => {
    const path = `/admin/clients/${clientId}`;

    return ApiWrapper.get(path);
};

export const adminCreateClient = async (data: PutClientRequest, note?: string): Promise<ApiResponse<string>> => {
    const path = `/admin/clients`;
    const body = {
        ...data,
        note: note,
    };

    return ApiWrapper.post(path, body);
};

export const adminUpdateClient = async (clientId: string, data: PutClientRequest): Promise<ApiResponse<string>> => {
    const path = `/admin/clients/${clientId}`;
    const body = {
        ...data,
    };

    return ApiWrapper.patch(path, body);
};

export const adminUpdateClientStatus = async (clientId: string, data: PatchClientStatusRequest): Promise<ApiResponse<string>> => {
    const path = `/admin/clients/${clientId}/status`;
    const body = {
        ...data,
    };

    return ApiWrapper.patch(path, body);
};

export const adminUpdateClientBankDetails = async (clientId: string, data: PatchClientBankDetailsRequest): Promise<ApiResponse<string>> => {
    const path = `/admin/clients/${clientId}/bank_details`;
    const body = {
        ...data,
    };

    return ApiWrapper.patch(path, body);
};

export const adminGetClientNotes = async (clientId: string, page: number): Promise<ApiResponse<GetClientNotesListResponse[]>> => {
    const path = `/admin/clients/${clientId}/notes?page=${page}`;

    return ApiWrapper.get(path);
};

export const adminGetClientEvents = async (clientId: string): Promise<ApiResponse<GetClientEventsListResponse[]>> => {
    const path = `/admin/clients/${clientId}/events`;

    return ApiWrapper.get(path);
};

export const adminPostClientNote = async (clientId: string, note: string): Promise<void> => {
    const path = `/admin/clients/${clientId}/notes`;
    const body = {
        note_text: note,
        note_category: NoteCategories.Other // there's only 1 note category as of now
    };

    return ApiWrapper.post(path, body);
};

export const adminGenerateOfferForClient = async (clientId: string, data: PostGenerateOfferForClientRequest): Promise<ApiResponse<string>> => {
    const path = `/admin/clients/${clientId}/generate_offer`;
    const body = {
        contact_person_id: data.contact_person_id,
        interest_rate_30_days: data.interest_rate_30_days,
        interest_rate_60_days: data.interest_rate_60_days,
        admin_fee_per_invoice: data.admin_fee_per_invoice,
        max_advance_payment_percent: data.max_advance_payment_percent,
        is_with_global_assignment: data.is_with_global_assignment,
        is_selective_factoring: data.is_selective_factoring,
        is_risk_assessment_completed: data.is_risk_assessment_completed,
    };

    return ApiWrapper.post(path, body);
};

export const adminGenerateContractForClient = async (clientId: string): Promise<void> => {
    const path = `/admin/clients/${clientId}/generate_contract`;

    return ApiWrapper.post(path, {});
};

export const adminGetCompanyProducts = async (): Promise<ApiResponse<string[]>> => {
    const path = `/admin/company_products`;

    return ApiWrapper.get(path);
};


export const cleanupClientContactPerson = async (clientId: string, contactPersonId: string, cpExists: boolean): Promise<void> => {
    const path = `/clients/${clientId}?cpID=${contactPersonId}&cpExists=${cpExists}`;

    return ApiWrapper.del(path, {});
};

