import * as React from "react";
import { useEffect, useState } from "react";
import styles from "./../AdminHomeTabs.module.scss";
import { AddBOUserModal } from "./AddBOUserModal";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, message, Popconfirm, Popover, Table, Typography } from "antd";
import {
    adminDisableBoUser,
    adminEnableBoUser,
    adminGetBOUsers,
    adminSendResetForBoUser,
} from "../../../../../api/boUserService";
import { GetBoUsersListItem } from "../../../../../api/types";
import { Spacer } from "components/common/presenters/spacer/Spacer";

export interface ManageBOUsersSectionProps {}

export const ManageBOUsersSection = (props: ManageBOUsersSectionProps) => {
    const { t } = useTranslation();
    const [boUsers, setBoUsers] = useState([] as GetBoUsersListItem[]);
    const [addBoUserModalOpen, setAddBoUserModalOpen] = useState(false);
    const [showDisabledBoUsers, setShowDisabledBoUsers] = useState(false);

    useEffect(() => {
        fetchBOUsers();
    }, []);

    const fetchBOUsers = async () => {
        try {
            const result = (await adminGetBOUsers()).data;
            setBoUsers(result);
        } catch (e) {
            console.error(e);
        }
    };

    const handleToggleBoUserAccount = async (boUser: GetBoUsersListItem) => {
        try {
            if (boUser.is_deleted) {
                await adminEnableBoUser(boUser.bo_user_id);
            } else {
                await adminDisableBoUser(boUser.bo_user_id);
            }
            message.success(t("messages:dataSaved"));
            fetchBOUsers();
        } catch (e) {
            message.error(t("messages:errorEncountered"));
            console.error(e);
        }
    };

    const handleSendPasswordReset = async (boUser: GetBoUsersListItem) => {
        try {
            await adminSendResetForBoUser(boUser.bo_user_id);
            message.success(t("messages:emailSent"));
            fetchBOUsers();
        } catch (e) {
            if ((e as any).response?.status === 409 || (e as any).response?.status === 404) {
                message.error(t("accountNotVerified"));
            } else {
                message.error(t("messages:errorEncountered"));
            }
            console.error(e);
        }
    };

    const renderActionButtons = (boUser: GetBoUsersListItem) => {
        return (
            <>
                <Popconfirm
                    title={boUser.is_deleted ? t("confirmUnlockAccount") : t("confirmLockAccount")}
                    onConfirm={() => handleToggleBoUserAccount(boUser)}
                    okText={t("yes")}
                    cancelText={t("no")}
                >
                    <Typography.Text className={styles.blueTextPointer}>
                        {boUser.is_deleted ? t("unlockAccount") : t("lockAccount")}
                    </Typography.Text>
                </Popconfirm>
                &nbsp; &nbsp;
                {boUser.is_email_confirmed && !boUser.is_deleted ? (
                    <Popconfirm
                        title={t("confirmSendResetPassword")}
                        onConfirm={() => handleSendPasswordReset(boUser)}
                        okText={t("yes")}
                        cancelText={t("no")}
                    >
                        <Typography.Text className={styles.blueTextPointer}>{t("sendResetPassword")}</Typography.Text>
                    </Popconfirm>
                ) : (
                    <Popover trigger={"hover"} content={t("accountNotVerified")}>
                        <Typography.Text className={styles.blueTextPointer} disabled>
                            {t("sendResetPassword")}
                        </Typography.Text>
                    </Popover>
                )}
            </>
        );
    };

    const columns = [
        // {
        //     title: t('tables:userId'),
        //     dataIndex: 'bo_user_id',
        //     render: (value) => value ? value : "-",
        // },
        {
            title: t("name"),
            dataIndex: "bo_user_name",
            render: (value) => (value ? value : "-"),
        },
        {
            title: t("email2"),
            dataIndex: "email",
            render: (value) => (value ? value : "-"),
        },
        {
            title: t("role"),
            dataIndex: "role",
            render: (value) => value,
        },
        {
            title: t("actions"),
            render: (boUser) => renderActionButtons(boUser),
        },
    ];

    return (
        <>
            <Button className={styles.addBOBtn} data-cy={"button_add_bo_user"} onClick={() => setAddBoUserModalOpen(true)}>
                {t("buttons:addEmployee")}
            </Button>
            <Checkbox checked={showDisabledBoUsers} onChange={(e) => setShowDisabledBoUsers(e.target.checked)}>
                {t("showInactiveUsers")}
            </Checkbox>
            <Spacer />

            <Table
                className={styles.wide}
                scroll={{ x: true }}
                rowKey={(record) => record.bo_user_id}
                columns={columns}
                dataSource={showDisabledBoUsers ? boUsers : boUsers?.filter((bo) => !bo.is_deleted)}
                pagination={{ position: ["bottomCenter"], pageSize: 20, total: boUsers?.length }}
            />

            <AddBOUserModal
                visible={addBoUserModalOpen}
                setVisible={setAddBoUserModalOpen}
                onDataChanged={fetchBOUsers}
            />
        </>
    );
};
