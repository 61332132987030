import { Button, DatePicker, Space } from "antd";
import moment from "moment";
import * as React from "react";
import { useState } from "react";
import { DATE_FORMAT } from "../api/apiConfig";

export const useDateRangeFilterOptions = (dataIndex: string) => {
    const [filterCreatedAt, setFilterCreatedAt] = useState([null, null]);

    return {
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
            <Space direction={"vertical"}>
                <DatePicker.RangePicker allowEmpty={[true, true]}
                    format={DATE_FORMAT.API}
                    onChange={(values) => {
                        const [from, to] = values ? values : [null, null];
                        if (!from && !to) {
                            clearFilters();
                            setFilterCreatedAt([null, null]);
                        }

                        setSelectedKeys([from?.startOf("day"), to?.endOf("day")]);
                        setFilterCreatedAt([from?.startOf("day"), to?.endOf("day")]);
                    }}
                />
                <Button onClick={() => {
                    confirm();
                }}>Ok</Button>
            </Space>
        ),
        onFilter: (v, record) => {
            if (!record[dataIndex]) {
                return false;
            }

            const [from, to] = filterCreatedAt;

            if (from && moment(record[dataIndex]).isBefore(from)) {
                return false;
            }
            if (to && moment(record[dataIndex]).isAfter(to)) {
                return false;
            }

            return true;
        },
    };
};
