import * as React from "react";
import { Button, Card, Col, Divider, Form, Input, Layout, message, Modal, Row, Tabs, Typography } from "antd";
import { ClientHeader } from "../client-dashboard/ClientHeader";
import styles from "components/common/pages/client-dashboard/ClientDashboardPage.module.scss";
import { goTo } from "navigation/navigationHelpers";
import { RouteStrings } from "routes/RouteStrings";
import { ArrowLeftOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { clientChangePassword, clientGetProfile } from "api/client";
import { useTranslation } from "react-i18next";
import { isValidPassword } from "helpers/inputValidationHelpers";
import { GetClientProfile } from "api/types";
import useMobileCheck from "helpers/useMobileCheck";
import moment from "moment";
import { currencyLocaleFormatter } from "helpers/formatters";

export const ClientSettingsPage = () => {
    const { Content } = Layout;
    const { Text } = Typography;
    const { TabPane } = Tabs;

    const [form] = Form.useForm();

    const { t } = useTranslation();

    const [profile, setProfile] = React.useState<GetClientProfile>();
    const [error, setError] = React.useState<string>("");

    const [modalOpen, setModalOpen] = React.useState<boolean>(false);

    const isMobile = useMobileCheck();

    const getClientProfile = async () => {
        try {
            const profileReq = await clientGetProfile();
            setProfile(profileReq.data);
        } catch (err) {
            console.error(err);
            setError(t("messages:errorEncountered"));
        }
    };

    React.useEffect(() => {
        getClientProfile();
    }, []);

    const handleOnFinish = async (values: any) => {
        if (values.password !== values.password_repeat) {
            void message.error(t("passwordsMatchError"));
        } else {
            try {
                await clientChangePassword(values.oldPassword, values.newPassword);
                void message.success(t("passChangeSuccess"));
            } catch (err) {
                void message.error(err);
            }
        }
    };

    const handleModalOk = () => {
        console.log("delete user account");
        setModalOpen(false);
    };

    const renderSectionPersonalInfo = () => {
        return (
            <Card
                title={<Typography.Title level={5}>Persönliche Angaben</Typography.Title>}
                extra={
                    <span className={styles.editBtn} onClick={() => {}}>
                        {<EditOutlined />}
                        {t("buttons:adjust")}
                    </span>
                }
            >
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Ansprache :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{profile?.salutation}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={8} className={styles.cardInfoLabel}>
                                Ansprache :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{profile?.salutation}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Vorname :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{profile?.first_name}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={8} className={styles.cardInfoLabel}>
                                Vorname :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{profile?.first_name}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Nachname :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{profile?.last_name}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={8} className={styles.cardInfoLabel}>
                                Nachname :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{profile?.last_name}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Geburtsdatum :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{moment(profile?.birth_date).format("DD.MM.YYYY")}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={8} className={styles.cardInfoLabel}>
                                Geburtsdatum :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{moment(profile?.birth_date).format("DD.MM.YYYY")}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Geburtsort :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{profile?.birth_city}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={8} className={styles.cardInfoLabel}>
                                Geburtsort :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{profile?.birth_city}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Nationalität :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{profile?.nationality_country_code}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={8} className={styles.cardInfoLabel}>
                                Nationalität :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{profile?.nationality_country_code}</div>
                            </Col>
                        </>
                    )}
                </Row>
            </Card>
        );
    };

    const renderAddressCard = () => {
        const hasPreviousAddress: boolean =
            !!profile?.prev_address_street &&
            !!profile?.prev_address_post_code &&
            !!profile?.prev_address_city &&
            !!profile?.prev_address_country_code;

        return (
            <Card
                title={<Typography.Title level={5}>Address</Typography.Title>}
                extra={
                    <span className={styles.editBtn} onClick={() => {}}>
                        {<EditOutlined />}
                        {t("buttons:adjust")}
                    </span>
                }
            >
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Addresse :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>
                                    {profile?.address_street} {profile?.address_house_number}
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>
                                    {profile?.address_post_code} {profile?.address_city}
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{profile?.address_country_code}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={8} className={styles.cardInfoLabel}>
                                Addresse :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>
                                    {profile?.address_street} {profile?.address_house_number}
                                </div>
                            </Col>
                            <Col span={12} offset={12}>
                                <div className={styles.info}>
                                    {profile?.address_post_code} {profile?.address_city}
                                </div>
                            </Col>
                            <Col span={12} offset={12}>
                                <div className={styles.info}>{profile?.address_country_code}</div>
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                {t("residentSince")} :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>
                                    {moment(profile?.address_resident_since).format("DD.MM.YYYY")}
                                </div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={8} className={styles.cardInfoLabel}>
                                {t("residentSince")} :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>
                                    {moment(profile?.address_resident_since).format("DD.MM.YYYY")}
                                </div>
                            </Col>
                        </>
                    )}
                </Row>
                {hasPreviousAddress && (
                    <Row>
                        <Col span={8} className={styles.cardInfoLabel}>
                            Vorherige Adresse :
                        </Col>
                        <Col span={12}>
                            <div className={styles.info}>
                                {profile?.prev_address_street}
                                {profile?.prev_address_house_number}
                            </div>
                        </Col>
                        <Col span={12} offset={12}>
                            <div className={styles.info}>
                                {profile?.prev_address_post_code}
                                {profile?.prev_address_city}
                            </div>
                        </Col>
                        <Col span={12} offset={12}>
                            <div className={styles.info}>{profile?.prev_address_country_code}</div>
                        </Col>
                    </Row>
                )}
            </Card>
        );
    };

    const renderEmploymentCard = () => {
        return (
            <Card
                title={<Typography.Title level={5}>Employment details</Typography.Title>}
                extra={
                    <span className={styles.editBtn} onClick={() => {}}>
                        {<EditOutlined />}
                        {t("buttons:adjust")}
                    </span>
                }
            >
                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Regular occupation :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{profile.job_title}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={8} className={styles.cardInfoLabel}>
                                Regular Occupation :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{profile.job_title}</div>
                            </Col>
                        </>
                    )}
                </Row>

                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Industry :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{profile.job_branch}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={8} className={styles.cardInfoLabel}>
                                Industry :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{profile.job_branch}</div>
                            </Col>
                        </>
                    )}
                </Row>

                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Professional group :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{profile.job_type}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={8} className={styles.cardInfoLabel}>
                                Professional group :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{profile.job_type}</div>
                            </Col>
                        </>
                    )}
                </Row>

                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Employed since :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{profile.employed_since}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={8} className={styles.cardInfoLabel}>
                                Employed since :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{profile.employed_since}</div>
                            </Col>
                        </>
                    )}
                </Row>

                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Employer :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{profile.employer_name}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={8} className={styles.cardInfoLabel}>
                                Employer :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{profile.employer_name}</div>
                            </Col>
                        </>
                    )}
                </Row>

                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Location :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{profile.employer_location}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={8} className={styles.cardInfoLabel}>
                                Location :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{profile.employer_location}</div>
                            </Col>
                        </>
                    )}
                </Row>

                <Row>
                    {isMobile ? (
                        <>
                            <Col span={24} className={styles.cardInfoLabelMobile}>
                                Net income (p.m) :
                            </Col>
                            <Col span={24}>
                                <div className={styles.info}>{currencyLocaleFormatter(profile.net_income)}</div>
                            </Col>
                            <Divider />
                        </>
                    ) : (
                        <>
                            <Col span={8} className={styles.cardInfoLabel}>
                                Net income (p.m) :
                            </Col>
                            <Col span={12}>
                                <div className={styles.info}>{currencyLocaleFormatter(profile.net_income)}</div>
                            </Col>
                        </>
                    )}
                </Row>
            </Card>
        );
    };
    const renderClientInfo = () => {
        return (
            <>
                {renderSectionPersonalInfo()}
                <Spacer />
                {renderAddressCard()}
                <Spacer />
                {renderEmploymentCard()}
            </>
        );
    };
    return (
        <Layout>
            <ClientHeader />

            <Content className={styles.contentWrapper}>
                <section className={styles.content}>
                    <Text style={{ cursor: "pointer" }} onClick={() => goTo(RouteStrings.ClientDashboard)}>
                        <ArrowLeftOutlined /> Back to Loans
                    </Text>
                    <Spacer />
                </section>

                <section className={styles.tabWrapper}>
                    <Tabs defaultActiveKey="1" className={styles.tabs}>
                        <TabPane tab="Account" key="1">
                            <Row gutter={20}>
                                <Col span={24}>
                                    <Card title={<Typography.Title level={5}>Email</Typography.Title>}>
                                        <Row>
                                            <Col>
                                                <span>E-Mail: &nbsp;</span>
                                            </Col>
                                            <Col>{profile ? profile.email : ""}</Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Spacer />
                            <Row>
                                <Col span={24}>
                                    <Card title={<Typography.Title level={5}>Passwort</Typography.Title>}>
                                        <Form
                                            className={styles.passwordForm}
                                            layout={"vertical"}
                                            form={form}
                                            onFinish={handleOnFinish}
                                            scrollToFirstError
                                        >
                                            <Col span={24}>
                                                <Form.Item
                                                    name="oldPassword"
                                                    label="Old Password"
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password
                                                        data-cy="input_password_old"
                                                        className={styles.input}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={24}>
                                                <Form.Item
                                                    name="newPassword"
                                                    label={t("password")}
                                                    hasFeedback
                                                    rules={[
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (value && !isValidPassword(value)) {
                                                                    return Promise.reject(t("errors:passwordInvalid"));
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input.Password data-cy="input_password" className={styles.input} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="newPassword_repeat"
                                                    label={t("passwordRepeat")}
                                                    dependencies={["password"]}
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t("errors:confirmPassword"),
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (!value || getFieldValue("newPassword") === value) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(t("errors:passwordsDoNotMatch"));
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input.Password
                                                        data-cy="input_password_repeat"
                                                        className={styles.input}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <div className="custom-error">{error}</div>
                                            <Spacer />

                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    {t("buttons:changePassword")}
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Card>
                                </Col>
                            </Row>
                            <Spacer />
                            <Card title={<Typography.Title level={4}>Delete Account</Typography.Title>}>
                                <Typography.Text>If you’re absolutely sure:</Typography.Text>
                                <span
                                    className={styles.delete}
                                    onClick={() => {
                                        setModalOpen(true);
                                    }}
                                >
                                    {" "}
                                    Delete your account
                                </span>
                            </Card>
                            <Modal
                                maskClosable
                                visible={modalOpen}
                                // onOk={handleModalOk}
                                onCancel={() => {
                                    setModalOpen(false);
                                }}
                                footer={[
                                    <Button
                                        key="back"
                                        onClick={() => {
                                            setModalOpen(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>,
                                    " ",
                                    <button onClick={handleModalOk} className={styles.deleteBtn}>
                                        Delete Account
                                    </button>,
                                ]}
                            >
                                <div className={styles.modalTitle}>
                                    <ExclamationCircleOutlined className={styles.errorIcon} />
                                    <Typography.Title level={5}>Delete your account?</Typography.Title>
                                </div>

                                <p className={styles.modalInfo}>
                                    Your account will be permanently deleted. This cannot be undone.
                                </p>
                            </Modal>
                        </TabPane>
                        <TabPane tab="Data & Privacy" key="2">
                            {profile && renderClientInfo()}

                            <Spacer />
                            <Card title={<Typography.Title level={5}>Personal Data Request</Typography.Title>}>
                                <Button>Download All Personal Data</Button>
                            </Card>
                        </TabPane>
                    </Tabs>
                </section>
            </Content>
        </Layout>
    );
};
