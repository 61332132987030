import { Card, Col, Divider, Empty, Layout, Row, Tag, Typography, Pagination } from "antd";
import { clientGetApplications } from "api/client";
import styles from "components/common/pages/client-dashboard/ClientDashboardPage.module.scss";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { currencyLocaleFormatter } from "formatters/currencyFormatters";
import useMobileCheck from "helpers/useMobileCheck";
import * as React from "react";
import { PaginationType } from "types/adminPanel";
import { ClientHeader } from "./ClientHeader";

interface ClientApplication {
    application_id: string
    short_id: string
    type: string
    status: string
    currency: string
    amount: number
    duration_months: number
    created_at: string
}

export const ClientDashboardPage = () => {
    const { Content } = Layout;
    const isMobile = useMobileCheck();

    const [applications, setApplications] = React.useState<ClientApplication[]>([]);
    const [applPagination, setApplPagination] = React.useState<PaginationType>({});

    React.useEffect(() => {
        getApplications(1);
    }, []);

    const getApplications = async (page: number) => {
        try {
            const { data } = await clientGetApplications(page);
            setApplications(data.result);
            setApplPagination(data.pagination);
        } catch (err) {
            console.error(err);
        }
    };

    const getColor = (status: string) => {
        switch (status) {
        case "open":
            return "blue";
        case "manual_review":
            return "orange";
        case "rejected":
            return "red";
        case "success":
            return "green";
        default:
            return "blue";
        }
    };
    const renderApplications = () => {
        return (
            <div className={styles.applWithPagination}>
                <Card title={<span style={{ color: "#0000008C" }}>My Loans</span>} style={{ width: "50vw" }}>
                    {applications.map((appl, idx) => {
                        return (
                            <React.Fragment key={`appl-${appl.short_id}`}>
                                <Row justify="space-between" gutter={24}>
                                    <Col span={isMobile ? 12 : 8}>
                                        <Typography.Title level={5}>{appl.application_id}</Typography.Title>
                                    </Col>
                                    <Col span={isMobile ? 12 : 6}>
                                        <Typography.Title level={5}>
                                            {appl.currency} {currencyLocaleFormatter(appl.amount)}
                                        </Typography.Title>
                                    </Col>
                                </Row>

                                <Row justify="space-between" gutter={24}>
                                    <Col span={isMobile ? 12 : 8}>
                                        <Typography.Text>{appl.created_at}</Typography.Text>
                                    </Col>
                                    <Col span={isMobile ? 12 : 6}>
                                        <Tag color={getColor(appl.status)}>
                                            {appl.status === "acquiring_offers_error" ? "Manual Review" : appl.status}
                                        </Tag>
                                    </Col>
                                </Row>
                                {idx !== applications.length - 1 && <Divider />}
                            </React.Fragment>
                        );
                    })}
                </Card>
                {applPagination?.total_rows > 10 ? (
                    <Pagination
                        current={applPagination.current_page}
                        total={applPagination.total_rows}
                        onChange={(pageNumber) => getApplications(pageNumber)}
                    />
                ) : null}
                
            </div>
        );
    };

    return (
        <Layout>
            <ClientHeader />

            <Content className={styles.sectionRight}>
                <Spacer />

                <Row className={styles.rowCenterX}>
                    {applications.length ? (
                        renderApplications()
                    ) : (
                        <Empty description="You currently do not have any applications" />
                    )}
                </Row>
            </Content>
        </Layout>
    );
};
