import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Row } from "antd";
import { loginClientUser, passwordResetClient } from "api/client/client";
import { loginAdmin, passwordResetAdmin } from "api/loginService";
import styles from "components/common/pages/authentication-page/AuthenticationPage.module.scss";
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { isAdminApp, isClientApp } from "helpers/appHelpers";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { RouteStrings } from "routes/RouteStrings";
export interface AuthenticationPageProps {}

export const AuthenticationPage = (props: AuthenticationPageProps) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [form] = Form.useForm();
    const [resetForm] = Form.useForm();

    const [token, setToken] = useState<string>(undefined);
    const [error, setError] = useState(null);

    const [resetPage, setResetPage] = useState<boolean>(false);
    const [resetSuccessPage, setResetSuccessPage] = useState<boolean>(false);

    function useQuery() {
        return new URLSearchParams(window?.location?.search);
    }

    const query = useQuery();

    const handleOnFinish = async (values) => {
        const { email, password } = values;

        if (email == null || email.length < 5 || password == null || password.length < 5) {
            return;
        }

        try {
            await setError("");

            if (isAdminApp()) {
                await loginAdmin(email, password);
                history.push(RouteStrings.AdminHome);
            }

            if (isClientApp()) {
                await loginClientUser(email, password);

                const userToken = token ? `?token=${token}` : "";
                history.push({
                    pathname: RouteStrings.ClientDashboard,
                    search: userToken,
                });
            }
        } catch (e: any) {
            if (e.response?.status === 401) {
                if (e.response?.data === "ErrEmailNotConfirmed") {
                    setError(t("errors:emailNotConfirmed"));
                } else {
                    setError(t("errors:incorrectCredentials"));
                }
                return;
            }
            console.error(e);
            setError(t("errors:loginError"));
        }
    };

    const handleForgotPassword = async () => {
        const email: string = resetForm.getFieldValue("resetEmail");
        try {
            if (isAdminApp()) {
                await passwordResetAdmin(email);
            }

            if (isClientApp()) {
                await passwordResetClient(email);
            }

            setResetPage(false);
            setResetSuccessPage(true);
            // message.success(t("messages:emailSent"), 2); // even is no such email existed
        } catch (e) {
            console.error(e);
            message.error(t("messages:errorEncountered"), 2);
        }
    };

    React.useEffect(() => {
        try {
            const queryEmail = query.get("email");
            const queryToken = query.get("token");

            if (queryEmail) {
                form.setFieldsValue({
                    email: queryEmail,
                });
            }

            if (token) {
                setToken(queryToken);
            }
        } catch (err) {
            console.error(err);
        }
    }, []);

    return (
        <div className={styles.page}>
            <ApplicationProcessHeader hideNav={true} loginPage />
            <div className={styles.innerContainer}>
                {!resetPage && !resetSuccessPage && (
                    <Form layout="vertical" form={form} onFinish={handleOnFinish} scrollToFirstError>
                        <div className="auth-page__logo"></div>

                        <Form.Item>
                            <h2 className={"forgot"}>{isAdminApp() ? "Admin Login" : "Kunden Login"}</h2>
                        </Form.Item>

                        <Form.Item
                            className={styles.formItem}
                            label={t("email3")}
                            name="email"
                            rules={[{ required: true, type: "email", message: t("errors:emailInvalid") }]}
                        >
                            <Input data-cy="input_email" />
                        </Form.Item>

                        <Form.Item
                            className={styles.formItem}
                            label={t("password")}
                            name="password"
                            rules={[{ required: true, message: t("errors:passwordInvalid") }]}
                        >
                            <Input.Password data-cy="input_password" />
                        </Form.Item>

                        <Form.Item>
                            <div className={styles.error}>{error}</div>
                        </Form.Item>
                        <Form.Item>
                            <Row className={styles.rowCenterX}>
                                <Button type="primary" htmlType="submit">
                                    {t("buttons:login2")}
                                </Button>
                            </Row>
                        </Form.Item>
                        <Form.Item>
                            {t("forgotPassword2")}
                            &nbsp;
                            <a
                                className="forgot"
                                onClick={() => {
                                    setResetPage(true);
                                }}
                            >
                                {t("clickHere2")}
                            </a>
                        </Form.Item>
                    </Form>
                )}
                {resetPage && (
                    <Form
                        layout="vertical"
                        form={resetForm}
                        className={styles.resetForm}
                        onFinish={handleForgotPassword}
                        scrollToFirstError
                    >
                        <Form.Item className={styles.heading}>
                            <h2>Passwort vergessen?</h2>
                        </Form.Item>
                        <Form.Item>
                            <p className={styles.forgotText}>
                                Keine Sorge. Geben Sie hier die E-Mail-Adresse von Ihrem Liquitree Konto ein.{" "}
                            </p>
                        </Form.Item>
                        <Form.Item
                            className={styles.resetItem}
                            label={t("email3")}
                            name="resetEmail"
                            rules={[{ required: true, type: "email", message: t("errors:emailInvalid") }]}
                        >
                            <Input data-cy="input_email" />
                        </Form.Item>
                        <Spacer />
                        <Form.Item className={styles.resetBtn}>
                            <Row className={styles.rowCenterX}>
                                <Button type="primary" htmlType="submit">
                                    Passwort zurücksetzen
                                </Button>
                            </Row>
                        </Form.Item>

                        <Form.Item>
                            Sie kennen Ihr Passwort? &nbsp;
                            <a
                                className="forgot"
                                onClick={() => {
                                    setResetPage(false);
                                }}
                            >
                                Hier geht's zum Login
                            </a>
                        </Form.Item>
                    </Form>
                )}
                {resetSuccessPage && (
                    <Form
                        layout="vertical"
                        onFinish={() => {
                            setResetSuccessPage(false);
                        }}
                        scrollToFirstError
                    >
                        <Form.Item className={styles.heading}>
                            <h2>E-Mail wurde versendet</h2>
                        </Form.Item>
                        <Form.Item>
                            <CheckCircleOutlined className={styles.successIcon} />
                        </Form.Item>
                        <Form.Item>
                            <p className={styles.successText}>
                                Wenn Sie mit der angegebenen E-Mail-Adresse bei uns registeriert sind, haben wir Ihnen
                                eine E-Mail versendet, mit welcher Sie Ihr Passwort ändern können.
                            </p>
                        </Form.Item>
                        <Spacer />

                        <Form.Item>
                            <Row className={styles.rowCenterX}>
                                <Button type="primary" htmlType="submit">
                                    Zurück zum Login
                                </Button>
                            </Row>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </div>
    );
};
