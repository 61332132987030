// Higher level helpers: app config related
import config from "config";
import { AppType, Environments } from "types/app";

export const isClientApp = (): boolean => {
    return config?.appType === AppType.Client;
};

export const isMerchantApp = (): boolean => {
    return config?.appType === AppType.Merchant;
};

export const isAdminApp = (): boolean => {
    return config?.appType === AppType.Admin;
};

export const isProductionApp = (): boolean => {
    return config?.env === Environments.Production;
};
