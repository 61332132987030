
/**
 * Pause execution for a certain amount of milliseconds.
 *
 * @param milliseconds
 * @returns 
 */
export const wait = async (milliseconds: number) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(undefined);
        }, milliseconds);
    });
};
