import { AppType } from "types/app";

const config = {
    api: {
        USER_SERVICE_URL: process.env.REACT_APP_USER_SERVICE_URL,
        LENDING_SERVICE_URL: process.env.REACT_APP_LENDING_SERVICE_URL,
        URL_BASE: process.env.REACT_APP_API_URL_BASE,
    },
    env: process.env.ENV,
    reactAppVersion: process.env.REACT_APP_VERSION,
    appType: process.env.REACT_APP_TYPE as AppType
};

export default config;
