import { ApiWrapper } from "api/ApiWrapper";
import { ApiResponse, LogoPutRequest, LogoRequest, MerchantUser, PostOrPutMerchant } from "api/types";
import { PostMerchantUser } from "api/types/merchant";

export const adminCreateMerchant = (merchant: PostOrPutMerchant): Promise<any> => {
    const path = `/admin/merchants`;

    return ApiWrapper.post(path, merchant);
};

export const adminGetMerchants = async (page: number, searchStr?: string): Promise<any> => {
    let path;

    if (searchStr) {
        path = `/admin/merchants?text=${searchStr}&page=${page}`;
    } else {
        path = `/admin/merchants?page=${page}`;
    }

    return ApiWrapper.get(path) as Promise<any>;
};

export const adminGetMerchantUsers = async (page: number, searchStr?: string): Promise<any> => {
    let path;

    if (searchStr) {
        path = `/admin/musers?text=${searchStr}&page=${page}`;
    } else {
        path = `/admin/musers?page=${page}`;
    }

    return ApiWrapper.get(path) as Promise<any>;
};

export const adminGetMerchant = async (merchantId: string): Promise<any> => {
    const path = `/admin/merchants/${merchantId}`;

    return ApiWrapper.get(path);
};
export const adminDisableMerchant = async (merchantId: string): Promise<any> => {
    const path = `/admin/merchants/${merchantId}/disable`;

    return ApiWrapper.put(path,{id:merchantId});
};

export const adminEnableMerchant = async (merchantId: string): Promise<any> => {
    const path = `/admin/merchants/${merchantId}/enable`;

    return ApiWrapper.put(path,{id:merchantId});
};
export const adminHandleMerchantUsers = async (mUserId: string, is_enabled: boolean): Promise<any> =>{
    const path = `/admin/musers/${mUserId}/enabled`;

    return ApiWrapper.post(path,{is_enabled});

};

export const adminResetMerchantUser = async (mUserId: string): Promise<any> =>{
    const path = `/admin/musers/${mUserId}/reset`;

    return ApiWrapper.post(path,{id:mUserId});

};


export const adminPutMerchantLogo = async (merchantId: string, file: any): Promise<void> =>{
    const path = `/admin/merchants/${merchantId}/logo`;

    return ApiWrapper.put(path,{image_data_uri:file});
};

export const adminGetMerchantApplications = async (merchantId: string, page: number): Promise<any> => {
    const path = `/admin/merchants/${merchantId}/applications?page=${page}`;

    return ApiWrapper.get(path);
};

export const adminGetMerchantCreatedUsers = async (merchantId: string, page: number): Promise<any> => {
    const path = `/admin/merchants/${merchantId}/musers?page=${page}`;

    return ApiWrapper.get(path);
};

export const adminCreateMerchantUser = async (merchantId: string, merchantUser: PostMerchantUser): Promise<any> => {
    const path = `/admin/merchants/${merchantId}/musers`;

    const body = {
        musers: [
            { ...merchantUser }
        ]
    };

    return ApiWrapper.post(path, body);
};


export const adminGetMerchantUser = async (muserId: string): Promise<ApiResponse<MerchantUser>> => {
    const path = `/admin/musers/${muserId}`;

    return ApiWrapper.get(path);
};

export const adminGetMerchantApiKey = async(merchantId: string): Promise<any> =>{
    const path = `/admin/merchants/${merchantId}/settings/apikey`;

    return ApiWrapper.get(path);
};
export const adminGenerateMerchantApiKey = async(merchantId: string): Promise<any> =>{
    const path = `/admin/merchants/${merchantId}/settings/generate_apikey`;

    return ApiWrapper.post(path,{merchantid:merchantId});
};

export const adminUpdateMerchantName = async (merchantId: string,name: string, display_name: string): Promise<any> => {
    const path = `/admin/merchants/${merchantId}`;
    
    return ApiWrapper.put(path,{id:merchantId,name, display_name});
};

export const adminUpdateMerchant = async (merchantId: string,body): Promise<any> => {
    const path = `/admin/merchants/${merchantId}`;
    
    return ApiWrapper.put(path,{...body});
};


export const adminUpdateMerchantDisplayName = async(merchantId: string, displayName: string): Promise<any> => {

    const path = `/admin/merchants/${merchantId}/display_name`;

    return ApiWrapper.put(path,{display_name: displayName});

};

export const merchantCheckRegisterTokenValid = async (registerToken: string): Promise<ApiResponse<void>> => {
    const path = `/merchant/account/invites/${registerToken}`;

    return ApiWrapper.get(path, true);
};


export const merchantUpdateMerchantDisplayName = async(displayName: string): Promise<any> => {

    const path = `/merchant/settings/display_name`;

    return ApiWrapper.put(path,{display_name: displayName});

};

export const merchantRegisterAccount = async (registerToken: string, newPassword: string): Promise<ApiResponse<void>> => {
    const path = `/merchant/account/register`;

    const body = {
        password: newPassword,
        token: registerToken,
    };

    return ApiWrapper.post(path, body, true);
};

export const MerchantGetOriginalLogo = async (): Promise<ApiResponse<LogoRequest>>  => {
    const path = `/merchant/settings/original_logo`;

    return ApiWrapper.get(path);
};

export const MerchantUploadOriginalLogo = async (uri: ArrayBuffer | string): Promise<ApiResponse<LogoPutRequest>>  => {
    const path = `/merchant/settings/original_logo`;

    return ApiWrapper.put(path,{image_data_uri:uri});
};