import { ApiWrapper } from "api/ApiWrapper";
import { ApiResponse, PostBOUserRequest } from "api/types";

export const adminCreateBOUser = async (req: PostBOUserRequest): Promise<ApiResponse<void>> => {
    const path = `/admin/bo_users`;

    const body = {
        name: req.name,
        email: req.email,
        role: req.role,
    };

    return ApiWrapper.post(path, body);
};
