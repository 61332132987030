import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Spin } from 'antd';
import styles from 'components/common/pages/application-flow/ApplicationFlow.module.scss';
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { CustomPageHeader } from "components/common/presenters/custom-page-header/CustomPageHeader";
import { Spacer } from 'components/common/presenters/spacer/Spacer';
import { isAdminApp, isClientApp } from 'helpers/appHelpers';
import { checkAuth, checkAuthMerchant } from 'helpers/authHelpers';
import hashHistory from 'helpers/hashHistory';
import history from 'helpers/history';
import { pageLoad } from 'helpers/pageLoad';
import { preventStepSkip } from 'helpers/preventStepSkip';
import useMobileCheck from 'helpers/useMobileCheck';
import * as React from "react";
import { useTranslation } from 'react-i18next';
import { RouteStrings } from "routes/RouteStrings";
import { setLastSuccessPage } from 'storage/actions/appActions';
import { AppContext } from 'storage/context/appContext';
import { LocalApplicationFlowDataManager } from 'storage/LocalApplicationFlowDataManager';

export const ApplicationStepLoanInfo = () => {
    const { t } = useTranslation();

    const [checkingLoggedIn, setCheckingLoggedIn] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loanAmount, setLoanAmount] = React.useState<number>(undefined);
    const [loanDuration, setLoanDuration] = React.useState<number>(undefined);
    const [{lastSuccessPage}, dispatch] = React.useContext(AppContext);

    const isMobile = useMobileCheck();
    // on finish is fired after succesful validation of ALL form fields
    const handleContinue = async () => {
        if (loading) {
            return null;
        }

        await setLoading(true);

        const applDataManager = new LocalApplicationFlowDataManager();

        const appl = applDataManager.get();
        appl.amount = loanAmount;
        appl.duration_months = loanDuration;

        applDataManager.update(appl);

        try {
            dispatch(setLastSuccessPage(RouteStrings.ApplicationFlowStepLoanInfo));

            hashHistory.push(RouteStrings.ApplicationFlowStepTwo);
        } catch (error) {
            console.error(error);

            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (isClientApp() || isAdminApp()) {
            checkAuth(setCheckingLoggedIn, null, () => {
                hashHistory.push(RouteStrings.ApplicationFlowStepOne);
            });
        } else {
            checkAuthMerchant(setCheckingLoggedIn, null, () => {
                history.push(RouteStrings.Login);
            });
        }

        pageLoad(dispatch, RouteStrings.ApplicationFlowStepLoanInfo);

        preventStepSkip(lastSuccessPage, hashHistory);
    }, []);


    return (
        <div className={styles.page}>
            <ApplicationProcessHeader checkLoggedInStatus />
            <CustomPageHeader small={isMobile}/>
            <div className={styles.container}>
                {checkingLoggedIn ?
                    <Spin className={styles.spin} indicator={<Loading3QuartersOutlined style={{ fontSize: 34 }} spin />} />
                    :
                    <React.Fragment>
                        <h2 className={styles.processTitle}>{t("applProcess:titleStepLoanInfo")}</h2>
                        <h4 className={styles.processTitle}>{t("applProcess:subtitleStepLoanInfo")}</h4>
                        <div className={styles.innerContainer}>

                            <Row gutter={24}>
                                <Col span={24}>
                                    <h4>{ t("loanAmount") }</h4>
                                    <Input data-cy="input_iban"
                                        placeholder={`${t("example")} 20,000`}
                                        type={"number"}
                                        value={loanAmount}
                                        onChange={(e) => {
                                            const amount = parseInt(e.target.value);

                                            if (!isNaN(amount)) {
                                                setLoanAmount(amount);
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Spacer/>
                    
                            <Row gutter={24}>
                                <Col span={24}>
                                    <h4>{ t("loanDuration") }</h4>
                                    <Input data-cy="input_iban"
                                        placeholder={`${t("example")} 6`}
                                        type={"number"}
                                        value={loanDuration}
                                        onChange={(e) => {
                                            const duration = parseInt(e.target.value);

                                            if (!isNaN(duration)) {
                                                setLoanDuration(duration);
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>

                            <Spacer hx={2}/>

                            <Row className={styles.rowCenterX}>
                                <Button type="primary" htmlType="submit"
                                    loading={loading} onClick={handleContinue}>
                                    {t("buttons:continue")}
                                </Button>
                            </Row>

                        </div>
                    </React.Fragment>
                }
            </div >
        </div>
    );
};
