
import { EditOutlined } from "@ant-design/icons";
import { currencyLocaleFormatter } from "formatters/currencyFormatters";
import { formatDateForTable } from "helpers/timeHelpers";
import i18n from "i18n/i18n";
import * as React from "react";
import { Link } from "react-router-dom";
import { RouteStrings } from "routes/RouteStrings";

export const columns = [
    {
        title: i18n.t("tables:clientName"),
        dataIndex: 'name',
        key: 'name',
        render: (_, appl) => {
            if (appl?.client_first_name == null && appl?.client_last_name == null) {
                return "";
            } 

            return `${appl?.client_first_name} ${appl?.client_last_name}`;
        }
    },
    {
        title: i18n.t("tables:applicationId"),
        dataIndex: 'short_id',
        key: 'short_id',
    },
    {
        title: i18n.t("tables:createdAt"),
        dataIndex: 'created_at',
        key: 'created_at',
        render: (_, appl) => {
            return formatDateForTable(appl?.created_at);
        },
        sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
    },
    {
        title: i18n.t("tables:amount"),
        dataIndex: 'amount',
        key: 'amount',
        sorter: (a, b) => Number.parseInt(a.amount) - Number.parseInt(b.amount),
        render: (_, appl) => {
            return currencyLocaleFormatter(appl?.amount);
        }
    },
    
    {
        title: i18n.t("tables:status"),
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: '',
        dataIndex: 'view',
        key: 'view',
        render: (_, appl) => {
            let link = RouteStrings.AdminApplicationDrilldown.replace(":applicationId", appl?.application_id);

            return (
                <Link to={link}><EditOutlined /> { i18n.t("buttons:view") }</Link>
            );
        }
    }
];
