import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, message, Popconfirm, Popover, Table, Typography } from "antd";
import {
    merchantDisableMerchantUser,
    merchantEnableMerchantUser,
    merchantGetMerchantUsers,
    merchantSendResetForMerchant,
} from "api/merchant";
import { MerchantUser } from "api/types/merchant";
import styles from "components/admin/presenters/admin-home-tabs/AdminHomeTabs.module.scss";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { formatDateForTable } from "helpers/timeHelpers";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaginationType } from "types/adminPanel";
import { AddMerchantUserModal } from "./AddMerchantUserModal";

export interface ManageMerchantUsersSectionProps {}

export const ManageMerchantUsersSection = (props: ManageMerchantUsersSectionProps) => {
    const { t } = useTranslation();

    const [merchantUsers, setMerchantUsers] = useState([] as MerchantUser[]);
    const [pagination, setPagination] = useState<PaginationType>({});
    const [addMerchantUserModalOpen, setAddMerchantUserModalOpen] = useState(false);
    const [showDisabledMerchantUsers, setShowDisabledMerchantUsers] = useState(false);

    useEffect(() => {
        fetchMerchantUsers();
    }, []);

    const fetchMerchantUsers = async (page?: number) => {
        try {
            const merchantUserReq = (await merchantGetMerchantUsers(page ? page : 1)).data;
            setMerchantUsers(merchantUserReq.result);
            setPagination(merchantUserReq.pagination);
        } catch (e) {
            console.error(e);
        }
    };

    const handleToggleBoUserAccount = async (merchantUser: MerchantUser) => {
        try {
            if (merchantUser.is_deleted) {
                await merchantEnableMerchantUser(merchantUser.entity_id);
            } else {
                await merchantDisableMerchantUser(merchantUser.entity_id);
            }
            message.success(t("messages:dataSaved"));
            fetchMerchantUsers();
        } catch (e) {
            message.error(t("messages:errorEncountered"));
            console.error(e);
        }
    };

    const handleSendPasswordReset = async (merchantUser: MerchantUser) => {
        try {
            await merchantSendResetForMerchant(merchantUser.email);

            message.success(t("messages:emailSent"));
            fetchMerchantUsers();
        } catch (e: any) {
            if (e.response?.status === 409 || e.response?.status === 404) {
                message.error(t("accountNotVerified"));
            } else {
                message.error(t("messages:errorEncountered"));
            }
            console.error(e);
        }
    };

    const renderActionButtons = (merchantUser: MerchantUser) => {
        return (
            <>
                <Popconfirm
                    title={merchantUser.is_deleted ? t("confirmUnlockAccount") : t("confirmLockAccount")}
                    onConfirm={() => handleToggleBoUserAccount(merchantUser)}
                    okText={t("yes")}
                    cancelText={t("no")}
                >
                    <Typography.Text className={styles.blueTextPointer}>
                        {merchantUser.is_deleted ? t("unlockAccount") : t("lockAccount")}
                    </Typography.Text>
                </Popconfirm>

                &nbsp; &nbsp;

                {merchantUser.is_registered && !merchantUser.is_deleted ? (
                    <Popconfirm
                        title={t("confirmSendResetPassword")}
                        onConfirm={() => handleSendPasswordReset(merchantUser)}
                        okText={t("yes")}
                        cancelText={t("no")}
                    >
                        <Typography.Text className={styles.blueTextPointer}>{t("sendResetPassword")}</Typography.Text>
                    </Popconfirm>
                ) : (
                    <Popover trigger={"hover"} content={t("accountNotVerified")}>
                        <Typography.Text disabled>{t("sendResetPassword")}</Typography.Text>
                    </Popover>
                )}
            </>
        );
    };

    const columns = [
        {
            title: t("id"),
            dataIndex: "short_id",
            width: 120,
            render: (value) => (value ? value : "-"),
        },
        {
            title: t("name"),
            render: (merchantUser: MerchantUser) => {
                return `${merchantUser.first_name} ${merchantUser.last_name}`;
            },
        },
        {
            title: t("email2"),
            dataIndex: "email",
            width: 350,
            ellipsis: true,
            render: (_, merchantUser) => {
                return (
                    <React.Fragment>
                        {/* { merchantUser?.email ? merchantUser?.email : "-" } */}
                        <Popover content={merchantUser?.email ? merchantUser?.email : "-"} title={t("tables:email")}>
                            {merchantUser?.email ? merchantUser?.email : "-"}
                        </Popover>
                    </React.Fragment>
                );
            },
        },
        {
            title: t("mobileNumber"),
            dataIndex: "mobile_number",
            width: 200,
            render: (value) => (value ? value : "-"),
        },
        {
            title: t("tables:createdAt"),
            dataIndex: "created_at",
            width: 120,
            render: (value) => (value ? formatDateForTable(value) : "-"),
        },
        {
            title: t("actions"),
            render: (merchantUser) => renderActionButtons(merchantUser),
        },
        // render: (value) => value ? t(`bo_user_roles:${value}`) : "-",
    ];

    return (
        <>
            <Button
                data-cy={"button_add_bo_user"}
                className={styles.addBOBtn}
                onClick={() => setAddMerchantUserModalOpen(true)}
            >
                <PlusOutlined />
                {t("buttons:addEmployee")}
            </Button>

            <Checkbox
                checked={showDisabledMerchantUsers}
                onChange={(e) => setShowDisabledMerchantUsers(e.target.checked)}
            >
                {t("showInactiveUsers")}
            </Checkbox>
            <Spacer />

            <Table
                className={styles.wide}
                rowKey={(record) => record.entity_id}
                columns={columns}
                dataSource={showDisabledMerchantUsers ? merchantUsers : merchantUsers?.filter((mu) => !mu.is_deleted)}
                pagination={{
                    position: ["bottomCenter"],
                    current: pagination.current_page,
                    total: pagination.total_rows,
                    pageSize: pagination.items_per_page,
                    showSizeChanger:false,
                }}
                onChange={(e) => fetchMerchantUsers(e.current)}

            />
            <Spacer />

            <AddMerchantUserModal
                visible={addMerchantUserModalOpen}
                setVisible={setAddMerchantUserModalOpen}
                onDataChanged={fetchMerchantUsers}
            />
        </>
    );
};
