import { Layout } from "antd";
import { adminGetClient } from "api/admin/admin";
import { adminCreateToDo, adminGetToDo } from "api/admin/todos";
import { DATE_FORMAT } from "api/apiConfig";
import { GetClientObject } from "api/types/client";
import styles from "components/admin/pages/client-drilldown/ClientDrilldownPage.module.scss";
import { NotesTab, NotesTabType } from "components/admin/presenters/applications-drilldown-tabs/notes-tab/NotesTab";
import { CreateClientModal } from "components/admin/presenters/create-client-modal/CreateClientModal";
import { NewToDoModal } from "components/common/presenters/new-todo-modal/NewToDoModal";
import { SideMenu } from "components/common/presenters/side-menu/SideMenu";
import { TabMenu } from "components/common/presenters/tab-menu/TabMenu";
import { UserDocsTab } from "components/common/presenters/user-drilldown-tabs/user-docs-tab/UserDocsTab";
import { wait } from "helpers/misc";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AdminCreateToDoForm, EntityTypes } from "types/applicationProcess";
import { AdminCreateToDoStates, ClientObject, ToDo } from "../../../../api/types";
import { TopInfoSection, TopInfoSectionType } from "../../../common/presenters/top-info-section/TopInfoSection";
import { LeadDetailsTab } from "./lead-unified-tab/LeadDetailsTab";

const { Header, Content } = Layout;

export const ClientDrilldownPage = () => {
    const { t } = useTranslation();

    let { clientId } = useParams<any>();

    const [clientData, setClientData] = useState({} as GetClientObject);

    const [newToDoModalOpen, setNewToDoModalOpen] = useState(false);
    const [newToDo, setNewToDo] = useState({} as ToDo);
    const [error, setError] = useState("");
    const [createToDoResult, setCreateToDoResult] = useState<AdminCreateToDoStates | undefined>(undefined);
    const [clientModalOpen, setClientModalOpen] = useState<boolean>(false);


    const getDataAndSetState = async (): Promise<any> => {
        // separate try-catch block for each req group, because otherwise if 1 fails, the rest don't get executed
        try {
            const result = await adminGetClient(clientId);
            setClientData(result.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleNewReminderSelected = () => {};

    const handleNewToDoSelected = () => {
        setNewToDoModalOpen(true);
    };

    const handleNewApplicationSelected = () => {};

    const handleCloseNewToDoModal = () => {
        setNewToDoModalOpen(false);
        setCreateToDoResult(undefined);
    };

    const handleCreateToDo = async (values: AdminCreateToDoForm): Promise<void> => {
        try {
            setError("");

            const data = {
                entity_id: clientId,
                entity_kind: EntityTypes.Client,
                assignee_id: "bou_1ufMd5Ah7zUB12e91LkP3gVsYgF", // TODO: choose a bo_user_id from a list of bo_users
                details: values.details,
                due_date: moment(values.due_date).format(DATE_FORMAT.API),
                send_email: values.send_email,
            };

            setCreateToDoResult(AdminCreateToDoStates.Loading);

            await wait(1500);

            const createToDoReq = await adminCreateToDo(data);
            const newToDo = await adminGetToDo(createToDoReq?.data);

            setNewToDo(newToDo.data);
            setCreateToDoResult(AdminCreateToDoStates.Success);
        } catch (error) {
            console.error(error);
            setError(t("toDoError"));

            setCreateToDoResult(AdminCreateToDoStates.Failure);
        }
    };

    const handleEdit = () => {
        // In the end call: getDataAndSetState()
        setClientModalOpen(true);
    };

    useEffect(() => {
        getDataAndSetState();
    }, [clientId]);

    let tabsTitles = [];
    let tabsContent = [];

    tabsTitles = [
        t("admin:tabs:clientInfo"),
        t("admin:tabs:docs"),
        t("admin:tabs:notes"),
    ];

    tabsContent = [
        <LeadDetailsTab client={clientData as ClientObject} onEdit={handleEdit} />,
        <UserDocsTab clientId={clientId} />,
        <NotesTab entityId={clientId} type={NotesTabType.Client} />,
    ];

    return (
        <Layout className={styles.page}>
            <SideMenu isLoggedIn={true} />

            <Layout className={styles.sectionRight}>
                <Header className={styles.header}>
                    <TopInfoSection
                        short={true}
                        type={TopInfoSectionType.TypeClient}
                        clientData={clientData}
                        dropdownOptions={[t("newReminder"), t("newToDo"), t("newApplication")]}
                        dropdownActions={[
                            handleNewReminderSelected,
                            handleNewToDoSelected,
                            handleNewApplicationSelected,
                        ]}
                    />
                </Header>

                <Content className={styles.clientDrill}>
                    <TabMenu titles={tabsTitles} content={tabsContent} />

                    <NewToDoModal
                        open={newToDoModalOpen}
                        onOk={handleCreateToDo}
                        onCancel={handleCloseNewToDoModal}
                        state={createToDoResult}
                        newToDo={newToDo}
                        error={error}
                    />

                    <CreateClientModal
                        existingClientData={clientData}
                        modalOpen={clientModalOpen}
                        setModalOpen={setClientModalOpen}
                        onDataChanged={getDataAndSetState}
                    />
                </Content>
            </Layout>
        </Layout>
    );
};
