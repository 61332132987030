import React from "react";
import { Steps } from "antd";
import styles from "components/common/presenters/progress-bar/ProgressBar.module.scss";

const { Step } = Steps;

export interface ProgressBarStepsItem {
    title: string
    subtitle: string
    description: string
}

export interface ProgressBarProps {
    activeStep: number
    steps: ProgressBarStepsItem[]
    small?: boolean
    type?: "navigation" | undefined
}

export const ProgressBar = (props: ProgressBarProps) => {
    const renderSteps = () => {
        const steps = [];

        if (props.steps) {
            props.steps.forEach((step) => {
                steps.push(
                    <Step key={step.title} title={step.title} subTitle={step.subtitle} description={step.description} />
                );
            });
        }

        return steps;
    };

    const mobileProgressBar = () => {
        const length = props.steps.length;

        const renderedSteps = [...Array(length)].map((_, idx) => {
            return (
                <div className={styles.mobileStepContainer} key={idx}>
                    <span
                        className={styles.mobileStep}
                        style={{
                            backgroundColor: idx <= props.activeStep && "#17B1E6",
                            height: idx === props.activeStep && "15px",
                            width: idx === props.activeStep && "15px",
                        }}
                    >
                        {idx === props.activeStep && <span className={styles.dot}></span>}
                    </span>

                    {idx < length - 1 && (
                        <span
                            className={styles.mobileAfter}
                            style={{ backgroundColor: idx < props.activeStep && "#17B1E6" }}
                        ></span>
                    )}
                </div>
            );
        });

        return <div className={styles.mobileContainer}>{renderedSteps}</div>;
    };
    // <Steps/> are not responsive out of the box: https://github.com/ant-design/ant-design/issues/14463
    return (
        <div className={styles.bar}>
            {props.small ? (
                mobileProgressBar()
            ) : (
                <Steps current={props.activeStep} responsive type={props.type || 'default'}>
                    {renderSteps()}
                </Steps>
            )}
        </div>
    );
};
