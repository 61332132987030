import { Col, Row } from "antd";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from 'components/common/presenters/documents-section/DocumentsSection.module.scss';

export interface DocumentsChecklistProps {
    companyCountry: string
}

export const DocumentsChecklist = (props: DocumentsChecklistProps) => {
    const {t} = useTranslation();

    const renderDocList = () => {
        // if (props.companyCountry) {
        //     let list = t(`docLists:${props.companyCountry}`);
        //     return list.split('\n').map(line => <p>{line}</p>);
        // }

        // For the demo we return the list for AT
        let list = t(`docLists:at`);
        return list.split('\n').map(line => <p>{line}</p>);
    };
    
    return (
        <Row className={styles.wide}
            gutter={36}
            justify={"start"}
        >
            <Col>
                <h4 className={styles.bold}>{ t("docLists:heading") }</h4>
                { renderDocList() }
            </Col>
        </Row>
    );
};
