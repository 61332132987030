export enum BoUserRoleID {
    ADMIN = "role_bo_admin",
    SALES_REP = "role_bo_user",
}

export enum MerchantUserRole {
    ADMIN = "role_merchant_admin",
    SALES_REP = "role_merchant_user",
}

export enum Currencies {
    EUR = "EUR",
    USD = "USD",
    CHF = "CHF",
}

export const LoanDurations = {
    Months6: 6,
    Months12: 12,
    Months24: 24,
    Months36: 36,
};

export enum ApplicationStatuses {
    Open = "open",
    Complete = "complete"
}

export enum ApplicationStates {
    Open = "open",
    Rejected = "rejected",
    Complete = "complete",
    AcquiringOffers = "acquiring_offers",
    AcquiringOffersError="acquiring_offers_error",
    InManualCheck = "in_manual_check",
    ApprovedWaitingKyc = "approved_waiting_kyc",
    ApprovedWaitingShipping = "approved_waiting_shipping",
    ApprovedBooked = "approved_booked",
    ApprovedWaitingDocuments = "approved_waiting_documents",
    InCancellation = "in_cancellation",
    CancelledByBank = "cancelled_by_bank",
    CancelledByMerchant = "cancelled_by_merchant",
    Expired = "expired",
    Closed = "closed",
}

export enum ApplicationFailedStates {
    General
}

export enum InvoiceStatuses {
    Ok = "ok",
    Repaid = "repaid",
    Overdue = "overdue"
}

export enum LeadTaskStatus {
    None = "None",
    Pre_Check = "Pre_Check",
    Risk_Review = "Risk_Review",
    Request_Docs_Sales = "Request_Docs_Sales",
    Request_Docs_Risk = "Request_Docs_Risk",
    Reject_User_Sales = "Reject_User_Sales",
    Reject_User_Risk = "Reject_User_Risk",
    Pending_User_Feedback = "Pending_User_Feedback",
    Follow_Up_Sales = "Follow_Up_Sales",
    Follow_Up_Risk = "Follow_Up_Risk",
    Setup_Link = "Setup_Link",
    Monitor = "Monitor",
}

export enum UserFlags {
    Unset = "-",
    None = "none",
    FraudRisk = "fraud_risk",
    MonitorRisk = "monitor_risk",
    MonitorLegal = "monitor_legal",
    InactiveUser = "inactive_user",
}

export type Application = {
    id: string
    application_id: string
    short_id: string
    client_id: string
    bo_assignee_id?: string
    merchant_name?: string
    merchant_id?: string
    type: string
    currency: string
    amount: number
    duration: number
    interest_rate: number
    status: ApplicationStates
    company_name: string
    company_type: string
    company_country: string
    company_post_code: string
    company_purpose: string
    purpose: string
    purpose_info: string
    largest_debitors_list: string
    tax_id: string
    bank_name: string
    iban: string
    contract_id: string
    created_at: string
    paid_out_on: string
    client_first_name: string
    client_last_name: string
    duration_months: number
    client_title: string
    client_address_resident_since: string
    client_prev_address_resident_since: string
    client_prev_address_house_number: string
    client_prev_address_street: string
    client_prev_address_post_code: string
    client_prev_address_city: string
    client_prev_address_country_code: string
    client_job_title: string
    client_job_branch: string
    client_job_type: string
    client_employed_since: string
    client_employer_location: string
    client_prev_employer_name: string
    client_prev_employed_since: string
    client_prev_employer_location?: string
    client_employer_name: string
    client_net_income: string
    client_type_of_residence: string
    client_living_costs: string
    client_has_vehicle: string
    client_num_children: string
    client_birth_city: string
    client_nationality_country_code: string
    client_id_doc_type: string
    client_id_doc_issued_place: string
    client_id_doc_number: string
    client_id_doc_issued_at: string
    client_id_doc_expires_at: string
    client_iban: string
    client_bank_name: string
    client_bic: string
    session_id: string
    order_id: string
};

export type Signatory = {
    id: string
    application_id: string
    first_name: string
    last_name: string
    date_of_birth: string
    street_name: string
    street_number: string
    city: string
    post_code: string
    phone: string
    passport_number: string
    is_signatory: boolean
    is_sole_signatory: boolean
    is_contract_signatory: boolean
    is_beneficial_owner: boolean
    is_deleted: boolean
    created_at: string
};

export type Component = JSX.Element | JSX.Element[] | HTMLElement | HTMLElement[] | string;

export enum ClientType {
    Client = "client",
    Lead = "lead",
}

export enum LeadStatus {
    LeadBacklog = "backlog",
    InBearbeitung = "in_bearbeitung",
    LeadContacted = "lead_contacted",
    ProjectIdentifiedQuestionnaireSent = "project_identified_questionnaire_sent",
    QuestionnaireReturned = "questionnaire_returned",
    ProposalSent = "proposal_sent",
    ContractSent = "contract_sent",
    ContractSigned = "contract_signed",
    Won = "won",
    Lost = "lost",
}

export const LeadSources = [ // TODO list by svea
    "Rückruf Formular",
    "Telefon (incoming)",
    "E-Mail (incoming)",
    "Google Ads",
    "Broker",
    "Cold Calling",
    "LinkedIn Kampagne",
    "LinkedIn Cold Mailing",
    "Chat",
    "Landing Page",
    "Persönliches Netzwerk",
    "Radio Kampagne",
    "Sonstiges",
];

export const CompanyIndustries = [ // TODO list by svea
    "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U"
];

export const CompanyLegalForms = [ // this list is also maintained on the backend, because we validate the input there too
    "AG",
    "AG / börsennotiert",
    "AG & Co. KG",
    "AG & Co. oHG",
    "BGB-Gesellschaft ( z.B. GbR )",
    "Einzelfirma",
    "e.G.",
    "e.V.",
    "Freiberufler",
    "Gewerbebetrieb",
    "GmbH",
    "GmbH & Co KG",
    "GmbH & Co. KGaA",
    "GmbH & Co oHG",
    "INC. & Co. KG",
    "KG",
    "KGaA",
    "Körperschaft d. öffentl. Rechts",
    "Körperschaft d. öffentl. Rechts / Bundesland",
    "Körperschaft d. öffentl. Rechts / Regierung",
    "Körperschaft d. öffentl. Rechts / Zentralregierung",
    "Limited",
    "Limited & Co. KG",
    "Limited Liability Partnership (LLP)",
    "OHG",
    "Partnerschaftsgesellschaft",
    "Partnergs. mbB",
    "Privatperson",
    "SE",
    "SE & Co. KG",
    "SE & Co. KGaA",
    "S.a.r.l. & Co. KG",
    "Stiftung",
    "UG (haftungsbeschränkt)",
    "UG & Co. KG",
];

export const CompanySizes = [ // TODO list by svea
    "1-10",
    "11-100",
    "101-500",
    "501-1000",
];

export enum ClientFinancingType {
    Loan = "loan",
    Factoring = "factoring",
}

export enum DocumentType {
    Client,
    Application,
    Merchant
}

export enum DocumentCategory {
    AnnualStatement = "annual_statement",
    Bwa = "bwa",
    Susa = "susa",
    HealthInsuranceExtract = "health_insurance_extract",
    FinanceMinistryExtract = "finance_ministry_extract",
    NegativeBankDeclaration = "negative_bank_declaration",
    DebtorAndCreditorList = "debtor_and_creditor_list",
    AssetDocuments = "asset_documents",
    IdCopy = "id_copy",
    Other = "other_documents",
    CommercialRegistration = "commercial_registration",
    CompanyRegisterExtract = "company_register_extract",
    CommercialRegisterExtract = "commercial_register_extract",
    SignatoryDirectory = "signatory_directory",
    UboDeclaration = "ubo_declaration",
    PassportCopy = "passport_copy",
    Schufa = "schufa",
    DebtRegistryExtract = "debt_registry_extract",
    Bisnode = "bisnode",
    Creditreform = "creditreform",
    Email = "e_mail",
    OtherCorrespondence = "other_correspondence",
    Contract = "contract",
    Offer = "offer",
    Fax = "fax",
    Letter = "letter",
    TermsAndConditions = "terms_and_conditions",
    DataProtectionPolicy = "data_protection_policy",
    PreContractualInformation = "pre_contractual_information",
}

// export enum DocumentCategories {

// }

// export enum NicerPayDocumentCategoryGroup {
//     FINANZEN
//     LEGITIMATION
//     CREDIT_RECORDS
//     PERMITS
//     NOTES_FOR_PERSON
//     COLLATERAL
// }

