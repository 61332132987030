import * as React from "react";
import styles from "components/common/pages/404-page/NotFoundPage.module.scss";
import "./additional.scss";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { ApplicationProcessHeader } from "components/common/presenters/application-process-header/ApplicationProcessHeader";
import { Card, Col, Row } from "antd";

export const NotFoundPage = () => {
    return (
        <section className={styles.page}>
            <ApplicationProcessHeader hideNav={true} loginPage />
            <Card className="errorContainer">
                <span className="notfound">404</span>
                <Spacer />
                <span className="desc">Die aufgerufene Seite konnte nicht gefunden werden. </span>
                <Spacer />
                <Row justify="center">
                    <Col span={12}>
                        <a href={"https://www.liquitree.com/"} className={styles.homeLink}>
                            Zurück zu Liquitree.com
                        </a>
                    </Col>
                </Row>
            </Card>
        </section>
    );
};
