import {
    ApartmentOutlined,
    CreditCardOutlined,
    CrownOutlined,
    DashboardOutlined,
    FileDoneOutlined,
    LeftOutlined,
    LogoutOutlined,
    MessageOutlined,
    PlusSquareOutlined,
    RightOutlined,
    SettingOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Typography } from "antd";
import { logoutAdmin } from "api/admin/admin";
import { logoutMerchant } from "api/merchant";
import classnames from "classnames";
import styles from "components/common/presenters/side-menu/SideMenu.module.scss";
import { isAdminApp, isClientApp, isMerchantApp } from "helpers/appHelpers";
import { getAdminMenuItems, goTo } from "navigation/navigationHelpers";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteStrings } from "routes/RouteStrings";
import { LocalToggleState } from "storage/LocalToggleState";

export interface SideMenuProps {
    isLoggedIn: boolean
    onItemClick?: (itemIndex: number) => void
    activeHash?: string
}

const { Sider } = Layout;
const { ItemGroup } = Menu;
const { Text } = Typography;

export const SideMenu = (props: SideMenuProps) => {
    const { t } = useTranslation();
    const localInstance = new LocalToggleState();
    const { isToggled } = localInstance.get();

    const [isCollapsed, setCollapsed] = useState(isToggled);
    const [wasToggled, setWasToggled] = useState(false);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [activePageIndex, setActivePageIndex] = useState(null);

    let dynamicClasses = classnames(styles.menu, {
        [styles.menuCollapsed]: isCollapsed,
        [styles.menuToggled]: wasToggled,
        [styles.menuFirstRender]: isFirstRender,
        [styles.active_3]: activePageIndex === 3,
    });

    const toggleMenu = () => {
        setCollapsed(!isCollapsed);
        setIsFirstRender(false);
    };
    useEffect(() => {
        if (isCollapsed) {
            localInstance.update({ isToggled: true });
        } else {
            localInstance.update({ isToggled: false });
        }
    }, [isCollapsed]);

    const adminMenuItemsOrder = [
        {
            hash: "#applications",
            index: 0,
            path: "application",
        },
        {
            hash: "#clients",
            index: 1,
            path: "client",
        },
        {
            hash: "#settings",
            index: 2,
            path: "settings",
        },
        {
            hash: "#dashboard",
            index: 3,
            path: "dashboard",
        },
        {
            hash: "#merchants",
            index: 4,
            path: "merchant",
        },
    ];
    const merchantMenuItemsOrder = [
        {
            hash: "#applications",
            index: 0,
            path: "application",
        },
        {
            hash: "#settings",
            index: 1,
            path: "settings",
        },
    ];

    const userMenuItemsOrder = [
        {
            page: RouteStrings.ApplicationsOverview,
            index: 0,
        },
        {
            page: RouteStrings.UserSettings,
            index: 1,
        },
    ];

    // Called on every re-render, including the first one.
    useEffect(() => {
        if (!isCollapsed && !wasToggled) {
            setWasToggled(true);
        }

        if (isCollapsed && wasToggled) {
            setWasToggled(false);
        }
    }, [isCollapsed]);

    useEffect(() => {
        if (props.activeHash) {
            let found;
            if (isAdminApp()) {
                found = adminMenuItemsOrder.find((item) => {
                    return item.hash === props.activeHash;
                });
            } else {
                found = merchantMenuItemsOrder.find((item) => {
                    return item.hash === props.activeHash;
                });
            }

            setActivePageIndex(found.index);
        }
    }, [props.activeHash]);
    // Mark active tab on first render before any side menu item is clicked.
    useEffect(() => {
        const pathname = window.location.pathname;
        const hash = window.location.hash;

        if (hash) {
            if (isAdminApp()) {
                for (let i = 0; i < adminMenuItemsOrder.length; i++) {
                    if (hash === adminMenuItemsOrder[i].hash) {
                        setActivePageIndex(adminMenuItemsOrder[i].index);
                    }
                }
            } else if (isMerchantApp()) {
                for (let i = 0; i < merchantMenuItemsOrder.length; i++) {
                    if (hash === merchantMenuItemsOrder[i].hash) {
                        setActivePageIndex(merchantMenuItemsOrder[i].index);
                    }
                }
            }
        } else if (!hash && pathname.indexOf("/home")) {
            setActivePageIndex(0);
        }
        if (pathname?.indexOf("admin") != -1) {
            for (let i = 0; i < adminMenuItemsOrder.length; i++) {
                if (pathname.indexOf(adminMenuItemsOrder[i].path) != -1) {
                    setActivePageIndex(adminMenuItemsOrder[i].index);
                }
            }
            if (!pathname) {
                setActivePageIndex(adminMenuItemsOrder[0].index);
            }
        } else if (isMerchantApp()) {
            for (let i = 0; i < merchantMenuItemsOrder.length; i++) {
                if (pathname.indexOf(merchantMenuItemsOrder[i].path) != -1) {
                    setActivePageIndex(merchantMenuItemsOrder[i].index);
                }
            }
        } else {
            for (let i = 0; i < userMenuItemsOrder.length; i++) {
                if (pathname.indexOf(userMenuItemsOrder[i].page) != -1) {
                    setActivePageIndex(userMenuItemsOrder[i].index);
                }
            }
        }
    }, []);

    const handleLogout = async () => {
        try {
            if (isAdminApp()) {
                await logoutAdmin();
                goTo(RouteStrings.AdminLogin);
            } else {
                await logoutMerchant();
                goTo(RouteStrings.Login);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const goToAdminHome = (hash) => {
        window.location.href = window.location.origin + RouteStrings.AdminHome + `/${hash}`;
    };

    const goToMerchantHome = (hash) => {
        window.location.assign(window.location.origin + RouteStrings.MerchantHome + `/${hash}`);
    };

    const goToUserApplications = () => {
        window.location.href = window.location.origin + RouteStrings.ApplicationsOverview;
    };

    const goToUserSettings = () => {
        window.location.href = window.location.origin + RouteStrings.UserSettings;
    };

    const goToUserAddBankAccount = () => {
        window.location.href = window.location.origin + RouteStrings.UserAddBankAccount;
    };

    const isAdminPage = () => {
        return !isMerchantApp() && window.location.pathname.includes("admin");
    };

    const renderMenuItems = (
        menuItems: string[],
        icons?: JSX.Element[],
        navActions?: any[],
        renderExtraItems?: boolean
    ) => {
        const items = [];

        for (let i = 0; i < menuItems.length; i++) {
            let classNameStr = styles.menuItem;

            // if (menuItems[i] === t("addBankAccount") || menuItems[i] === t("buttons:newInquiry")) {
            //     classNameStr = styles.menuItemHighlight;
            // }

            items.push(
                <Menu.Item
                    key={menuItems[i]}
                    className={classNameStr}
                    data-active={activePageIndex === i}
                    onClick={() => {
                        setActivePageIndex(i);

                        if (props.onItemClick && menuItems[i] !== t("buttons:newInquiry")) {
                            props.onItemClick(i);
                        } else {
                            if (navActions) {
                                navActions[i]();
                            }
                        }
                    }}
                >
                    {icons && icons[i]}
                    <Text className={styles.menuText}>{menuItems[i]}</Text>
                </Menu.Item>
            );
        }

        return items;
    };

    const adminMenuIcons = [
        <FileDoneOutlined className={styles.adminIcon} />,
        <UserOutlined className={styles.adminIcon} />,
        <SettingOutlined className={styles.adminIcon} />,
        <DashboardOutlined className={styles.adminIcon} />,
        <ApartmentOutlined className={styles.adminIcon} />,
    ];

    const adminMenuNavActions = [
        goToAdminHome.bind(this, "#applications"),
        goToAdminHome.bind(this, "#clients"),
        goToAdminHome.bind(this, "#settings"),
        goToAdminHome.bind(this, "#dashboard"),
        goToAdminHome.bind(this, "#merchants"),
    ];

    const renderAdminMenu = () => {
        const menuItems = getAdminMenuItems();

        return renderMenuItems(menuItems, adminMenuIcons, adminMenuNavActions);
    };

    let userMenuIcons = [
        <FileDoneOutlined className={styles.adminIcon} />,
        <SettingOutlined className={styles.adminIcon} />,
        <CreditCardOutlined className={styles.adminIcon} />,
        <PlusSquareOutlined className={styles.adminIconHighlight} />,
        <CrownOutlined className={styles.adminIcon} />,
    ];

    let userMenuNavActions = [goToUserApplications, goToUserSettings, () => {}, goToUserAddBankAccount, () => {}];

    const renderUserMenu = () => {
        let menuItems = [t("loans"), t("settings"), t("myAccounts"), t("addBankAccount"), t("myInsurances")];

        return renderMenuItems(menuItems, userMenuIcons, userMenuNavActions, true);
    };

    const renderMerchantMenu = () => {
        let merchantMenuItems = [t("loans"), t("settings")];

        const merchantMenuIcons = [
            <FileDoneOutlined className={styles.adminIcon} />,
            <SettingOutlined className={styles.adminIcon} />,
        ];

        const merchantMenuNavActions = [
            goToMerchantHome.bind(this, "#applications"),
            goToMerchantHome.bind(this, "#settings"),
        ];

        return renderMenuItems(merchantMenuItems, merchantMenuIcons, merchantMenuNavActions);
    };

    const renderMenu = () => {
        if (isClientApp()) {
            return null;
        }

        if (isAdminPage()) {
            return renderAdminMenu();
        }

        if (isMerchantApp()) {
            return renderMerchantMenu();
        }

        // TODO: discuss with Marc what to render when the user is logged in
        return renderUserMenu();
    };

    return (
        <Sider className={`${styles.sider}${dynamicClasses}`} trigger={null} collapsible collapsed={isToggled}>
            <div className={styles.containerLogo}>
                <div className={styles.logo}></div>
            </div>

            <Menu mode="vertical" className={styles.sidemenu}>
                {props.isLoggedIn ? <ItemGroup className={styles.containerMid}>{renderMenu()}</ItemGroup> : null}

                <ItemGroup className={styles.containerBot}>
                    {isAdminPage() || isMerchantApp() ? null : (
                        <Menu.Item className={styles.menuItem} onClick={() => {}}>
                            <MessageOutlined className={styles.adminIcon} />
                            <Text>{t("buttons:contactCustomerSupport")}</Text>
                        </Menu.Item>
                    )}
                    <Menu.Item>
                        <div className={styles.toggleBtn} onClick={toggleMenu}>
                            {isCollapsed ? (
                                <RightOutlined className={styles.toggleIcon} />
                            ) : (
                                <LeftOutlined className={styles.toggleIcon} />
                            )}
                            <Text>{t("buttons:toggle")}</Text>
                        </div>
                    </Menu.Item>

                    {props.isLoggedIn ? (
                        <Menu.Item>
                            <div data-cy="button_logout" className={styles.logoutBtn} onClick={handleLogout}>
                                <LogoutOutlined className={styles.logoutIcon} />
                                <Text>{t("buttons:logout")}</Text>
                            </div>
                        </Menu.Item>
                    ) : null}
                </ItemGroup>
            </Menu>
        </Sider>
    );
};
