import * as React from "react";
import styles from "components/common/presenters/confirm-modal-close/ConfirmModalClose.module.scss";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export interface ConfirmModalCloseProps {
    visible: boolean
    onCancel: () => void
    onOk: () => void
}

export const ConfirmModalClose = (props: ConfirmModalCloseProps) => {
    const { t } = useTranslation();
    
    const renderTitle = () => {
        return (
            <div className={styles.modalTitle}>
                <ExclamationCircleOutlined className={styles.icon}/>
                &nbsp;
                &nbsp;
                <div className="ant-modal-title">{ t("modals:confirmCloseTitle") }</div>
            </div>
        );
    };

    return (
        <Modal className={styles.modal}
            centered
            title={renderTitle()}
            visible={props.visible}
            destroyOnClose={true}
            keyboard={false}
            maskClosable
            onCancel={props.onCancel}
            onOk={props.onOk}
            footer={[
                <div key="fr" className={styles.footerRow}>
                    <div key="footer_row_2" className={styles.rowCenterSpace}>
                        <Button key="cancel" className={styles.buttonCancel} 
                            onClick={props.onCancel}>
                            { t("buttons:back") }
                        </Button>
                        <Button key="submit" onClick={props.onOk}>
                            { t("buttons:ok") }
                        </Button>
                    </div>
                </div>
            ]}>
            <div>
                { t("modals:confirmCloseText") }
            </div>
        </Modal>
    );
};
