import { RouteStrings } from "routes/RouteStrings";
import { isMerchantApp } from "./appHelpers";

export const preventStepSkip = (lastPage: string, history) => {
    // different routes depending on user or merchant
    const routesToUse = isMerchantApp()
        ? [...RouteStrings.MERCHANT_PROCESS_FLOW_PATHS]
        : [...RouteStrings.USER_PROCESS_FLOW_PATHS];
    
    const lastIdx = routesToUse.indexOf(lastPage);
    const currentHash = "/" + window.location.hash.substring(1);
    const currentIdx = routesToUse.indexOf(currentHash);

    // if the typed/visited path is before or same as the last successful submit page.
    const isBefore = () => {
        // get paths up to the last successful submit page.
        const prevPaths = routesToUse.slice(0, lastIdx + 1);
        return prevPaths.includes(routesToUse[currentIdx]);
    };

    // checks if user refreshed the page/went back or went forward by 1 relative to last successful page submission
    if (!(lastIdx !== -1 && (routesToUse[lastIdx + 1] === currentHash || isBefore()))) {
        // go to a page after the last successfully submitted page.
        history.push({
            pathname: routesToUse[lastIdx + 1] || "/",
            state: "skipped",
        });
    }
};