import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import { DATE_FORMAT } from "api/apiConfig";
import { AdminCreateToDoStates, ToDo } from "api/types";
import { ConfirmModalClose } from "components/common/presenters/confirm-modal-close/ConfirmModalClose";
import styles from "components/common/presenters/new-offer-modal/NewOfferModal.module.scss";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { AdminCreateToDoForm } from "types/applicationProcess";

export interface NewToDoModalProps {
    open: boolean
    state: AdminCreateToDoStates
    error: string
    newToDo: ToDo
    onOk: (values: any) => void
    onCancel: () => void
}

export const NewToDoModal = (props: NewToDoModalProps) => {
    const { t } = useTranslation();
	
    const [form] = Form.useForm();

    const [secondaryModalOpen, setSecondaryModalOpen] = React.useState(false);

    const handleOnFinish = (values: AdminCreateToDoForm) => {

        const data: AdminCreateToDoForm = {
            ...values
        };

        props.onOk(data);
    };

    const secondaryModalOnCancel = () => {
        setSecondaryModalOpen(false);
    };

    const secondaryModalOnOk = () => {
        setSecondaryModalOpen(false);
        form.resetFields();
        props.onCancel();
    };

    React.useEffect(() => {
        form.resetFields();
    }, [props]);

    const renderForm = () => {
        return (
            <div className={styles.inputsContainer}>
                <Form id="createOffer" className={styles.form}
                    layout={"vertical"}
                    form={form}
                    onFinish={handleOnFinish}
                    scrollToFirstError
                >
                    <Col>
                        <div>{ t("modals:infoFinancingOffer") }</div>
                        <Spacer/>

                        <Form.Item name="details" label={t("details")} hasFeedback
                            rules={[{ required: false, message: t("errors:invalidData"), min: 1, max: 250 }]}>
                            <Input.TextArea data-cy="details"
                                className={styles.input}
                                placeholder={t("example") + " "}/>
                        </Form.Item>

                        <Form.Item name="due_date" label={t("dueDate")} hasFeedback>
                            <DatePicker className={styles.datePicker}
                                format={DATE_FORMAT.DE} allowClear={true}/>
                        </Form.Item>

                        <Form.Item name="send_email" valuePropName="checked">
                            <Checkbox>{ t("sendEmail") }</Checkbox>
                        </Form.Item>
                        <Spacer hx={1} />
                    </Col>
                </Form>
            </div>
        );
    };

    const renderLoading = () => {
        return (
            <React.Fragment>
                <div>{ t("modals:adminCreateToDo_Loading") }</div>

                <Spacer/>

                <Row>
                    
                </Row>

                <div className={styles.rowCenter}>
                    <Spin className={styles.spin} indicator={<Loading3QuartersOutlined style={{ fontSize: 54 }} spin />} />
                </div>
            </React.Fragment>
        );
    };

    const renderSuccess = () => {
        return (
            <React.Fragment>
                <div>{ t("modals:adminCreateToDo_Success_info1") }</div>

                <Spacer/>

                {/* <Row>
                    <Col span={6}>
                        <Row>{t("financingAmount")}</Row>
                        <Row>{t("numberOfInstallements_2")}</Row>
                        <Row>{t("installementAmount")}</Row>
                    </Col>
                    <Col span={6}>
                        <Row>{ props.newAppl?.currency } { props.newAppl?.amount }</Row>
                        <Row>{ props.newAppl?.duration_months }</Row>
                        <Row>{ props.newAppl?.currency } 454,16</Row>
                    </Col>
                </Row>

                <Spacer/> */}

            </React.Fragment>
        );
    };

    const renderAddressNotFound = () => {
        return (
            <React.Fragment>
                <div>{ t("modals:adminCreateToDo_NotFound") }</div>
            </React.Fragment>
        );
    };

    const render = (): React.ReactNode => {
        if (props.state === AdminCreateToDoStates.Loading) {
            return renderLoading();
        } 
        
        if (props.state === AdminCreateToDoStates.Success) {
            return renderSuccess();
        }
        
        if (props.state === AdminCreateToDoStates.Failure) {
            return renderAddressNotFound();
        }
            
        return renderForm();
    };

    const renderModalButton = () => {
        if (props.state === AdminCreateToDoStates.Success ||
            props.state === AdminCreateToDoStates.Failure) {
            return (<Button key="view_offer" onClick={() => {
                // goToAdminApplicationDrilldown(props.newAppl?.application_id);
            }}>
                { t("buttons:viewOffer") }
            </Button>);
        }

        if (props.state === AdminCreateToDoStates.Loading) {
            return null;
        }

        return (
            <Button form="createOffer" key="submit" htmlType="submit">
                { t("buttons:createOffer") }
            </Button>
        );
    };

    return (
        <React.Fragment>
            <ConfirmModalClose visible={secondaryModalOpen} onCancel={secondaryModalOnCancel} onOk={secondaryModalOnOk}/>

            <Modal className={styles.modal}
                title={t("modals:createToDo")}
                visible={props.open}
                centered
                destroyOnClose={true}
                maskClosable
                keyboard={true}
                onCancel={(e: any) => {
                    if (e.code === "Escape") {
                        e.preventDefault();
                        e.stopPropagation();
                        setSecondaryModalOpen(true);
                    } else {
                        form.resetFields();
                        props.onCancel();
                    }
                }}
                footer={[
                    <div key="fr" className={styles.footerRow}>
                        <div key="footer_row_1" className={styles.error}>{props.error}</div>
                        <div key="footer_row_2" className={styles.rowCenterSpace}>
                            <Button form="createOffer" key="cancel" className={styles.buttonCancel} 
                                onClick={props.onCancel}>
                                { t("buttons:cancel") }
                            </Button>
                            {
                                renderModalButton()
                            }
                        </div>
                    </div>
                ]}>

                { render() }
                
            </Modal>
        </React.Fragment>
    );
};
