import i18n from "i18n/i18n";
import { RouteStrings } from "routes/RouteStrings";

export const goTo = (pageName: string) => {
    window.location.href = window.location.origin + `${pageName}`;
};

export const goToExternal = (url: string) => {
    window.location.href = url;
};

export const goToUserDashboardHome = () => {
    window.location.href = window.location.origin + `${RouteStrings.ApplicationsOverview}`;
};

export const goToFlowStepTwo = () => {
    goTo(RouteStrings.ApplicationFlowStepTwo);
};

export const goToFlowStepLoan = () => {
    goTo(RouteStrings.ApplicationFlowStepLoan);
};

export const goToFlowStepRegistration = () => {
    goTo(RouteStrings.ApplicationFlowStepCreateAccount);
};


export const goToAdminClientDrilldown = (clientId: string) => {
    goTo(RouteStrings.AdminClientDrilldown.replace(":clientId", clientId));
};

export const goToAdminApplicationDrilldown = (applId: string) => {
    goTo(RouteStrings.AdminApplicationDrilldown.replace(":applicationId", applId));
};

export const goToAdminMerchantsList = () => {
    goTo("/admin/home/#merchants");
};

export const isLoanDrilldownPage = () => {

};

export const getAdminMenuItems = () => {
    return [
        i18n.t("applications"),
        i18n.t("clients"),
        i18n.t("settings"),
        i18n.t("dashboard"),
        i18n.t("merchants"),
    ];
};

export const getMerchantMenuItems = () => {
    return [
        i18n.t("applications"),
        i18n.t("settings"),
    ];
};

export const goToApplicationFlowStepOne = () => {
    goTo(`/application/`);
};

export const openInNewTab = (url: string) => {
    window.open(url, '_blank').focus();
};
