import { FileAddOutlined } from "@ant-design/icons";
import { Button, Col, message, Modal, Row, Typography } from "antd";
import { adminGetMerchantOriginalLogo, adminUploadMerchantOriginalLogo } from "api/admin/admin";
import { adminPutMerchantLogo, MerchantGetOriginalLogo, MerchantUploadOriginalLogo } from "api/admin/merchants";
import { MerchantPutLogo } from "api/merchant";
import React, { useEffect, useRef, useState } from "react";
import ReactAvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Spacer } from "../spacer/Spacer";
import styles from "./ImageUpload.module.scss";

interface ImageProps {
    uploader: "merchant" | "admin"
    entityProps?: {
        company?: {
            logo_url?: string
        }
        merchant?: {
            logo_url?: string
            merchant_id?: string
        }
    }
}

const ImageUpload = ({ uploader, entityProps }: ImageProps) => {
    const [image, setImage] = useState();
    const [modalShowing, setModalShowing] = useState<boolean>(false);
    const [logoPreview, setLogoPreview] = useState<string>("");
    const [originalLogo, setOriginalLogo] = useState<string>("");
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const editorRef = useRef();

    const { t } = useTranslation();

    const getOriginalImage = async () => {
        try {
            const { data } =
                uploader === "admin"
                    ? await adminGetMerchantOriginalLogo(entityProps.merchant.merchant_id)
                    : await MerchantGetOriginalLogo();
            setOriginalLogo(data.original_logo_url);
        } catch (err) {
            console.error(err);
        }
    };

    const reverse = (str) => str.split("").reverse().join("");

    const getFileExtensitonFromUrl = (url) => {
        let str = reverse(url);
        let strPos = str.indexOf("X?");
        str = str.substring(strPos + 2, str.indexOf("."));
        return reverse(str);
    };
    const handleEditOriginalLogo = async () => {
        setIsEditing(true);

        // turn the image we got to File
        const ext = getFileExtensitonFromUrl(originalLogo);
        try {
            const response = await fetch(originalLogo);

            const blob = await response.blob();
            const file = new File([blob], "merchantImg." + ext, {
                type: blob.type,
            });
            setImage(file as any);
        } catch (err) {
            console.error(err);
        }

        setModalShowing(true);
    };

    useEffect(() => {
        getOriginalImage();
    }, [getOriginalImage]);

    const handleDrop = (dropped) => {
        const allowedTypes = ["image/jpg", "image/jpeg", "image/png"];
        if (allowedTypes.includes(dropped[0].type)) {
            setModalShowing(true);
            setImage(dropped[0]);
        } else {
            void message.error(t("wrongType"));
        }
    };

    const handleModalOk = async () => {
        const editor = editorRef.current as any;
        // cropped image
        const cropped = editor.getImageScaledToCanvas().toDataURL();

        setLogoPreview(cropped);

        // upload the correctly cropped image
        try {
            uploader === "merchant"
                ? await MerchantPutLogo(cropped)
                : await adminPutMerchantLogo(entityProps.merchant.merchant_id, cropped);

            if (!isEditing) {
                // original image is also uploaded
                const reader = new FileReader();
                // convert image file to base64 string
                reader.readAsDataURL(image);
                reader.addEventListener(
                    "load",
                    async function () {
                        try {
                            uploader === "admin"
                                ? await adminUploadMerchantOriginalLogo(entityProps.merchant.merchant_id, reader.result)
                                : await MerchantUploadOriginalLogo(reader.result);
                        } catch (err) {
                            void message.error("Failed to upload original file");
                        }
                    },
                    false
                );
            }

            // refresh the original image, with a timeout so it's propagated on the BE
            setTimeout(async () => {
                await getOriginalImage();
            }, 1000);

            void message.success(t("logoUpdated"));
        } catch (err) {
            void message.error(t("errors:generalError"));
        }
        setIsEditing(false);
        setModalShowing(false);
    };

    const editBtn = <Button onClick={handleEditOriginalLogo}>Edit</Button>;
    const renderAdminView = () => {
        let fragment;
        if (!entityProps.merchant) {
            return null;
        }
        if (!entityProps.merchant.logo_url) {
            fragment = <Typography.Text>{t("logoInfo")}</Typography.Text>;
        } else {
            fragment = <img src={entityProps?.merchant?.logo_url} alt={t("merchantLogo")} />;
        }
        return (
            <Row align="middle" justify="start" gutter={0}>
                <Col span={4}>{t("yourLogo")}:</Col>
                <Col>{fragment}</Col>
            </Row>
        );
    };

    const renderMerchantView = () => {
        let fragment;
        if (!entityProps.company) {
            return null;
        }
        if (!entityProps.company.logo_url) {
            fragment = <Typography.Text>{t("logoInfo")}</Typography.Text>;
        } else {
            fragment = <img src={entityProps?.company?.logo_url} alt={t("merchantLogo")} />;
        }
        return (
            <Row align="middle" justify="start" gutter={0}>
                <Col span={4}>{t("yourLogo")}:</Col>
                <Col>{fragment}</Col>
            </Row>
        );
    };

    return (
        <>
            {!logoPreview ? (
                entityProps ? (
                    uploader === "admin" ? (
                        renderAdminView()
                    ) : (
                        renderMerchantView()
                    )
                ) : (
                    <Typography.Text>{t("logoInfo")}</Typography.Text>
                )
            ) : (
                <Row align="middle" justify="start" gutter={0}>
                    <Col span={3}>Preview :</Col>
                    <Col>
                        <img src={logoPreview} alt={t("merchantLogo")} />
                    </Col>
                </Row>
            )}
            <Spacer hx={2} />
            {originalLogo && (
                <Row>
                    <Col span={4}>{editBtn}</Col>
                </Row>
            )}

            <Spacer hx={2} />
            <Dropzone onDrop={handleDrop}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className={styles.uploadArea}>
                            <FileAddOutlined />
                            {t("uploadLogo")}
                        </p>
                    </div>
                )}
            </Dropzone>
            <Modal
                width={600}
                title={t("editLogo")}
                maskClosable
                onCancel={() => {
                    setModalShowing(false);
                }}
                visible={modalShowing}
                onOk={handleModalOk}
            >
                <ReactAvatarEditor
                    ref={editorRef}
                    style={{ width: "500px", height: "200px" }}
                    scale={1.5}
                    width={200}
                    height={50}
                    border={10}
                    image={image}
                />
            </Modal>
        </>
    );
};
export default ImageUpload;
