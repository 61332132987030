import * as React from "react";
import styles from "components/admin/presenters/preview-pane/PreviewPane.module.scss";
import { Card, Divider, Empty, Typography } from "antd";
import { FileSearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { adminGetApplicationById } from "api/applicationService";
import history from "helpers/history";
import { RouteStrings } from "routes/RouteStrings";
import { DataItemsColumn } from "components/common/presenters/data-items-column/DataItemsColumn";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { currencyLocaleFormatter } from "formatters/currencyFormatters";
import { adminGetClient } from "api/admin/admin";
import { merchantGetApplication } from "api/merchant";

interface PreviewPaneProps {
    applicationId?: string
    clientId?: string
    merchantApplId?: string
}

interface ApplicationData {
    application_id: string
    client_title: string
    client_first_name: string
    client_last_name: string
    client_birth_date: string
    client_email: string
    client_mobile_number: string | number
    client_address_street: string
    client_address_house_number: string
    client_address_post_code: string
    client_address_city: string
    client_address_country_code: string
    client_job_type: string
    client_job_title: string
    client_job_branch: string
    client_employer_name: string
    bo_assignee_id: string
    status: string
    amount: string | number
    merchant_name: string
    order_id: string
    session_id: string
}

interface ClientData {
    address_city: string
    address_country_code: string
    address_house_number: string
    address_post_code: string
    address_resident_since: string
    address_street: string
    bank_name: null | string
    bic: null | string
    birth_city: string
    birth_date: string
    bo_contact_id: null | string
    client_id: string
    created_at: string
    email: string
    employed_since: string
    employer_location: string
    employer_name: string
    family_status: string
    first_name: string
    has_vehicle: boolean
    iban: string
    id_doc_expires_at: null | string
    id_doc_issued_at: null | string
    id_doc_issued_place: string
    id_doc_number: string
    id_doc_type: string
    is_accepted_agb: true
    is_accepted_privacy_policy: true
    is_deleted: false
    is_received_pre_contract_info: true
    job_branch: string
    job_title: string
    job_type: string
    language_code: string
    last_name: string
    living_costs: string
    mobile_number: string
    nationality_country_code: string
    net_income: string
    num_children: number
    prev_address_city: string
    prev_address_country_code: string
    prev_address_house_number: string
    prev_address_post_code: string
    prev_address_resident_since: string
    prev_address_street: string
    product_interest: number | string
    short_id: string
    title: string
    type_of_residence: string
}
const { Text } = Typography;

export const PreviewPane = (props: PreviewPaneProps) => {
    const { t } = useTranslation();

    const [data, setData] = React.useState<ApplicationData>();
    const [clientData, setClientData] = React.useState<ClientData>();
    const [isEmpty, setIsEmpty] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (props.applicationId) {
            fetchAllData(props.applicationId);
        }
    }, [props.applicationId]);

    React.useEffect(() => {
        if (props.clientId) {
            fetchAllData(props.clientId);
        }
    }, [props.clientId]);

    React.useEffect(()=>{
        if (props.merchantApplId) {
            fetchAllData(props.merchantApplId);
        }
    },[props.merchantApplId]);

    const fetchAllData = async (id: string) => {
        // await adminGetAllContactPersonsForClient();
        if(props.applicationId) {
            const appData = await adminGetApplicationById(id);
            setData(appData.data);
        }else if(props.merchantApplId){
            const appData = await merchantGetApplication(id);
            setData(appData.data);   
        }else {
            const clientData = await adminGetClient(id);
            setClientData(clientData.data);
        }

        setIsEmpty(false);
    };

    const handleOnView = () => {
        let link: string;

        if (props.applicationId) {
            link = RouteStrings.AdminApplicationBase + props.applicationId;
        } else if (props.merchantApplId) {
            link = RouteStrings.MerchantApplicationBase + props.merchantApplId;
        } else {
            link = RouteStrings.AdminClientBase + props.clientId;
        }
        history.push(link);
    };

    const renderApplicationDataItems = () => {
        const combinedAddress = props.applicationId || props.merchantApplId ? (
            <Text style={{ display: "inline-block", verticalAlign: "top" }}>
                <Text>
                    {data.client_address_street}, {data.client_address_house_number}
                </Text>
                <br />
                <Text>
                    {data.client_address_post_code},{data.client_address_city}
                </Text>
                <br />
                <Text>{t(data.client_address_country_code)}</Text>
                <br />
            </Text>
        ) : (
            <Text style={{ display: "inline-block", verticalAlign: "top" }}>
                <Text>
                    {clientData.address_street}, {clientData.address_house_number}
                </Text>
                <br />
                <Text>
                    {clientData.address_post_code},{clientData.address_city}
                </Text>
                <br />
                <Text>{t(clientData.address_country_code)}</Text>
                <br />
            </Text>
        );

        if (props.applicationId || props.merchantApplId) {
            return (
                <React.Fragment>
                    <DataItemsColumn
                        dataItems={[
                            { itemName: "Antrags ID", itemValue: data.application_id.substring(0, 10) },
                            { itemName: t("clientTitle"), itemValue: data.client_title },
                            { itemName: t("clientName"), itemValue: data.client_first_name },
                            { itemName: t("clientLastName"), itemValue: data.client_last_name },
                            { itemName: t("dateOfBirth"), itemValue: data.client_birth_date },
                            { itemName: t("email2"), itemValue: data.client_email },
                            { itemName: t("mobileNumber"), itemValue: data.client_mobile_number },
                            { itemName: t("address"), itemValue: combinedAddress },
                        ]}
                    />
                    <Divider />

                    <DataItemsColumn
                        dataItems={[
                            { itemName: t("jobType"), itemValue: t(`occupationGroups:${data.client_job_type}`) },
                            { itemName: t("jobBranch"), itemValue: data.client_job_branch },
                            { itemName: t("jobTitle"), itemValue: data.client_job_title },
                            { itemName: t("employer"), itemValue: data.client_employer_name },
                            { itemName: t("handler"), itemValue: data.bo_assignee_id },
                        ]}
                    />

                    <Divider />
                    <DataItemsColumn
                        dataItems={[
                            { itemName: t("status"), itemValue: t(`applicationStates:${data.status}`) },
                            { itemName: t("labels:amount"), itemValue: currencyLocaleFormatter(data.amount) },
                            { itemName: "Order ID", itemValue: data.order_id },
                            { itemName: "Session ID", itemValue: data.session_id },
                            { itemName: t("handler"), itemValue: data.bo_assignee_id },
                            { itemName: t("channel"), itemValue: data.merchant_name },
                        ]}
                    />
                    <Spacer />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <DataItemsColumn
                        dataItems={[
                            { itemName: "Kunden ID", itemValue: clientData.short_id },
                            { itemName: t("clientTitle"), itemValue: clientData.title },
                            { itemName: t("clientName"), itemValue: clientData.first_name },
                            { itemName: t("clientLastName"), itemValue: clientData.last_name },
                            { itemName: t("dateOfBirth"), itemValue: clientData.birth_date },
                            { itemName: t("email2"), itemValue: clientData.email },
                            { itemName: t("mobileNumber"), itemValue: clientData.mobile_number },
                            { itemName: t("address"), itemValue: combinedAddress },
                        ]}
                    />
                    <Divider />

                    <DataItemsColumn
                        dataItems={[
                            { itemName: t("jobType"), itemValue: clientData.job_type },
                            { itemName: t("jobBranch"), itemValue: clientData.job_branch },
                            { itemName: t("jobTitle"), itemValue: clientData.job_title },
                            { itemName: t("employer"), itemValue: clientData.employer_name },
                            { itemName: "Einkommen", itemValue: clientData.net_income },
                        ]}
                    />

                    <Divider />
                    <DataItemsColumn
                        dataItems={[
                            { itemName: "Wohnart", itemValue: clientData.type_of_residence },
                            { itemName: "Wohnkosten", itemValue: clientData.living_costs },
                            { itemName: "Kinder", itemValue: clientData.num_children },
                            { itemName: "Fahrzeuge", itemValue: clientData.has_vehicle },
                        ]}
                    />
                    <Spacer />
                </React.Fragment>
            );
        }
    };

    return (
        <Card className={styles.paneBody}>
            <Typography.Title level={5} className={styles.previewHeader}>
                Antragsinformationen
            </Typography.Title>
            {!isEmpty ? (
                <div className={styles.paneBodyFlex}>
                    {renderApplicationDataItems()}

                    <button onClick={handleOnView} className={styles.previewButton}>
                        &nbsp; {props.applicationId || props.merchantApplId ? "Antrag" : "Kunden"} {t("view")}
                    </button>
                </div>
            ) : (
                <Empty
                    className={styles.empty}
                    image={<FileSearchOutlined className={styles.emptyPreview} />}
                    description={t("emptyPreview")}
                />
            )}
        </Card>
    );
};
