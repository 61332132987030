import { ApiWrapper } from "api/ApiWrapper";
import { ApiResponse } from "api/types";
import {
    ClientUpdateApplicationIdData,
    ClientUpdateIdData,
    ClientUpdateProfile
} from "api/types/client";
import { IdentificationInfo } from "types/applicationProcess";

export const clientUpdateProfile = async (data: ClientUpdateProfile): Promise<any> => {
    const path = `/client/profile`;

    return ApiWrapper.put(path, data) as Promise<any>;
};

export const clientUpdateIdData = async (data: ClientUpdateIdData): Promise<any> => {
    const path = `/client/identification_data`;

    return ApiWrapper.put(path, data) as Promise<any>;
};

export const clientCreateApplication = async (sessionId: string,duration: number): Promise<any> => {
    const path = `/client/new_application`;
    const body = {
        session_id: sessionId,
        duration_months: duration,
    };
    return ApiWrapper.post(path, body) as Promise<any>;
};

export const clientGetApplicationDraft = async (sessionId: string): Promise<any> =>{
    const path = `/client/sessions/${sessionId}`;

    return ApiWrapper.get(path);
};

export const clientAcquireOffers = async (applicationId: string): Promise<any> => {
    const path = `/client/applications/${applicationId}/acquire_offers`;

    const body = {

    };

    return ApiWrapper.post(path, body) as Promise<any>;
};

export const clientGetApplicationById = async (applicationId: string): Promise<any> =>{
    const path = `/client/applications/${applicationId}`;

    return ApiWrapper.get(path) as Promise<any>;
};

export const clientGetApplications = async(pageNumber: number): Promise<any> =>{
    const path = `/client/applications?page=${pageNumber}`;

    return ApiWrapper.get(path);
};

export const clientGetProfile = async(): Promise<any> =>{
    const path = `/client/profile`;

    return ApiWrapper.get(path);
};

export const clientChangePassword = async(oldPassword,newPassword): Promise<any> =>{
    const path = `/client/account/change_password`;

    return ApiWrapper.post(path,{
        old_password:oldPassword,
        new_password:newPassword,
    });
};
export const clientGetSelectedOffer = async (applicationId: string): Promise<any> => {
    const path = `/client/applications/${applicationId}/selected_offer`;

    return ApiWrapper.get(path) as Promise<any>;
};

export const clientUpdateApplicationIdData = async (id: string, data: ClientUpdateApplicationIdData): Promise<any> => {
    const path = `/client/applications/${id}/identification_data`;

    return ApiWrapper.put(path, data) as Promise<any>;
};

export const clientUpdateIdentificationData = async(data: IdentificationInfo): Promise<any> =>{

    const path = `/client/identification_data`;
    
    return ApiWrapper.put(path,data) as Promise<any>;
};
// Client registration
export const clientCheckRegisterTokenValid = async (registerToken: string): Promise<ApiResponse<void>> => {
    const path = `/client/account/invites/${registerToken}`;

    return ApiWrapper.get(path, true);
};
export const clientAcceptInvitation = async (registerToken: string, newPassword: string): Promise<ApiResponse<void>> => {
    const path = `/client/account/accept_invitation`;

    const body = {
        password: newPassword,
        token: registerToken,
    };

    return ApiWrapper.post(path, body, true);
};
