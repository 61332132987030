// App config and related types
export enum AppType {
    Client = "client",
    Merchant = "merchant",
    Admin = "admin"
}

export enum Environments {
    Development = "dev",
    Production = "prod"
}
