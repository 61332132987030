import { Button, Card, Col, Form, Input, message, Row, Tabs, Typography } from "antd";
import { adminChangeBOUserPassword, adminGetSelfProfile } from "api/boUserService";
import { GetAdminProfileResponse } from "api/types";
import styles from "components/admin/presenters/admin-home-tabs/AdminHomeTabs.module.scss";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ManageBOUsersSection } from "./ManageBOUsersSection";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { isValidPassword } from "../../../../../helpers/inputValidationHelpers";
import { isAdminApp } from "helpers/appHelpers";

const { TabPane } = Tabs;

export const SettingsTab = () => {
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState({} as GetAdminProfileResponse);
    const [error, setError] = useState("");

    const getData = async () => {
        try {
            const profileReq = await adminGetSelfProfile();

            setProfile(profileReq.data);
        } catch (error) {
            console.error(error);
            setError(t("messages:errorEncountered"));
        }
    };

    const changePassword = async (password) => {
        setError("");
        setLoading(true);
        try {
            await adminChangeBOUserPassword(password);
            message.success(t("messages:passwordChanged"));
        } catch (error) {
            message.error(t("messages:couldNotSave"));
            console.error();
        }
        setLoading(false);
    };

    const handleOnFinish = (values: any) => {
        if (values.password !== values.password_repeat) {
            message.error(t("passwordsMatchError"));
        } else {
            changePassword(values.password);
        }
    };

    React.useEffect(() => {
        getData();
    }, []);

    return (
        <section style={{ height: "100%", background: "#FBFBFB" }}>
            <Tabs defaultActiveKey="profile" className="admin-home-tab">
                <TabPane tab="Profil" key="profile">
                    <Row gutter={20}>
                        <Col span={12}>
                            <Card title={<Typography.Title level={5}>Email</Typography.Title>}>
                                <span>E-Mail: &nbsp;</span>
                                {profile ? profile.email : ""}
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card title={<Typography.Title level={5}>Benutzerkonten</Typography.Title>}>
                                <Form
                                    className={styles.passwordForm}
                                    layout={"vertical"}
                                    form={form}
                                    onFinish={handleOnFinish}
                                    scrollToFirstError
                                >
                                    <Form.Item
                                        name="password"
                                        label={t("password")}
                                        hasFeedback
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (value && !isValidPassword(value)) {
                                                        return Promise.reject(t("errors:passwordInvalid"));
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password data-cy="input_password" className={styles.input} />
                                    </Form.Item>

                                    <Form.Item
                                        name="password_repeat"
                                        label={t("passwordRepeat")}
                                        dependencies={["password"]}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: t("errors:confirmPassword"),
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue("password") === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(t("errors:passwordsDoNotMatch"));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password data-cy="input_password_repeat" className={styles.input} />
                                    </Form.Item>

                                    <div className="custom-error">{error}</div>
                                    <Spacer />

                                    <Form.Item>
                                        <Row className={styles.rowCenterX}>
                                            <Button type="primary" htmlType="submit" loading={loading}>
                                                {t("buttons:changePassword")}
                                            </Button>
                                        </Row>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </TabPane>
                {profile?.role === "BO Admin" && (
                    <TabPane tab="Benutzerkonten" key="manage">
                        <Row>
                            {isAdminApp() ? (
                                <React.Fragment>
                                    <ManageBOUsersSection />
                                </React.Fragment>
                            ) : null}
                        </Row>
                    </TabPane>
                )}
            </Tabs>
        </section>
    );
};
