import { Button, Card, Col, Row, Tag, Typography } from "antd";
import { Action, merchantChangeApplicationStates } from "api/merchant";
import { ApplicationObject } from "api/types";
import styles from "components/admin/pages/application-drilldown/ApplicationDrilldownPage.module.scss";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface MerchantApplicationDetailsTabProps {
    application: ApplicationObject
    updateApplication: () => Promise<any>
}


export const MerchantApplicationDetailsTab = (props: MerchantApplicationDetailsTabProps) => {
    const { t } = useTranslation();
    const appl = props.application;

    return (
        <>
            <Row gutter={24} align="stretch">
                <Col span={12}>
                    <Card
                        style={{ height: "100%" }}
                        title={
                            <Typography.Title className={styles.clientTitle} level={5}>
                                Antragsinformationen
                            </Typography.Title>
                        }
                    >
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Antrags ID :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.short_id ? appl.short_id : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>{t("handler")} :</span>
                            </Col>
                            <Col span={16}>
                                <span>
                                    {appl.created_by_merchant_user_name ? appl.created_by_merchant_user_name : "-"}
                                </span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>{t("amount")} :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.amount ? appl.amount : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>{t("currency")} :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.currency ? appl.currency : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>{t("status")} :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.status ? appl.status : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Letzte Statusänderung :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.created_at ? appl.created_at.split("T")[0] : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Notizen der Bank :</span>
                            </Col>
                            <Col span={16}>
                                <span> - </span>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col span={12}>
                    <Card
                        style={{ height: "100%" }}
                        title={
                            <Typography.Title className={styles.clientTitle} level={5}>
                                Verfügbare Aktionen
                            </Typography.Title>
                        }
                    >
                        <Row justify="space-between" wrap>
                            {appl && appl.actions ? (
                                appl.actions.map((action: Action) => {
                                    return (
                                        <>
                                            <Col>
                                                <Button
                                                    type="primary"
                                                    className={`btn-${action.color}`}
                                                    onClick={() => {
                                                        merchantChangeApplicationStates(
                                                            props.application.application_id,
                                                            action
                                                        );
                                                    }}
                                                >
                                                    {t(`buttons:${action.params?.new_status || action.name}`)}
                                                </Button>
                                            </Col>
                                        </>
                                    );
                                })
                            ) : (
                                <Col span={6}>
                                    <Button type="primary" className={`btn-primary`} onClick={props.updateApplication}>
                                        {t("buttons:refresh")}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Spacer hx={1} />
            <Row gutter={24} align="stretch">
                <Col span={12}>
                    <Card
                        style={{ height: "100%" }}
                        title={
                            <Typography.Title className={styles.clientTitle} level={5}>
                                Kundeninformationen
                            </Typography.Title>
                        }
                    >
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Kunden ID : </span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_id ? appl.client_id : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Registriert am :</span>
                            </Col>
                            <Col span={16}>
                                <span> - </span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}> Vorname :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_first_name ? appl.client_first_name : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}> Nachname :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_last_name ? appl.client_last_name : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}> Geburtsdatum :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_birth_date ? appl.client_birth_date : "-"}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}> E-Mail :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_email ? appl.client_email : "-"}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Handynummer :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_mobile_number ? appl.client_mobile_number : "-"}</span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"} align="top">
                            <Col span={8}>
                                <span className={styles.faintText}>Adresse :</span>
                            </Col>
                            <Col span={16}>
                                <Typography.Text style={{ display: "inline-block", verticalAlign: "top" }}>
                                    <Typography.Text>
                                        {appl.client_address_street}, {appl.client_address_house_number}
                                    </Typography.Text>
                                    <br />
                                    <Typography.Text>
                                        {appl.client_address_post_code},{appl.client_address_city}
                                    </Typography.Text>
                                    <br />
                                    <Typography.Text>
                                        {t(appl.client_address_country_code).toUpperCase()}
                                    </Typography.Text>
                                    <br />
                                </Typography.Text>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col span={12}>
                    <Card
                        style={{ height: "100%" }}
                        title={
                            <Typography.Title className={styles.clientTitle} level={5}>
                                Beruf & Haushalt
                            </Typography.Title>
                        }
                    >
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Beruf :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_job_type ? appl.client_job_type : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Beschreibung :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_job_branch ? appl.client_job_branch : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Angestellt seit :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_employed_since ? appl.client_employed_since : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Arbeitgeber :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_employer_name ? appl.client_employer_name : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Wohnkosten :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_living_costs ? appl.client_living_costs : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Kinder :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_num_children ? appl.client_num_children : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Fahrzeuge :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.client_has_vehicle ? appl.client_has_vehicle : "-"}</span>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Spacer hx={1} />

            <Row gutter={24}>
                <Col span={12}>
                    <Card
                        style={{ height: "100%" }}
                        title={
                            <Typography.Title className={styles.clientTitle} level={5}>
                                Händlerinformationen
                            </Typography.Title>
                        }
                    >
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Händler ID : </span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.merchant_id}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Händlername :</span>
                            </Col>
                            <Col span={16}>
                                <span>{appl.merchant_name ? appl.merchant_name : "-"}</span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}> Warenkorb ID :</span>
                            </Col>
                            <Col span={16}>
                                <span> {appl.merchant_id} </span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}> Produkt :</span>
                            </Col>
                            <Col span={16}>
                                <span> - </span>
                            </Col>
                        </Row>

                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}> Kanal :</span>
                            </Col>
                            <Col span={16}>
                                <span> - </span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}> Händler E-Mail :</span>
                            </Col>
                            <Col span={16}>
                                <span> - </span>
                            </Col>
                        </Row>
                        <Row gutter={12} justify={"space-between"}>
                            <Col span={8}>
                                <span className={styles.faintText}>Handynummer :</span>
                            </Col>
                            <Col span={16}>
                                <span> - </span>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col span={12}>
                    <Card
                        title={
                            <Typography.Title className={styles.clientTitle} level={5}>
                                Bank Angebote
                            </Typography.Title>
                        }
                    >
                        <div className={styles.infoContainer}>
                            <Row className={styles.cell}>
                                <Col span={8}>
                                    <b>Bank Name</b>
                                </Col>
                                <Col span={8}>
                                    <b>Zins</b>
                                </Col>
                                <Col span={8}>
                                    <b>Status</b>
                                </Col>
                            </Row>
                            <hr />
                            <Row className={styles.cell}>
                                <Col span={8}>
                                    <span>Santander</span>
                                </Col>
                                <Col span={8}>
                                    <span>9.9%</span>
                                </Col>
                                <Col span={8}>
                                    <Tag color="red">Abgelehnt</Tag>
                                </Col>
                            </Row>
                            <hr />
                            <Row className={styles.cell}>
                                <Col span={8}>
                                    <span>Solarisbank</span>
                                </Col>
                                <Col span={8}>
                                    <span>18.4%</span>
                                </Col>
                                <Col span={8}>
                                    <Tag color="blue">Angebot</Tag>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    );
};
