import { Layout, message, Tabs } from "antd";
import { merchantGetApplication } from "api/merchant";
import { ApplicationObject } from "api/types";
import { SideMenu } from "components/common/presenters/side-menu/SideMenu";
import { TopInfoSection, TopInfoSectionType } from "components/common/presenters/top-info-section/TopInfoSection";
import { MerchantApplicationDetailsTab } from "components/merchant-panel/application-details-tab/MerchantApplicationDetailsTab";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ApplicationStates } from "types/types";
import styles from "./MerchantApplicationDrilldownPage.module.scss";
import "./MerchantTabMenu.scss";

const { TabPane } = Tabs;
const { Header, Content } = Layout;
export const MerchantApplicationDrilldownPage = () => {
    const { t } = useTranslation();

    let { applicationId } = useParams<any>();

    const [appl, setAppl] = useState({} as ApplicationObject);

    // const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

    const tabTitles = [t("applicationDetails"), t("documents"), t("notes"), t("logs")];

    const [activeTab, setActiveTab] = useState("-1");

    const onTabChange = (key) => {
        setActiveTab(key);
    };

    const getDataAndSetState = async (): Promise<any> => {
        try {
            const applReq = await merchantGetApplication(applicationId);

            setAppl(applReq.data);

        } catch (error) {
            console.error(error);
            message.error("errors:getApplication");
        }
    };

    useEffect(() => {
        try {
            getDataAndSetState();
        } catch (error) {
            console.error(error);
        }
    }, []);

    return (
        <Layout className={styles.page}>
            <SideMenu isLoggedIn={true} />

            <Layout className={styles.sectionRight}>
                <Header className={styles.header}>
                    <TopInfoSection
                        type={TopInfoSectionType.TypeApplication}
                        companyName={"Muster Shop GmbH"}
                        applicationId={applicationId}
                        application={appl as any}
                        applicationState={appl?.status as ApplicationStates}
                        applicationType={appl?.type}
                        dropdownOptions={[t("newReminder"), t("newToDo"), t("newApplication")]}
                        dropdownActions={
                            [
                                // handleNewReminderSelected,
                                // handleNewToDoSelected,
                                // handleNewApplicationSelected,
                            ]
                        }
                    />
                </Header>

                <Content className={styles.clientDrill}>
                    <Tabs className="tab-menu" defaultActiveKey="-1" activeKey={activeTab} onChange={onTabChange}>
                        <TabPane tab={tabTitles[0]} key={`-1`}>
                            <MerchantApplicationDetailsTab key="adt" application={appl} updateApplication={getDataAndSetState}/>
                        </TabPane>
                    </Tabs>
                </Content>
            </Layout>
        </Layout>
    );
};
