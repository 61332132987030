import { LockOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import styles from "components/common/presenters/application-process-header/ApplicationProcessHeader.module.scss";
import { getCurrentPageStepNumber } from "navigation/applicationSteps";
import { goTo, goToExternal } from "navigation/navigationHelpers";
import React, { useEffect } from "react";
import { RouteStrings } from "routes/RouteStrings";

export interface ApplicationProcessHeaderProps {
    hideNav?: boolean
    logoLink?: string
    checkLoggedInStatus?: boolean
    loginPage?: boolean
}

export const ApplicationProcessHeader = (props: ApplicationProcessHeaderProps) => {
    // const [t] = useTranslation();
    const [, setActiveStep] = React.useState(null);
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const [, setMobileBreakpointReached] = React.useState(false);

    // this breakpoint is specific for this component.
    // when the content gets updated, the breakpoint might also need to get updated
    const mobileBreakpointPx = 500;

    // The header should know which step it's on automatically instead of manually passing
    // the active step in each place where ApplicationProcessHeader is used.
    // That way we can reorder steps and won't need to update each step individually.
    useEffect(() => {
        const stepNumber = getCurrentPageStepNumber();
        setActiveStep(stepNumber);
    });

    const updateMobileStatus = () => {
        setMobileBreakpointReached(window.innerWidth <= mobileBreakpointPx);
    };

    useEffect(() => {
        // has to run once at mount
        updateMobileStatus();

        window.addEventListener("resize", updateMobileStatus);

        return () => {
            window.removeEventListener("resize", updateMobileStatus);
        };
    }, []);

    const handleLogoClick = () => {
        if (props.logoLink) {
            goToExternal(props.logoLink);
        } else {
            goTo(RouteStrings.Index);
        }
    };

    useEffect(() => {
        setIsLoggedIn(false);
    }, []);

    // const renderButton = () => {
    //     if (mobileBreakpointReached) {
    //         return <Link className={styles.link} to={RouteStrings.Login}>
    //             {t("buttons:login")}
    //         </Link>;
    //     }

    //     return <Button data-cy="button_login"
    //         className={styles.button}
    //         type="primary"
    //         size="large"
    //         disabled={false}
    //         onClick={() => {
    //             window.location.href = window.location.origin + `${RouteStrings.Login}`;
    //         }}>
    //         {t("buttons:login")}
    //     </Button>;
    // };

    return (
        <div className={`${styles.header} ${props.loginPage && styles.transparent}`}>
            <div className={styles.logoContainer} onClick={handleLogoClick}>
                <div className={styles.logo}></div>
            </div>

            <div className={styles.buttonsContainer}>
                {props.hideNav || isLoggedIn || !props.checkLoggedInStatus ? null : (
                    <>
                        <LockOutlined className={styles.lockIcon} />
                        <Typography.Title level={5}>Secure Order</Typography.Title>
                    </>
                )}
            </div>
        </div>
    );
};
