import { ProgressBarStepsItem } from "components/common/presenters/progress-bar/ProgressBar";
import { isMerchantApp } from "helpers/appHelpers";
import i18n from "i18n/i18n";
import { RouteStrings } from "routes/RouteStrings";

const stepName = () => i18n.t("applProcess:step");

export const defaultFlowSteps: ProgressBarStepsItem[] = [
    {
        title: `${i18n.t("applProcess:step")} 1`,
        subtitle: "",
        description: i18n.t("applProcess:stepRegistration"),
    },
    {
        title: `${i18n.t("applProcess:step")} 2`,
        subtitle: "",
        description: i18n.t("applProcess:stepPersonalData"),
    },
    {
        title: `${i18n.t("applProcess:step")} 3`,
        subtitle: "",
        description: i18n.t("applProcess:stepIncomeData"),
    },
    {
        title: `${i18n.t("applProcess:step")} 4`,
        subtitle: "",
        description: i18n.t("applProcess:stepSummary"),
    },
    {
        title: `${i18n.t("applProcess:step")} 5`,
        subtitle: "",
        description: i18n.t("applProcess:stepFinancingOffer"),
    },
    {
        title: `${i18n.t("applProcess:step")} 6`,
        subtitle: "",
        description: i18n.t("applProcess:stepEnd"),
    },
];

const modifyStepsIndexes = (steps: ProgressBarStepsItem[]) => {
    for (let i = 0; i < steps.length; i++) {
        steps[i].title = `${stepName()} ${i + 1}`;
    }

    return steps;
};

export const getCurrentPageStepNumber = () => {
    const currentHash = "/" + window.location.hash.substring(1).split("?")[0];


    const processFlowPaths = isMerchantApp() ? RouteStrings.MERCHANT_PROCESS_FLOW_PATHS : RouteStrings.USER_PROCESS_FLOW_PATHS; 

    // search current page in array, containing pathnames of application flow steps
    const stepNumber = processFlowPaths.findIndex((stepName) => {
        return stepName === currentHash;
    });


    return stepNumber;
};

export const getFlowSteps = () => {
    let steps = [...defaultFlowSteps];

    steps = modifyStepsIndexes(steps);

    return steps;
};
