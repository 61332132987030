import { EyeOutlined, RedoOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Layout, message, Popconfirm, Row, Table, Tabs, Typography } from "antd";
import Search from "antd/lib/input/Search";
import {
    adminGenerateMerchantApiKey,
    adminGetMerchant,
    adminGetMerchantApiKey,
    adminGetMerchantApplications,
    adminGetMerchantCreatedUsers,
    adminUpdateMerchantDisplayName,
} from "api/admin/merchants";
import { ApplicationObject, Merchant, MerchantUser } from "api/types";
import { MerchantDetailsSection } from "components/admin/pages/merchant-drilldown/MerchantDetailsSection";
import { DocumentsTab } from "components/admin/presenters/applications-drilldown-tabs/documents-tab/DocumentsTab";
import { NotesTab, NotesTabType } from "components/admin/presenters/applications-drilldown-tabs/notes-tab/NotesTab";
import ImageUpload from "components/common/presenters/image-upload/ImageUpload";
import { SideMenu } from "components/common/presenters/side-menu/SideMenu";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { AddMerchantUserModal } from "components/merchant-panel/settings-tab/AddMerchantUserModal";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PaginationType } from "types/adminPanel";
import styles from "./AdminMerchantDrilldownPage.module.scss";
import "./AdminMerchantTabMenu.scss";
import MerchantTab from "./merchant-tabs/MerchantTab";
import { columns as applicationsListColumns } from "./merchantsDrilldownApplicationsColumns";
import { columns as userListColumns } from "./merchantsDrilldownUsersColumns";

const { Header, Content } = Layout;
const { TabPane } = Tabs;
export const AdminMerchantDrilldownPage = () => {
    const { t } = useTranslation();

    let { merchantId } = useParams<any>();

    const [merchant, setMerchant] = useState({} as Merchant);
    const [merchantUsersList, setMerchantUsersList] = useState([] as MerchantUser[]);
    const [merchantUserPagination, setMerchantUserPagination] = React.useState<PaginationType>({});

    const [merchantApplicationsList, setMerchantApplicationsList] = useState([] as ApplicationObject[]);
    const [merchantAppPagination, setMerchantAppPagination] = React.useState<PaginationType>({});

    const [loading, setLoading] = useState<boolean>(false);

    const [apiForm] = Form.useForm();

    const [nameForm] = Form.useForm();

    const [activeTab, setActiveTab] = useState("-1");

    const [addMerchantUserModalOpen, setAddMerchantUserModalOpen] = useState(false);

    const [searchStr, setSearchStr] = useState<string>("");
    const [keyShowing, setKeyShowing] = useState<boolean>(false);

    const showApiKey = async () => {
        setKeyShowing(!keyShowing);
        //  get key
        try {
            const { data } = await adminGetMerchantApiKey(merchant.merchant_id);
            !keyShowing
                ? apiForm.setFieldsValue({
                    apikey: data.api_key,
                })
                : apiForm.setFieldsValue({
                    apikey: "••••••••••••••••••••",
                });
        } catch {
            void message.error(t("errorAPIRetrieve"));
        }
    };

    const generateApiKey = async () => {
        //  reset key
        try {
            const response = await adminGenerateMerchantApiKey(merchant.merchant_id);
            keyShowing
                ? apiForm.setFieldsValue({
                    apikey: response.data.api_key,
                })
                : apiForm.setFieldsValue({
                    apikey: "••••••••••••••••••••",
                });

            void message.success(t("generatedAPI"));
        } catch {
            void message.error(t("errorAPIGeneration"));
        }
    };
    const fetchMerchantUsers = async (): Promise<any> => {
        try {
            const mUsersReq = await adminGetMerchantCreatedUsers(merchantId, 1);

            setMerchantUsersList(mUsersReq?.data?.result);
        } catch (error) {
            console.error(error);
        }
    };

    const onTabChange = (key) => {
        setActiveTab(key);
    };

    const getDataAndSetState = async (): Promise<any> => {
        setLoading(true);

        // separate try-catch block for each req group, because otherwise if 1 fails, the rest don't get executed
        try {
            const merchantReq = await adminGetMerchant(merchantId);
            setMerchant(merchantReq?.data);
        } catch (error) {
            console.error(error);
        }

        try {
            const mUsersReq = await adminGetMerchantCreatedUsers(merchantId, 1);
            setMerchantUsersList(mUsersReq?.data?.result);
            setMerchantUserPagination(mUsersReq?.data?.pagination);
        } catch (error) {
            console.error(error);
        }

        try {
            const merchantApplReq = await adminGetMerchantApplications(merchantId, 1);
            setMerchantApplicationsList(merchantApplReq?.data?.result);
            setMerchantAppPagination(merchantApplReq?.data?.pagination);
        } catch (error) {
            console.error(error);
        }

        setLoading(false);
    };


    const getMerchantAppls = async (page: number) => {
        try {
            const merchantApplReq = await adminGetMerchantApplications(merchantId, page);
            setMerchantApplicationsList(merchantApplReq?.data?.result);
            setMerchantAppPagination(merchantApplReq?.data?.pagination);
        } catch (error) {
            console.error(error);
        }
    };

    
    const getMerchantUsers = async (page: number) => {
        try {
            const merchantUsersReq = await adminGetMerchantCreatedUsers(merchantId, page);
            setMerchantUsersList(merchantUsersReq?.data?.result);
            setMerchantUserPagination(merchantUsersReq?.data?.pagination);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSearch = async () => {
        if (searchStr && searchStr?.length > 2) {
            const merchantAppls = await adminGetMerchantApplications(merchantId, 1);

            setMerchantApplicationsList(
                merchantAppls?.data?.result.filter((item) => {
                    return item.application_id.includes(searchStr);
                })
            );
        } else if (searchStr === "") {
            const merchantAppls = await adminGetMerchantApplications(merchantId, 1);
            setMerchantApplicationsList(merchantAppls?.data?.result);
        }
    };

    const updateMerchantDisplayName = async () => {
        const displayName = nameForm.getFieldValue("display_name");
        if (!displayName.length) return;
        try {
            await adminUpdateMerchantDisplayName(merchant.merchant_id, displayName);
            void message.success(t("success"));
        } catch (err) {
            console.error(err);
            void message.error(t("errors:generalError"));
        }
    };

    useEffect(() => {
        try {
            getDataAndSetState();
        } catch (error) {
            console.error(error);
        }
    }, []);

    return (
        <Layout className={styles.page}>
            <SideMenu isLoggedIn={true} />

            <Layout className={styles.sectionRight}>
                <Header className={styles.header}>
                    <MerchantDetailsSection showTitle merchant={merchant} setMerchant={setAddMerchantUserModalOpen} />
                </Header>

                <Content className={styles.clientDrill}>
                    <Tabs className="tab-menu" defaultActiveKey="-1" activeKey={activeTab} onChange={onTabChange}>
                        <TabPane tab="Händlerinformationen " key={`-1`}>
                            <MerchantTab merchantInfo={merchant} refreshData={getDataAndSetState} />
                        </TabPane>

                        <TabPane tab="Anträge" key={`2`}>
                            <Row>
                                <Col span={24}>
                                    <Search
                                        placeholder={t("freeTextSearch")}
                                        value={searchStr}
                                        onChange={(e) => {
                                            setSearchStr(e.target.value);
                                        }}
                                        onSearch={() => {
                                            handleSearch();
                                        }}
                                    />
                                    <Spacer/>
                                </Col>
                                <Col>
                                    <p
                                        className="clearSearch"
                                        onClick={() => {
                                            setSearchStr("");
                                            handleSearch();
                                        }}
                                    >
                                        Suche zurücksetzen
                                    </p>
                                </Col>
                            </Row>
                            <Spacer />

                            <Table
                                loading={loading}
                                columns={applicationsListColumns}
                                dataSource={merchantApplicationsList}
                                scroll={{ x: true }}
                                pagination={{
                                    position: ["bottomCenter"],
                                    current: merchantAppPagination.current_page,
                                    total: merchantAppPagination.total_rows,
                                    pageSize: merchantAppPagination.items_per_page,
                                    showSizeChanger:false,
                                }}
                                onChange={(e) => getMerchantAppls(e.current)}
                            />
                        </TabPane>

                        <TabPane tab="Nutzerkonten" key={`3`}>
                            <Table
                                loading={loading}
                                columns={userListColumns}
                                dataSource={merchantUsersList}
                                scroll={{ x: true }}
                                pagination={{
                                    position: ["bottomCenter"],
                                    current: merchantUserPagination.current_page,
                                    total: merchantUserPagination.total_rows,
                                    pageSize: merchantUserPagination.items_per_page,
                                    showSizeChanger:false,
                                }}
                                onChange={(e)=>getMerchantUsers(e.current)}
                            />
                        </TabPane>
                        <TabPane tab="Dokumente" key={`4`}>
                            <DocumentsTab key="dt" merchantId={merchant.merchant_id} type="merchant" />
                        </TabPane>
                        <TabPane tab="Notizen" key={`5`}>
                            <NotesTab
                                key="nt"
                                entityId={merchantApplicationsList[0]?.application_id || ""}
                                type={NotesTabType.Application}
                            />
                        </TabPane>
                        <TabPane tab="Loan Application Portal">
                            <Row gutter={24} align="stretch">
                                <Col span={12}>
                                    <Card
                                        style={{ height: "100%" }}
                                        title={<Typography.Title level={5}>API</Typography.Title>}
                                    >
                                        <Typography.Paragraph className={styles.desc}>
                                            The API key allows you to make calls to the the Liquitree API.Only share
                                            this key with third-party services and applications that you trust.
                                        </Typography.Paragraph>

                                        <Form layout="vertical" form={apiForm}>
                                            <Row align="middle" justify="space-around" gutter={12}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="apikey"
                                                        initialValue="••••••••••••••••••••"
                                                        label={<Typography.Text>API Key</Typography.Text>}
                                                    >
                                                        <Input
                                                            data-cy="input_api"
                                                            className={styles.input}
                                                            disabled={!keyShowing}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={4}>
                                                    <div className={styles.actions} onClick={showApiKey}>
                                                        <EyeOutlined /> {keyShowing ? "Hide Key" : "show key"}
                                                    </div>
                                                </Col>
                                                <Col span={8}>
                                                    <Popconfirm
                                                        placement="topLeft"
                                                        title={"Are you sure?"}
                                                        onConfirm={generateApiKey}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <div className={styles.actions}>
                                                            <RedoOutlined /> {t("regenerateAPI")}
                                                        </div>
                                                    </Popconfirm>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card title={<Typography.Title level={5}>{t("appearance")}</Typography.Title>}>
                                        <Typography.Paragraph className={styles.desc}>
                                            {t("customizeLogo")}
                                        </Typography.Paragraph>
                                        <Spacer />
                                        <ImageUpload uploader="admin" entityProps={{ merchant: merchant }} />
                                        <Spacer />
                                        <Typography.Paragraph className={styles.desc2}>
                                            {t("logoExtra")}
                                        </Typography.Paragraph>
                                    </Card>
                                </Col>
                            </Row>
                            <Spacer />
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Card title={<Typography.Title level={5}>Display Name</Typography.Title>}>
                                        <Form layout="vertical" form={nameForm}>
                                            <Row align="middle" justify="space-around" gutter={12}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name="display_name"
                                                        initialValue={merchant.display_name}
                                                        label={<Typography.Text>Display Name</Typography.Text>}
                                                    >
                                                        <Input data-cy="input_api" className={styles.input} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Button
                                                        style={{ marginTop: "8px" }}
                                                        onClick={() => {
                                                            updateMerchantDisplayName();
                                                        }}
                                                    >
                                                        Update
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>

                    <AddMerchantUserModal
                        merchantId={merchantId}
                        visible={addMerchantUserModalOpen}
                        setVisible={setAddMerchantUserModalOpen}
                        onDataChanged={fetchMerchantUsers}
                    />
                </Content>
            </Layout>
        </Layout>
    );
};
