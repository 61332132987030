import { Button, Card, Col, Form, Input, Layout, message, Row, Steps, Typography } from "antd";
import React, { useContext, useState } from "react";
import { ShopContext } from "../storage/shopContext";
import { Link } from "react-router-dom";
import styles from "./MockShop.module.scss";
import { Spacer } from "components/common/presenters/spacer/Spacer";
import { ApiWrapper } from "api/ApiWrapper";
import { v4 as uuidv4 } from "uuid";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";

const { Header, Content, Footer } = Layout;
const { Step } = Steps;
const MockCart = (props) => {
    const [shopState, dispatch] = useContext(ShopContext);
    const [apiKey, setApiKey] = useState("");

    const [userForm] = useForm();

    function getQuery() {
        return new URLSearchParams(props?.location?.search);
    }

    const query = getQuery();

    const resolution = query.get("resolution");

    const renderCartItems = () => {
        return shopState.cartItems.map((cartItem, idx) => {
            // find in stock
            const found = shopState.stock.find((item) => {
                return cartItem.item === item.name;
            });

            return cartItem.quantity ? (
                <React.Fragment key={idx}>
                    <div>
                        <Row>
                            <Col span={2}>
                                <img src={cartItem.img} alt="pic" className={styles.cartItem} />
                            </Col>
                            <Col span={10}>
                                <Typography.Title className={styles.name}>{cartItem.item}</Typography.Title>
                                <Typography.Paragraph className={styles.paragraph}>{found.desc}</Typography.Paragraph>
                            </Col>
                            <Col span={4}>
                                <Typography.Text className={styles.price}>{cartItem.quantity}</Typography.Text>
                            </Col>
                            <Col span={4}>
                                <Typography.Text className={styles.price}>30.00 EUR</Typography.Text>
                            </Col>
                            <Col span={3}>
                                <Typography.Text className={styles.price}>
                                    {cartItem.quantity * found.price + 30} EUR
                                </Typography.Text>
                            </Col>
                            <Col span={1}>
                                <span
                                    onClick={() => {
                                        dispatch({
                                            type: "REMOVE_CART_ITEM",
                                            payload: {
                                                oldItem: cartItem.item,
                                            },
                                        });
                                    }}
                                    className={styles.cancel}
                                >
                                    x
                                </span>
                            </Col>
                        </Row>
                    </div>
                    <Spacer />
                </React.Fragment>
            ) : null;
        });
    };

    const calculateTotal = () => {
        let total = 0;

        shopState.cartItems.forEach((cartItem) => {
            const found = shopState.stock.find((item) => {
                return cartItem.item === item.name;
            });
            if (cartItem.quantity) {
                total += cartItem.quantity * found.price + 30;
            }
        });
        return total;
    };
    const proceedToLiquitree = async () => {
        const { fname, lname, email } = userForm.getFieldsValue();

        try {
            const { data } = await ApiWrapper.postWAuth(
                "/merchant_api/new_session",
                {
                    order_id: uuidv4(),
                    amount: calculateTotal(),
                    currency: "EUR",
                    country_code: "DE",
                    email: email,
                    first_name: fname,
                    last_name: lname,
                    birth_date: "1999-12-31",
                    phone: "+4912345678",
                    mobile_phone: "+4912345679",
                    address_street: "1 Test str",
                    address_house_number: "27",
                    address_post_code: "14109",
                    address_city: "Berlin",
                    address_country_code: "DE",
                    success_url: "https://localhost:3000/mock/cart?resolution=success",
                    failure_url: "https://localhost:3000/mock/cart?resolution=failure",
                    cancel_url: "https://localhost:3000/mock/cart?resolution=cancel",
                    async_url: "https://localhost:3000/mock/cart?resolution=async",
                },
                apiKey
            );
            dispatch({
                type: "CHANGE_SESSION",
                payload: {
                    newSession: data.session_id,
                },
            });
            window.location.href = data.redirect_url;
        } catch (error) {
            void message.error(error);
        }
    };

    const renderSuccess = () => {
        return (
            <Card bordered={false}>
                <div className={styles.container}>
                    <Typography.Title level={2}>Order successfully placed</Typography.Title>
                    <CheckCircleOutlined className={styles.success} />
                    <Spacer />
                    <Typography.Paragraph>
                        Der Händler wird Sie durch den Rest des Antragsprozesses führen. Sie können das Fenster
                        schliessen.
                    </Typography.Paragraph>
                </div>
            </Card>
        );
    };
    const renderFailure = () => {
        return (
            <Card bordered={false}>
                <div className={styles.container}>
                    <Typography.Title level={2}>Application Rejected</Typography.Title>
                    <CloseCircleOutlined className={styles.error} />
                    <Spacer />
                    <Typography.Paragraph>Contact us for further details</Typography.Paragraph>
                </div>
            </Card>
        );
    };
    return (
        <Layout>
            <Header style={{ background: "transparent", borderBottom: "1px solid #9fd7ee" }}>
                <Row justify="space-between" style={{ transform: "translateY(-25px)" }}>
                    <Col span={2}>
                        <span className={styles.title}> Test Shop</span>
                    </Col>
                    <Col span={3}>
                        <Link to="/mock/shop"> &larr; zurück zum shop</Link>
                    </Col>
                </Row>
            </Header>
            <Content className={styles.cartBody}>
                {resolution ? (
                    resolution === "success" ? (
                        renderSuccess()
                    ) : (
                        renderFailure()
                    )
                ) : (
                    <>
                        <Steps size="default" current={2}>
                            <Step title="Ihre Adresse" />
                            <Step title="Zahlungsart & Versandart" />
                            <Step title="Prüfen und Bestellen" />
                        </Steps>

                        <Spacer />
                        <Row justify="space-between">
                            <Col span={6}>
                                <h2 className={styles.h2}> Rechnungs und Lieferadresse</h2>
                                <span>Herr Test User</span>
                                <br />
                                <span>Test</span>
                                <br />
                                <span>1 Test str</span>
                                <br />
                                <span>österreich</span>
                            </Col>

                            <Col span={6}>
                                <h2 className={styles.h2}> Zahlung und Versand</h2>
                                <b>Zahlungsart</b>
                                <br />
                                <span>Sofortüberweisung</span>
                                <br />
                                <b>Versandart: </b>
                                <span>Standard Versand</span>
                            </Col>
                        </Row>
                        <Spacer hx={2} />
                        <Card
                            title={
                                <Row>
                                    <Col span={12}>
                                        <span className={styles.summary}>Artikel</span>
                                    </Col>
                                    <Col span={4}>
                                        <span className={styles.summary}>Anzahl</span>
                                    </Col>
                                    <Col span={4}>
                                        <span className={styles.summary}>Enthaltene MwSt.</span>
                                    </Col>
                                    <Col span={4}>
                                        <span className={styles.summary}>Summe</span>
                                    </Col>
                                </Row>
                            }
                        >
                            {renderCartItems()}
                            <Spacer />
                            <Row justify="end">
                                <Col span={8}>
                                    <h2 className={styles.h2}>Summe: {calculateTotal()} EUR</h2>
                                </Col>
                            </Row>
                        </Card>
                        <Spacer hx={3} />

                        <Form layout="vertical" form={userForm}>
                            <Row align="middle" justify="space-around" gutter={24}>
                                <Col span={12}>
                                    <Form.Item name="fname" label={<Typography.Text>First Name</Typography.Text>}>
                                        <Input data-cy="first_name_mock" className={styles.input} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="lname"
                                        initialValue=""
                                        label={<Typography.Text>Last Name</Typography.Text>}
                                    >
                                        <Input data-cy="last_name_mock" className={styles.input} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item name="email" label={<Typography.Text>Email</Typography.Text>}>
                                        <Input data-cy="email_mock" className={styles.input} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item name="apikey" label={<Typography.Text>API Key</Typography.Text>}>
                                        <Input
                                            value={apiKey}
                                            onChange={(e) => {
                                                setApiKey(e.target.value);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="end">
                                <Button
                                    onClick={() => {
                                        if (calculateTotal() > 0) {
                                            proceedToLiquitree();
                                        }
                                    }}
                                >
                                    Zahlungspflichtig bestellen
                                </Button>
                            </Row>
                        </Form>
                    </>
                )}
            </Content>
            <Footer style={{ textAlign: "center" }}>&copy; fake webshop</Footer>
        </Layout>
    );
};
export default MockCart;
